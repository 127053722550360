import { RETAGUARDA_API_URL } from 'config'

import { queryTenantsControllerObterPorSubdominio } from 'api/retaguarda'

export function getId() {
  return window.sessionStorage.getItem(process.env.REACT_APP_TENANT_ID_KEY)
}

export function setId(tenantId) {
  return window.sessionStorage.setItem(process.env.REACT_APP_TENANT_ID_KEY, tenantId)
}

export function removeId() {
  return window.sessionStorage.removeItem(process.env.REACT_APP_TENANT_ID_KEY)
}

export function getSubdominioLocal() {
  return window.localStorage.getItem(process.env.REACT_APP_TENANT_SUBDOMINIO_KEY)
}

export function getSubdominioWeb() {
  return process.env.NODE_ENV === 'production' ? window.location.host.split('.')[0] : null
}

export function getSubdominio() {
  return process.env.NODE_ENV === 'production' ? getSubdominioWeb() : getSubdominioLocal()
}

export function setSubdominio(subdominio) {
  return window.localStorage.setItem(process.env.REACT_APP_TENANT_SUBDOMINIO_KEY, subdominio)
}

export function removeSubdominio() {
  return window.localStorage.removeItem(process.env.REACT_APP_TENANT_SUBDOMINIO_KEY)
}

export async function access({ subdominio }) {
  return queryTenantsControllerObterPorSubdominio(
    {
      subdominio,
      base: RETAGUARDA_API_URL
    },
    {},
    { timeout: 30000 }
  ).then((data) => {
    setId(data.id)
    setSubdominio(subdominio)

    let theme

    // TODO: remover theme customizado
    if (subdominio === 'wizard') {
      theme = {
        name: 'Wizard by Pearson',
        assets: {
          logo: `https://logodownload.org/wp-content/uploads/2015/02/wizard-logo-escola.png`,
          loginBackgroundImage: `https://www.wizard.com.br/galeria/ebook/2017112813562026.jpg`
        },
        colors: {
          primary: '#003057',
          secondary: '#785afd'
        }
      }
    }

    if (subdominio === 'materdei') {
      theme = {
        name: 'Faculdade Mater Dei',
        assets: {
          logo: `https://i.postimg.cc/nrjRdQkv/materdei.png`,
          loginBackgroundImage: `https://www.diariodosudoeste.com.br/fl/normal/1537363306-5ba24d9166f04_mater_dei.jpg`
        },
        colors: {
          primary: '#0094D9',
          secondary: '#785afd'
        }
      }
    }

    return { ...data, theme }
  })
}

export function remove() {
  removeId()
  removeSubdominio()

  return Promise.resolve(null)
}

export function bootstrap() {
  const subdominio = getSubdominio()

  if (!subdominio) {
    removeId()
    return Promise.resolve(null)
  }

  return access({ subdominio }).catch(remove)
}

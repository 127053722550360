/* eslint-disable */
/* tslint:disable */
import ky from 'ky'
import { useQuery, useMutation } from 'react-query'

let api = ky.create({
  "prefixUrl": "https://api.sponte.com.br",
  "throwHttpErrors": false,
  "retry": {
    "statusCodes": [
      401
    ]
  }
})

export const getApi = () => api

export const setApi = (newApi) => {
  api = newApi
}

export const extendApi = (options) => {
  api = getApi().extend(options)
}

const requestFn = async ({ url, method, pathParams, queryParams, ...rest }) => {
  const urlPathParams = url.match(/{([^}]+)}/g)

  if (urlPathParams) {
    url = urlPathParams.reduce((acc, param) => acc.replace(param, pathParams[param.replace(/{|}/g, '')]), url)
  } else {
    queryParams = pathParams
  }

  if (url.charAt(0) === '/') {
    url = url.replace('/', '')
  }

  const response = await api(url, {
    method,
    ...rest,
    searchParams: {
      ...(rest.searchParams || {}),
      ...queryParams
    }
  })

  let data

  try {
    const contentType = (response.headers.get('content-type') || '').split('; ')[0]

    const responseType =
      {
        'application/json': 'json',
        'application/pdf': 'blob',
        'application/octet-stream': 'blob'
      }[contentType] || 'text'

    data = await response[responseType]()
  } catch (e) {
    data = e.message
  }

  if (!response.ok) {
    const error = {
      data,
      status: response.status,
      message: `Failed to fetch: ${response.status} ${response.statusText}`
    }

    throw error
  }

  return data
}

const queryFn = (options = {}) => (url, pathParams = {}, queryParams = {}) =>
  requestFn({
    url,
    method: 'get',
    pathParams,
    queryParams,
    ...options
  })

const mutationFn = (
  method,
  url,
  pathParams = {},
  queryParams = {},
  options = {}
) => (body = {}) => {
  if (Array.isArray(body)) {
    pathParams = { ...pathParams, ...(body[0] || {}) }
    queryParams = { ...queryParams, ...(body[1] || {}) }
    options = { ...options, ...(body[3] || {}) }
    body = body[2]
  }

  const request = {
    url,
    method,
    pathParams,
    queryParams,
    ...options
  }

  if (method !== 'delete') {
    try {
      request[body.toString() === '[object FormData]' ? 'body' : 'json'] = body
    } catch(e) {
    }
  }

  return requestFn(request)
}

export const queryAcrescimoParcelaLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/acrescimo-parcela-link-pagamento', queryParams)
export const useQueryAcrescimoParcelaLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/acrescimo-parcela-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAcrescimoParcelaLinkPagamentoControllerListar.queryKey = '/api/v1/acrescimo-parcela-link-pagamento'

export const queryAcrescimoParcelaLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/acrescimo-parcela-link-pagamento/{id}', pathParams)
export const useQueryAcrescimoParcelaLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/acrescimo-parcela-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAcrescimoParcelaLinkPagamentoControllerObterPorId.queryKey = '/api/v1/acrescimo-parcela-link-pagamento/{id}'

export const queryAcrescimoParcelaLinkPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/acrescimo-parcela-link-pagamento/{chave}', pathParams)
export const useQueryAcrescimoParcelaLinkPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/acrescimo-parcela-link-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryAcrescimoParcelaLinkPagamentoControllerObterPorChave.queryKey = '/api/v1/acrescimo-parcela-link-pagamento/{chave}'

export const mutationAlergiaControllerCadastrarAlergia = (options) => mutationFn('post', '/api/v1/alergias', {}, {}, options)
export const useMutationAlergiaControllerCadastrarAlergia = (config, options) => useMutation(mutationFn('post', '/api/v1/alergias', {}, {}, options), config)

export const queryAlergiaControllerListarAlergias = (queryParams, options) => queryFn(options)('/api/v1/alergias', queryParams)
export const useQueryAlergiaControllerListarAlergias = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alergias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAlergiaControllerListarAlergias.queryKey = '/api/v1/alergias'

export const mutationAlergiaControllerDeletarAlergia = (pathParams, options) => mutationFn('delete', '/api/v1/alergias/{id}', pathParams, {}, options)
export const useMutationAlergiaControllerDeletarAlergia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/alergias/{id}', pathParams, {}, options), config)

export const mutationAlergiaControllerCadastrarAlergiaConta = (pathParams, options) => mutationFn('post', '/api/v1/alergias/{id}/contas', pathParams, {}, options)
export const useMutationAlergiaControllerCadastrarAlergiaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/alergias/{id}/contas', pathParams, {}, options), config)

export const queryAlergiaControllerListarAlergiaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/alergias/{id}/contas', pathParams, queryParams)
export const useQueryAlergiaControllerListarAlergiaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alergias/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAlergiaControllerListarAlergiaContas.queryKey = '/api/v1/alergias/{id}/contas'

export const queryAlergiaControllerObterAlergiaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/alergias/{id}/contas/{contaId}', pathParams)
export const useQueryAlergiaControllerObterAlergiaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alergias/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryAlergiaControllerObterAlergiaContaPorId.queryKey = '/api/v1/alergias/{id}/contas/{contaId}'

export const mutationAlergiaControllerAtualizarAlergiaConta = (pathParams, options) => mutationFn('put', '/api/v1/alergias/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationAlergiaControllerAtualizarAlergiaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/alergias/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryAlergiaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/alergias/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryAlergiaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alergias/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAlergiaControllerListarContasDisponiveis.queryKey = '/api/v1/alergias/{id}/contas-disponiveis'

export const queryAlunosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/alunos', queryParams)
export const useQueryAlunosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alunos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAlunosControllerBuscar.queryKey = '/api/v1/alunos'

export const mutationAlunosControllerCadastrar = (options) => mutationFn('post', '/api/v1/alunos', {}, {}, options)
export const useMutationAlunosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/alunos', {}, {}, options), config)

export const queryAlunosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/alunos/{id}', pathParams)
export const useQueryAlunosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/alunos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAlunosControllerObterPorId.queryKey = '/api/v1/alunos/{id}'

export const mutationAlunosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/alunos/{id}', pathParams, {}, options)
export const useMutationAlunosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/alunos/{id}', pathParams, {}, options), config)

export const mutationAlunosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/alunos/{id}', pathParams, {}, options)
export const useMutationAlunosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/alunos/{id}', pathParams, {}, options), config)

export const queryAnosFaixasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/anos-faixas', queryParams)
export const useQueryAnosFaixasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/anos-faixas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAnosFaixasControllerBuscar.queryKey = '/api/v1/anos-faixas'

export const mutationAnosFaixasControllerCadastrar = (options) => mutationFn('post', '/api/v1/anos-faixas', {}, {}, options)
export const useMutationAnosFaixasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/anos-faixas', {}, {}, options), config)

export const queryAnosFaixasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/anos-faixas/{id}', pathParams)
export const useQueryAnosFaixasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/anos-faixas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAnosFaixasControllerObterPorId.queryKey = '/api/v1/anos-faixas/{id}'

export const mutationAnosFaixasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/anos-faixas/{id}', pathParams, {}, options)
export const useMutationAnosFaixasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/anos-faixas/{id}', pathParams, {}, options), config)

export const mutationAnosFaixasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/anos-faixas/{id}', pathParams, {}, options)
export const useMutationAnosFaixasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/anos-faixas/{id}', pathParams, {}, options), config)

export const queryAnosFaixasControllerListarTurmas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/anos-faixas/{id}/turmas', pathParams, queryParams)
export const useQueryAnosFaixasControllerListarTurmas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/anos-faixas/{id}/turmas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAnosFaixasControllerListarTurmas.queryKey = '/api/v1/anos-faixas/{id}/turmas'

export const queryAnosFaixasControllerListarAnosFaixasPorAnoLetivo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/anos-faixas/por-ano-letivo/{anoLetivo}', pathParams, queryParams)
export const useQueryAnosFaixasControllerListarAnosFaixasPorAnoLetivo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/anos-faixas/por-ano-letivo/{anoLetivo}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.anoLetivo, ...config }
})
useQueryAnosFaixasControllerListarAnosFaixasPorAnoLetivo.queryKey = '/api/v1/anos-faixas/por-ano-letivo/{anoLetivo}'

export const queryAreasConhecimentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/areas-de-conhecimento', queryParams)
export const useQueryAreasConhecimentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/areas-de-conhecimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAreasConhecimentoControllerBuscar.queryKey = '/api/v1/areas-de-conhecimento'

export const mutationAreasConhecimentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/areas-de-conhecimento', {}, {}, options)
export const useMutationAreasConhecimentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/areas-de-conhecimento', {}, {}, options), config)

export const queryAreasConhecimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/areas-de-conhecimento/{id}', pathParams)
export const useQueryAreasConhecimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/areas-de-conhecimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAreasConhecimentoControllerObterPorId.queryKey = '/api/v1/areas-de-conhecimento/{id}'

export const mutationAreasConhecimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/areas-de-conhecimento/{id}', pathParams, {}, options)
export const useMutationAreasConhecimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/areas-de-conhecimento/{id}', pathParams, {}, options), config)

export const mutationAreasConhecimentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/areas-de-conhecimento/{id}', pathParams, {}, options)
export const useMutationAreasConhecimentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/areas-de-conhecimento/{id}', pathParams, {}, options), config)

export const mutationAreasConhecimentoControllerCadastrarAreaConhecimentoConta = (pathParams, options) => mutationFn('post', '/api/v1/areas-de-conhecimento/{id}/contas', pathParams, {}, options)
export const useMutationAreasConhecimentoControllerCadastrarAreaConhecimentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/areas-de-conhecimento/{id}/contas', pathParams, {}, options), config)

export const queryAreasConhecimentoControllerListarAreaConhecimentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/areas-de-conhecimento/{id}/contas', pathParams, queryParams)
export const useQueryAreasConhecimentoControllerListarAreaConhecimentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/areas-de-conhecimento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAreasConhecimentoControllerListarAreaConhecimentoContas.queryKey = '/api/v1/areas-de-conhecimento/{id}/contas'

export const queryAreasConhecimentoControllerObterAreaConhecimentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/areas-de-conhecimento/{id}/contas/{contaId}', pathParams)
export const useQueryAreasConhecimentoControllerObterAreaConhecimentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/areas-de-conhecimento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryAreasConhecimentoControllerObterAreaConhecimentoContaPorId.queryKey = '/api/v1/areas-de-conhecimento/{id}/contas/{contaId}'

export const mutationAreasConhecimentoControllerAtualizarAreaConhecimentoConta = (pathParams, options) => mutationFn('put', '/api/v1/areas-de-conhecimento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationAreasConhecimentoControllerAtualizarAreaConhecimentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/areas-de-conhecimento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryAreasConhecimentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/areas-de-conhecimento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryAreasConhecimentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/areas-de-conhecimento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAreasConhecimentoControllerListarContasDisponiveis.queryKey = '/api/v1/areas-de-conhecimento/{id}/contas-disponiveis'

export const mutationAreasConhecimentoControllerCadastrarAreaConhecimentoCompetencia = (pathParams, options) => mutationFn('post', '/api/v1/areas-de-conhecimento/{id}/competencias', pathParams, {}, options)
export const useMutationAreasConhecimentoControllerCadastrarAreaConhecimentoCompetencia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/areas-de-conhecimento/{id}/competencias', pathParams, {}, options), config)

export const queryAreasConhecimentoControllerListarCompetenciasPorAreaConhecimentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/areas-de-conhecimento/{id}/competencias', pathParams, queryParams)
export const useQueryAreasConhecimentoControllerListarCompetenciasPorAreaConhecimentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/areas-de-conhecimento/{id}/competencias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAreasConhecimentoControllerListarCompetenciasPorAreaConhecimentoId.queryKey = '/api/v1/areas-de-conhecimento/{id}/competencias'

export const mutationAreasConhecimentoControllerDeletarAreaConhecimentoCompetencia = (pathParams, options) => mutationFn('delete', '/api/v1/areas-de-conhecimento/{id}/competencias/{competenciaId}', pathParams, {}, options)
export const useMutationAreasConhecimentoControllerDeletarAreaConhecimentoCompetencia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/areas-de-conhecimento/{id}/competencias/{competenciaId}', pathParams, {}, options), config)

export const mutationAreasConhecimentoControllerCadastrarAreaConhecimentoCompetenciaEspecifica = (pathParams, options) => mutationFn('post', '/api/v1/areas-de-conhecimento/{id}/competencias-especificas', pathParams, {}, options)
export const useMutationAreasConhecimentoControllerCadastrarAreaConhecimentoCompetenciaEspecifica = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/areas-de-conhecimento/{id}/competencias-especificas', pathParams, {}, options), config)

export const queryAreasConhecimentoControllerListarAreaConhecimentoCompetenciasEspecificasPorAreaConhecimentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/areas-de-conhecimento/{id}/competencias-especificas', pathParams, queryParams)
export const useQueryAreasConhecimentoControllerListarAreaConhecimentoCompetenciasEspecificasPorAreaConhecimentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/areas-de-conhecimento/{id}/competencias-especificas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAreasConhecimentoControllerListarAreaConhecimentoCompetenciasEspecificasPorAreaConhecimentoId.queryKey = '/api/v1/areas-de-conhecimento/{id}/competencias-especificas'

export const queryAreasConhecimentoControllerObterAreaConhecimentoCompetenciaEspecificaPorId = (pathParams, options) => queryFn(options)('/api/v1/areas-de-conhecimento/{id}/competencias-especificas/{competenciaEspecificaId}', pathParams)
export const useQueryAreasConhecimentoControllerObterAreaConhecimentoCompetenciaEspecificaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/areas-de-conhecimento/{id}/competencias-especificas/{competenciaEspecificaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.competenciaEspecificaId, ...config }
})
useQueryAreasConhecimentoControllerObterAreaConhecimentoCompetenciaEspecificaPorId.queryKey = '/api/v1/areas-de-conhecimento/{id}/competencias-especificas/{competenciaEspecificaId}'

export const mutationAreasConhecimentoControllerDeletarAreaConhecimentoCompetenciaEspecifica = (pathParams, options) => mutationFn('delete', '/api/v1/areas-de-conhecimento/{id}/competencias-especificas/{competenciaEspecificaId}', pathParams, {}, options)
export const useMutationAreasConhecimentoControllerDeletarAreaConhecimentoCompetenciaEspecifica = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/areas-de-conhecimento/{id}/competencias-especificas/{competenciaEspecificaId}', pathParams, {}, options), config)

export const queryArquivosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/arquivos/{id}', pathParams)
export const useQueryArquivosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryArquivosControllerObterPorId.queryKey = '/api/v1/arquivos/{id}'

export const mutationArquivosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/arquivos/{id}', pathParams, {}, options)
export const useMutationArquivosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/arquivos/{id}', pathParams, {}, options), config)

export const mutationArquivosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/arquivos/{id}', pathParams, {}, options)
export const useMutationArquivosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/arquivos/{id}', pathParams, {}, options), config)

export const queryArquivosControllerDownload = (pathParams, options) => queryFn(options)('/api/v1/arquivos/{id}/download', pathParams)
export const useQueryArquivosControllerDownload = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/{id}/download', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryArquivosControllerDownload.queryKey = '/api/v1/arquivos/{id}/download'

export const queryArquivosControllerDownloadPorChave = (pathParams, options) => queryFn(options)('/api/v1/arquivos/{chave}/download', pathParams)
export const useQueryArquivosControllerDownloadPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/{chave}/download', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryArquivosControllerDownloadPorChave.queryKey = '/api/v1/arquivos/{chave}/download'

export const queryArquivosControllerObterUrlArquivoPadrao = (queryParams, options) => queryFn(options)('/api/v1/arquivos/defaults/url', queryParams)
export const useQueryArquivosControllerObterUrlArquivoPadrao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/defaults/url', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryArquivosControllerObterUrlArquivoPadrao.queryKey = '/api/v1/arquivos/defaults/url'

export const queryArquivosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/arquivos/tipo-arquivo', queryParams)
export const useQueryArquivosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arquivos/tipo-arquivo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryArquivosControllerBuscar.queryKey = '/api/v1/arquivos/tipo-arquivo'

export const queryArredondamentosNotaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/arredondamentos-de-notas/{id}', pathParams)
export const useQueryArredondamentosNotaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/arredondamentos-de-notas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryArredondamentosNotaControllerObterPorId.queryKey = '/api/v1/arredondamentos-de-notas/{id}'

export const mutationArredondamentosNotaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/arredondamentos-de-notas/{id}', pathParams, {}, options)
export const useMutationArredondamentosNotaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/arredondamentos-de-notas/{id}', pathParams, {}, options), config)

export const mutationArredondamentosNotaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/arredondamentos-de-notas/{id}', pathParams, {}, options)
export const useMutationArredondamentosNotaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/arredondamentos-de-notas/{id}', pathParams, {}, options), config)

export const queryAssinaturasEletronicasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas', queryParams)
export const useQueryAssinaturasEletronicasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAssinaturasEletronicasControllerListar.queryKey = '/api/v1/assinaturas-eletronicas'

export const mutationAssinaturasEletronicasControllerCadastrar = (options) => mutationFn('post', '/api/v1/assinaturas-eletronicas/cadastrar', {}, {}, options)
export const useMutationAssinaturasEletronicasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas-eletronicas/cadastrar', {}, {}, options), config)

export const mutationAssinaturasEletronicasControllerInicializar = (options) => mutationFn('post', '/api/v1/assinaturas-eletronicas/inicializar', {}, {}, options)
export const useMutationAssinaturasEletronicasControllerInicializar = (config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas-eletronicas/inicializar', {}, {}, options), config)

export const queryAssinaturasEletronicasControllerBuscarConfiguracaoPorDocumentoId = (pathParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}', pathParams)
export const useQueryAssinaturasEletronicasControllerBuscarConfiguracaoPorDocumentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tipoDocumentoAssinatura, ...config }
})
useQueryAssinaturasEletronicasControllerBuscarConfiguracaoPorDocumentoId.queryKey = '/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}'

export const queryAssinaturasEletronicasControllerBuscarConfiguracaoAssinantesPorDocumentoId = (pathParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes', pathParams)
export const useQueryAssinaturasEletronicasControllerBuscarConfiguracaoAssinantesPorDocumentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tipoDocumentoAssinatura, ...config }
})
useQueryAssinaturasEletronicasControllerBuscarConfiguracaoAssinantesPorDocumentoId.queryKey = '/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes'

export const mutationAssinaturasEletronicasControllerCadastrarAssinanteParaAssinaturaEletronica = (pathParams, options) => mutationFn('post', '/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes', pathParams, {}, options)
export const useMutationAssinaturasEletronicasControllerCadastrarAssinanteParaAssinaturaEletronica = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes', pathParams, {}, options), config)

export const queryAssinaturasEletronicasControllerBuscarConfiguracaoAssinantesETipoSignatarioPorDocumentoId = (pathParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes/{tipoSignatario}', pathParams)
export const useQueryAssinaturasEletronicasControllerBuscarConfiguracaoAssinantesETipoSignatarioPorDocumentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes/{tipoSignatario}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tipoDocumentoAssinatura && pathParams.tipoSignatario, ...config }
})
useQueryAssinaturasEletronicasControllerBuscarConfiguracaoAssinantesETipoSignatarioPorDocumentoId.queryKey = '/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes/{tipoSignatario}'

export const mutationAssinaturasEletronicasControllerDeletarAssinanteDeConfiguracaoEletronicaETipoSignatario = (pathParams, options) => mutationFn('delete', '/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes/{idAssinante}', pathParams, {}, options)
export const useMutationAssinaturasEletronicasControllerDeletarAssinanteDeConfiguracaoEletronicaETipoSignatario = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/assinaturas-eletronicas/configuracao/{id}/{tipoDocumentoAssinatura}/assinantes/{idAssinante}', pathParams, {}, options), config)

export const queryAssinaturasEletronicasControllerObterIndicadoresAssinaturaEletronica = (queryParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas/indicadores', queryParams)
export const useQueryAssinaturasEletronicasControllerObterIndicadoresAssinaturaEletronica = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAssinaturasEletronicasControllerObterIndicadoresAssinaturaEletronica.queryKey = '/api/v1/assinaturas-eletronicas/indicadores'

export const mutationAssinaturasEletronicasControllerCancelar = (options) => mutationFn('patch', '/api/v1/assinaturas-eletronicas/cancelar', {}, {}, options)
export const useMutationAssinaturasEletronicasControllerCancelar = (config, options) => useMutation(mutationFn('patch', '/api/v1/assinaturas-eletronicas/cancelar', {}, {}, options), config)

export const queryAssinaturasEletronicasControllerListarMatriculaContrato = (pathParams, queryParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas/{id}/matriculas-contratos', pathParams, queryParams)
export const useQueryAssinaturasEletronicasControllerListarMatriculaContrato = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas/{id}/matriculas-contratos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAssinaturasEletronicasControllerListarMatriculaContrato.queryKey = '/api/v1/assinaturas-eletronicas/{id}/matriculas-contratos'

export const queryAssinaturasEletronicasControllerBuscarDetalhesDaAssinatura = (pathParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas/{id}/{tipoDocumentoAssinatura}/detalhes', pathParams)
export const useQueryAssinaturasEletronicasControllerBuscarDetalhesDaAssinatura = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas/{id}/{tipoDocumentoAssinatura}/detalhes', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tipoDocumentoAssinatura, ...config }
})
useQueryAssinaturasEletronicasControllerBuscarDetalhesDaAssinatura.queryKey = '/api/v1/assinaturas-eletronicas/{id}/{tipoDocumentoAssinatura}/detalhes'

export const mutationAssinaturasEletronicasControllerAssinar = (options) => mutationFn('patch', '/api/v1/assinaturas-eletronicas/assinar', {}, {}, options)
export const useMutationAssinaturasEletronicasControllerAssinar = (config, options) => useMutation(mutationFn('patch', '/api/v1/assinaturas-eletronicas/assinar', {}, {}, options), config)

export const mutationAssinaturasEletronicasControllerReenviar = (pathParams, options) => mutationFn('patch', '/api/v1/assinaturas-eletronicas/{id}/reenviar', pathParams, {}, options)
export const useMutationAssinaturasEletronicasControllerReenviar = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/assinaturas-eletronicas/{id}/reenviar', pathParams, {}, options), config)

export const mutationAssinaturasEletronicasConfiguracoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/assinaturas-eletronicas-configuracoes', {}, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas-eletronicas-configuracoes', {}, {}, options), config)

export const queryAssinaturasEletronicasConfiguracoesControllerObterConfiguracaoGeral = (options) => queryFn(options)('/api/v1/assinaturas-eletronicas-configuracoes')
export const useQueryAssinaturasEletronicasConfiguracoesControllerObterConfiguracaoGeral = (config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas-configuracoes'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryAssinaturasEletronicasConfiguracoesControllerObterConfiguracaoGeral.queryKey = '/api/v1/assinaturas-eletronicas-configuracoes'

export const mutationAssinaturasEletronicasConfiguracoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/assinaturas-eletronicas-configuracoes/{id}', pathParams, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/assinaturas-eletronicas-configuracoes/{id}', pathParams, {}, options), config)

export const queryAssinaturasEletronicasConfiguracoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas-configuracoes/{id}', pathParams)
export const useQueryAssinaturasEletronicasConfiguracoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas-configuracoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAssinaturasEletronicasConfiguracoesControllerObterPorId.queryKey = '/api/v1/assinaturas-eletronicas-configuracoes/{id}'

export const mutationAssinaturasEletronicasConfiguracoesControllerCadastrarAssinante = (options) => mutationFn('post', '/api/v1/assinaturas-eletronicas-configuracoes/assinante', {}, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerCadastrarAssinante = (config, options) => useMutation(mutationFn('post', '/api/v1/assinaturas-eletronicas-configuracoes/assinante', {}, {}, options), config)

export const queryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}', pathParams, queryParams)
export const useQueryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoId.queryKey = '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}'

export const mutationAssinaturasEletronicasConfiguracoesControllerDeletarAssinante = (pathParams, options) => mutationFn('delete', '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}', pathParams, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerDeletarAssinante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}', pathParams, {}, options), config)

export const queryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoIdETipoSignatario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/{tipoSignatario}', pathParams, queryParams)
export const useQueryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoIdETipoSignatario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/{tipoSignatario}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tipoSignatario, ...config }
})
useQueryAssinaturasEletronicasConfiguracoesControllerObterAssinantesPorConfiguracaoIdETipoSignatario.queryKey = '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/{tipoSignatario}'

export const mutationAssinaturasEletronicasConfiguracoesControllerAtribuirRsaPrivateKeyAssinante = (pathParams, options) => mutationFn('patch', '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/rsakey', pathParams, {}, options)
export const useMutationAssinaturasEletronicasConfiguracoesControllerAtribuirRsaPrivateKeyAssinante = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/assinaturas-eletronicas-configuracoes/assinantes/{id}/rsakey', pathParams, {}, options), config)

export const mutationAtividadesPraticasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/atividades-praticas/{id}', pathParams, {}, options)
export const useMutationAtividadesPraticasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/atividades-praticas/{id}', pathParams, {}, options), config)

export const queryAtividadesPraticasControllerObterAtividadePratica = (pathParams, options) => queryFn(options)('/api/v1/atividades-praticas/{id}', pathParams)
export const useQueryAtividadesPraticasControllerObterAtividadePratica = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/atividades-praticas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAtividadesPraticasControllerObterAtividadePratica.queryKey = '/api/v1/atividades-praticas/{id}'

export const queryAutorizacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/autorizacoes/{id}', pathParams)
export const useQueryAutorizacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/autorizacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAutorizacoesControllerObterPorId.queryKey = '/api/v1/autorizacoes/{id}'

export const mutationAutorizacoesControllerAtualizarAutorizacao = (pathParams, options) => mutationFn('put', '/api/v1/autorizacoes/{id}', pathParams, {}, options)
export const useMutationAutorizacoesControllerAtualizarAutorizacao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/autorizacoes/{id}', pathParams, {}, options), config)

export const mutationAutorizacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/autorizacoes/{id}', pathParams, {}, options)
export const useMutationAutorizacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/autorizacoes/{id}', pathParams, {}, options), config)

export const queryAutorizacoesControllerBuscarAutorizacoesAnosFaixa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/autorizacoes/{id}/anos-faixa', pathParams, queryParams)
export const useQueryAutorizacoesControllerBuscarAutorizacoesAnosFaixa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/autorizacoes/{id}/anos-faixa', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAutorizacoesControllerBuscarAutorizacoesAnosFaixa.queryKey = '/api/v1/autorizacoes/{id}/anos-faixa'

export const mutationAutorizacoesControllerCadastrarAutorizacaoAnoFaixa = (pathParams, options) => mutationFn('post', '/api/v1/autorizacoes/{id}/anos-faixa', pathParams, {}, options)
export const useMutationAutorizacoesControllerCadastrarAutorizacaoAnoFaixa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/autorizacoes/{id}/anos-faixa', pathParams, {}, options), config)

export const queryAutorizacoesAnosFaixaControllerObterPorIdQuery = (pathParams, options) => queryFn(options)('/api/v1/autorizacoes-anos-faixa/{id}', pathParams)
export const useQueryAutorizacoesAnosFaixaControllerObterPorIdQuery = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/autorizacoes-anos-faixa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAutorizacoesAnosFaixaControllerObterPorIdQuery.queryKey = '/api/v1/autorizacoes-anos-faixa/{id}'

export const mutationAutorizacoesAnosFaixaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/autorizacoes-anos-faixa/{id}', pathParams, {}, options)
export const useMutationAutorizacoesAnosFaixaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/autorizacoes-anos-faixa/{id}', pathParams, {}, options), config)

export const mutationAvaliacaoHabilidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/avaliacao-tipo-avaliacoes-habilidades/{id}', pathParams, {}, options)
export const useMutationAvaliacaoHabilidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/avaliacao-tipo-avaliacoes-habilidades/{id}', pathParams, {}, options), config)

export const queryAvaliacaoHabilidadesControllerObterTipoAvaliacaoHabilidade = (pathParams, options) => queryFn(options)('/api/v1/avaliacao-tipo-avaliacoes-habilidades/{id}', pathParams)
export const useQueryAvaliacaoHabilidadesControllerObterTipoAvaliacaoHabilidade = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/avaliacao-tipo-avaliacoes-habilidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAvaliacaoHabilidadesControllerObterTipoAvaliacaoHabilidade.queryKey = '/api/v1/avaliacao-tipo-avaliacoes-habilidades/{id}'

export const mutationAvaliacaoPeriodosAvaliativosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/avaliacao-periodos-avaliativos/{id}', pathParams, {}, options)
export const useMutationAvaliacaoPeriodosAvaliativosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/avaliacao-periodos-avaliativos/{id}', pathParams, {}, options), config)

export const queryAvaliacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/avaliacoes/{id}', pathParams)
export const useQueryAvaliacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/avaliacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAvaliacoesControllerObterPorId.queryKey = '/api/v1/avaliacoes/{id}'

export const mutationAvaliacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/avaliacoes/{id}', pathParams, {}, options)
export const useMutationAvaliacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/avaliacoes/{id}', pathParams, {}, options), config)

export const mutationAvaliacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/avaliacoes/{id}', pathParams, {}, options)
export const useMutationAvaliacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/avaliacoes/{id}', pathParams, {}, options), config)

export const queryAvaliacoesControllerListarAvaliacaoHabilidades = (pathParams, queryParams, options) => queryFn(options)('/api/v1/avaliacoes/{id}/habilidades', pathParams, queryParams)
export const useQueryAvaliacoesControllerListarAvaliacaoHabilidades = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/avaliacoes/{id}/habilidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAvaliacoesControllerListarAvaliacaoHabilidades.queryKey = '/api/v1/avaliacoes/{id}/habilidades'

export const mutationAvaliacoesTipoAvaliacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/avaliacoes-tipo-avaliacoes/{id}', pathParams, {}, options)
export const useMutationAvaliacoesTipoAvaliacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/avaliacoes-tipo-avaliacoes/{id}', pathParams, {}, options), config)

export const queryAvaliacoesTipoAvaliacoesControllerListarTipoAvaliacaoHabilidade = (pathParams, queryParams, options) => queryFn(options)('/api/v1/avaliacoes-tipo-avaliacoes/{id}/tipo-avaliacao-habilidades', pathParams, queryParams)
export const useQueryAvaliacoesTipoAvaliacoesControllerListarTipoAvaliacaoHabilidade = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/avaliacoes-tipo-avaliacoes/{id}/tipo-avaliacao-habilidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAvaliacoesTipoAvaliacoesControllerListarTipoAvaliacaoHabilidade.queryKey = '/api/v1/avaliacoes-tipo-avaliacoes/{id}/tipo-avaliacao-habilidades'

export const mutationAvatarsControllerPost = (options) => mutationFn('post', '/api/v1/avatars', {}, {}, options)
export const useMutationAvatarsControllerPost = (config, options) => useMutation(mutationFn('post', '/api/v1/avatars', {}, {}, options), config)

export const queryAvatarsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/avatars/{id}', pathParams)
export const useQueryAvatarsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/avatars/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryAvatarsControllerObterPorId.queryKey = '/api/v1/avatars/{id}'

export const queryBaixasTituloControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/baixas-titulo/{id}', pathParams)
export const useQueryBaixasTituloControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBaixasTituloControllerObterPorId.queryKey = '/api/v1/baixas-titulo/{id}'

export const mutationBaixasTituloControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/baixas-titulo/{id}', pathParams, {}, options)
export const useMutationBaixasTituloControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/baixas-titulo/{id}', pathParams, {}, options), config)

export const queryBaixasTituloControllerListarBaixaTitulosCheques = (pathParams, queryParams, options) => queryFn(options)('/api/v1/baixas-titulo/{id}/cheques', pathParams, queryParams)
export const useQueryBaixasTituloControllerListarBaixaTitulosCheques = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo/{id}/cheques', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBaixasTituloControllerListarBaixaTitulosCheques.queryKey = '/api/v1/baixas-titulo/{id}/cheques'

export const queryBaixasTituloCartaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/baixas-titulo-cartoes', queryParams)
export const useQueryBaixasTituloCartaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo-cartoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBaixasTituloCartaoControllerListar.queryKey = '/api/v1/baixas-titulo-cartoes'

export const queryBaixasTituloCartaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/baixas-titulo-cartoes/{id}', pathParams)
export const useQueryBaixasTituloCartaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo-cartoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBaixasTituloCartaoControllerObterPorId.queryKey = '/api/v1/baixas-titulo-cartoes/{id}'

export const mutationBaixasTituloCartaoControllerDeletarBaixaTituloCartao = (pathParams, options) => mutationFn('delete', '/api/v1/baixas-titulo-cartoes/{id}', pathParams, {}, options)
export const useMutationBaixasTituloCartaoControllerDeletarBaixaTituloCartao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/baixas-titulo-cartoes/{id}', pathParams, {}, options), config)

export const mutationBaixasTituloCartaoControllerConciliar = (pathParams, options) => mutationFn('post', '/api/v1/baixas-titulo-cartoes/{id}/concilia', pathParams, {}, options)
export const useMutationBaixasTituloCartaoControllerConciliar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/baixas-titulo-cartoes/{id}/concilia', pathParams, {}, options), config)

export const mutationBaixasTituloCartaoControllerEstonar = (pathParams, options) => mutationFn('post', '/api/v1/baixas-titulo-cartoes/{id}/estono', pathParams, {}, options)
export const useMutationBaixasTituloCartaoControllerEstonar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/baixas-titulo-cartoes/{id}/estono', pathParams, {}, options), config)

export const queryBaixasTituloChequeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/baixas-titulo-cheques/{id}', pathParams)
export const useQueryBaixasTituloChequeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/baixas-titulo-cheques/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBaixasTituloChequeControllerObterPorId.queryKey = '/api/v1/baixas-titulo-cheques/{id}'

export const mutationBaixasTituloChequeControllerDeletarBaixaTituloCheque = (pathParams, options) => mutationFn('delete', '/api/v1/baixas-titulo-cheques/{id}', pathParams, {}, options)
export const useMutationBaixasTituloChequeControllerDeletarBaixaTituloCheque = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/baixas-titulo-cheques/{id}', pathParams, {}, options), config)

export const mutationBandeirasControllerCadastrar = (options) => mutationFn('post', '/api/v1/bandeiras', {}, {}, options)
export const useMutationBandeirasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/bandeiras', {}, {}, options), config)

export const queryBandeirasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/bandeiras', queryParams)
export const useQueryBandeirasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBandeirasControllerListar.queryKey = '/api/v1/bandeiras'

export const queryBandeirasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/bandeiras/{id}', pathParams)
export const useQueryBandeirasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBandeirasControllerObterPorId.queryKey = '/api/v1/bandeiras/{id}'

export const mutationBandeirasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/bandeiras/{id}', pathParams, {}, options)
export const useMutationBandeirasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/bandeiras/{id}', pathParams, {}, options), config)

export const mutationBandeirasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/bandeiras/{id}', pathParams, {}, options)
export const useMutationBandeirasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/bandeiras/{id}', pathParams, {}, options), config)

export const mutationBandeirasControllerCadastrarBandeiraConta = (pathParams, options) => mutationFn('post', '/api/v1/bandeiras/{id}/contas', pathParams, {}, options)
export const useMutationBandeirasControllerCadastrarBandeiraConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/bandeiras/{id}/contas', pathParams, {}, options), config)

export const queryBandeirasControllerListarBandeiraContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/bandeiras/{id}/contas', pathParams, queryParams)
export const useQueryBandeirasControllerListarBandeiraContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBandeirasControllerListarBandeiraContas.queryKey = '/api/v1/bandeiras/{id}/contas'

export const queryBandeirasControllerObterBandeiraContaPorId = (pathParams, options) => queryFn(options)('/api/v1/bandeiras/{id}/contas/{contaId}', pathParams)
export const useQueryBandeirasControllerObterBandeiraContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryBandeirasControllerObterBandeiraContaPorId.queryKey = '/api/v1/bandeiras/{id}/contas/{contaId}'

export const mutationBandeirasControllerAtualizarBandeiraConta = (pathParams, options) => mutationFn('put', '/api/v1/bandeiras/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationBandeirasControllerAtualizarBandeiraConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/bandeiras/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryBandeirasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/bandeiras/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryBandeirasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bandeiras/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBandeirasControllerListarContasDisponiveis.queryKey = '/api/v1/bandeiras/{id}/contas-disponiveis'

export const queryBannersControllerListar = (queryParams, options) => queryFn(options)('/api/v1/banners', queryParams)
export const useQueryBannersControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/banners', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBannersControllerListar.queryKey = '/api/v1/banners'

export const mutationBannersControllerCadastrar = (options) => mutationFn('post', '/api/v1/banners', {}, {}, options)
export const useMutationBannersControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/banners', {}, {}, options), config)

export const queryBannersControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/banners/{id}', pathParams)
export const useQueryBannersControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/banners/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBannersControllerObterPorId.queryKey = '/api/v1/banners/{id}'

export const mutationBannersControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/banners/{id}', pathParams, {}, options)
export const useMutationBannersControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/banners/{id}', pathParams, {}, options), config)

export const mutationBannersControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/banners/{id}', pathParams, {}, options)
export const useMutationBannersControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/banners/{id}', pathParams, {}, options), config)

export const mutationBannersControllerCadastrarBannerConta = (pathParams, options) => mutationFn('post', '/api/v1/banners/{id}/contas', pathParams, {}, options)
export const useMutationBannersControllerCadastrarBannerConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/banners/{id}/contas', pathParams, {}, options), config)

export const queryBannersControllerListarBannerContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/banners/{id}/contas', pathParams, queryParams)
export const useQueryBannersControllerListarBannerContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/banners/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBannersControllerListarBannerContas.queryKey = '/api/v1/banners/{id}/contas'

export const queryBannersControllerObterBannerContaPorId = (pathParams, options) => queryFn(options)('/api/v1/banners/{id}/contas/{contaId}', pathParams)
export const useQueryBannersControllerObterBannerContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/banners/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryBannersControllerObterBannerContaPorId.queryKey = '/api/v1/banners/{id}/contas/{contaId}'

export const mutationBannersControllerAtualizarBannerConta = (pathParams, options) => mutationFn('put', '/api/v1/banners/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationBannersControllerAtualizarBannerConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/banners/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryBannersControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/banners/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryBannersControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/banners/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBannersControllerListarContasDisponiveis.queryKey = '/api/v1/banners/{id}/contas-disponiveis'

export const mutationBannersControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/banners/{id}/arquivo', pathParams, {}, options)
export const useMutationBannersControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/banners/{id}/arquivo', pathParams, {}, options), config)

export const mutationBannersControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/banners/{id}/arquivo/{arquivoId}', pathParams, {}, options)
export const useMutationBannersControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/banners/{id}/arquivo/{arquivoId}', pathParams, {}, options), config)

export const queryBannersPeriodoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/banners-periodo/{id}', pathParams)
export const useQueryBannersPeriodoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/banners-periodo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBannersPeriodoControllerObterPorId.queryKey = '/api/v1/banners-periodo/{id}'

export const mutationBibliografiasBasicasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/bibliografias-basicas/{id}', pathParams, {}, options)
export const useMutationBibliografiasBasicasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/bibliografias-basicas/{id}', pathParams, {}, options), config)

export const queryBibliografiasBasicasControllerObterBibliografiaBasica = (pathParams, options) => queryFn(options)('/api/v1/bibliografias-basicas/{id}', pathParams)
export const useQueryBibliografiasBasicasControllerObterBibliografiaBasica = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bibliografias-basicas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBibliografiasBasicasControllerObterBibliografiaBasica.queryKey = '/api/v1/bibliografias-basicas/{id}'

export const mutationBibliografiasComplementaresControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/bibliografias-complementares/{id}', pathParams, {}, options)
export const useMutationBibliografiasComplementaresControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/bibliografias-complementares/{id}', pathParams, {}, options), config)

export const queryBibliografiasComplementaresControllerObterBibliografiaComplementar = (pathParams, options) => queryFn(options)('/api/v1/bibliografias-complementares/{id}', pathParams)
export const useQueryBibliografiasComplementaresControllerObterBibliografiaComplementar = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/bibliografias-complementares/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryBibliografiasComplementaresControllerObterBibliografiaComplementar.queryKey = '/api/v1/bibliografias-complementares/{id}'

export const queryBoletoConfiguracaoControllerBuscar = (options) => queryFn(options)('/api/v1/boleto-configuracao')
export const useQueryBoletoConfiguracaoControllerBuscar = (config, options) => useQuery({
  queryKey: ['/api/v1/boleto-configuracao'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBoletoConfiguracaoControllerBuscar.queryKey = '/api/v1/boleto-configuracao'

export const mutationBoletoConfiguracaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/boleto-configuracao/{id}', pathParams, {}, options)
export const useMutationBoletoConfiguracaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/boleto-configuracao/{id}', pathParams, {}, options), config)

export const queryBoletoConfiguracaoControllerBuscarTemplatesSms = (queryParams, options) => queryFn(options)('/api/v1/boleto-configuracao/lista-templates-sms', queryParams)
export const useQueryBoletoConfiguracaoControllerBuscarTemplatesSms = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/boleto-configuracao/lista-templates-sms', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBoletoConfiguracaoControllerBuscarTemplatesSms.queryKey = '/api/v1/boleto-configuracao/lista-templates-sms'

export const queryBoletoConfiguracaoControllerBuscarTemplatesEmail = (queryParams, options) => queryFn(options)('/api/v1/boleto-configuracao/lista-templates-email', queryParams)
export const useQueryBoletoConfiguracaoControllerBuscarTemplatesEmail = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/boleto-configuracao/lista-templates-email', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryBoletoConfiguracaoControllerBuscarTemplatesEmail.queryKey = '/api/v1/boleto-configuracao/lista-templates-email'

export const mutationCaixasControllerCadastrar = (options) => mutationFn('post', '/api/v1/caixas', {}, {}, options)
export const useMutationCaixasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas', {}, {}, options), config)

export const queryCaixasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/caixas', queryParams)
export const useQueryCaixasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasControllerListar.queryKey = '/api/v1/caixas'

export const mutationCaixasControllerFechar = (pathParams, options) => mutationFn('post', '/api/v1/caixas/{id}/fechamento', pathParams, {}, options)
export const useMutationCaixasControllerFechar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/caixas/{id}/fechamento', pathParams, {}, options), config)

export const mutationCaixasControllerSangrar = (pathParams, options) => mutationFn('post', '/api/v1/caixas/{id}/sangria', pathParams, {}, options)
export const useMutationCaixasControllerSangrar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/caixas/{id}/sangria', pathParams, {}, options), config)

export const mutationCaixasControllerSuprir = (pathParams, options) => mutationFn('post', '/api/v1/caixas/{id}/suprimento', pathParams, {}, options)
export const useMutationCaixasControllerSuprir = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/caixas/{id}/suprimento', pathParams, {}, options), config)

export const mutationCaixasControllerConsolidar = (pathParams, options) => mutationFn('post', '/api/v1/caixas/{id}/consolidar', pathParams, {}, options)
export const useMutationCaixasControllerConsolidar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/caixas/{id}/consolidar', pathParams, {}, options), config)

export const mutationCaixasControllerDesfazerConsolidacao = (pathParams, options) => mutationFn('put', '/api/v1/caixas/{id}/desfazer-consolidacao', pathParams, {}, options)
export const useMutationCaixasControllerDesfazerConsolidacao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/caixas/{id}/desfazer-consolidacao', pathParams, {}, options), config)

export const queryCaixasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/caixas/{id}', pathParams)
export const useQueryCaixasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasControllerObterPorId.queryKey = '/api/v1/caixas/{id}'

export const queryCaixasControllerListarSaldoPorFormaDePagamento = (pathParams, options) => queryFn(options)('/api/v1/caixas/{id}/saldo-por-forma-de-pagamento', pathParams)
export const useQueryCaixasControllerListarSaldoPorFormaDePagamento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/{id}/saldo-por-forma-de-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasControllerListarSaldoPorFormaDePagamento.queryKey = '/api/v1/caixas/{id}/saldo-por-forma-de-pagamento'

export const queryCaixasControllerListarSaldoPorFormaDePagamentoParaConsolidacao = (pathParams, options) => queryFn(options)('/api/v1/caixas/{id}/saldo-por-forma-de-pagamento-para-consolidacao', pathParams)
export const useQueryCaixasControllerListarSaldoPorFormaDePagamentoParaConsolidacao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/{id}/saldo-por-forma-de-pagamento-para-consolidacao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasControllerListarSaldoPorFormaDePagamentoParaConsolidacao.queryKey = '/api/v1/caixas/{id}/saldo-por-forma-de-pagamento-para-consolidacao'

export const queryCaixasControllerListarMovimentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/caixas/{id}/movimentos-caixa', pathParams, queryParams)
export const useQueryCaixasControllerListarMovimentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/{id}/movimentos-caixa', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasControllerListarMovimentos.queryKey = '/api/v1/caixas/{id}/movimentos-caixa'

export const queryCaixasControllerListarCaixasPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/caixas/listar-caixas-por-periodo', queryParams)
export const useQueryCaixasControllerListarCaixasPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/listar-caixas-por-periodo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasControllerListarCaixasPorPeriodo.queryKey = '/api/v1/caixas/listar-caixas-por-periodo'

export const queryCaixasControllerListarMovimentosCaixaExtrato = (queryParams, options) => queryFn(options)('/api/v1/caixas/extrato', queryParams)
export const useQueryCaixasControllerListarMovimentosCaixaExtrato = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/extrato', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasControllerListarMovimentosCaixaExtrato.queryKey = '/api/v1/caixas/extrato'

export const queryCaixasControllerListarTitulosMovimentosCaixasPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/caixas/listar-titulos-movimentos-por-periodo', queryParams)
export const useQueryCaixasControllerListarTitulosMovimentosCaixasPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas/listar-titulos-movimentos-por-periodo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasControllerListarTitulosMovimentosCaixasPorPeriodo.queryKey = '/api/v1/caixas/listar-titulos-movimentos-por-periodo'

export const mutationCaixasGeralControllerConsolidar = (options) => mutationFn('post', '/api/v1/caixas-geral', {}, {}, options)
export const useMutationCaixasGeralControllerConsolidar = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral', {}, {}, options), config)

export const queryCaixasGeralControllerListar = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral', queryParams)
export const useQueryCaixasGeralControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListar.queryKey = '/api/v1/caixas-geral'

export const queryCaixasGeralControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/caixas-geral/{id}', pathParams)
export const useQueryCaixasGeralControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCaixasGeralControllerObterPorId.queryKey = '/api/v1/caixas-geral/{id}'

export const queryCaixasGeralControllerObterCaixaGeralPorPerido = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/indicadores', queryParams)
export const useQueryCaixasGeralControllerObterCaixaGeralPorPerido = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerObterCaixaGeralPorPerido.queryKey = '/api/v1/caixas-geral/indicadores'

export const queryCaixasGeralControllerObterIndicadorContasReceber = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/indicadores-de-contas-a-receber', queryParams)
export const useQueryCaixasGeralControllerObterIndicadorContasReceber = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/indicadores-de-contas-a-receber', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerObterIndicadorContasReceber.queryKey = '/api/v1/caixas-geral/indicadores-de-contas-a-receber'

export const queryCaixasGeralControllerListarCaixasConsolidacao = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/caixas', queryParams)
export const useQueryCaixasGeralControllerListarCaixasConsolidacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/caixas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarCaixasConsolidacao.queryKey = '/api/v1/caixas-geral/caixas'

export const queryCaixasGeralControllerListarSaldoCaixasConsolidacao = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/saldo-caixas-consolidacao', queryParams)
export const useQueryCaixasGeralControllerListarSaldoCaixasConsolidacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/saldo-caixas-consolidacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarSaldoCaixasConsolidacao.queryKey = '/api/v1/caixas-geral/saldo-caixas-consolidacao'

export const mutationCaixasGeralControllerConsolidarCaixas = (options) => mutationFn('post', '/api/v1/caixas-geral/consolidar/caixas', {}, {}, options)
export const useMutationCaixasGeralControllerConsolidarCaixas = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral/consolidar/caixas', {}, {}, options), config)

export const mutationCaixasGeralControllerDesfazerConsolidacaoCaixas = (options) => mutationFn('post', '/api/v1/caixas-geral/desfazer-consolidacao/caixas', {}, {}, options)
export const useMutationCaixasGeralControllerDesfazerConsolidacaoCaixas = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral/desfazer-consolidacao/caixas', {}, {}, options), config)

export const queryCaixasGeralControllerListarSaldoCaixasConsolidado = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/saldo-caixas-consolidado', queryParams)
export const useQueryCaixasGeralControllerListarSaldoCaixasConsolidado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/saldo-caixas-consolidado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarSaldoCaixasConsolidado.queryKey = '/api/v1/caixas-geral/saldo-caixas-consolidado'

export const queryCaixasGeralControllerListarTitulosMovimentosConsolidacao = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/movimentos', queryParams)
export const useQueryCaixasGeralControllerListarTitulosMovimentosConsolidacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/movimentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarTitulosMovimentosConsolidacao.queryKey = '/api/v1/caixas-geral/movimentos'

export const queryCaixasGeralControllerListarSaldoMovimentosConsolidacao = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/saldo-movimentos-consolidacao', queryParams)
export const useQueryCaixasGeralControllerListarSaldoMovimentosConsolidacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/saldo-movimentos-consolidacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarSaldoMovimentosConsolidacao.queryKey = '/api/v1/caixas-geral/saldo-movimentos-consolidacao'

export const mutationCaixasGeralControllerConsolidarMovimentos = (options) => mutationFn('post', '/api/v1/caixas-geral/consolidar/movimentos', {}, {}, options)
export const useMutationCaixasGeralControllerConsolidarMovimentos = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral/consolidar/movimentos', {}, {}, options), config)

export const mutationCaixasGeralControllerDesfazerConsolidacaoMovimentos = (options) => mutationFn('post', '/api/v1/caixas-geral/desfazer-consolidacao/movimentos', {}, {}, options)
export const useMutationCaixasGeralControllerDesfazerConsolidacaoMovimentos = (config, options) => useMutation(mutationFn('post', '/api/v1/caixas-geral/desfazer-consolidacao/movimentos', {}, {}, options), config)

export const queryCaixasGeralControllerListarSaldoMovimentosConsolidado = (queryParams, options) => queryFn(options)('/api/v1/caixas-geral/saldo-movimentos-consolidado', queryParams)
export const useQueryCaixasGeralControllerListarSaldoMovimentosConsolidado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/caixas-geral/saldo-movimentos-consolidado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCaixasGeralControllerListarSaldoMovimentosConsolidado.queryKey = '/api/v1/caixas-geral/saldo-movimentos-consolidado'

export const mutationCalendariosControllerCadastrarCalendarioEducacao = (options) => mutationFn('post', '/api/v1/calendarios/educacao', {}, {}, options)
export const useMutationCalendariosControllerCadastrarCalendarioEducacao = (config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/educacao', {}, {}, options), config)

export const queryCalendariosControllerBuscarEducacao = (queryParams, options) => queryFn(options)('/api/v1/calendarios/educacao', queryParams)
export const useQueryCalendariosControllerBuscarEducacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/educacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendariosControllerBuscarEducacao.queryKey = '/api/v1/calendarios/educacao'

export const mutationCalendariosControllerAtualizarCalendarioEducacao = (pathParams, options) => mutationFn('put', '/api/v1/calendarios/{id}/educacao', pathParams, {}, options)
export const useMutationCalendariosControllerAtualizarCalendarioEducacao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/calendarios/{id}/educacao', pathParams, {}, options), config)

export const mutationCalendariosControllerDeletarCalendarioEducacao = (pathParams, options) => mutationFn('delete', '/api/v1/calendarios/{id}/educacao', pathParams, {}, options)
export const useMutationCalendariosControllerDeletarCalendarioEducacao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/calendarios/{id}/educacao', pathParams, {}, options), config)

export const mutationCalendariosControllerCadastrarHorarioFuncionamentoEducacao = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/horarios', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarHorarioFuncionamentoEducacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/horarios', pathParams, {}, options), config)

export const queryCalendariosControllerObterListaHorariosFuncionamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/horarios', pathParams, queryParams)
export const useQueryCalendariosControllerObterListaHorariosFuncionamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/horarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterListaHorariosFuncionamento.queryKey = '/api/v1/calendarios/{id}/horarios'

export const queryCalendariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/calendarios/{id}', pathParams)
export const useQueryCalendariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterPorId.queryKey = '/api/v1/calendarios/{id}'

export const mutationCalendariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/calendarios/{id}', pathParams, {}, options)
export const useMutationCalendariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/calendarios/{id}', pathParams, {}, options), config)

export const mutationCalendariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/calendarios/{id}', pathParams, {}, options)
export const useMutationCalendariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/calendarios/{id}', pathParams, {}, options), config)

export const mutationCalendariosControllerCadastrarCopiaCalendario = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/copia-calendario', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarCopiaCalendario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/copia-calendario', pathParams, {}, options), config)

export const queryCalendariosControllerBuscarCalendarioTurma = (queryParams, options) => queryFn(options)('/api/v1/calendarios/calendario-turma', queryParams)
export const useQueryCalendariosControllerBuscarCalendarioTurma = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/calendario-turma', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendariosControllerBuscarCalendarioTurma.queryKey = '/api/v1/calendarios/calendario-turma'

export const mutationCalendariosControllerCadastrarFeriadoEventoEducacao = (pathParams, queryParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/feriados-e-eventos-educacao', pathParams, queryParams, options)
export const useMutationCalendariosControllerCadastrarFeriadoEventoEducacao = (pathParams, queryParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/feriados-e-eventos-educacao', pathParams, queryParams, options), config)

export const queryCalendariosControllerObterFeriadosEventosEducacaoListagem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/feriados-e-eventos-educacao-listagem', pathParams, queryParams)
export const useQueryCalendariosControllerObterFeriadosEventosEducacaoListagem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/feriados-e-eventos-educacao-listagem', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterFeriadosEventosEducacaoListagem.queryKey = '/api/v1/calendarios/{id}/feriados-e-eventos-educacao-listagem'

export const queryCalendariosControllerObterFeriadosEventosIndicadores = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/feriados-e-eventos-indicadores', pathParams, queryParams)
export const useQueryCalendariosControllerObterFeriadosEventosIndicadores = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/feriados-e-eventos-indicadores', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterFeriadosEventosIndicadores.queryKey = '/api/v1/calendarios/{id}/feriados-e-eventos-indicadores'

export const queryCalendariosControllerObterFeriadosEventosData = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/feriados-e-eventos-data', pathParams, queryParams)
export const useQueryCalendariosControllerObterFeriadosEventosData = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/feriados-e-eventos-data', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterFeriadosEventosData.queryKey = '/api/v1/calendarios/{id}/feriados-e-eventos-data'

export const queryCalendariosControllerObterPeriodosAvaliativosCalendario = (pathParams, options) => queryFn(options)('/api/v1/calendarios/{id}/periodos-avaliativo-calendario', pathParams)
export const useQueryCalendariosControllerObterPeriodosAvaliativosCalendario = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/periodos-avaliativo-calendario', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterPeriodosAvaliativosCalendario.queryKey = '/api/v1/calendarios/{id}/periodos-avaliativo-calendario'

export const mutationCalendariosControllerAtualizarPeriodosAvaliativosCalendario = (pathParams, options) => mutationFn('put', '/api/v1/calendarios/{id}/periodos-avaliativo-calendario', pathParams, {}, options)
export const useMutationCalendariosControllerAtualizarPeriodosAvaliativosCalendario = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/calendarios/{id}/periodos-avaliativo-calendario', pathParams, {}, options), config)

export const mutationCalendariosControllerGerarPeriodosAvaliativosModulo = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/periodos-avaliativo-calendario/gerar-periodos-modulo', pathParams, {}, options)
export const useMutationCalendariosControllerGerarPeriodosAvaliativosModulo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/periodos-avaliativo-calendario/gerar-periodos-modulo', pathParams, {}, options), config)

export const queryCalendariosControllerObterExisteCalendarioPadrao = (queryParams, options) => queryFn(options)('/api/v1/calendarios/existe-calendario-padrao', queryParams)
export const useQueryCalendariosControllerObterExisteCalendarioPadrao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/existe-calendario-padrao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendariosControllerObterExisteCalendarioPadrao.queryKey = '/api/v1/calendarios/existe-calendario-padrao'

export const queryCalendariosControllerObterTurmasEmFormacaoEEmAndamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/turmas-formacao-andamento', pathParams, queryParams)
export const useQueryCalendariosControllerObterTurmasEmFormacaoEEmAndamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/turmas-formacao-andamento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterTurmasEmFormacaoEEmAndamento.queryKey = '/api/v1/calendarios/{id}/turmas-formacao-andamento'

export const mutationCalendariosControllerReplicarCalendarioParaTurmasVinculadas = (pathParams, options) => mutationFn('put', '/api/v1/calendarios/{id}/replicar-turmas', pathParams, {}, options)
export const useMutationCalendariosControllerReplicarCalendarioParaTurmasVinculadas = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/calendarios/{id}/replicar-turmas', pathParams, {}, options), config)

export const mutationCalendariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/calendarios', {}, {}, options)
export const useMutationCalendariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/calendarios', {}, {}, options), config)

export const queryCalendariosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/calendarios', queryParams)
export const useQueryCalendariosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendariosControllerBuscar.queryKey = '/api/v1/calendarios'

export const queryCalendariosControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/calendarios/buscar-detalhado/{id}', pathParams)
export const useQueryCalendariosControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterPorIdDetalhado.queryKey = '/api/v1/calendarios/buscar-detalhado/{id}'

export const mutationCalendariosControllerCadastrarCalendarioConta = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/contas', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarCalendarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/contas', pathParams, {}, options), config)

export const queryCalendariosControllerListarCalendarioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/contas', pathParams, queryParams)
export const useQueryCalendariosControllerListarCalendarioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerListarCalendarioContas.queryKey = '/api/v1/calendarios/{id}/contas'

export const queryCalendariosControllerObterCalendarioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/calendarios/{id}/contas/{contaId}', pathParams)
export const useQueryCalendariosControllerObterCalendarioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCalendariosControllerObterCalendarioContaPorId.queryKey = '/api/v1/calendarios/{id}/contas/{contaId}'

export const mutationCalendariosControllerAtualizarCalendarioConta = (pathParams, options) => mutationFn('put', '/api/v1/calendarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCalendariosControllerAtualizarCalendarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/calendarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCalendariosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCalendariosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerListarContasDisponiveis.queryKey = '/api/v1/calendarios/{id}/contas-disponiveis'

export const queryCalendariosControllerObterFeriadosEventosListagem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/feriados-e-eventos-listagem', pathParams, queryParams)
export const useQueryCalendariosControllerObterFeriadosEventosListagem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/feriados-e-eventos-listagem', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterFeriadosEventosListagem.queryKey = '/api/v1/calendarios/{id}/feriados-e-eventos-listagem'

export const queryCalendariosControllerObterFeriadosEventos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/feriados-e-eventos', pathParams, queryParams)
export const useQueryCalendariosControllerObterFeriadosEventos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/feriados-e-eventos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterFeriadosEventos.queryKey = '/api/v1/calendarios/{id}/feriados-e-eventos'

export const mutationCalendariosControllerCadastrarFeriadoEvento = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/feriados-e-eventos', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarFeriadoEvento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/feriados-e-eventos', pathParams, {}, options), config)

export const queryCalendariosControllerObterTempoEventos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/calendarios/{id}/tempo-de-eventos', pathParams, queryParams)
export const useQueryCalendariosControllerObterTempoEventos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/calendarios/{id}/tempo-de-eventos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosControllerObterTempoEventos.queryKey = '/api/v1/calendarios/{id}/tempo-de-eventos'

export const mutationCalendariosControllerCadastrarTempoEvento = (pathParams, options) => mutationFn('post', '/api/v1/calendarios/{id}/tempo-de-eventos', pathParams, {}, options)
export const useMutationCalendariosControllerCadastrarTempoEvento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/calendarios/{id}/tempo-de-eventos', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}', pathParams)
export const useQueryCalendariosHorarioFuncionamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerObterPorId.queryKey = '/api/v1/horarios-de-funcionamento/{id}'

export const mutationCalendariosHorarioFuncionamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/horarios-de-funcionamento/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/horarios-de-funcionamento/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/horarios-de-funcionamento/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/horarios-de-funcionamento/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoControllerDeletarHorarioIntervalo = (pathParams, options) => mutationFn('delete', '/api/v1/horarios-de-funcionamento/{id}/horarios-intervalos/{horarioIntervaloId}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerDeletarHorarioIntervalo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/horarios-de-funcionamento/{id}/horarios-intervalos/{horarioIntervaloId}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarProfissionalEducacao = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/profissionais-educacao', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarProfissionalEducacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/profissionais-educacao', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoControllerDeletarProfissionalEducacao = (pathParams, options) => mutationFn('delete', '/api/v1/horarios-de-funcionamento/{id}/profissionais-educacao', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerDeletarProfissionalEducacao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/horarios-de-funcionamento/{id}/profissionais-educacao', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarProfissionaisEducacaoPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/profissionais-educacao', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarProfissionaisEducacaoPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/profissionais-educacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarProfissionaisEducacaoPorId.queryKey = '/api/v1/horarios-de-funcionamento/{id}/profissionais-educacao'

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarConfiguracaoHorarios = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/configuracao-horarios', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarConfiguracaoHorarios = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/configuracao-horarios', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarConfiguracaoHorarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/configuracao-horarios', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarConfiguracaoHorarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/configuracao-horarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarConfiguracaoHorarios.queryKey = '/api/v1/horarios-de-funcionamento/{id}/configuracao-horarios'

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarProfissional = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/profissionais', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarProfissional = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/profissionais', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarProfissionais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/profissionais', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarProfissionais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/profissionais', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarProfissionais.queryKey = '/api/v1/horarios-de-funcionamento/{id}/profissionais'

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarEquipamento = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/equipamentos', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarEquipamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/equipamentos', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarEquipamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/equipamentos', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarEquipamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/equipamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarEquipamentos.queryKey = '/api/v1/horarios-de-funcionamento/{id}/equipamentos'

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarItem = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/itens', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/itens', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarItens = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/itens', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarItens = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarItens.queryKey = '/api/v1/horarios-de-funcionamento/{id}/itens'

export const mutationCalendariosHorarioFuncionamentoControllerCadastrarSala = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/salas', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoControllerCadastrarSala = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento/{id}/salas', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoControllerListarSalas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/salas', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarSalas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/salas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarSalas.queryKey = '/api/v1/horarios-de-funcionamento/{id}/salas'

export const queryCalendariosHorarioFuncionamentoControllerListarRecursosHorario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento/{id}/recursos', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoControllerListarRecursosHorario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento/{id}/recursos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoControllerListarRecursosHorario.queryKey = '/api/v1/horarios-de-funcionamento/{id}/recursos'

export const queryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento-profissionais-educacao', queryParams)
export const useQueryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento-profissionais-educacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerListar.queryKey = '/api/v1/horarios-de-funcionamento-profissionais-educacao'

export const queryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}', pathParams)
export const useQueryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerObterPorId.queryKey = '/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}'

export const queryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerListarProfissionais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}/disciplinas', pathParams, queryParams)
export const useQueryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerListarProfissionais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerListarProfissionais.queryKey = '/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}/disciplinas'

export const mutationCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerCadastrarProfissionalEducacaoDisciplina = (pathParams, options) => mutationFn('post', '/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}/disciplinas', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerCadastrarProfissionalEducacaoDisciplina = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}/disciplinas', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerDeletarProfissionalEducacaoDisciplina = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}/disciplinas', pathParams, queryParams, options)
export const useMutationCalendariosHorarioFuncionamentoProfissionaisEducacaoControllerDeletarProfissionalEducacaoDisciplina = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/horarios-de-funcionamento-profissionais-educacao/{id}/disciplinas', pathParams, queryParams, options), config)

export const queryCalendariosHorarioFuncionamentoProfissionalEducacaoDisciplinasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento-profissionais-educacao-disciplinas', queryParams)
export const useQueryCalendariosHorarioFuncionamentoProfissionalEducacaoDisciplinasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento-profissionais-educacao-disciplinas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendariosHorarioFuncionamentoProfissionalEducacaoDisciplinasControllerListar.queryKey = '/api/v1/horarios-de-funcionamento-profissionais-educacao-disciplinas'

export const queryCalendariosHorarioFuncionamentoProfissionalEducacaoDisciplinasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/horarios-de-funcionamento-profissionais-educacao-disciplinas/{id}', pathParams)
export const useQueryCalendariosHorarioFuncionamentoProfissionalEducacaoDisciplinasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/horarios-de-funcionamento-profissionais-educacao-disciplinas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoProfissionalEducacaoDisciplinasControllerObterPorId.queryKey = '/api/v1/horarios-de-funcionamento-profissionais-educacao-disciplinas/{id}'

export const mutationCalendariosHorarioFuncionamentoRecursosControllerDeletarEquipamento = (pathParams, options) => mutationFn('delete', '/api/v1/recursos/equipamento/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerDeletarEquipamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos/equipamento/{id}', pathParams, {}, options), config)

export const queryCalendariosHorarioFuncionamentoRecursosControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/recursos/profissional/{id}', pathParams)
export const useQueryCalendariosHorarioFuncionamentoRecursosControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos/profissional/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendariosHorarioFuncionamentoRecursosControllerObterPorIdDetalhado.queryKey = '/api/v1/recursos/profissional/{id}'

export const mutationCalendariosHorarioFuncionamentoRecursosControllerAtualizarProfissional = (pathParams, options) => mutationFn('put', '/api/v1/recursos/profissional/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerAtualizarProfissional = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/recursos/profissional/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoRecursosControllerDeletarProfissional = (pathParams, options) => mutationFn('delete', '/api/v1/recursos/profissional/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerDeletarProfissional = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos/profissional/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoRecursosControllerDeletarItem = (pathParams, options) => mutationFn('delete', '/api/v1/recursos/item/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerDeletarItem = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos/item/{id}', pathParams, {}, options), config)

export const mutationCalendariosHorarioFuncionamentoRecursosControllerDeletarSala = (pathParams, options) => mutationFn('delete', '/api/v1/recursos/sala/{id}', pathParams, {}, options)
export const useMutationCalendariosHorarioFuncionamentoRecursosControllerDeletarSala = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos/sala/{id}', pathParams, {}, options), config)

export const queryCalendarioTiposControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-calendario', queryParams)
export const useQueryCalendarioTiposControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calendario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCalendarioTiposControllerListar.queryKey = '/api/v1/tipos-de-calendario'

export const queryCalendarioTiposControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calendario/{id}', pathParams)
export const useQueryCalendarioTiposControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calendario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCalendarioTiposControllerObterPorId.queryKey = '/api/v1/tipos-de-calendario/{id}'

export const queryCalendarioTiposControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calendario/{chave}', pathParams)
export const useQueryCalendarioTiposControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calendario/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCalendarioTiposControllerObterPorChave.queryKey = '/api/v1/tipos-de-calendario/{chave}'

export const queryCalendarioTiposControllerObterPorCodigo = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calendario/{codigo}', pathParams)
export const useQueryCalendarioTiposControllerObterPorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calendario/{codigo}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigo, ...config }
})
useQueryCalendarioTiposControllerObterPorCodigo.queryKey = '/api/v1/tipos-de-calendario/{codigo}'

export const queryCampanhasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/campanhas', queryParams)
export const useQueryCampanhasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campanhas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCampanhasControllerListar.queryKey = '/api/v1/campanhas'

export const mutationCampanhasControllerCadastrar = (options) => mutationFn('post', '/api/v1/campanhas', {}, {}, options)
export const useMutationCampanhasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/campanhas', {}, {}, options), config)

export const queryCampanhasControllerListarVigentes = (queryParams, options) => queryFn(options)('/api/v1/campanhas/vigentes', queryParams)
export const useQueryCampanhasControllerListarVigentes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campanhas/vigentes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCampanhasControllerListarVigentes.queryKey = '/api/v1/campanhas/vigentes'

export const queryCampanhasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/campanhas/{id}', pathParams)
export const useQueryCampanhasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campanhas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCampanhasControllerObterPorId.queryKey = '/api/v1/campanhas/{id}'

export const mutationCampanhasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/campanhas/{id}', pathParams, {}, options)
export const useMutationCampanhasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/campanhas/{id}', pathParams, {}, options), config)

export const mutationCampanhasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/campanhas/{id}', pathParams, {}, options)
export const useMutationCampanhasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campanhas/{id}', pathParams, {}, options), config)

export const queryCamposAtuacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/campos-de-atuacao', queryParams)
export const useQueryCamposAtuacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-de-atuacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCamposAtuacaoControllerBuscar.queryKey = '/api/v1/campos-de-atuacao'

export const mutationCamposAtuacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/campos-de-atuacao', {}, {}, options)
export const useMutationCamposAtuacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/campos-de-atuacao', {}, {}, options), config)

export const queryCamposAtuacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/campos-de-atuacao/{id}', pathParams)
export const useQueryCamposAtuacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-de-atuacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposAtuacaoControllerObterPorId.queryKey = '/api/v1/campos-de-atuacao/{id}'

export const mutationCamposAtuacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/campos-de-atuacao/{id}', pathParams, {}, options)
export const useMutationCamposAtuacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/campos-de-atuacao/{id}', pathParams, {}, options), config)

export const mutationCamposAtuacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/campos-de-atuacao/{id}', pathParams, {}, options)
export const useMutationCamposAtuacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campos-de-atuacao/{id}', pathParams, {}, options), config)

export const mutationCamposAtuacaoControllerCadastrarCampoAtuacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/campos-de-atuacao/{id}/contas', pathParams, {}, options)
export const useMutationCamposAtuacaoControllerCadastrarCampoAtuacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/campos-de-atuacao/{id}/contas', pathParams, {}, options), config)

export const queryCamposAtuacaoControllerListarCampoAtuacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/campos-de-atuacao/{id}/contas', pathParams, queryParams)
export const useQueryCamposAtuacaoControllerListarCampoAtuacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-de-atuacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposAtuacaoControllerListarCampoAtuacaoContas.queryKey = '/api/v1/campos-de-atuacao/{id}/contas'

export const queryCamposAtuacaoControllerObterCampoAtuacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/campos-de-atuacao/{id}/contas/{contaId}', pathParams)
export const useQueryCamposAtuacaoControllerObterCampoAtuacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-de-atuacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCamposAtuacaoControllerObterCampoAtuacaoContaPorId.queryKey = '/api/v1/campos-de-atuacao/{id}/contas/{contaId}'

export const mutationCamposAtuacaoControllerAtualizarCampoAtuacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/campos-de-atuacao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCamposAtuacaoControllerAtualizarCampoAtuacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/campos-de-atuacao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCamposAtuacaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/campos-de-atuacao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCamposAtuacaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-de-atuacao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposAtuacaoControllerListarContasDisponiveis.queryKey = '/api/v1/campos-de-atuacao/{id}/contas-disponiveis'

export const mutationCamposAtuacaoControllerCadastrarCampoAtuacaoHabilidadeEnsinoMedio = (pathParams, options) => mutationFn('post', '/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio', pathParams, {}, options)
export const useMutationCamposAtuacaoControllerCadastrarCampoAtuacaoHabilidadeEnsinoMedio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio', pathParams, {}, options), config)

export const queryCamposAtuacaoControllerListarCampoAtuacaoHabilidadesEnsinoMedio = (pathParams, queryParams, options) => queryFn(options)('/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio', pathParams, queryParams)
export const useQueryCamposAtuacaoControllerListarCampoAtuacaoHabilidadesEnsinoMedio = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposAtuacaoControllerListarCampoAtuacaoHabilidadesEnsinoMedio.queryKey = '/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio'

export const queryCamposAtuacaoControllerObterCampoAtuacaoHabilidadeEnsinoMedioPorId = (pathParams, options) => queryFn(options)('/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio/{habilidadeEnsinoMedioId}', pathParams)
export const useQueryCamposAtuacaoControllerObterCampoAtuacaoHabilidadeEnsinoMedioPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio/{habilidadeEnsinoMedioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.habilidadeEnsinoMedioId, ...config }
})
useQueryCamposAtuacaoControllerObterCampoAtuacaoHabilidadeEnsinoMedioPorId.queryKey = '/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio/{habilidadeEnsinoMedioId}'

export const mutationCamposAtuacaoControllerDeletarCampoAtuacaoHabilidadeEnsinoMedio = (pathParams, options) => mutationFn('delete', '/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio/{habilidadeEnsinoMedioId}', pathParams, {}, options)
export const useMutationCamposAtuacaoControllerDeletarCampoAtuacaoHabilidadeEnsinoMedio = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campos-de-atuacao/{id}/habilidadesEnsinoMedio/{habilidadeEnsinoMedioId}', pathParams, {}, options), config)

export const mutationCamposCadastroPersonalizadoControllerDeletarCampoCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/campos-cadastro-personalizado/{id}', pathParams, {}, options)
export const useMutationCamposCadastroPersonalizadoControllerDeletarCampoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campos-cadastro-personalizado/{id}', pathParams, {}, options), config)

export const mutationCamposCadastroPersonalizadoControllerCadastrarItemCampoCadastroPersonalizado = (pathParams, options) => mutationFn('post', '/api/v1/campos-cadastro-personalizado/{id}/itens', pathParams, {}, options)
export const useMutationCamposCadastroPersonalizadoControllerCadastrarItemCampoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/campos-cadastro-personalizado/{id}/itens', pathParams, {}, options), config)

export const queryCamposCadastroPersonalizadoControllerListarItensCampoCadastroPersonalizado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/campos-cadastro-personalizado/{id}/itens', pathParams, queryParams)
export const useQueryCamposCadastroPersonalizadoControllerListarItensCampoCadastroPersonalizado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-cadastro-personalizado/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposCadastroPersonalizadoControllerListarItensCampoCadastroPersonalizado.queryKey = '/api/v1/campos-cadastro-personalizado/{id}/itens'

export const mutationCamposCadastroPersonalizadoControllerDeletarItemCampoCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/campos-cadastro-personalizado/itens/{id}', pathParams, {}, options)
export const useMutationCamposCadastroPersonalizadoControllerDeletarItemCampoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campos-cadastro-personalizado/itens/{id}', pathParams, {}, options), config)

export const queryCamposExperienciaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/campos-experiencia', queryParams)
export const useQueryCamposExperienciaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-experiencia', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCamposExperienciaControllerBuscar.queryKey = '/api/v1/campos-experiencia'

export const mutationCamposExperienciaControllerCadastrar = (options) => mutationFn('post', '/api/v1/campos-experiencia', {}, {}, options)
export const useMutationCamposExperienciaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/campos-experiencia', {}, {}, options), config)

export const queryCamposExperienciaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/campos-experiencia/{id}', pathParams)
export const useQueryCamposExperienciaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-experiencia/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposExperienciaControllerObterPorId.queryKey = '/api/v1/campos-experiencia/{id}'

export const mutationCamposExperienciaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/campos-experiencia/{id}', pathParams, {}, options)
export const useMutationCamposExperienciaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/campos-experiencia/{id}', pathParams, {}, options), config)

export const mutationCamposExperienciaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/campos-experiencia/{id}', pathParams, {}, options)
export const useMutationCamposExperienciaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campos-experiencia/{id}', pathParams, {}, options), config)

export const mutationCamposExperienciaControllerCadastrarCampoExperienciaConta = (pathParams, options) => mutationFn('post', '/api/v1/campos-experiencia/{id}/contas', pathParams, {}, options)
export const useMutationCamposExperienciaControllerCadastrarCampoExperienciaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/campos-experiencia/{id}/contas', pathParams, {}, options), config)

export const queryCamposExperienciaControllerListarCampoExperienciaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/campos-experiencia/{id}/contas', pathParams, queryParams)
export const useQueryCamposExperienciaControllerListarCampoExperienciaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-experiencia/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposExperienciaControllerListarCampoExperienciaContas.queryKey = '/api/v1/campos-experiencia/{id}/contas'

export const queryCamposExperienciaControllerObterCampoExperienciaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/campos-experiencia/{id}/contas/{contaId}', pathParams)
export const useQueryCamposExperienciaControllerObterCampoExperienciaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-experiencia/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCamposExperienciaControllerObterCampoExperienciaContaPorId.queryKey = '/api/v1/campos-experiencia/{id}/contas/{contaId}'

export const mutationCamposExperienciaControllerAtualizarCampoExperienciaConta = (pathParams, options) => mutationFn('put', '/api/v1/campos-experiencia/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCamposExperienciaControllerAtualizarCampoExperienciaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/campos-experiencia/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCamposExperienciaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/campos-experiencia/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCamposExperienciaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-experiencia/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposExperienciaControllerListarContasDisponiveis.queryKey = '/api/v1/campos-experiencia/{id}/contas-disponiveis'

export const mutationCamposExperienciaControllerCadastrarCampoExperienciaDireitoAprendizagem = (pathParams, options) => mutationFn('post', '/api/v1/campos-experiencia/{id}/direitos-aprendizagem', pathParams, {}, options)
export const useMutationCamposExperienciaControllerCadastrarCampoExperienciaDireitoAprendizagem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/campos-experiencia/{id}/direitos-aprendizagem', pathParams, {}, options), config)

export const queryCamposExperienciaControllerListarCampoExperienciaDireitosAprendizagem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/campos-experiencia/{id}/direitos-aprendizagem', pathParams, queryParams)
export const useQueryCamposExperienciaControllerListarCampoExperienciaDireitosAprendizagem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-experiencia/{id}/direitos-aprendizagem', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCamposExperienciaControllerListarCampoExperienciaDireitosAprendizagem.queryKey = '/api/v1/campos-experiencia/{id}/direitos-aprendizagem'

export const queryCamposExperienciaControllerObterCampoExperienciaDireitoAprendizagemPorId = (pathParams, options) => queryFn(options)('/api/v1/campos-experiencia/{id}/direitos-aprendizagem/{direitoAprendizagemId}', pathParams)
export const useQueryCamposExperienciaControllerObterCampoExperienciaDireitoAprendizagemPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/campos-experiencia/{id}/direitos-aprendizagem/{direitoAprendizagemId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.direitoAprendizagemId, ...config }
})
useQueryCamposExperienciaControllerObterCampoExperienciaDireitoAprendizagemPorId.queryKey = '/api/v1/campos-experiencia/{id}/direitos-aprendizagem/{direitoAprendizagemId}'

export const mutationCamposExperienciaControllerDeletarCampoExperienciaDireitoAprendizagem = (pathParams, options) => mutationFn('delete', '/api/v1/campos-experiencia/{id}/direitos-aprendizagem/{direitoAprendizagemId}', pathParams, {}, options)
export const useMutationCamposExperienciaControllerDeletarCampoExperienciaDireitoAprendizagem = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/campos-experiencia/{id}/direitos-aprendizagem/{direitoAprendizagemId}', pathParams, {}, options), config)

export const queryCanaisEnvioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/canais-envio', queryParams)
export const useQueryCanaisEnvioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/canais-envio', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCanaisEnvioControllerListar.queryKey = '/api/v1/canais-envio'

export const queryCanaisEnvioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/canais-envio/{id}', pathParams)
export const useQueryCanaisEnvioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/canais-envio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCanaisEnvioControllerObterPorId.queryKey = '/api/v1/canais-envio/{id}'

export const queryCanaisEnvioControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/canais-envio/{chave}', pathParams)
export const useQueryCanaisEnvioControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/canais-envio/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCanaisEnvioControllerObterPorChave.queryKey = '/api/v1/canais-envio/{chave}'

export const queryCargosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cargos', queryParams)
export const useQueryCargosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCargosControllerListar.queryKey = '/api/v1/cargos'

export const mutationCargosControllerCadastrar = (options) => mutationFn('post', '/api/v1/cargos', {}, {}, options)
export const useMutationCargosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/cargos', {}, {}, options), config)

export const mutationCargosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/cargos/{id}', pathParams, {}, options)
export const useMutationCargosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cargos/{id}', pathParams, {}, options), config)

export const mutationCargosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/cargos/{id}', pathParams, {}, options)
export const useMutationCargosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cargos/{id}', pathParams, {}, options), config)

export const queryCargosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cargos/{id}', pathParams)
export const useQueryCargosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerObterPorId.queryKey = '/api/v1/cargos/{id}'

export const queryCargosControllerObterUsuariosPorCargo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{id}/usuarios', pathParams, queryParams)
export const useQueryCargosControllerObterUsuariosPorCargo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/usuarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerObterUsuariosPorCargo.queryKey = '/api/v1/cargos/{id}/usuarios'

export const mutationCargosControllerCadastrarCargoConta = (pathParams, options) => mutationFn('post', '/api/v1/cargos/{id}/contas', pathParams, {}, options)
export const useMutationCargosControllerCadastrarCargoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/cargos/{id}/contas', pathParams, {}, options), config)

export const queryCargosControllerListarCargoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{id}/contas', pathParams, queryParams)
export const useQueryCargosControllerListarCargoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerListarCargoContas.queryKey = '/api/v1/cargos/{id}/contas'

export const queryCargosControllerObterCargoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/cargos/{id}/contas/{contaId}', pathParams)
export const useQueryCargosControllerObterCargoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCargosControllerObterCargoContaPorId.queryKey = '/api/v1/cargos/{id}/contas/{contaId}'

export const mutationCargosControllerAtualizarCargoConta = (pathParams, options) => mutationFn('put', '/api/v1/cargos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCargosControllerAtualizarCargoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cargos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCargosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCargosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerListarContasDisponiveis.queryKey = '/api/v1/cargos/{id}/contas-disponiveis'

export const queryCargosControllerListarFuncionarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{id}/funcionarios', pathParams, queryParams)
export const useQueryCargosControllerListarFuncionarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{id}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCargosControllerListarFuncionarios.queryKey = '/api/v1/cargos/{id}/funcionarios'

export const queryCargosControllerListarFuncionariosPorChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cargos/{chave}/funcionarios', pathParams, queryParams)
export const useQueryCargosControllerListarFuncionariosPorChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cargos/{chave}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCargosControllerListarFuncionariosPorChave.queryKey = '/api/v1/cargos/{chave}/funcionarios'

export const mutationCartoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/cartoes', {}, {}, options)
export const useMutationCartoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/cartoes', {}, {}, options), config)

export const queryCartoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cartoes', queryParams)
export const useQueryCartoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCartoesControllerListar.queryKey = '/api/v1/cartoes'

export const queryCartoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cartoes/{id}', pathParams)
export const useQueryCartoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCartoesControllerObterPorId.queryKey = '/api/v1/cartoes/{id}'

export const mutationCartoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/cartoes/{id}', pathParams, {}, options)
export const useMutationCartoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cartoes/{id}', pathParams, {}, options), config)

export const mutationCartoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/cartoes/{id}', pathParams, {}, options)
export const useMutationCartoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cartoes/{id}', pathParams, {}, options), config)

export const mutationCartoesControllerCadastrarCartaoConta = (pathParams, options) => mutationFn('post', '/api/v1/cartoes/{id}/contas', pathParams, {}, options)
export const useMutationCartoesControllerCadastrarCartaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/cartoes/{id}/contas', pathParams, {}, options), config)

export const queryCartoesControllerListarCartaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cartoes/{id}/contas', pathParams, queryParams)
export const useQueryCartoesControllerListarCartaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCartoesControllerListarCartaoContas.queryKey = '/api/v1/cartoes/{id}/contas'

export const queryCartoesControllerObterCartaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/cartoes/{id}/contas/{contaId}', pathParams)
export const useQueryCartoesControllerObterCartaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCartoesControllerObterCartaoContaPorId.queryKey = '/api/v1/cartoes/{id}/contas/{contaId}'

export const mutationCartoesControllerAtualizarCartaoConta = (pathParams, options) => mutationFn('put', '/api/v1/cartoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCartoesControllerAtualizarCartaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cartoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCartoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cartoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCartoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCartoesControllerListarContasDisponiveis.queryKey = '/api/v1/cartoes/{id}/contas-disponiveis'

export const mutationCartoesControllerCadastrarConfiguracaoCartao = (pathParams, options) => mutationFn('post', '/api/v1/cartoes/{id}/configuracoes', pathParams, {}, options)
export const useMutationCartoesControllerCadastrarConfiguracaoCartao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/cartoes/{id}/configuracoes', pathParams, {}, options), config)

export const queryCartoesControllerListarConfiguracoesCartao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cartoes/{id}/configuracoes', pathParams, queryParams)
export const useQueryCartoesControllerListarConfiguracoesCartao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/configuracoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCartoesControllerListarConfiguracoesCartao.queryKey = '/api/v1/cartoes/{id}/configuracoes'

export const queryCartoesControllerObterConfiguracaoCartaoPorId = (pathParams, options) => queryFn(options)('/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams)
export const useQueryCartoesControllerObterConfiguracaoCartaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.configuracaoCartaoId, ...config }
})
useQueryCartoesControllerObterConfiguracaoCartaoPorId.queryKey = '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}'

export const mutationCartoesControllerAtualizarConfiguracaoCartao = (pathParams, options) => mutationFn('patch', '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams, {}, options)
export const useMutationCartoesControllerAtualizarConfiguracaoCartao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams, {}, options), config)

export const mutationCartoesControllerDeletarConfiguracaoCartao = (pathParams, options) => mutationFn('delete', '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams, {}, options)
export const useMutationCartoesControllerDeletarConfiguracaoCartao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cartoes/{id}/configuracoes/{configuracaoCartaoId}', pathParams, {}, options), config)

export const mutationCategoriasControllerCadastrar = (options) => mutationFn('post', '/api/v1/categorias', {}, {}, options)
export const useMutationCategoriasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/categorias', {}, {}, options), config)

export const queryCategoriasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/categorias', queryParams)
export const useQueryCategoriasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasControllerBuscar.queryKey = '/api/v1/categorias'

export const mutationCategoriasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/categorias/{id}', pathParams, {}, options)
export const useMutationCategoriasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/categorias/{id}', pathParams, {}, options), config)

export const mutationCategoriasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/categorias/{id}', pathParams, {}, options)
export const useMutationCategoriasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/categorias/{id}', pathParams, {}, options), config)

export const queryCategoriasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/categorias/{id}', pathParams)
export const useQueryCategoriasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerObterPorId.queryKey = '/api/v1/categorias/{id}'

export const queryCategoriasControllerBuscarSubcategorias = (queryParams, options) => queryFn(options)('/api/v1/categorias/subcategorias', queryParams)
export const useQueryCategoriasControllerBuscarSubcategorias = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/subcategorias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasControllerBuscarSubcategorias.queryKey = '/api/v1/categorias/subcategorias'

export const queryCategoriasControllerBuscarCategoriasPai = (queryParams, options) => queryFn(options)('/api/v1/categorias/categorias-pai', queryParams)
export const useQueryCategoriasControllerBuscarCategoriasPai = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/categorias-pai', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasControllerBuscarCategoriasPai.queryKey = '/api/v1/categorias/categorias-pai'

export const queryCategoriasControllerBuscarSubcategoriasPorCategoriaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias/{id}/subcategorias', pathParams, queryParams)
export const useQueryCategoriasControllerBuscarSubcategoriasPorCategoriaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}/subcategorias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerBuscarSubcategoriasPorCategoriaId.queryKey = '/api/v1/categorias/{id}/subcategorias'

export const queryCategoriasControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/categorias/buscar-detalhado/{id}', pathParams)
export const useQueryCategoriasControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerObterPorIdDetalhado.queryKey = '/api/v1/categorias/buscar-detalhado/{id}'

export const mutationCategoriasControllerCadastrarCategoriaConta = (pathParams, options) => mutationFn('post', '/api/v1/categorias/{id}/contas', pathParams, {}, options)
export const useMutationCategoriasControllerCadastrarCategoriaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/categorias/{id}/contas', pathParams, {}, options), config)

export const queryCategoriasControllerListarCategoriaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias/{id}/contas', pathParams, queryParams)
export const useQueryCategoriasControllerListarCategoriaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerListarCategoriaContas.queryKey = '/api/v1/categorias/{id}/contas'

export const queryCategoriasControllerObterCategoriaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/categorias/{id}/contas/{contaId}', pathParams)
export const useQueryCategoriasControllerObterCategoriaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCategoriasControllerObterCategoriaContaPorId.queryKey = '/api/v1/categorias/{id}/contas/{contaId}'

export const mutationCategoriasControllerAtualizarCategoriaConta = (pathParams, options) => mutationFn('put', '/api/v1/categorias/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCategoriasControllerAtualizarCategoriaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/categorias/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCategoriasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/categorias/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCategoriasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCategoriasControllerListarContasDisponiveis.queryKey = '/api/v1/categorias/{id}/contas-disponiveis'

export const queryCategoriasTelefoneControllerListar = (queryParams, options) => queryFn(options)('/api/v1/categorias-de-telefone', queryParams)
export const useQueryCategoriasTelefoneControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/categorias-de-telefone', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCategoriasTelefoneControllerListar.queryKey = '/api/v1/categorias-de-telefone'

export const queryCbosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cbos/{id}', pathParams)
export const useQueryCbosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cbos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCbosControllerObterPorId.queryKey = '/api/v1/cbos/{id}'

export const queryCbosControllerObterPorCodigo = (pathParams, options) => queryFn(options)('/api/v1/cbos/{codigo}', pathParams)
export const useQueryCbosControllerObterPorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cbos/{codigo}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigo, ...config }
})
useQueryCbosControllerObterPorCodigo.queryKey = '/api/v1/cbos/{codigo}'

export const queryCbosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/cbos', queryParams)
export const useQueryCbosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cbos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCbosControllerBuscar.queryKey = '/api/v1/cbos'

export const queryCentralDocumentosControllerObter = (queryParams, options) => queryFn(options)('/api/v1/central-de-documentos', queryParams)
export const useQueryCentralDocumentosControllerObter = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-de-documentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCentralDocumentosControllerObter.queryKey = '/api/v1/central-de-documentos'

export const queryCentralDocumentosControllerObterIndicadores = (options) => queryFn(options)('/api/v1/central-de-documentos/indicadores')
export const useQueryCentralDocumentosControllerObterIndicadores = (config, options) => useQuery({
  queryKey: ['/api/v1/central-de-documentos/indicadores'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCentralDocumentosControllerObterIndicadores.queryKey = '/api/v1/central-de-documentos/indicadores'

export const mutationCentralDocumentosControllerCancelarNfse = (pathParams, options) => mutationFn('put', '/api/v1/central-de-documentos/{id}/cancelar-nfse', pathParams, {}, options)
export const useMutationCentralDocumentosControllerCancelarNfse = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/central-de-documentos/{id}/cancelar-nfse', pathParams, {}, options), config)

export const queryCentralDocumentosControllerObterHistoricoDocumentoFiscal = (pathParams, options) => queryFn(options)('/api/v1/central-de-documentos/{id}/historico-documento-fiscal', pathParams)
export const useQueryCentralDocumentosControllerObterHistoricoDocumentoFiscal = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-de-documentos/{id}/historico-documento-fiscal', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentralDocumentosControllerObterHistoricoDocumentoFiscal.queryKey = '/api/v1/central-de-documentos/{id}/historico-documento-fiscal'

export const queryCentralDocumentosControllerObterLinkPdf = (pathParams, options) => queryFn(options)('/api/v1/central-de-documentos/{rpsId}/link-pdf', pathParams)
export const useQueryCentralDocumentosControllerObterLinkPdf = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/central-de-documentos/{rpsId}/link-pdf', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.rpsId, ...config }
})
useQueryCentralDocumentosControllerObterLinkPdf.queryKey = '/api/v1/central-de-documentos/{rpsId}/link-pdf'

export const mutationCentrosCustoControllerCadastrar = (options) => mutationFn('post', '/api/v1/centros-de-custo', {}, {}, options)
export const useMutationCentrosCustoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/centros-de-custo', {}, {}, options), config)

export const queryCentrosCustoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/centros-de-custo', queryParams)
export const useQueryCentrosCustoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCentrosCustoControllerBuscar.queryKey = '/api/v1/centros-de-custo'

export const queryCentrosCustoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/centros-de-custo/{id}', pathParams)
export const useQueryCentrosCustoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentrosCustoControllerObterPorId.queryKey = '/api/v1/centros-de-custo/{id}'

export const mutationCentrosCustoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/centros-de-custo/{id}', pathParams, {}, options)
export const useMutationCentrosCustoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/centros-de-custo/{id}', pathParams, {}, options), config)

export const mutationCentrosCustoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/centros-de-custo/{id}', pathParams, {}, options)
export const useMutationCentrosCustoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/centros-de-custo/{id}', pathParams, {}, options), config)

export const mutationCentrosCustoControllerCadastrarCentroCustoConta = (pathParams, options) => mutationFn('post', '/api/v1/centros-de-custo/{id}/contas', pathParams, {}, options)
export const useMutationCentrosCustoControllerCadastrarCentroCustoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/centros-de-custo/{id}/contas', pathParams, {}, options), config)

export const queryCentrosCustoControllerListarCentroCustoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/centros-de-custo/{id}/contas', pathParams, queryParams)
export const useQueryCentrosCustoControllerListarCentroCustoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentrosCustoControllerListarCentroCustoContas.queryKey = '/api/v1/centros-de-custo/{id}/contas'

export const queryCentrosCustoControllerObterCentroCustoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/centros-de-custo/{id}/contas/{contaId}', pathParams)
export const useQueryCentrosCustoControllerObterCentroCustoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCentrosCustoControllerObterCentroCustoContaPorId.queryKey = '/api/v1/centros-de-custo/{id}/contas/{contaId}'

export const mutationCentrosCustoControllerAtualizarCentroCustoConta = (pathParams, options) => mutationFn('put', '/api/v1/centros-de-custo/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCentrosCustoControllerAtualizarCentroCustoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/centros-de-custo/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCentrosCustoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/centros-de-custo/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCentrosCustoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/centros-de-custo/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCentrosCustoControllerListarContasDisponiveis.queryKey = '/api/v1/centros-de-custo/{id}/contas-disponiveis'

export const queryCfopsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cfops', queryParams)
export const useQueryCfopsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cfops', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCfopsControllerListar.queryKey = '/api/v1/cfops'

export const queryCfopsControllerListarEntrada = (queryParams, options) => queryFn(options)('/api/v1/cfops/entrada', queryParams)
export const useQueryCfopsControllerListarEntrada = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cfops/entrada', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCfopsControllerListarEntrada.queryKey = '/api/v1/cfops/entrada'

export const queryCfopsControllerListarSaida = (queryParams, options) => queryFn(options)('/api/v1/cfops/saida', queryParams)
export const useQueryCfopsControllerListarSaida = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cfops/saida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCfopsControllerListarSaida.queryKey = '/api/v1/cfops/saida'

export const queryCfopsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cfops/{id}', pathParams)
export const useQueryCfopsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cfops/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCfopsControllerObterPorId.queryKey = '/api/v1/cfops/{id}'

export const mutationChequesControllerCadastrar = (options) => mutationFn('post', '/api/v1/cheques', {}, {}, options)
export const useMutationChequesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/cheques', {}, {}, options), config)

export const queryChequesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cheques', queryParams)
export const useQueryChequesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryChequesControllerListar.queryKey = '/api/v1/cheques'

export const queryChequesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cheques/{id}', pathParams)
export const useQueryChequesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChequesControllerObterPorId.queryKey = '/api/v1/cheques/{id}'

export const mutationChequesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/cheques/{id}', pathParams, {}, options)
export const useMutationChequesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cheques/{id}', pathParams, {}, options), config)

export const mutationChequesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/cheques/{id}', pathParams, {}, options)
export const useMutationChequesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cheques/{id}', pathParams, {}, options), config)

export const queryChequesControllerListarBaixaTitulosCheques = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cheques/{id}/baixas-de-titulos', pathParams, queryParams)
export const useQueryChequesControllerListarBaixaTitulosCheques = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}/baixas-de-titulos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChequesControllerListarBaixaTitulosCheques.queryKey = '/api/v1/cheques/{id}/baixas-de-titulos'

export const mutationChequesControllerCadastrarChequeConta = (pathParams, options) => mutationFn('post', '/api/v1/cheques/{id}/contas', pathParams, {}, options)
export const useMutationChequesControllerCadastrarChequeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/cheques/{id}/contas', pathParams, {}, options), config)

export const queryChequesControllerListarChequeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cheques/{id}/contas', pathParams, queryParams)
export const useQueryChequesControllerListarChequeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChequesControllerListarChequeContas.queryKey = '/api/v1/cheques/{id}/contas'

export const queryChequesControllerObterChequeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/cheques/{id}/contas/{contaId}', pathParams)
export const useQueryChequesControllerObterChequeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryChequesControllerObterChequeContaPorId.queryKey = '/api/v1/cheques/{id}/contas/{contaId}'

export const mutationChequesControllerAtualizarChequeConta = (pathParams, options) => mutationFn('put', '/api/v1/cheques/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationChequesControllerAtualizarChequeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cheques/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryChequesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/cheques/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryChequesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryChequesControllerListarContasDisponiveis.queryKey = '/api/v1/cheques/{id}/contas-disponiveis'

export const queryChequesControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/cheques/listar-filtros', queryParams)
export const useQueryChequesControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cheques/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryChequesControllerListarPorFiltros.queryKey = '/api/v1/cheques/listar-filtros'

export const queryClientesControllerBuscarClienteEducacao = (queryParams, options) => queryFn(options)('/api/v1/clientes', queryParams)
export const useQueryClientesControllerBuscarClienteEducacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerBuscarClienteEducacao.queryKey = '/api/v1/clientes'

export const mutationClientesControllerCadastrarClienteEducacao = (options) => mutationFn('post', '/api/v1/clientes', {}, {}, options)
export const useMutationClientesControllerCadastrarClienteEducacao = (config, options) => useMutation(mutationFn('post', '/api/v1/clientes', {}, {}, options), config)

export const queryClientesControllerObterClienteEducacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}', pathParams)
export const useQueryClientesControllerObterClienteEducacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterClienteEducacaoPorId.queryKey = '/api/v1/clientes/{id}'

export const mutationClientesControllerAtualizarClienteEducacao = (pathParams, options) => mutationFn('put', '/api/v1/clientes/{id}', pathParams, {}, options)
export const useMutationClientesControllerAtualizarClienteEducacao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes/{id}', pathParams, {}, options), config)

export const mutationClientesControllerDeletarClienteEducacao = (pathParams, options) => mutationFn('delete', '/api/v1/clientes/{id}', pathParams, {}, options)
export const useMutationClientesControllerDeletarClienteEducacao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes/{id}', pathParams, {}, options), config)

export const mutationClientesControllerAtualizarClienteEducacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/clientes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationClientesControllerAtualizarClienteEducacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryClientesControllerObterClienteContaPorId = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}/contas/{contaId}', pathParams)
export const useQueryClientesControllerObterClienteContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryClientesControllerObterClienteContaPorId.queryKey = '/api/v1/clientes/{id}/contas/{contaId}'

export const queryClientesControllerListarTurmas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/turmas/{situacaoTurmaChave}', pathParams, queryParams)
export const useQueryClientesControllerListarTurmas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/turmas/{situacaoTurmaChave}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.situacaoTurmaChave, ...config }
})
useQueryClientesControllerListarTurmas.queryKey = '/api/v1/clientes/{id}/turmas/{situacaoTurmaChave}'

export const mutationClientesControllerCadastrarHistoricoEscolar = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/historicos-escolares', pathParams, {}, options)
export const useMutationClientesControllerCadastrarHistoricoEscolar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/historicos-escolares', pathParams, {}, options), config)

export const queryClientesControllerBuscarHistoricoEscolarLista = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/historicos-escolares', pathParams, queryParams)
export const useQueryClientesControllerBuscarHistoricoEscolarLista = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/historicos-escolares', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerBuscarHistoricoEscolarLista.queryKey = '/api/v1/clientes/{id}/historicos-escolares'

export const queryClientesControllerObterOcorrencias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/ocorrencias', pathParams, queryParams)
export const useQueryClientesControllerObterOcorrencias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/ocorrencias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterOcorrencias.queryKey = '/api/v1/clientes/{id}/ocorrencias'

export const queryClientesControllerListarCamposRelatorio = (queryParams, options) => queryFn(options)('/api/v1/clientes/report/campos-disponiveis', queryParams)
export const useQueryClientesControllerListarCamposRelatorio = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/report/campos-disponiveis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerListarCamposRelatorio.queryKey = '/api/v1/clientes/report/campos-disponiveis'

export const mutationClientesControllerImportarClientes = (queryParams, options) => mutationFn('post', '/api/v1/clientes/importacao', {}, queryParams, options)
export const useMutationClientesControllerImportarClientes = (queryParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/importacao', {}, queryParams, options), config)

export const queryClientesControllerObterClienteComDependentes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/com-dependentes', pathParams, queryParams)
export const useQueryClientesControllerObterClienteComDependentes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/com-dependentes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterClienteComDependentes.queryKey = '/api/v1/clientes/{id}/com-dependentes'

export const mutationClientesControllerAtualizarClienteComDependentes = (pathParams, options) => mutationFn('put', '/api/v1/clientes/{id}/com-dependentes', pathParams, {}, options)
export const useMutationClientesControllerAtualizarClienteComDependentes = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes/{id}/com-dependentes', pathParams, {}, options), config)

export const mutationClientesControllerCadastrarClienteTag = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/tags', pathParams, {}, options)
export const useMutationClientesControllerCadastrarClienteTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/tags', pathParams, {}, options), config)

export const queryClientesControllerListarTagsCliente = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/tags', pathParams, queryParams)
export const useQueryClientesControllerListarTagsCliente = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarTagsCliente.queryKey = '/api/v1/clientes/{id}/tags'

export const mutationClientesControllerDeletarClienteTag = (pathParams, options) => mutationFn('delete', '/api/v1/clientes/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationClientesControllerDeletarClienteTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryClientesControllerListarClientesTags = (queryParams, options) => queryFn(options)('/api/v1/clientes/tags', queryParams)
export const useQueryClientesControllerListarClientesTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerListarClientesTags.queryKey = '/api/v1/clientes/tags'

export const queryClientesControllerBuscarResponsaveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/responsaveis', pathParams, queryParams)
export const useQueryClientesControllerBuscarResponsaveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerBuscarResponsaveis.queryKey = '/api/v1/clientes/{id}/responsaveis'

export const mutationClientesControllerCadastrarResponsavelCliente = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/responsaveis', pathParams, {}, options)
export const useMutationClientesControllerCadastrarResponsavelCliente = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/responsaveis', pathParams, {}, options), config)

export const queryClientesControllerObterResponsavelFinanceiroPadrao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/responsaveis/financeiro-padrao', pathParams, queryParams)
export const useQueryClientesControllerObterResponsavelFinanceiroPadrao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/responsaveis/financeiro-padrao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterResponsavelFinanceiroPadrao.queryKey = '/api/v1/clientes/{id}/responsaveis/financeiro-padrao'

export const mutationClientesControllerAtualizarResponsavelCliente = (pathParams, options) => mutationFn('put', '/api/v1/clientes/responsaveis/{responsavelClienteId}', pathParams, {}, options)
export const useMutationClientesControllerAtualizarResponsavelCliente = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/clientes/responsaveis/{responsavelClienteId}', pathParams, {}, options), config)

export const mutationClientesControllerAtualizarAcessosCliente = (pathParams, options) => mutationFn('patch', '/api/v1/clientes/{id}/acessos', pathParams, {}, options)
export const useMutationClientesControllerAtualizarAcessosCliente = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/clientes/{id}/acessos', pathParams, {}, options), config)

export const queryClientesControllerObterAcessosCliente = (pathParams, options) => queryFn(options)('/api/v1/clientes/{id}/acessos', pathParams)
export const useQueryClientesControllerObterAcessosCliente = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/acessos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerObterAcessosCliente.queryKey = '/api/v1/clientes/{id}/acessos'

export const mutationClientesControllerAtualizarAcessosMultiplosClientes = (options) => mutationFn('patch', '/api/v1/clientes/acessos', {}, {}, options)
export const useMutationClientesControllerAtualizarAcessosMultiplosClientes = (config, options) => useMutation(mutationFn('patch', '/api/v1/clientes/acessos', {}, {}, options), config)

export const mutationClientesControllerEnviarEmailCadastroClienteApp = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/acessos/email-app', pathParams, {}, options)
export const useMutationClientesControllerEnviarEmailCadastroClienteApp = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/acessos/email-app', pathParams, {}, options), config)

export const mutationClientesControllerDeletarResponsavelCliente = (pathParams, options) => mutationFn('delete', '/api/v1/clientes/{id}/responsaveis/{responsavelId}', pathParams, {}, options)
export const useMutationClientesControllerDeletarResponsavelCliente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes/{id}/responsaveis/{responsavelId}', pathParams, {}, options), config)

export const queryClientesControllerBuscarResponsavelTipos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/responsaveis/{responsavelClienteId}/tipos', pathParams, queryParams)
export const useQueryClientesControllerBuscarResponsavelTipos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/responsaveis/{responsavelClienteId}/tipos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.responsavelClienteId, ...config }
})
useQueryClientesControllerBuscarResponsavelTipos.queryKey = '/api/v1/clientes/responsaveis/{responsavelClienteId}/tipos'

export const mutationClientesControllerCadastrarResponsavelClienteTipo = (pathParams, options) => mutationFn('post', '/api/v1/clientes/responsaveis/{responsavelId}/tipos', pathParams, {}, options)
export const useMutationClientesControllerCadastrarResponsavelClienteTipo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/responsaveis/{responsavelId}/tipos', pathParams, {}, options), config)

export const mutationClientesControllerDeletarResponsavelClienteTipo = (pathParams, options) => mutationFn('delete', '/api/v1/clientes/responsaveis/{responsavelId}/tipos/{id}', pathParams, {}, options)
export const useMutationClientesControllerDeletarResponsavelClienteTipo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/clientes/responsaveis/{responsavelId}/tipos/{id}', pathParams, {}, options), config)

export const mutationClientesControllerCadastrarClienteConta = (pathParams, options) => mutationFn('post', '/api/v1/clientes/{id}/contas', pathParams, {}, options)
export const useMutationClientesControllerCadastrarClienteConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes/{id}/contas', pathParams, {}, options), config)

export const queryClientesControllerListarClienteContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/contas', pathParams, queryParams)
export const useQueryClientesControllerListarClienteContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarClienteContas.queryKey = '/api/v1/clientes/{id}/contas'

export const queryClientesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryClientesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarContasDisponiveis.queryKey = '/api/v1/clientes/{id}/contas-disponiveis'

export const queryClientesControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryClientesControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarTagsDisponiveis.queryKey = '/api/v1/clientes/{id}/tags-disponiveis'

export const queryClientesControllerListarIndicadoresClientes = (queryParams, options) => queryFn(options)('/api/v1/clientes/indicadores', queryParams)
export const useQueryClientesControllerListarIndicadoresClientes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryClientesControllerListarIndicadoresClientes.queryKey = '/api/v1/clientes/indicadores'

export const queryClientesControllerListarPessoasResponsaveisPorPessoaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/pessoas-responsaveis', pathParams, queryParams)
export const useQueryClientesControllerListarPessoasResponsaveisPorPessoaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/pessoas-responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarPessoasResponsaveisPorPessoaId.queryKey = '/api/v1/clientes/{id}/pessoas-responsaveis'

export const queryClientesControllerListarTarefasPorClienteId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes/{id}/tarefas-acompanhamento', pathParams, queryParams)
export const useQueryClientesControllerListarTarefasPorClienteId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes/{id}/tarefas-acompanhamento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesControllerListarTarefasPorClienteId.queryKey = '/api/v1/clientes/{id}/tarefas-acompanhamento'

export const mutationClientesEducacaoControllerCadastrarClienteEducacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/clientes-educacao/{id}/contas', pathParams, {}, options)
export const useMutationClientesEducacaoControllerCadastrarClienteEducacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/clientes-educacao/{id}/contas', pathParams, {}, options), config)

export const queryClientesEducacaoControllerListarClienteEducacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/clientes-educacao/{id}/contas', pathParams, queryParams)
export const useQueryClientesEducacaoControllerListarClienteEducacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes-educacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryClientesEducacaoControllerListarClienteEducacaoContas.queryKey = '/api/v1/clientes-educacao/{id}/contas'

export const queryClientesEducacaoControllerObterClienteEducacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/clientes-educacao/{id}/contas/{contaId}', pathParams)
export const useQueryClientesEducacaoControllerObterClienteEducacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/clientes-educacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryClientesEducacaoControllerObterClienteEducacaoContaPorId.queryKey = '/api/v1/clientes-educacao/{id}/contas/{contaId}'

export const queryCnaesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cnaes', queryParams)
export const useQueryCnaesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cnaes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCnaesControllerListar.queryKey = '/api/v1/cnaes'

export const queryCnaesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cnaes/{id}', pathParams)
export const useQueryCnaesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cnaes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCnaesControllerObterPorId.queryKey = '/api/v1/cnaes/{id}'

export const queryCodigosServicoPrestadoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/codigos-servicos-prestados', queryParams)
export const useQueryCodigosServicoPrestadoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/codigos-servicos-prestados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCodigosServicoPrestadoControllerListar.queryKey = '/api/v1/codigos-servicos-prestados'

export const queryCodigosServicoPrestadoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/codigos-servicos-prestados/{id}', pathParams)
export const useQueryCodigosServicoPrestadoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/codigos-servicos-prestados/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCodigosServicoPrestadoControllerObterPorId.queryKey = '/api/v1/codigos-servicos-prestados/{id}'

export const queryCompetenciasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/competencias', queryParams)
export const useQueryCompetenciasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCompetenciasControllerBuscar.queryKey = '/api/v1/competencias'

export const mutationCompetenciasControllerCadastrar = (options) => mutationFn('post', '/api/v1/competencias', {}, {}, options)
export const useMutationCompetenciasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/competencias', {}, {}, options), config)

export const queryCompetenciasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/competencias/{id}', pathParams)
export const useQueryCompetenciasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCompetenciasControllerObterPorId.queryKey = '/api/v1/competencias/{id}'

export const mutationCompetenciasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/competencias/{id}', pathParams, {}, options)
export const useMutationCompetenciasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/competencias/{id}', pathParams, {}, options), config)

export const mutationCompetenciasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/competencias/{id}', pathParams, {}, options)
export const useMutationCompetenciasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/competencias/{id}', pathParams, {}, options), config)

export const mutationCompetenciasControllerCadastrarCompetenciaConta = (pathParams, options) => mutationFn('post', '/api/v1/competencias/{id}/contas', pathParams, {}, options)
export const useMutationCompetenciasControllerCadastrarCompetenciaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/competencias/{id}/contas', pathParams, {}, options), config)

export const queryCompetenciasControllerListarCompetenciaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/competencias/{id}/contas', pathParams, queryParams)
export const useQueryCompetenciasControllerListarCompetenciaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCompetenciasControllerListarCompetenciaContas.queryKey = '/api/v1/competencias/{id}/contas'

export const queryCompetenciasControllerObterCompetenciaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/competencias/{id}/contas/{contaId}', pathParams)
export const useQueryCompetenciasControllerObterCompetenciaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCompetenciasControllerObterCompetenciaContaPorId.queryKey = '/api/v1/competencias/{id}/contas/{contaId}'

export const mutationCompetenciasControllerAtualizarCompetenciaConta = (pathParams, options) => mutationFn('put', '/api/v1/competencias/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCompetenciasControllerAtualizarCompetenciaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/competencias/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCompetenciasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/competencias/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCompetenciasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCompetenciasControllerListarContasDisponiveis.queryKey = '/api/v1/competencias/{id}/contas-disponiveis'

export const queryCompetenciasControllerListarDisciplinaCompetencias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/competencias/{id}/disciplinas', pathParams, queryParams)
export const useQueryCompetenciasControllerListarDisciplinaCompetencias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCompetenciasControllerListarDisciplinaCompetencias.queryKey = '/api/v1/competencias/{id}/disciplinas'

export const queryCompetenciasEspecificasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/competencias-especificas', queryParams)
export const useQueryCompetenciasEspecificasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias-especificas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCompetenciasEspecificasControllerBuscar.queryKey = '/api/v1/competencias-especificas'

export const mutationCompetenciasEspecificasControllerCadastrar = (options) => mutationFn('post', '/api/v1/competencias-especificas', {}, {}, options)
export const useMutationCompetenciasEspecificasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/competencias-especificas', {}, {}, options), config)

export const queryCompetenciasEspecificasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/competencias-especificas/{id}', pathParams)
export const useQueryCompetenciasEspecificasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias-especificas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCompetenciasEspecificasControllerObterPorId.queryKey = '/api/v1/competencias-especificas/{id}'

export const mutationCompetenciasEspecificasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/competencias-especificas/{id}', pathParams, {}, options)
export const useMutationCompetenciasEspecificasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/competencias-especificas/{id}', pathParams, {}, options), config)

export const mutationCompetenciasEspecificasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/competencias-especificas/{id}', pathParams, {}, options)
export const useMutationCompetenciasEspecificasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/competencias-especificas/{id}', pathParams, {}, options), config)

export const mutationCompetenciasEspecificasControllerCadastrarCompetenciaEspecificaConta = (pathParams, options) => mutationFn('post', '/api/v1/competencias-especificas/{id}/contas', pathParams, {}, options)
export const useMutationCompetenciasEspecificasControllerCadastrarCompetenciaEspecificaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/competencias-especificas/{id}/contas', pathParams, {}, options), config)

export const queryCompetenciasEspecificasControllerListarCompetenciaEspecificaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/competencias-especificas/{id}/contas', pathParams, queryParams)
export const useQueryCompetenciasEspecificasControllerListarCompetenciaEspecificaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias-especificas/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCompetenciasEspecificasControllerListarCompetenciaEspecificaContas.queryKey = '/api/v1/competencias-especificas/{id}/contas'

export const queryCompetenciasEspecificasControllerObterCompetenciaEspecificaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/competencias-especificas/{id}/contas/{contaId}', pathParams)
export const useQueryCompetenciasEspecificasControllerObterCompetenciaEspecificaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias-especificas/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCompetenciasEspecificasControllerObterCompetenciaEspecificaContaPorId.queryKey = '/api/v1/competencias-especificas/{id}/contas/{contaId}'

export const mutationCompetenciasEspecificasControllerAtualizarCompetenciaEspecificaConta = (pathParams, options) => mutationFn('put', '/api/v1/competencias-especificas/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCompetenciasEspecificasControllerAtualizarCompetenciaEspecificaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/competencias-especificas/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCompetenciasEspecificasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/competencias-especificas/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCompetenciasEspecificasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias-especificas/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCompetenciasEspecificasControllerListarContasDisponiveis.queryKey = '/api/v1/competencias-especificas/{id}/contas-disponiveis'

export const queryCompetenciasEspecificasControllerListarDisciplinaCompetenciasEspecificas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/competencias-especificas/{id}/disciplinas', pathParams, queryParams)
export const useQueryCompetenciasEspecificasControllerListarDisciplinaCompetenciasEspecificas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/competencias-especificas/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCompetenciasEspecificasControllerListarDisciplinaCompetenciasEspecificas.queryKey = '/api/v1/competencias-especificas/{id}/disciplinas'

export const mutationConceitosControllerAtualizarConceito = (pathParams, options) => mutationFn('put', '/api/v1/conceitos/{id}', pathParams, {}, options)
export const useMutationConceitosControllerAtualizarConceito = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/conceitos/{id}', pathParams, {}, options), config)

export const mutationConceitosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/conceitos/{id}', pathParams, {}, options)
export const useMutationConceitosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/conceitos/{id}', pathParams, {}, options), config)

export const queryConceitosControllerObterConceitoPorId = (pathParams, options) => queryFn(options)('/api/v1/conceitos/{id}', pathParams)
export const useQueryConceitosControllerObterConceitoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/conceitos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConceitosControllerObterConceitoPorId.queryKey = '/api/v1/conceitos/{id}'

export const queryConceitosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/conceitos', queryParams)
export const useQueryConceitosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/conceitos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConceitosControllerBuscar.queryKey = '/api/v1/conceitos'

export const queryCondicoesEnvioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/condicoes-envio', queryParams)
export const useQueryCondicoesEnvioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-envio', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCondicoesEnvioControllerListar.queryKey = '/api/v1/condicoes-envio'

export const queryCondicoesEnvioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-envio/{id}', pathParams)
export const useQueryCondicoesEnvioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-envio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesEnvioControllerObterPorId.queryKey = '/api/v1/condicoes-envio/{id}'

export const queryCondicoesEnvioControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/condicoes-envio/{chave}', pathParams)
export const useQueryCondicoesEnvioControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-envio/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCondicoesEnvioControllerObterPorChave.queryKey = '/api/v1/condicoes-envio/{chave}'

export const mutationCondicoesPagamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/condicoes-de-pagamento', {}, {}, options)
export const useMutationCondicoesPagamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/condicoes-de-pagamento', {}, {}, options), config)

export const queryCondicoesPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento', queryParams)
export const useQueryCondicoesPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCondicoesPagamentoControllerListar.queryKey = '/api/v1/condicoes-de-pagamento'

export const queryCondicoesPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}', pathParams)
export const useQueryCondicoesPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerObterPorId.queryKey = '/api/v1/condicoes-de-pagamento/{id}'

export const mutationCondicoesPagamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/condicoes-de-pagamento/{id}', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/condicoes-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationCondicoesPagamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/condicoes-de-pagamento/{id}', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/condicoes-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationCondicoesPagamentoControllerCadastrarCondicaoPagamentoConta = (pathParams, options) => mutationFn('post', '/api/v1/condicoes-de-pagamento/{id}/contas', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerCadastrarCondicaoPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/condicoes-de-pagamento/{id}/contas', pathParams, {}, options), config)

export const queryCondicoesPagamentoControllerListarCondicaoPagamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/contas', pathParams, queryParams)
export const useQueryCondicoesPagamentoControllerListarCondicaoPagamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerListarCondicaoPagamentoContas.queryKey = '/api/v1/condicoes-de-pagamento/{id}/contas'

export const queryCondicoesPagamentoControllerObterCondicaoPagamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}', pathParams)
export const useQueryCondicoesPagamentoControllerObterCondicaoPagamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCondicoesPagamentoControllerObterCondicaoPagamentoContaPorId.queryKey = '/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}'

export const mutationCondicoesPagamentoControllerAtualizarCondicaoPagamentoConta = (pathParams, options) => mutationFn('put', '/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerAtualizarCondicaoPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/condicoes-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCondicoesPagamentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCondicoesPagamentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerListarContasDisponiveis.queryKey = '/api/v1/condicoes-de-pagamento/{id}/contas-disponiveis'

export const mutationCondicoesPagamentoControllerCadastrarCondicaoPagamentoRestricao = (pathParams, options) => mutationFn('post', '/api/v1/condicoes-de-pagamento/{id}/restricoes', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerCadastrarCondicaoPagamentoRestricao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/condicoes-de-pagamento/{id}/restricoes', pathParams, {}, options), config)

export const queryCondicoesPagamentoControllerListarCondicaoPagamentoRestricoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/restricoes', pathParams, queryParams)
export const useQueryCondicoesPagamentoControllerListarCondicaoPagamentoRestricoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/restricoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerListarCondicaoPagamentoRestricoes.queryKey = '/api/v1/condicoes-de-pagamento/{id}/restricoes'

export const queryCondicoesPagamentoControllerObterCondicaoPagamentoRestricaoPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}', pathParams)
export const useQueryCondicoesPagamentoControllerObterCondicaoPagamentoRestricaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.restricaoId, ...config }
})
useQueryCondicoesPagamentoControllerObterCondicaoPagamentoRestricaoPorId.queryKey = '/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}'

export const mutationCondicoesPagamentoControllerDeletarCondicaoPagamentoRestricao = (pathParams, options) => mutationFn('delete', '/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}', pathParams, {}, options)
export const useMutationCondicoesPagamentoControllerDeletarCondicaoPagamentoRestricao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/condicoes-de-pagamento/{id}/restricao/{restricaoId}', pathParams, {}, options), config)

export const queryCondicoesPagamentoControllerCalcularValoresParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/condicoes-de-pagamento/{id}/parcelas', pathParams, queryParams)
export const useQueryCondicoesPagamentoControllerCalcularValoresParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-de-pagamento/{id}/parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoControllerCalcularValoresParcelas.queryKey = '/api/v1/condicoes-de-pagamento/{id}/parcelas'

export const mutationCondicoesPagamentoRecorrentesControllerCadastrar = (options) => mutationFn('post', '/api/v1/condicoes-pagamento-recorrentes', {}, {}, options)
export const useMutationCondicoesPagamentoRecorrentesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/condicoes-pagamento-recorrentes', {}, {}, options), config)

export const queryCondicoesPagamentoRecorrentesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/condicoes-pagamento-recorrentes', queryParams)
export const useQueryCondicoesPagamentoRecorrentesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-pagamento-recorrentes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCondicoesPagamentoRecorrentesControllerListar.queryKey = '/api/v1/condicoes-pagamento-recorrentes'

export const mutationCondicoesPagamentoRecorrentesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams, {}, options)
export const useMutationCondicoesPagamentoRecorrentesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams, {}, options), config)

export const mutationCondicoesPagamentoRecorrentesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams, {}, options)
export const useMutationCondicoesPagamentoRecorrentesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams, {}, options), config)

export const queryCondicoesPagamentoRecorrentesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams)
export const useQueryCondicoesPagamentoRecorrentesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/condicoes-pagamento-recorrentes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCondicoesPagamentoRecorrentesControllerObterPorId.queryKey = '/api/v1/condicoes-pagamento-recorrentes/{id}'

export const mutationConfiguracaoHorariosControllerDeletar = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/configuracao-de-horarios/{id}', pathParams, queryParams, options)
export const useMutationConfiguracaoHorariosControllerDeletar = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-de-horarios/{id}', pathParams, queryParams, options), config)

export const queryConfiguracaoHorariosControllerListaTipoAulas = (queryParams, options) => queryFn(options)('/api/v1/configuracao-de-horarios/tipo-aulas', queryParams)
export const useQueryConfiguracaoHorariosControllerListaTipoAulas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-de-horarios/tipo-aulas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracaoHorariosControllerListaTipoAulas.queryKey = '/api/v1/configuracao-de-horarios/tipo-aulas'

export const mutationConfiguracaoLinkPagamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracao-link-pagamento', {}, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-link-pagamento', {}, {}, options), config)

export const queryConfiguracaoLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracao-link-pagamento', queryParams)
export const useQueryConfiguracaoLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracaoLinkPagamentoControllerListar.queryKey = '/api/v1/configuracao-link-pagamento'

export const mutationConfiguracaoLinkPagamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracao-link-pagamento/{id}', pathParams, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracao-link-pagamento/{id}', pathParams, {}, options), config)

export const mutationConfiguracaoLinkPagamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracao-link-pagamento/{id}', pathParams, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-link-pagamento/{id}', pathParams, {}, options), config)

export const queryConfiguracaoLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracao-link-pagamento/{id}', pathParams)
export const useQueryConfiguracaoLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoLinkPagamentoControllerObterPorId.queryKey = '/api/v1/configuracao-link-pagamento/{id}'

export const mutationConfiguracaoLinkPagamentoControllerCriarEnviarLinkPagamento = (options) => mutationFn('post', '/api/v1/configuracao-link-pagamento/criar-enviar-link-pagamento', {}, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerCriarEnviarLinkPagamento = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-link-pagamento/criar-enviar-link-pagamento', {}, {}, options), config)

export const queryConfiguracaoLinkPagamentoControllerObterLinkPagamento = (pathParams, options) => queryFn(options)('/api/v1/configuracao-link-pagamento/obter-link-pagamento/{tituloId}', pathParams)
export const useQueryConfiguracaoLinkPagamentoControllerObterLinkPagamento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-link-pagamento/obter-link-pagamento/{tituloId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.tituloId, ...config }
})
useQueryConfiguracaoLinkPagamentoControllerObterLinkPagamento.queryKey = '/api/v1/configuracao-link-pagamento/obter-link-pagamento/{tituloId}'

export const mutationConfiguracaoLinkPagamentoControllerCancelarLinkPagamento = (pathParams, options) => mutationFn('post', '/api/v1/configuracao-link-pagamento/cancelar-link-pagamento/{tituloId}', pathParams, {}, options)
export const useMutationConfiguracaoLinkPagamentoControllerCancelarLinkPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-link-pagamento/cancelar-link-pagamento/{tituloId}', pathParams, {}, options), config)

export const queryConfiguracaoModelosAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracao-modelos-avaliacao/{id}', pathParams)
export const useQueryConfiguracaoModelosAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelos-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoModelosAvaliacaoControllerObterPorId.queryKey = '/api/v1/configuracao-modelos-avaliacao/{id}'

export const mutationConfiguracaoModelosAvaliacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracao-modelos-avaliacao/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracao-modelos-avaliacao/{id}', pathParams, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoControllerDeletarAssinante = (pathParams, options) => mutationFn('delete', '/api/v1/configuracao-modelos-avaliacao/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoControllerDeletarAssinante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-modelos-avaliacao/{id}', pathParams, {}, options), config)

export const queryConfiguracaoModelosAvaliacaoControllerObterPeriodos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracao-modelos-avaliacao/{id}/periodos', pathParams, queryParams)
export const useQueryConfiguracaoModelosAvaliacaoControllerObterPeriodos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelos-avaliacao/{id}/periodos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoModelosAvaliacaoControllerObterPeriodos.queryKey = '/api/v1/configuracao-modelos-avaliacao/{id}/periodos'

export const queryConfiguracaoModelosAvaliacaoControllerObterFormulas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracao-modelos-avaliacao/{id}/formulas', pathParams, queryParams)
export const useQueryConfiguracaoModelosAvaliacaoControllerObterFormulas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelos-avaliacao/{id}/formulas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoModelosAvaliacaoControllerObterFormulas.queryKey = '/api/v1/configuracao-modelos-avaliacao/{id}/formulas'

export const mutationConfiguracaoModelosAvaliacaoControllerRemoverConfiguracaoModeloAvaliacaoPeriodoGruposPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/configuracao-modelos-avaliacao/{id}/remover-grupos', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoControllerRemoverConfiguracaoModeloAvaliacaoPeriodoGruposPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/configuracao-modelos-avaliacao/{id}/remover-grupos', pathParams, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoControllerRemoverConfiguracaoModeloAvaliacaoPeriodoAvaliacoesPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/configuracao-modelos-avaliacao/{id}/remover-avaliacoes', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoControllerRemoverConfiguracaoModeloAvaliacaoPeriodoAvaliacoesPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/configuracao-modelos-avaliacao/{id}/remover-avaliacoes', pathParams, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoControllerRemoverConfiguracaoModeloAvaliacaoPeriodoConceitosPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/configuracao-modelos-avaliacao/{id}/remover-conceitos', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoControllerRemoverConfiguracaoModeloAvaliacaoPeriodoConceitosPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/configuracao-modelos-avaliacao/{id}/remover-conceitos', pathParams, {}, options), config)

export const queryConfiguracaoModelosAvaliacaoPeriodoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracao-modelo-avaliacao-periodo/{id}', pathParams)
export const useQueryConfiguracaoModelosAvaliacaoPeriodoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelo-avaliacao-periodo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoModelosAvaliacaoPeriodoControllerObterPorId.queryKey = '/api/v1/configuracao-modelo-avaliacao-periodo/{id}'

export const queryConfiguracaoModelosAvaliacaoPeriodoControllerObterGrupos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracao-modelo-avaliacao-periodo/{id}/grupos', pathParams, queryParams)
export const useQueryConfiguracaoModelosAvaliacaoPeriodoControllerObterGrupos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelo-avaliacao-periodo/{id}/grupos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoModelosAvaliacaoPeriodoControllerObterGrupos.queryKey = '/api/v1/configuracao-modelo-avaliacao-periodo/{id}/grupos'

export const queryConfiguracaoModelosAvaliacaoPeriodoControllerObterConceitos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracao-modelo-avaliacao-periodo/{id}/conceitos', pathParams, queryParams)
export const useQueryConfiguracaoModelosAvaliacaoPeriodoControllerObterConceitos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelo-avaliacao-periodo/{id}/conceitos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoModelosAvaliacaoPeriodoControllerObterConceitos.queryKey = '/api/v1/configuracao-modelo-avaliacao-periodo/{id}/conceitos'

export const mutationConfiguracaoModelosAvaliacaoPeriodoConceitoControllerCadastrarModeloAvaliacaoPeriodoConceito = (options) => mutationFn('post', '/api/v1/configuracao-modelo-avaliacao-periodo-conceito', {}, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoConceitoControllerCadastrarModeloAvaliacaoPeriodoConceito = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-modelo-avaliacao-periodo-conceito', {}, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoPeriodoConceitoControllerAtualizarModeloAvaliacaoPeriodoConceito = (pathParams, options) => mutationFn('put', '/api/v1/configuracao-modelo-avaliacao-periodo-conceito/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoConceitoControllerAtualizarModeloAvaliacaoPeriodoConceito = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracao-modelo-avaliacao-periodo-conceito/{id}', pathParams, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoPeriodoConceitoControllerDeletarModeloAvaliacaoPeriodoConceito = (pathParams, options) => mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-conceito/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoConceitoControllerDeletarModeloAvaliacaoPeriodoConceito = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-conceito/{id}', pathParams, {}, options), config)

export const queryConfiguracaoModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/configuracao-modelo-avaliacao-periodo-conceito/conceitos-identicos-por-periodos', queryParams)
export const useQueryConfiguracaoModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelo-avaliacao-periodo-conceito/conceitos-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracaoModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo.queryKey = '/api/v1/configuracao-modelo-avaliacao-periodo-conceito/conceitos-identicos-por-periodos'

export const mutationConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerCadastrarModeloAvaliacaoPeriodoDetalhe = (options) => mutationFn('post', '/api/v1/configuracao-modelo-avaliacao-periodo-detalhe', {}, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerCadastrarModeloAvaliacaoPeriodoDetalhe = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-modelo-avaliacao-periodo-detalhe', {}, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/{id}', pathParams, {}, options), config)

export const queryConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/{id}', pathParams)
export const useQueryConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerObterPorId.queryKey = '/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/{id}'

export const mutationConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerDeletarAvaliacoesPorPeriodoId = (pathParams, options) => mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/{periodoId}/avaliacoes', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerDeletarAvaliacoesPorPeriodoId = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/{periodoId}/avaliacoes', pathParams, {}, options), config)

export const queryConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos', queryParams)
export const useQueryConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracaoModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo.queryKey = '/api/v1/configuracao-modelo-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos'

export const mutationConfiguracaoModelosAvaliacaoPeriodoFormulasControllerCadastrarModeloAvaliacaoPeriodoFormula = (options) => mutationFn('post', '/api/v1/configuracao-modelo-avaliacao-periodo-formula', {}, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoFormulasControllerCadastrarModeloAvaliacaoPeriodoFormula = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-modelo-avaliacao-periodo-formula', {}, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoPeriodoFormulasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracao-modelo-avaliacao-periodo-formula/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoFormulasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracao-modelo-avaliacao-periodo-formula/{id}', pathParams, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoPeriodoFormulasControllerDeletarModeloAvaliacaoPeriodoFormula = (pathParams, options) => mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-formula/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoFormulasControllerDeletarModeloAvaliacaoPeriodoFormula = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-formula/{id}', pathParams, {}, options), config)

export const queryConfiguracaoModelosAvaliacaoPeriodoFormulasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracao-modelo-avaliacao-periodo-formula/{id}', pathParams)
export const useQueryConfiguracaoModelosAvaliacaoPeriodoFormulasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelo-avaliacao-periodo-formula/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoModelosAvaliacaoPeriodoFormulasControllerObterPorId.queryKey = '/api/v1/configuracao-modelo-avaliacao-periodo-formula/{id}'

export const mutationConfiguracaoModelosAvaliacaoPeriodoGrupoControllerCadastrarModeloAvaliacaoPeriodoGrupo = (options) => mutationFn('post', '/api/v1/configuracao-modelo-avaliacao-periodo-grupo', {}, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoGrupoControllerCadastrarModeloAvaliacaoPeriodoGrupo = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracao-modelo-avaliacao-periodo-grupo', {}, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoPeriodoGrupoControllerAtualizarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('put', '/api/v1/configuracao-modelo-avaliacao-periodo-grupo/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoGrupoControllerAtualizarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracao-modelo-avaliacao-periodo-grupo/{id}', pathParams, {}, options), config)

export const mutationConfiguracaoModelosAvaliacaoPeriodoGrupoControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-grupo/{id}', pathParams, {}, options)
export const useMutationConfiguracaoModelosAvaliacaoPeriodoGrupoControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracao-modelo-avaliacao-periodo-grupo/{id}', pathParams, {}, options), config)

export const queryConfiguracaoModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/configuracao-modelo-avaliacao-periodo-grupo/grupos-identicos-por-periodos', queryParams)
export const useQueryConfiguracaoModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-modelo-avaliacao-periodo-grupo/grupos-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracaoModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo.queryKey = '/api/v1/configuracao-modelo-avaliacao-periodo-grupo/grupos-identicos-por-periodos'

export const queryConfiguracaoNotasParciaisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracao-notas-parciais/{id}', pathParams)
export const useQueryConfiguracaoNotasParciaisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracao-notas-parciais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracaoNotasParciaisControllerObterPorId.queryKey = '/api/v1/configuracao-notas-parciais/{id}'

export const mutationConfiguracaoNotasParciaisControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracao-notas-parciais/{id}', pathParams, {}, options)
export const useMutationConfiguracaoNotasParciaisControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracao-notas-parciais/{id}', pathParams, {}, options), config)

export const queryConfiguracoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/configuracoes/{chave}', pathParams)
export const useQueryConfiguracoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryConfiguracoesControllerObterPorChave.queryKey = '/api/v1/configuracoes/{chave}'

export const queryConfiguracoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracoes', queryParams)
export const useQueryConfiguracoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesControllerListar.queryKey = '/api/v1/configuracoes'

export const queryConfiguracoesControllerListarDetalhado = (queryParams, options) => queryFn(options)('/api/v1/configuracoes/detalhes', queryParams)
export const useQueryConfiguracoesControllerListarDetalhado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes/detalhes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesControllerListarDetalhado.queryKey = '/api/v1/configuracoes/detalhes'

export const queryConfiguracoesAppControllerObterConfiguracoes = (options) => queryFn(options)('/api/v1/configuracoes-app')
export const useQueryConfiguracoesAppControllerObterConfiguracoes = (config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-app'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesAppControllerObterConfiguracoes.queryKey = '/api/v1/configuracoes-app'

export const mutationConfiguracoesAppControllerCadastrarConfiguracaoApp = (options) => mutationFn('post', '/api/v1/configuracoes-app', {}, {}, options)
export const useMutationConfiguracoesAppControllerCadastrarConfiguracaoApp = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-app', {}, {}, options), config)

export const mutationConfiguracoesAppControllerAtivarSincronizacaoConfiguracaoApp = (options) => mutationFn('patch', '/api/v1/configuracoes-app/ativar-sincronizacao', {}, {}, options)
export const useMutationConfiguracoesAppControllerAtivarSincronizacaoConfiguracaoApp = (config, options) => useMutation(mutationFn('patch', '/api/v1/configuracoes-app/ativar-sincronizacao', {}, {}, options), config)

export const mutationConfiguracoesAppControllerCadastrarArquivo = (options) => mutationFn('post', '/api/v1/configuracoes-app/arquivo', {}, {}, options)
export const useMutationConfiguracoesAppControllerCadastrarArquivo = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-app/arquivo', {}, {}, options), config)

export const mutationConfiguracoesBolsaControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracoes-de-bolsa', {}, {}, options)
export const useMutationConfiguracoesBolsaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-bolsa', {}, {}, options), config)

export const queryConfiguracoesBolsaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa', queryParams)
export const useQueryConfiguracoesBolsaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesBolsaControllerListar.queryKey = '/api/v1/configuracoes-de-bolsa'

export const queryConfiguracoesBolsaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa/{id}', pathParams)
export const useQueryConfiguracoesBolsaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesBolsaControllerObterPorId.queryKey = '/api/v1/configuracoes-de-bolsa/{id}'

export const mutationConfiguracoesBolsaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-bolsa/{id}', pathParams, {}, options)
export const useMutationConfiguracoesBolsaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-bolsa/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesBolsaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-bolsa/{id}', pathParams, {}, options)
export const useMutationConfiguracoesBolsaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-bolsa/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesBolsaControllerCadastrarConfiguracaoBolsaConta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-bolsa/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesBolsaControllerCadastrarConfiguracaoBolsaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-bolsa/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesBolsaControllerListarConfiguracaoBolsaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesBolsaControllerListarConfiguracaoBolsaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesBolsaControllerListarConfiguracaoBolsaContas.queryKey = '/api/v1/configuracoes-de-bolsa/{id}/contas'

export const queryConfiguracoesBolsaControllerObterConfiguracaoBolsaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesBolsaControllerObterConfiguracaoBolsaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesBolsaControllerObterConfiguracaoBolsaContaPorId.queryKey = '/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}'

export const mutationConfiguracoesBolsaControllerAtualizarConfiguracaoBolsaConta = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesBolsaControllerAtualizarConfiguracaoBolsaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-bolsa/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesBolsaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-bolsa/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesBolsaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-bolsa/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesBolsaControllerListarContasDisponiveis.queryKey = '/api/v1/configuracoes-de-bolsa/{id}/contas-disponiveis'

export const queryConfiguracoesCartoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-cartoes/{id}', pathParams)
export const useQueryConfiguracoesCartoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-cartoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesCartoesControllerObterPorId.queryKey = '/api/v1/configuracoes-de-cartoes/{id}'

export const mutationConfiguracoesComissaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracoes-de-comissao', {}, {}, options)
export const useMutationConfiguracoesComissaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-comissao', {}, {}, options), config)

export const queryConfiguracoesComissaoControllerObterLista = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao', queryParams)
export const useQueryConfiguracoesComissaoControllerObterLista = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesComissaoControllerObterLista.queryKey = '/api/v1/configuracoes-de-comissao'

export const mutationConfiguracoesComissaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-comissao/{id}', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-comissao/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesComissaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-comissao/{id}', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-comissao/{id}', pathParams, {}, options), config)

export const queryConfiguracoesComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}', pathParams)
export const useQueryConfiguracoesComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesComissaoControllerObterPorId.queryKey = '/api/v1/configuracoes-de-comissao/{id}'

export const mutationConfiguracoesComissaoControllerCadastrarMeta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-comissao/{id}/meta', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerCadastrarMeta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-comissao/{id}/meta', pathParams, {}, options), config)

export const queryConfiguracoesComissaoControllerObterListaMeta = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}/metas', pathParams, queryParams)
export const useQueryConfiguracoesComissaoControllerObterListaMeta = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}/metas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesComissaoControllerObterListaMeta.queryKey = '/api/v1/configuracoes-de-comissao/{id}/metas'

export const mutationConfiguracoesComissaoControllerCadastrarComissaoConta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-comissao/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerCadastrarComissaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-comissao/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesComissaoControllerListarComissaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesComissaoControllerListarComissaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesComissaoControllerListarComissaoContas.queryKey = '/api/v1/configuracoes-de-comissao/{id}/contas'

export const queryConfiguracoesComissaoControllerObterComissaoContaPorComissaoIdContaId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesComissaoControllerObterComissaoContaPorComissaoIdContaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesComissaoControllerObterComissaoContaPorComissaoIdContaId.queryKey = '/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}'

export const mutationConfiguracoesComissaoControllerAtualizarComissaoConta = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesComissaoControllerAtualizarComissaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-comissao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesComissaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-comissao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesComissaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-comissao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesComissaoControllerListarContasDisponiveis.queryKey = '/api/v1/configuracoes-de-comissao/{id}/contas-disponiveis'

export const mutationConfiguracoesConvenioControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracoes-de-convenio', {}, {}, options)
export const useMutationConfiguracoesConvenioControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-convenio', {}, {}, options), config)

export const queryConfiguracoesConvenioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio', queryParams)
export const useQueryConfiguracoesConvenioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesConvenioControllerListar.queryKey = '/api/v1/configuracoes-de-convenio'

export const queryConfiguracoesConvenioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}', pathParams)
export const useQueryConfiguracoesConvenioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerObterPorId.queryKey = '/api/v1/configuracoes-de-convenio/{id}'

export const mutationConfiguracoesConvenioControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesConvenioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioConta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesConvenioControllerListarConfiguracaoConvenioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioContas.queryKey = '/api/v1/configuracoes-de-convenio/{id}/contas'

export const queryConfiguracoesConvenioControllerObterConfiguracaoConvenioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioContaPorId.queryKey = '/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}'

export const mutationConfiguracoesConvenioControllerAtualizarConfiguracaoConvenioConta = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerAtualizarConfiguracaoConvenioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesConvenioControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesConvenioControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerListarContasDisponiveis.queryKey = '/api/v1/configuracoes-de-convenio/{id}/contas-disponiveis'

export const mutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioDesconto = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/descontos', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/descontos', pathParams, {}, options), config)

export const queryConfiguracoesConvenioControllerListarConfiguracaoConvenioDescontos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/descontos', pathParams, queryParams)
export const useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioDescontos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioDescontos.queryKey = '/api/v1/configuracoes-de-convenio/{id}/descontos'

export const queryConfiguracoesConvenioControllerObterConfiguracaoConvenioDescontoPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams)
export const useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioDescontoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.descontoId, ...config }
})
useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioDescontoPorId.queryKey = '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}'

export const mutationConfiguracoesConvenioControllerAtualizarConfiguracaoConvenioDesconto = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerAtualizarConfiguracaoConvenioDesconto = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams, {}, options), config)

export const mutationConfiguracoesConvenioControllerDeletarConfiguracaoConvenioDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerDeletarConfiguracaoConvenioDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}/desconto/{descontoId}', pathParams, {}, options), config)

export const mutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioPortador = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/portadores', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerCadastrarConfiguracaoConvenioPortador = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-convenio/{id}/portadores', pathParams, {}, options), config)

export const queryConfiguracoesConvenioControllerListarConfiguracaoConvenioportadores = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/portadores', pathParams, queryParams)
export const useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioportadores = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/portadores', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesConvenioControllerListarConfiguracaoConvenioportadores.queryKey = '/api/v1/configuracoes-de-convenio/{id}/portadores'

export const queryConfiguracoesConvenioControllerObterConfiguracaoConvenioPortadorPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}', pathParams)
export const useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioPortadorPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.formaPagamentoId, ...config }
})
useQueryConfiguracoesConvenioControllerObterConfiguracaoConvenioPortadorPorId.queryKey = '/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}'

export const mutationConfiguracoesConvenioControllerDeletarConfiguracaoConvenioPortador = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}', pathParams, {}, options)
export const useMutationConfiguracoesConvenioControllerDeletarConfiguracaoConvenioPortador = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-convenio/{id}/portador/{formaPagamentoId}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerCadastrar = (options) => mutationFn('post', '/api/v1/configuracoes-de-desconto', {}, {}, options)
export const useMutationConfiguracoesDescontoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-desconto', {}, {}, options), config)

export const queryConfiguracoesDescontoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto', queryParams)
export const useQueryConfiguracoesDescontoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryConfiguracoesDescontoControllerListar.queryKey = '/api/v1/configuracoes-de-desconto'

export const queryConfiguracoesDescontoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}', pathParams)
export const useQueryConfiguracoesDescontoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerObterPorId.queryKey = '/api/v1/configuracoes-de-desconto/{id}'

export const mutationConfiguracoesDescontoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoConta = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/contas', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/contas', pathParams, {}, options), config)

export const queryConfiguracoesDescontoControllerListarConfiguracaoDescontoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/contas', pathParams, queryParams)
export const useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoContas.queryKey = '/api/v1/configuracoes-de-desconto/{id}/contas'

export const queryConfiguracoesDescontoControllerObterConfiguracaoDescontoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}', pathParams)
export const useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoContaPorId.queryKey = '/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}'

export const mutationConfiguracoesDescontoControllerAtualizarConfiguracaoDescontoConta = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerAtualizarConfiguracaoDescontoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryConfiguracoesDescontoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryConfiguracoesDescontoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerListarContasDisponiveis.queryKey = '/api/v1/configuracoes-de-desconto/{id}/contas-disponiveis'

export const mutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoDesconto = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/descontos', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/descontos', pathParams, {}, options), config)

export const queryConfiguracoesDescontoControllerListarConfiguracaoDescontoDescontos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/descontos', pathParams, queryParams)
export const useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoDescontos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoDescontos.queryKey = '/api/v1/configuracoes-de-desconto/{id}/descontos'

export const queryConfiguracoesDescontoControllerObterConfiguracaoDescontoDescontoPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams)
export const useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoDescontoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.descontoId, ...config }
})
useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoDescontoPorId.queryKey = '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}'

export const mutationConfiguracoesDescontoControllerAtualizarConfiguracaoDescontoDesconto = (pathParams, options) => mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerAtualizarConfiguracaoDescontoDesconto = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerDeletarConfiguracaoDescontoDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerDeletarConfiguracaoDescontoDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}/desconto/{descontoId}', pathParams, {}, options), config)

export const mutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoPortador = (pathParams, options) => mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/portadores', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerCadastrarConfiguracaoDescontoPortador = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/configuracoes-de-desconto/{id}/portadores', pathParams, {}, options), config)

export const queryConfiguracoesDescontoControllerListarConfiguracaoDescontoPortadores = (pathParams, queryParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/portadores', pathParams, queryParams)
export const useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoPortadores = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/portadores', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConfiguracoesDescontoControllerListarConfiguracaoDescontoPortadores.queryKey = '/api/v1/configuracoes-de-desconto/{id}/portadores'

export const queryConfiguracoesDescontoControllerObterConfiguracaoDescontoPortadorPorId = (pathParams, options) => queryFn(options)('/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}', pathParams)
export const useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoPortadorPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.formaPagamentoId, ...config }
})
useQueryConfiguracoesDescontoControllerObterConfiguracaoDescontoPortadorPorId.queryKey = '/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}'

export const mutationConfiguracoesDescontoControllerDeletarConfiguracaoDescontoPortador = (pathParams, options) => mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}', pathParams, {}, options)
export const useMutationConfiguracoesDescontoControllerDeletarConfiguracaoDescontoPortador = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/configuracoes-de-desconto/{id}/portador/{formaPagamentoId}', pathParams, {}, options), config)

export const queryContasControllerObterListaHorariosFuncionamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/horarios', pathParams, queryParams)
export const useQueryContasControllerObterListaHorariosFuncionamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/horarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterListaHorariosFuncionamento.queryKey = '/api/v1/contas/{id}/horarios'

export const queryContasControllerBuscarAutorizacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/autorizacoes', pathParams, queryParams)
export const useQueryContasControllerBuscarAutorizacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/autorizacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerBuscarAutorizacoes.queryKey = '/api/v1/contas/{id}/autorizacoes'

export const mutationContasControllerCadastrarAutorizacao = (pathParams, options) => mutationFn('post', '/api/v1/contas/{id}/autorizacoes', pathParams, {}, options)
export const useMutationContasControllerCadastrarAutorizacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas/{id}/autorizacoes', pathParams, {}, options), config)

export const mutationContasControllerCadastrar = (options) => mutationFn('post', '/api/v1/contas', {}, {}, options)
export const useMutationContasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/contas', {}, {}, options), config)

export const queryContasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/contas', queryParams)
export const useQueryContasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasControllerListar.queryKey = '/api/v1/contas'

export const queryContasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}', pathParams)
export const useQueryContasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterPorId.queryKey = '/api/v1/contas/{id}'

export const mutationContasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}', pathParams, {}, options)
export const useMutationContasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}', pathParams, {}, options), config)

export const queryContasControllerObterEmitenteReciboContaPorId = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}/emitente-recibo', pathParams)
export const useQueryContasControllerObterEmitenteReciboContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/emitente-recibo', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterEmitenteReciboContaPorId.queryKey = '/api/v1/contas/{id}/emitente-recibo'

export const mutationContasControllerAtualizarEmitenteRecibo = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}/emitente-recibo', pathParams, {}, options)
export const useMutationContasControllerAtualizarEmitenteRecibo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}/emitente-recibo', pathParams, {}, options), config)

export const mutationContasControllerFinalizarCadastro = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}/finalizar-cadastro', pathParams, {}, options)
export const useMutationContasControllerFinalizarCadastro = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}/finalizar-cadastro', pathParams, {}, options), config)

export const queryContasControllerObterCalendario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/calendario', pathParams, queryParams)
export const useQueryContasControllerObterCalendario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/calendario', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerObterCalendario.queryKey = '/api/v1/contas/{id}/calendario'

export const mutationContasControllerPersistirLogotipoEmpresa = (pathParams, options) => mutationFn('patch', '/api/v1/contas/{id}/logotipo', pathParams, {}, options)
export const useMutationContasControllerPersistirLogotipoEmpresa = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/contas/{id}/logotipo', pathParams, {}, options), config)

export const mutationContasControllerDeletarLogotipoEmpresa = (pathParams, options) => mutationFn('delete', '/api/v1/contas/{id}/logotipo', pathParams, {}, options)
export const useMutationContasControllerDeletarLogotipoEmpresa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas/{id}/logotipo', pathParams, {}, options), config)

export const mutationContasControllerPersistirBrasaoEstadoEmpresa = (pathParams, options) => mutationFn('patch', '/api/v1/contas/{id}/brasao', pathParams, {}, options)
export const useMutationContasControllerPersistirBrasaoEstadoEmpresa = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/contas/{id}/brasao', pathParams, {}, options), config)

export const mutationContasControllerDeletarBrasaoEstadoEmpresa = (pathParams, options) => mutationFn('delete', '/api/v1/contas/{id}/brasao', pathParams, {}, options)
export const useMutationContasControllerDeletarBrasaoEstadoEmpresa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas/{id}/brasao', pathParams, {}, options), config)

export const mutationContasControllerCadastrarContaEmissorPrestador = (pathParams, options) => mutationFn('post', '/api/v1/contas/{id}/emissor', pathParams, {}, options)
export const useMutationContasControllerCadastrarContaEmissorPrestador = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas/{id}/emissor', pathParams, {}, options), config)

export const queryContasControllerBuscarEmissorPrestador = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas/{id}/emissor', pathParams, queryParams)
export const useQueryContasControllerBuscarEmissorPrestador = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/emissor', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasControllerBuscarEmissorPrestador.queryKey = '/api/v1/contas/{id}/emissor'

export const mutationContasControllerAtualizarContaEmissorPrestador = (pathParams, options) => mutationFn('put', '/api/v1/contas/{id}/emissor/{emissorId}', pathParams, {}, options)
export const useMutationContasControllerAtualizarContaEmissorPrestador = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas/{id}/emissor/{emissorId}', pathParams, {}, options), config)

export const mutationContasControllerDeletarContaEmissorPrestador = (pathParams, options) => mutationFn('delete', '/api/v1/contas/{id}/emissor/{emissorId}', pathParams, {}, options)
export const useMutationContasControllerDeletarContaEmissorPrestador = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas/{id}/emissor/{emissorId}', pathParams, {}, options), config)

export const queryContasControllerObterEmissorPrestadorPorId = (pathParams, options) => queryFn(options)('/api/v1/contas/{id}/emissor/{emissorId}', pathParams)
export const useQueryContasControllerObterEmissorPrestadorPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas/{id}/emissor/{emissorId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.emissorId && pathParams.id, ...config }
})
useQueryContasControllerObterEmissorPrestadorPorId.queryKey = '/api/v1/contas/{id}/emissor/{emissorId}'

export const mutationContasControllerPersistirCertificadoEmissor = (pathParams, options) => mutationFn('patch', '/api/v1/contas/{id}/emissor/{idEmissor}/certificado', pathParams, {}, options)
export const useMutationContasControllerPersistirCertificadoEmissor = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/contas/{id}/emissor/{idEmissor}/certificado', pathParams, {}, options), config)

export const mutationContasControllerDeletarCertificadoEmissor = (pathParams, options) => mutationFn('delete', '/api/v1/contas/{id}/emissor/{idEmissor}/certificado', pathParams, {}, options)
export const useMutationContasControllerDeletarCertificadoEmissor = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas/{id}/emissor/{idEmissor}/certificado', pathParams, {}, options), config)

export const mutationContasBancariaControllerCadastrar = (options) => mutationFn('post', '/api/v1/contas-bancaria', {}, {}, options)
export const useMutationContasBancariaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/contas-bancaria', {}, {}, options), config)

export const queryContasBancariaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/contas-bancaria', queryParams)
export const useQueryContasBancariaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasBancariaControllerListar.queryKey = '/api/v1/contas-bancaria'

export const queryContasBancariaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}', pathParams)
export const useQueryContasBancariaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerObterPorId.queryKey = '/api/v1/contas-bancaria/{id}'

export const mutationContasBancariaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas-bancaria/{id}', pathParams, {}, options)
export const useMutationContasBancariaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-bancaria/{id}', pathParams, {}, options), config)

export const mutationContasBancariaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/contas-bancaria/{id}', pathParams, {}, options)
export const useMutationContasBancariaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas-bancaria/{id}', pathParams, {}, options), config)

export const mutationContasBancariaControllerCadastrarResponsavelContaBancaria = (pathParams, options) => mutationFn('post', '/api/v1/contas-bancaria/{id}/responsaveis', pathParams, {}, options)
export const useMutationContasBancariaControllerCadastrarResponsavelContaBancaria = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas-bancaria/{id}/responsaveis', pathParams, {}, options), config)

export const queryContasBancariaControllerListarResponsaveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/responsaveis', pathParams, queryParams)
export const useQueryContasBancariaControllerListarResponsaveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerListarResponsaveis.queryKey = '/api/v1/contas-bancaria/{id}/responsaveis'

export const mutationContasBancariaControllerDeletarResponsavelCliente = (pathParams, options) => mutationFn('delete', '/api/v1/contas-bancaria/{id}/responsaveis/{responsavelId}', pathParams, {}, options)
export const useMutationContasBancariaControllerDeletarResponsavelCliente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas-bancaria/{id}/responsaveis/{responsavelId}', pathParams, {}, options), config)

export const mutationContasBancariaControllerCadastrarContaBancariaConta = (pathParams, options) => mutationFn('post', '/api/v1/contas-bancaria/{id}/contas', pathParams, {}, options)
export const useMutationContasBancariaControllerCadastrarContaBancariaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas-bancaria/{id}/contas', pathParams, {}, options), config)

export const queryContasBancariaControllerListarContaBancariaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/contas', pathParams, queryParams)
export const useQueryContasBancariaControllerListarContaBancariaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerListarContaBancariaContas.queryKey = '/api/v1/contas-bancaria/{id}/contas'

export const queryContasBancariaControllerObterContaBancariaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/contas/{contaId}', pathParams)
export const useQueryContasBancariaControllerObterContaBancariaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryContasBancariaControllerObterContaBancariaContaPorId.queryKey = '/api/v1/contas-bancaria/{id}/contas/{contaId}'

export const mutationContasBancariaControllerAtualizarContaBancariaConta = (pathParams, options) => mutationFn('put', '/api/v1/contas-bancaria/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationContasBancariaControllerAtualizarContaBancariaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-bancaria/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryContasBancariaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryContasBancariaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerListarContasDisponiveis.queryKey = '/api/v1/contas-bancaria/{id}/contas-disponiveis'

export const queryContasBancariaControllerListarContasBancariasPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/listar-contas-bancarias-por-periodo', queryParams)
export const useQueryContasBancariaControllerListarContasBancariasPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/listar-contas-bancarias-por-periodo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasBancariaControllerListarContasBancariasPorPeriodo.queryKey = '/api/v1/contas-bancaria/listar-contas-bancarias-por-periodo'

export const queryContasBancariaControllerObterContaBancariaCaixaPorId = (pathParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/detalhado', pathParams)
export const useQueryContasBancariaControllerObterContaBancariaCaixaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/detalhado', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerObterContaBancariaCaixaPorId.queryKey = '/api/v1/contas-bancaria/{id}/detalhado'

export const queryContasBancariaControllerListarContasBancariaDetalhado = (queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/detalhado', queryParams)
export const useQueryContasBancariaControllerListarContasBancariaDetalhado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/detalhado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasBancariaControllerListarContasBancariaDetalhado.queryKey = '/api/v1/contas-bancaria/detalhado'

export const queryContasBancariaControllerExtratoContasBancaria = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-bancaria/{id}/extrato', pathParams, queryParams)
export const useQueryContasBancariaControllerExtratoContasBancaria = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-bancaria/{id}/extrato', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasBancariaControllerExtratoContasBancaria.queryKey = '/api/v1/contas-bancaria/{id}/extrato'

export const queryContasConfiguracoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/contas-configuracoes/{chave}', pathParams)
export const useQueryContasConfiguracoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryContasConfiguracoesControllerObterPorChave.queryKey = '/api/v1/contas-configuracoes/{chave}'

export const queryContasConfiguracoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/contas-configuracoes', queryParams)
export const useQueryContasConfiguracoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasConfiguracoesControllerListar.queryKey = '/api/v1/contas-configuracoes'

export const mutationContasConfiguracoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas-configuracoes/{id}', pathParams, {}, options)
export const useMutationContasConfiguracoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-configuracoes/{id}', pathParams, {}, options), config)

export const queryContasConfiguracoesControllerListarDetalhado = (queryParams, options) => queryFn(options)('/api/v1/contas-configuracoes/detalhes', queryParams)
export const useQueryContasConfiguracoesControllerListarDetalhado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes/detalhes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContasConfiguracoesControllerListarDetalhado.queryKey = '/api/v1/contas-configuracoes/detalhes'

export const mutationContasConfiguracoesOpenBankingControllerCadastrar = (pathParams, options) => mutationFn('post', '/api/v1/contas-configuracoes-open-banking/{contaId}', pathParams, {}, options)
export const useMutationContasConfiguracoesOpenBankingControllerCadastrar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contas-configuracoes-open-banking/{contaId}', pathParams, {}, options), config)

export const queryContasConfiguracoesOpenBankingControllerObterPorContaId = (pathParams, options) => queryFn(options)('/api/v1/contas-configuracoes-open-banking/{contaId}', pathParams)
export const useQueryContasConfiguracoesOpenBankingControllerObterPorContaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes-open-banking/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.contaId, ...config }
})
useQueryContasConfiguracoesOpenBankingControllerObterPorContaId.queryKey = '/api/v1/contas-configuracoes-open-banking/{contaId}'

export const mutationContasConfiguracoesOpenBankingControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contas-configuracoes-open-banking/{contaId}/{id}', pathParams, {}, options)
export const useMutationContasConfiguracoesOpenBankingControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-configuracoes-open-banking/{contaId}/{id}', pathParams, {}, options), config)

export const queryContasConfiguracoesOpenBankingControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/contas-configuracoes-open-banking/{contaId}/{id}', pathParams)
export const useQueryContasConfiguracoesOpenBankingControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-configuracoes-open-banking/{contaId}/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.contaId && pathParams.id, ...config }
})
useQueryContasConfiguracoesOpenBankingControllerObterPorId.queryKey = '/api/v1/contas-configuracoes-open-banking/{contaId}/{id}'

export const mutationContasConfiguracoesOpenBankingControllerDeletarContaConfiguracaoOpenBanking = (pathParams, options) => mutationFn('delete', '/api/v1/contas-configuracoes-open-banking/{id}', pathParams, {}, options)
export const useMutationContasConfiguracoesOpenBankingControllerDeletarContaConfiguracaoOpenBanking = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contas-configuracoes-open-banking/{id}', pathParams, {}, options), config)

export const queryContasEmissoresPrestadoresControllerGetById = (pathParams, options) => queryFn(options)('/api/v1/contas-emissores-prestadores/{id}', pathParams)
export const useQueryContasEmissoresPrestadoresControllerGetById = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-emissores-prestadores/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasEmissoresPrestadoresControllerGetById.queryKey = '/api/v1/contas-emissores-prestadores/{id}'

export const queryContasEmissoresPrestadoresControllerGetLogs = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contas-emissores-prestadores/{id}/logs', pathParams, queryParams)
export const useQueryContasEmissoresPrestadoresControllerGetLogs = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-emissores-prestadores/{id}/logs', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasEmissoresPrestadoresControllerGetLogs.queryKey = '/api/v1/contas-emissores-prestadores/{id}/logs'

export const mutationContasEmissoresPrestadoresControllerResend = (pathParams, options) => mutationFn('put', '/api/v1/contas-emissores-prestadores/{id}/reenviar', pathParams, {}, options)
export const useMutationContasEmissoresPrestadoresControllerResend = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contas-emissores-prestadores/{id}/reenviar', pathParams, {}, options), config)

export const queryContasEmissoresPrestadoresLogsControllerGetById = (pathParams, options) => queryFn(options)('/api/v1/contas-emissores-prestadores-logs/{id}', pathParams)
export const useQueryContasEmissoresPrestadoresLogsControllerGetById = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contas-emissores-prestadores-logs/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContasEmissoresPrestadoresLogsControllerGetById.queryKey = '/api/v1/contas-emissores-prestadores-logs/{id}'

export const queryContatosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/geral/contatos/{id}', pathParams)
export const useQueryContatosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContatosControllerObterPorId.queryKey = '/api/v1/geral/contatos/{id}'

export const mutationContatosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/geral/contatos/{id}', pathParams, {}, options)
export const useMutationContatosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/geral/contatos/{id}', pathParams, {}, options), config)

export const mutationContatosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/geral/contatos/{id}', pathParams, {}, options)
export const useMutationContatosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/geral/contatos/{id}', pathParams, {}, options), config)

export const queryConteudosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/conteudos/{id}', pathParams)
export const useQueryConteudosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/conteudos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConteudosControllerObterPorId.queryKey = '/api/v1/conteudos/{id}'

export const mutationConteudosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/conteudos/{id}', pathParams, {}, options)
export const useMutationConteudosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/conteudos/{id}', pathParams, {}, options), config)

export const mutationConteudosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/conteudos/{id}', pathParams, {}, options)
export const useMutationConteudosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/conteudos/{id}', pathParams, {}, options), config)

export const queryConteudosControllerListarConteudoAvaliacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/conteudos/{id}/avaliacoes', pathParams, queryParams)
export const useQueryConteudosControllerListarConteudoAvaliacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/conteudos/{id}/avaliacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConteudosControllerListarConteudoAvaliacoes.queryKey = '/api/v1/conteudos/{id}/avaliacoes'

export const mutationConteudosControllerCadastrarConteudoAvaliacao = (pathParams, options) => mutationFn('post', '/api/v1/conteudos/{id}/avaliacao', pathParams, {}, options)
export const useMutationConteudosControllerCadastrarConteudoAvaliacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/conteudos/{id}/avaliacao', pathParams, {}, options), config)

export const mutationConteudosControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/conteudos/{id}/arquivo', pathParams, {}, options)
export const useMutationConteudosControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/conteudos/{id}/arquivo', pathParams, {}, options), config)

export const queryConteudosControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/conteudos/{id}/arquivos', pathParams, queryParams)
export const useQueryConteudosControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/conteudos/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConteudosControllerListarArquivos.queryKey = '/api/v1/conteudos/{id}/arquivos'

export const mutationConteudosControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/conteudos/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationConteudosControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/conteudos/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const queryConteudosAulaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/conteudos-aula/{id}', pathParams)
export const useQueryConteudosAulaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/conteudos-aula/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConteudosAulaControllerObterPorId.queryKey = '/api/v1/conteudos-aula/{id}'

export const mutationConteudosAulaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/conteudos-aula/{id}', pathParams, {}, options)
export const useMutationConteudosAulaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/conteudos-aula/{id}', pathParams, {}, options), config)

export const mutationConteudosAulaControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/conteudos-aula/{id}', pathParams, {}, options)
export const useMutationConteudosAulaControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/conteudos-aula/{id}', pathParams, {}, options), config)

export const mutationConteudosAulaControllerCadastrarArquivo = (pathParams, queryParams, options) => mutationFn('post', '/api/v1/conteudos-aula/{id}/arquivo', pathParams, queryParams, options)
export const useMutationConteudosAulaControllerCadastrarArquivo = (pathParams, queryParams, config, options) => useMutation(mutationFn('post', '/api/v1/conteudos-aula/{id}/arquivo', pathParams, queryParams, options), config)

export const queryConteudosAulaControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/conteudos-aula/{id}/arquivos', pathParams, queryParams)
export const useQueryConteudosAulaControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/conteudos-aula/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConteudosAulaControllerListarArquivos.queryKey = '/api/v1/conteudos-aula/{id}/arquivos'

export const mutationConteudosAulaControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/conteudos-aula/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationConteudosAulaControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/conteudos-aula/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const queryConteudosAvaliacoesControllerObterConteudoAvaliacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/conteudos-avaliacoes/{id}', pathParams)
export const useQueryConteudosAvaliacoesControllerObterConteudoAvaliacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/conteudos-avaliacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConteudosAvaliacoesControllerObterConteudoAvaliacaoPorId.queryKey = '/api/v1/conteudos-avaliacoes/{id}'

export const mutationConteudosAvaliacoesControllerDeletarConteudoAvaliacao = (pathParams, options) => mutationFn('delete', '/api/v1/conteudos-avaliacoes/{id}', pathParams, {}, options)
export const useMutationConteudosAvaliacoesControllerDeletarConteudoAvaliacao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/conteudos-avaliacoes/{id}', pathParams, {}, options), config)

export const mutationContratosControllerCadastrar = (options) => mutationFn('post', '/api/v1/contratos', {}, {}, options)
export const useMutationContratosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/contratos', {}, {}, options), config)

export const queryContratosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/contratos', queryParams)
export const useQueryContratosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContratosControllerListar.queryKey = '/api/v1/contratos'

export const queryContratosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/contratos/{id}', pathParams)
export const useQueryContratosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerObterPorId.queryKey = '/api/v1/contratos/{id}'

export const mutationContratosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/contratos/{id}', pathParams, {}, options)
export const useMutationContratosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contratos/{id}', pathParams, {}, options), config)

export const mutationContratosControllerDeletarContrato = (pathParams, options) => mutationFn('delete', '/api/v1/contratos/{id}', pathParams, {}, options)
export const useMutationContratosControllerDeletarContrato = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contratos/{id}', pathParams, {}, options), config)

export const queryContratosControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/contratos/listar-filtros', queryParams)
export const useQueryContratosControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContratosControllerListarPorFiltros.queryKey = '/api/v1/contratos/listar-filtros'

export const queryContratosControllerListarOrcamentosSaida = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/orcamentos-saida', pathParams, queryParams)
export const useQueryContratosControllerListarOrcamentosSaida = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/orcamentos-saida', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerListarOrcamentosSaida.queryKey = '/api/v1/contratos/{id}/orcamentos-saida'

export const mutationContratosControllerAtualizarRelacionamentoOrcamento = (pathParams, options) => mutationFn('put', '/api/v1/contratos/{id}/orcamentos-saida', pathParams, {}, options)
export const useMutationContratosControllerAtualizarRelacionamentoOrcamento = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contratos/{id}/orcamentos-saida', pathParams, {}, options), config)

export const queryContratosControllerListarOrcamentosSaidaDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/orcamentos-saida-disponiveis', pathParams, queryParams)
export const useQueryContratosControllerListarOrcamentosSaidaDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/orcamentos-saida-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerListarOrcamentosSaidaDisponiveis.queryKey = '/api/v1/contratos/{id}/orcamentos-saida-disponiveis'

export const mutationContratosControllerDeletarOrcamentoEducacaoContrato = (pathParams, options) => mutationFn('delete', '/api/v1/contratos/{id}/orcamentos-saida/{orcamentoSaidaId}', pathParams, {}, options)
export const useMutationContratosControllerDeletarOrcamentoEducacaoContrato = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contratos/{id}/orcamentos-saida/{orcamentoSaidaId}', pathParams, {}, options), config)

export const queryContratosControllerListarPedidosVenda = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/pedidos-venda', pathParams, queryParams)
export const useQueryContratosControllerListarPedidosVenda = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/pedidos-venda', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerListarPedidosVenda.queryKey = '/api/v1/contratos/{id}/pedidos-venda'

export const mutationContratosControllerAtualizarRelacionamentoPedidoVenda = (pathParams, options) => mutationFn('put', '/api/v1/contratos/{id}/pedidos-venda', pathParams, {}, options)
export const useMutationContratosControllerAtualizarRelacionamentoPedidoVenda = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contratos/{id}/pedidos-venda', pathParams, {}, options), config)

export const queryContratosControllerListarPedidosVendaDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/pedidos-venda-disponiveis', pathParams, queryParams)
export const useQueryContratosControllerListarPedidosVendaDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/pedidos-venda-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerListarPedidosVendaDisponiveis.queryKey = '/api/v1/contratos/{id}/pedidos-venda-disponiveis'

export const mutationContratosControllerDeletarPedidoVendaEducacaoContrato = (pathParams, options) => mutationFn('delete', '/api/v1/contratos/{id}/pedidos-venda/{pedidoVendaId}', pathParams, {}, options)
export const useMutationContratosControllerDeletarPedidoVendaEducacaoContrato = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contratos/{id}/pedidos-venda/{pedidoVendaId}', pathParams, {}, options), config)

export const mutationContratosControllerAtualizarRelacionamentoTitulo = (pathParams, options) => mutationFn('put', '/api/v1/contratos/{id}/titulos', pathParams, {}, options)
export const useMutationContratosControllerAtualizarRelacionamentoTitulo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contratos/{id}/titulos', pathParams, {}, options), config)

export const mutationContratosControllerDeletarTituloEducacaoContrato = (pathParams, options) => mutationFn('delete', '/api/v1/contratos/{id}/titulos/{tituloId}', pathParams, {}, options)
export const useMutationContratosControllerDeletarTituloEducacaoContrato = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contratos/{id}/titulos/{tituloId}', pathParams, {}, options), config)

export const queryContratosControllerListarTurmas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/turmas', pathParams, queryParams)
export const useQueryContratosControllerListarTurmas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/turmas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerListarTurmas.queryKey = '/api/v1/contratos/{id}/turmas'

export const mutationContratosControllerCadastrarContratoTurmas = (pathParams, options) => mutationFn('post', '/api/v1/contratos/{id}/turmas', pathParams, {}, options)
export const useMutationContratosControllerCadastrarContratoTurmas = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contratos/{id}/turmas', pathParams, {}, options), config)

export const mutationContratosControllerDeletarContratoTurma = (pathParams, options) => mutationFn('delete', '/api/v1/contratos/turmas/{contratoTurmaId}', pathParams, {}, options)
export const useMutationContratosControllerDeletarContratoTurma = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contratos/turmas/{contratoTurmaId}', pathParams, {}, options), config)

export const mutationContratosControllerCadastrarCadastroPersonalizado = (pathParams, options) => mutationFn('post', '/api/v1/contratos/{id}/cadastros-personalizados', pathParams, {}, options)
export const useMutationContratosControllerCadastrarCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contratos/{id}/cadastros-personalizados', pathParams, {}, options), config)

export const queryContratosControllerObterCadastrosPersonalizadosPorContrato = (pathParams, options) => queryFn(options)('/api/v1/contratos/{id}/cadastros-personalizados', pathParams)
export const useQueryContratosControllerObterCadastrosPersonalizadosPorContrato = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/cadastros-personalizados', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerObterCadastrosPersonalizadosPorContrato.queryKey = '/api/v1/contratos/{id}/cadastros-personalizados'

export const mutationContratosControllerDeletarCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/contratos/{id}/cadastros-personalizados/{grupoEntidadeCadastroPersonalizadoContratoId}', pathParams, {}, options)
export const useMutationContratosControllerDeletarCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contratos/{id}/cadastros-personalizados/{grupoEntidadeCadastroPersonalizadoContratoId}', pathParams, {}, options), config)

export const queryContratosControllerListarCadastrosPersonalizadosDisponveisPorContrato = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/cadastros-personalizados-disponiveis', pathParams, queryParams)
export const useQueryContratosControllerListarCadastrosPersonalizadosDisponveisPorContrato = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/cadastros-personalizados-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerListarCadastrosPersonalizadosDisponveisPorContrato.queryKey = '/api/v1/contratos/{id}/cadastros-personalizados-disponiveis'

export const mutationContratosControllerCadastrarTagIntegrante = (pathParams, options) => mutationFn('post', '/api/v1/contratos/{id}/integrantes/{pessoaId}/tags', pathParams, {}, options)
export const useMutationContratosControllerCadastrarTagIntegrante = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contratos/{id}/integrantes/{pessoaId}/tags', pathParams, {}, options), config)

export const queryContratosControllerListarTagsIntegrante = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/integrantes/{pessoaId}/tags', pathParams, queryParams)
export const useQueryContratosControllerListarTagsIntegrante = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/integrantes/{pessoaId}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.pessoaId, ...config }
})
useQueryContratosControllerListarTagsIntegrante.queryKey = '/api/v1/contratos/{id}/integrantes/{pessoaId}/tags'

export const mutationContratosControllerDeletarTagIntegrante = (pathParams, options) => mutationFn('delete', '/api/v1/contratos/{id}/integrantes/{pessoaId}/tags/{tagId}', pathParams, {}, options)
export const useMutationContratosControllerDeletarTagIntegrante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contratos/{id}/integrantes/{pessoaId}/tags/{tagId}', pathParams, {}, options), config)

export const queryContratosControllerListarTagsDisponiveisIntegrante = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/integrantes/{pessoaId}/tags-disponiveis', pathParams, queryParams)
export const useQueryContratosControllerListarTagsDisponiveisIntegrante = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/integrantes/{pessoaId}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.pessoaId, ...config }
})
useQueryContratosControllerListarTagsDisponiveisIntegrante.queryKey = '/api/v1/contratos/{id}/integrantes/{pessoaId}/tags-disponiveis'

export const queryContratosControllerListarMatriculaContrato = (pathParams, queryParams, options) => queryFn(options)('/api/v1/contratos/{id}/matriculas-contratos', pathParams, queryParams)
export const useQueryContratosControllerListarMatriculaContrato = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/matriculas-contratos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerListarMatriculaContrato.queryKey = '/api/v1/contratos/{id}/matriculas-contratos'

export const mutationContratosControllerCadastrarMatriculaContrato = (pathParams, options) => mutationFn('post', '/api/v1/contratos/{id}/matriculas-contratos', pathParams, {}, options)
export const useMutationContratosControllerCadastrarMatriculaContrato = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/contratos/{id}/matriculas-contratos', pathParams, {}, options), config)

export const queryContratosControllerObterMatriculaContratoPorId = (pathParams, options) => queryFn(options)('/api/v1/contratos/{id}/matriculas-contratos/{matriculaContratoId}', pathParams)
export const useQueryContratosControllerObterMatriculaContratoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/{id}/matriculas-contratos/{matriculaContratoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.matriculaContratoId, ...config }
})
useQueryContratosControllerObterMatriculaContratoPorId.queryKey = '/api/v1/contratos/{id}/matriculas-contratos/{matriculaContratoId}'

export const mutationContratosControllerDeletarMatriculaContrato = (pathParams, options) => mutationFn('delete', '/api/v1/contratos/{id}/matriculas-contratos/{matriculaContratoId}', pathParams, {}, options)
export const useMutationContratosControllerDeletarMatriculaContrato = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/contratos/{id}/matriculas-contratos/{matriculaContratoId}', pathParams, {}, options), config)

export const queryContratosControllerObterHistoricoPorId = (pathParams, options) => queryFn(options)('/api/v1/contratos/historico/{id}', pathParams)
export const useQueryContratosControllerObterHistoricoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/historico/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryContratosControllerObterHistoricoPorId.queryKey = '/api/v1/contratos/historico/{id}'

export const mutationContratosControllerAtualizarHistorico = (pathParams, options) => mutationFn('put', '/api/v1/contratos/historico/{id}', pathParams, {}, options)
export const useMutationContratosControllerAtualizarHistorico = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/contratos/historico/{id}', pathParams, {}, options), config)

export const queryContratosControllerListarHistoricos = (queryParams, options) => queryFn(options)('/api/v1/contratos/historico', queryParams)
export const useQueryContratosControllerListarHistoricos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/contratos/historico', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryContratosControllerListarHistoricos.queryKey = '/api/v1/contratos/historico'

export const mutationConveniosOrcamentoSaidaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/convenios-orcamento-saida/{id}', pathParams, {}, options)
export const useMutationConveniosOrcamentoSaidaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/convenios-orcamento-saida/{id}', pathParams, {}, options), config)

export const queryConveniosOrcamentoSaidaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/convenios-orcamento-saida/{id}', pathParams)
export const useQueryConveniosOrcamentoSaidaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/convenios-orcamento-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryConveniosOrcamentoSaidaControllerObterPorId.queryKey = '/api/v1/convenios-orcamento-saida/{id}'

export const queryCoresControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cores', queryParams)
export const useQueryCoresControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCoresControllerListar.queryKey = '/api/v1/cores'

export const queryCoresControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cores/{id}', pathParams)
export const useQueryCoresControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cores/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCoresControllerObterPorId.queryKey = '/api/v1/cores/{id}'

export const queryCoresControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/cores/{chave}', pathParams)
export const useQueryCoresControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cores/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryCoresControllerObterPorChave.queryKey = '/api/v1/cores/{chave}'

export const mutationCredenciadorasControllerCadastrar = (options) => mutationFn('post', '/api/v1/credenciadoras', {}, {}, options)
export const useMutationCredenciadorasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/credenciadoras', {}, {}, options), config)

export const queryCredenciadorasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/credenciadoras', queryParams)
export const useQueryCredenciadorasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCredenciadorasControllerListar.queryKey = '/api/v1/credenciadoras'

export const queryCredenciadorasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/credenciadoras/{id}', pathParams)
export const useQueryCredenciadorasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCredenciadorasControllerObterPorId.queryKey = '/api/v1/credenciadoras/{id}'

export const mutationCredenciadorasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/credenciadoras/{id}', pathParams, {}, options)
export const useMutationCredenciadorasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/credenciadoras/{id}', pathParams, {}, options), config)

export const mutationCredenciadorasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/credenciadoras/{id}', pathParams, {}, options)
export const useMutationCredenciadorasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/credenciadoras/{id}', pathParams, {}, options), config)

export const mutationCredenciadorasControllerCadastrarCredenciadoraConta = (pathParams, options) => mutationFn('post', '/api/v1/credenciadoras/{id}/contas', pathParams, {}, options)
export const useMutationCredenciadorasControllerCadastrarCredenciadoraConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/credenciadoras/{id}/contas', pathParams, {}, options), config)

export const queryCredenciadorasControllerListarCredenciadoraContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/credenciadoras/{id}/contas', pathParams, queryParams)
export const useQueryCredenciadorasControllerListarCredenciadoraContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCredenciadorasControllerListarCredenciadoraContas.queryKey = '/api/v1/credenciadoras/{id}/contas'

export const queryCredenciadorasControllerObterCredenciadoraContaPorId = (pathParams, options) => queryFn(options)('/api/v1/credenciadoras/{id}/contas/{contaId}', pathParams)
export const useQueryCredenciadorasControllerObterCredenciadoraContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryCredenciadorasControllerObterCredenciadoraContaPorId.queryKey = '/api/v1/credenciadoras/{id}/contas/{contaId}'

export const mutationCredenciadorasControllerAtualizarCredenciadoraConta = (pathParams, options) => mutationFn('put', '/api/v1/credenciadoras/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationCredenciadorasControllerAtualizarCredenciadoraConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/credenciadoras/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryCredenciadorasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/credenciadoras/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryCredenciadorasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/credenciadoras/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCredenciadorasControllerListarContasDisponiveis.queryKey = '/api/v1/credenciadoras/{id}/contas-disponiveis'

export const mutationCriteriosAvaliacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/criterios-avaliacao/{id}', pathParams, {}, options)
export const useMutationCriteriosAvaliacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/criterios-avaliacao/{id}', pathParams, {}, options), config)

export const queryCriteriosAvaliacaoControllerObterCriterioAvaliacao = (pathParams, options) => queryFn(options)('/api/v1/criterios-avaliacao/{id}', pathParams)
export const useQueryCriteriosAvaliacaoControllerObterCriterioAvaliacao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/criterios-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCriteriosAvaliacaoControllerObterCriterioAvaliacao.queryKey = '/api/v1/criterios-avaliacao/{id}'

export const mutationCursosControllerCadastrar = (options) => mutationFn('post', '/api/v1/cursos', {}, {}, options)
export const useMutationCursosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/cursos', {}, {}, options), config)

export const queryCursosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/cursos', queryParams)
export const useQueryCursosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cursos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryCursosControllerListar.queryKey = '/api/v1/cursos'

export const queryCursosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/cursos/{id}', pathParams)
export const useQueryCursosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/cursos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryCursosControllerObterPorId.queryKey = '/api/v1/cursos/{id}'

export const mutationCursosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/cursos/{id}', pathParams, {}, options)
export const useMutationCursosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/cursos/{id}', pathParams, {}, options), config)

export const mutationCursosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/cursos/{id}', pathParams, {}, options)
export const useMutationCursosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/cursos/{id}', pathParams, {}, options), config)

export const queryDashboardAlunosControllerListarIndicadoresAlunos = (queryParams, options) => queryFn(options)('/api/v1/dashboard-alunos/indicadores', queryParams)
export const useQueryDashboardAlunosControllerListarIndicadoresAlunos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-alunos/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardAlunosControllerListarIndicadoresAlunos.queryKey = '/api/v1/dashboard-alunos/indicadores'

export const queryDashboardAlunosControllerListarDashboardContratoVigenteAnoFAixa = (queryParams, options) => queryFn(options)('/api/v1/dashboard-alunos/contratos-vigentes-ano-faixa', queryParams)
export const useQueryDashboardAlunosControllerListarDashboardContratoVigenteAnoFAixa = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-alunos/contratos-vigentes-ano-faixa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardAlunosControllerListarDashboardContratoVigenteAnoFAixa.queryKey = '/api/v1/dashboard-alunos/contratos-vigentes-ano-faixa'

export const queryDashboardClientesControllerObterAnosComClientesCadastrados = (options) => queryFn(options)('/api/v1/dashboard-clientes/anos-com-clientes-cadastrados')
export const useQueryDashboardClientesControllerObterAnosComClientesCadastrados = (config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-clientes/anos-com-clientes-cadastrados'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardClientesControllerObterAnosComClientesCadastrados.queryKey = '/api/v1/dashboard-clientes/anos-com-clientes-cadastrados'

export const queryDashboardClientesControllerObterIndicadoresClientesApp = (queryParams, options) => queryFn(options)('/api/v1/dashboard-clientes/indicadores-clientes-app', queryParams)
export const useQueryDashboardClientesControllerObterIndicadoresClientesApp = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-clientes/indicadores-clientes-app', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardClientesControllerObterIndicadoresClientesApp.queryKey = '/api/v1/dashboard-clientes/indicadores-clientes-app'

export const queryDashboardClientesControllerListarDashboardClientes = (queryParams, options) => queryFn(options)('/api/v1/dashboard-clientes/indicadores', queryParams)
export const useQueryDashboardClientesControllerListarDashboardClientes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-clientes/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardClientesControllerListarDashboardClientes.queryKey = '/api/v1/dashboard-clientes/indicadores'

export const queryDashboardClientesControllerObterAnosComAcessosApp = (options) => queryFn(options)('/api/v1/dashboard-clientes/anos-com-acessos-app')
export const useQueryDashboardClientesControllerObterAnosComAcessosApp = (config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-clientes/anos-com-acessos-app'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardClientesControllerObterAnosComAcessosApp.queryKey = '/api/v1/dashboard-clientes/anos-com-acessos-app'

export const queryDashboardClientesControllerObterIndicadoresAcessosApp = (queryParams, options) => queryFn(options)('/api/v1/dashboard-clientes/indicadores-acessos-app', queryParams)
export const useQueryDashboardClientesControllerObterIndicadoresAcessosApp = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-clientes/indicadores-acessos-app', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardClientesControllerObterIndicadoresAcessosApp.queryKey = '/api/v1/dashboard-clientes/indicadores-acessos-app'

export const queryDashboardComercialControllerListarDashoboardComercial = (queryParams, options) => queryFn(options)('/api/v1/dashboard-comercial', queryParams)
export const useQueryDashboardComercialControllerListarDashoboardComercial = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-comercial', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardComercialControllerListarDashoboardComercial.queryKey = '/api/v1/dashboard-comercial'

export const queryDashboardContasBancariasControllerListarDashoboardContaBancaria = (queryParams, options) => queryFn(options)('/api/v1/dashboard-movimentacoes-contas-bancarias', queryParams)
export const useQueryDashboardContasBancariasControllerListarDashoboardContaBancaria = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-movimentacoes-contas-bancarias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardContasBancariasControllerListarDashoboardContaBancaria.queryKey = '/api/v1/dashboard-movimentacoes-contas-bancarias'

export const queryDashboardDREsControllerListarDashboardDRE = (queryParams, options) => queryFn(options)('/api/v1/dashboard-dre/dashboard', queryParams)
export const useQueryDashboardDREsControllerListarDashboardDRE = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-dre/dashboard', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardDREsControllerListarDashboardDRE.queryKey = '/api/v1/dashboard-dre/dashboard'

export const queryDashboardDREsControllerListarDashboardResultadoExercicioDRE = (queryParams, options) => queryFn(options)('/api/v1/dashboard-dre/dashboard-resultado-exercicios', queryParams)
export const useQueryDashboardDREsControllerListarDashboardResultadoExercicioDRE = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-dre/dashboard-resultado-exercicios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardDREsControllerListarDashboardResultadoExercicioDRE.queryKey = '/api/v1/dashboard-dre/dashboard-resultado-exercicios'

export const queryDashboardDREsControllerListarIndicadoresDRE = (queryParams, options) => queryFn(options)('/api/v1/dashboard-dre/Indicadores', queryParams)
export const useQueryDashboardDREsControllerListarIndicadoresDRE = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-dre/Indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardDREsControllerListarIndicadoresDRE.queryKey = '/api/v1/dashboard-dre/Indicadores'

export const queryDashboardMatriculasControllerListarIndicadoresMatriculas = (queryParams, options) => queryFn(options)('/api/v1/dashboard-matriculas/indicadores', queryParams)
export const useQueryDashboardMatriculasControllerListarIndicadoresMatriculas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-matriculas/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardMatriculasControllerListarIndicadoresMatriculas.queryKey = '/api/v1/dashboard-matriculas/indicadores'

export const queryDashboardMovimentacoesCaixaGeralControllerListarIndicadoresMovimentacaoCaixaGeral = (queryParams, options) => queryFn(options)('/api/v1/dashboard-movimentacoes-caixa-geral/Indicadores', queryParams)
export const useQueryDashboardMovimentacoesCaixaGeralControllerListarIndicadoresMovimentacaoCaixaGeral = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-movimentacoes-caixa-geral/Indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardMovimentacoesCaixaGeralControllerListarIndicadoresMovimentacaoCaixaGeral.queryKey = '/api/v1/dashboard-movimentacoes-caixa-geral/Indicadores'

export const queryDashboardMovimentacoesCaixaGeralControllerListarDashboardMovimentacaoCaixaGeral = (queryParams, options) => queryFn(options)('/api/v1/dashboard-movimentacoes-caixa-geral', queryParams)
export const useQueryDashboardMovimentacoesCaixaGeralControllerListarDashboardMovimentacaoCaixaGeral = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-movimentacoes-caixa-geral', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardMovimentacoesCaixaGeralControllerListarDashboardMovimentacaoCaixaGeral.queryKey = '/api/v1/dashboard-movimentacoes-caixa-geral'

export const queryDashboardTarefaControllerObterIndicadores = (queryParams, options) => queryFn(options)('/api/v1/dashboard-tarefas/indicadores', queryParams)
export const useQueryDashboardTarefaControllerObterIndicadores = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-tarefas/indicadores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTarefaControllerObterIndicadores.queryKey = '/api/v1/dashboard-tarefas/indicadores'

export const queryDashboardTarefaControllerObterTarefas = (queryParams, options) => queryFn(options)('/api/v1/dashboard-tarefas/tarefas', queryParams)
export const useQueryDashboardTarefaControllerObterTarefas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-tarefas/tarefas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTarefaControllerObterTarefas.queryKey = '/api/v1/dashboard-tarefas/tarefas'

export const queryDashboardTipoMovimentacoesCaixaControllerListarDashboardTipoMovimentacaoEstornoCaixa = (queryParams, options) => queryFn(options)('/api/v1/dashboard-tipo-movimentacoes-caixa/Estorno', queryParams)
export const useQueryDashboardTipoMovimentacoesCaixaControllerListarDashboardTipoMovimentacaoEstornoCaixa = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-tipo-movimentacoes-caixa/Estorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTipoMovimentacoesCaixaControllerListarDashboardTipoMovimentacaoEstornoCaixa.queryKey = '/api/v1/dashboard-tipo-movimentacoes-caixa/Estorno'

export const queryDashboardTurmasControllerListarIndicadoresSituacaoTurmas = (queryParams, options) => queryFn(options)('/api/v1/dashboard-turmas/indicadores-situacao-turmas', queryParams)
export const useQueryDashboardTurmasControllerListarIndicadoresSituacaoTurmas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-turmas/indicadores-situacao-turmas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTurmasControllerListarIndicadoresSituacaoTurmas.queryKey = '/api/v1/dashboard-turmas/indicadores-situacao-turmas'

export const queryDashboardTurmasControllerObterAnosComTurmasCadastradas = (options) => queryFn(options)('/api/v1/dashboard-turmas/anos-com-turmas')
export const useQueryDashboardTurmasControllerObterAnosComTurmasCadastradas = (config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-turmas/anos-com-turmas'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTurmasControllerObterAnosComTurmasCadastradas.queryKey = '/api/v1/dashboard-turmas/anos-com-turmas'

export const queryDashboardTurmasControllerObterIndicadoresTurmasApp = (queryParams, options) => queryFn(options)('/api/v1/dashboard-turmas/indicadores-turmas-app', queryParams)
export const useQueryDashboardTurmasControllerObterIndicadoresTurmasApp = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dashboard-turmas/indicadores-turmas-app', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDashboardTurmasControllerObterIndicadoresTurmasApp.queryKey = '/api/v1/dashboard-turmas/indicadores-turmas-app'

export const queryDescontosControllerObter = (queryParams, options) => queryFn(options)('/api/v1/descontos', queryParams)
export const useQueryDescontosControllerObter = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/descontos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDescontosControllerObter.queryKey = '/api/v1/descontos'

export const mutationDescontosControllerCadastrar = (options) => mutationFn('post', '/api/v1/descontos', {}, {}, options)
export const useMutationDescontosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/descontos', {}, {}, options), config)

export const mutationDescontosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/descontos/{id}', pathParams, {}, options)
export const useMutationDescontosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/descontos/{id}', pathParams, {}, options), config)

export const queryDetalhesOportunidadesPersonalizadosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/detalhes-oportunidades-personalizados', queryParams)
export const useQueryDetalhesOportunidadesPersonalizadosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/detalhes-oportunidades-personalizados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDetalhesOportunidadesPersonalizadosControllerBuscar.queryKey = '/api/v1/detalhes-oportunidades-personalizados'

export const mutationDetalhesOportunidadesPersonalizadosControllerCadastrar = (options) => mutationFn('post', '/api/v1/detalhes-oportunidades-personalizados', {}, {}, options)
export const useMutationDetalhesOportunidadesPersonalizadosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/detalhes-oportunidades-personalizados', {}, {}, options), config)

export const queryDetalhesOportunidadesPersonalizadosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams)
export const useQueryDetalhesOportunidadesPersonalizadosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDetalhesOportunidadesPersonalizadosControllerObterPorId.queryKey = '/api/v1/detalhes-oportunidades-personalizados/{id}'

export const mutationDetalhesOportunidadesPersonalizadosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams, {}, options)
export const useMutationDetalhesOportunidadesPersonalizadosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams, {}, options), config)

export const mutationDetalhesOportunidadesPersonalizadosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams, {}, options)
export const useMutationDetalhesOportunidadesPersonalizadosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/detalhes-oportunidades-personalizados/{id}', pathParams, {}, options), config)

export const queryDiasSemanaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/dias-da-semana', queryParams)
export const useQueryDiasSemanaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dias-da-semana', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDiasSemanaControllerListar.queryKey = '/api/v1/dias-da-semana'

export const queryDiasSemanaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/dias-da-semana/{id}', pathParams)
export const useQueryDiasSemanaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dias-da-semana/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDiasSemanaControllerObterPorId.queryKey = '/api/v1/dias-da-semana/{id}'

export const queryDiasSemanaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/dias-da-semana/{chave}', pathParams)
export const useQueryDiasSemanaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dias-da-semana/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryDiasSemanaControllerObterPorChave.queryKey = '/api/v1/dias-da-semana/{chave}'

export const queryDireitosAprendizagemControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/direitos-aprendizagem', queryParams)
export const useQueryDireitosAprendizagemControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/direitos-aprendizagem', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDireitosAprendizagemControllerBuscar.queryKey = '/api/v1/direitos-aprendizagem'

export const mutationDireitosAprendizagemControllerCadastrar = (options) => mutationFn('post', '/api/v1/direitos-aprendizagem', {}, {}, options)
export const useMutationDireitosAprendizagemControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/direitos-aprendizagem', {}, {}, options), config)

export const queryDireitosAprendizagemControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/direitos-aprendizagem/{id}', pathParams)
export const useQueryDireitosAprendizagemControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/direitos-aprendizagem/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDireitosAprendizagemControllerObterPorId.queryKey = '/api/v1/direitos-aprendizagem/{id}'

export const mutationDireitosAprendizagemControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/direitos-aprendizagem/{id}', pathParams, {}, options)
export const useMutationDireitosAprendizagemControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/direitos-aprendizagem/{id}', pathParams, {}, options), config)

export const mutationDireitosAprendizagemControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/direitos-aprendizagem/{id}', pathParams, {}, options)
export const useMutationDireitosAprendizagemControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/direitos-aprendizagem/{id}', pathParams, {}, options), config)

export const mutationDireitosAprendizagemControllerCadastrarDireitoAprendizagemConta = (pathParams, options) => mutationFn('post', '/api/v1/direitos-aprendizagem/{id}/contas', pathParams, {}, options)
export const useMutationDireitosAprendizagemControllerCadastrarDireitoAprendizagemConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/direitos-aprendizagem/{id}/contas', pathParams, {}, options), config)

export const queryDireitosAprendizagemControllerListarDireitoAprendizagemContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/direitos-aprendizagem/{id}/contas', pathParams, queryParams)
export const useQueryDireitosAprendizagemControllerListarDireitoAprendizagemContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/direitos-aprendizagem/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDireitosAprendizagemControllerListarDireitoAprendizagemContas.queryKey = '/api/v1/direitos-aprendizagem/{id}/contas'

export const queryDireitosAprendizagemControllerObterDireitoAprendizagemContaPorId = (pathParams, options) => queryFn(options)('/api/v1/direitos-aprendizagem/{id}/contas/{contaId}', pathParams)
export const useQueryDireitosAprendizagemControllerObterDireitoAprendizagemContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/direitos-aprendizagem/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryDireitosAprendizagemControllerObterDireitoAprendizagemContaPorId.queryKey = '/api/v1/direitos-aprendizagem/{id}/contas/{contaId}'

export const mutationDireitosAprendizagemControllerAtualizarDireitoAprendizagemConta = (pathParams, options) => mutationFn('put', '/api/v1/direitos-aprendizagem/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationDireitosAprendizagemControllerAtualizarDireitoAprendizagemConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/direitos-aprendizagem/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryDireitosAprendizagemControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/direitos-aprendizagem/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryDireitosAprendizagemControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/direitos-aprendizagem/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDireitosAprendizagemControllerListarContasDisponiveis.queryKey = '/api/v1/direitos-aprendizagem/{id}/contas-disponiveis'

export const queryDisciplinasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/disciplinas', queryParams)
export const useQueryDisciplinasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDisciplinasControllerBuscar.queryKey = '/api/v1/disciplinas'

export const mutationDisciplinasControllerCadastrar = (options) => mutationFn('post', '/api/v1/disciplinas', {}, {}, options)
export const useMutationDisciplinasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/disciplinas', {}, {}, options), config)

export const queryDisciplinasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/disciplinas/{id}', pathParams)
export const useQueryDisciplinasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerObterPorId.queryKey = '/api/v1/disciplinas/{id}'

export const mutationDisciplinasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/disciplinas/{id}', pathParams, {}, options)
export const useMutationDisciplinasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/disciplinas/{id}', pathParams, {}, options), config)

export const mutationDisciplinasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/disciplinas/{id}', pathParams, {}, options)
export const useMutationDisciplinasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/disciplinas/{id}', pathParams, {}, options), config)

export const queryDisciplinasControllerBuscarSubdisciplinas = (queryParams, options) => queryFn(options)('/api/v1/disciplinas/subdisciplinas', queryParams)
export const useQueryDisciplinasControllerBuscarSubdisciplinas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/subdisciplinas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDisciplinasControllerBuscarSubdisciplinas.queryKey = '/api/v1/disciplinas/subdisciplinas'

export const queryDisciplinasControllerBuscarDisciplinasPai = (queryParams, options) => queryFn(options)('/api/v1/disciplinas/disciplinas-pai', queryParams)
export const useQueryDisciplinasControllerBuscarDisciplinasPai = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/disciplinas-pai', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDisciplinasControllerBuscarDisciplinasPai.queryKey = '/api/v1/disciplinas/disciplinas-pai'

export const queryDisciplinasControllerBuscarSubdisciplinasPorDisciplinaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/subdisciplinas', pathParams, queryParams)
export const useQueryDisciplinasControllerBuscarSubdisciplinasPorDisciplinaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/subdisciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerBuscarSubdisciplinasPorDisciplinaId.queryKey = '/api/v1/disciplinas/{id}/subdisciplinas'

export const mutationDisciplinasControllerCadastrarDisciplinaConta = (pathParams, options) => mutationFn('post', '/api/v1/disciplinas/{id}/contas', pathParams, {}, options)
export const useMutationDisciplinasControllerCadastrarDisciplinaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/disciplinas/{id}/contas', pathParams, {}, options), config)

export const queryDisciplinasControllerListarDisciplinaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/contas', pathParams, queryParams)
export const useQueryDisciplinasControllerListarDisciplinaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerListarDisciplinaContas.queryKey = '/api/v1/disciplinas/{id}/contas'

export const queryDisciplinasControllerObterDisciplinaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/contas/{contaId}', pathParams)
export const useQueryDisciplinasControllerObterDisciplinaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryDisciplinasControllerObterDisciplinaContaPorId.queryKey = '/api/v1/disciplinas/{id}/contas/{contaId}'

export const mutationDisciplinasControllerAtualizarDisciplinaConta = (pathParams, options) => mutationFn('put', '/api/v1/disciplinas/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationDisciplinasControllerAtualizarDisciplinaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/disciplinas/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryDisciplinasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryDisciplinasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerListarContasDisponiveis.queryKey = '/api/v1/disciplinas/{id}/contas-disponiveis'

export const mutationDisciplinasControllerCadastrarDisciplinaCompetencia = (pathParams, options) => mutationFn('post', '/api/v1/disciplinas/{id}/competencias-gerais', pathParams, {}, options)
export const useMutationDisciplinasControllerCadastrarDisciplinaCompetencia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/disciplinas/{id}/competencias-gerais', pathParams, {}, options), config)

export const queryDisciplinasControllerListarDisciplinaCompetenciasPorDisplinaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/competencias-gerais', pathParams, queryParams)
export const useQueryDisciplinasControllerListarDisciplinaCompetenciasPorDisplinaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/competencias-gerais', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerListarDisciplinaCompetenciasPorDisplinaId.queryKey = '/api/v1/disciplinas/{id}/competencias-gerais'

export const queryDisciplinasControllerObterDisciplinaCompetenciaGeralPorId = (pathParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/competencias-gerais/{competenciaGeralId}', pathParams)
export const useQueryDisciplinasControllerObterDisciplinaCompetenciaGeralPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/competencias-gerais/{competenciaGeralId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.competenciaGeralId, ...config }
})
useQueryDisciplinasControllerObterDisciplinaCompetenciaGeralPorId.queryKey = '/api/v1/disciplinas/{id}/competencias-gerais/{competenciaGeralId}'

export const mutationDisciplinasControllerDeletarDisciplinaCompetenciaGeral = (pathParams, options) => mutationFn('delete', '/api/v1/disciplinas/{id}/competencias-geral/{competenciaGeralId}', pathParams, {}, options)
export const useMutationDisciplinasControllerDeletarDisciplinaCompetenciaGeral = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/disciplinas/{id}/competencias-geral/{competenciaGeralId}', pathParams, {}, options), config)

export const mutationDisciplinasControllerCadastrarDisciplinaCompetenciaEspecifica = (pathParams, options) => mutationFn('post', '/api/v1/disciplinas/{id}/competencias-especificas', pathParams, {}, options)
export const useMutationDisciplinasControllerCadastrarDisciplinaCompetenciaEspecifica = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/disciplinas/{id}/competencias-especificas', pathParams, {}, options), config)

export const queryDisciplinasControllerListarDisciplinaCompetenciasEspecificasPorDisciplina = (pathParams, queryParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/competencias-especificas', pathParams, queryParams)
export const useQueryDisciplinasControllerListarDisciplinaCompetenciasEspecificasPorDisciplina = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/competencias-especificas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerListarDisciplinaCompetenciasEspecificasPorDisciplina.queryKey = '/api/v1/disciplinas/{id}/competencias-especificas'

export const queryDisciplinasControllerObterDisciplinaCompetenciaEspecificaPorId = (pathParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/competencias-especificas/{competenciaEspecificaId}', pathParams)
export const useQueryDisciplinasControllerObterDisciplinaCompetenciaEspecificaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/competencias-especificas/{competenciaEspecificaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.competenciaEspecificaId, ...config }
})
useQueryDisciplinasControllerObterDisciplinaCompetenciaEspecificaPorId.queryKey = '/api/v1/disciplinas/{id}/competencias-especificas/{competenciaEspecificaId}'

export const mutationDisciplinasControllerDeletarDisciplinaCompetenciaEspecifica = (pathParams, options) => mutationFn('delete', '/api/v1/disciplinas/{id}/competencia-especifica/{competenciaEspecificaId}', pathParams, {}, options)
export const useMutationDisciplinasControllerDeletarDisciplinaCompetenciaEspecifica = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/disciplinas/{id}/competencia-especifica/{competenciaEspecificaId}', pathParams, {}, options), config)

export const mutationDisciplinasControllerCadastrarDisciplinaAnoFaixa = (pathParams, options) => mutationFn('post', '/api/v1/disciplinas/{id}/anos-faixas', pathParams, {}, options)
export const useMutationDisciplinasControllerCadastrarDisciplinaAnoFaixa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/disciplinas/{id}/anos-faixas', pathParams, {}, options), config)

export const queryDisciplinasControllerListarDisciplinaAnosFaixas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/anos-faixas', pathParams, queryParams)
export const useQueryDisciplinasControllerListarDisciplinaAnosFaixas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/anos-faixas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerListarDisciplinaAnosFaixas.queryKey = '/api/v1/disciplinas/{id}/anos-faixas'

export const queryDisciplinasControllerObterDisciplinaAnoFaixaPorId = (pathParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/anos-faixa/{anoFaixaId}', pathParams)
export const useQueryDisciplinasControllerObterDisciplinaAnoFaixaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/anos-faixa/{anoFaixaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.anoFaixaId, ...config }
})
useQueryDisciplinasControllerObterDisciplinaAnoFaixaPorId.queryKey = '/api/v1/disciplinas/{id}/anos-faixa/{anoFaixaId}'

export const mutationDisciplinasControllerDeletarDisciplinaAnoFaixa = (pathParams, options) => mutationFn('delete', '/api/v1/disciplinas/{id}/anos-faixas/{anoFaixaId}', pathParams, {}, options)
export const useMutationDisciplinasControllerDeletarDisciplinaAnoFaixa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/disciplinas/{id}/anos-faixas/{anoFaixaId}', pathParams, {}, options), config)

export const mutationDisciplinasControllerCadastrarDisciplinaEtapa = (pathParams, options) => mutationFn('post', '/api/v1/disciplinas/{id}/etapas', pathParams, {}, options)
export const useMutationDisciplinasControllerCadastrarDisciplinaEtapa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/disciplinas/{id}/etapas', pathParams, {}, options), config)

export const queryDisciplinasControllerListarDisciplinaEtapas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/etapas', pathParams, queryParams)
export const useQueryDisciplinasControllerListarDisciplinaEtapas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/etapas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerListarDisciplinaEtapas.queryKey = '/api/v1/disciplinas/{id}/etapas'

export const queryDisciplinasControllerObterDisciplinaEtapaPorId = (pathParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/etapas/{etapaId}', pathParams)
export const useQueryDisciplinasControllerObterDisciplinaEtapaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/etapas/{etapaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.etapaId, ...config }
})
useQueryDisciplinasControllerObterDisciplinaEtapaPorId.queryKey = '/api/v1/disciplinas/{id}/etapas/{etapaId}'

export const mutationDisciplinasControllerAtualizarDisciplinaEtapa = (pathParams, options) => mutationFn('put', '/api/v1/disciplinas/{id}/etapas/{etapaId}', pathParams, {}, options)
export const useMutationDisciplinasControllerAtualizarDisciplinaEtapa = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/disciplinas/{id}/etapas/{etapaId}', pathParams, {}, options), config)

export const mutationDisciplinasControllerDeletarDisciplinaEtapa = (pathParams, options) => mutationFn('delete', '/api/v1/disciplinas/{id}/etapas/{etapaId}', pathParams, {}, options)
export const useMutationDisciplinasControllerDeletarDisciplinaEtapa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/disciplinas/{id}/etapas/{etapaId}', pathParams, {}, options), config)

export const queryDisciplinasControllerListarProfessoresHabilitados = (pathParams, queryParams, options) => queryFn(options)('/api/v1/disciplinas/{id}/professores-habilitados', pathParams, queryParams)
export const useQueryDisciplinasControllerListarProfessoresHabilitados = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/disciplinas/{id}/professores-habilitados', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDisciplinasControllerListarProfessoresHabilitados.queryKey = '/api/v1/disciplinas/{id}/professores-habilitados'

export const mutationDocumentosControllerObterPdfDocumentoPorId = (pathParams, options) => mutationFn('post', '/api/v1/documentos/{id}/impressao', pathParams, {}, options)
export const useMutationDocumentosControllerObterPdfDocumentoPorId = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/documentos/{id}/impressao', pathParams, {}, options), config)

export const mutationDocumentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/documentos/{id}', pathParams, {}, options)
export const useMutationDocumentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/documentos/{id}', pathParams, {}, options), config)

export const queryDocumentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/documentos/{id}', pathParams)
export const useQueryDocumentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDocumentosControllerObterPorId.queryKey = '/api/v1/documentos/{id}'

export const mutationDocumentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/documentos/{id}', pathParams, {}, options)
export const useMutationDocumentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/documentos/{id}', pathParams, {}, options), config)

export const queryDocumentosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/documentos', queryParams)
export const useQueryDocumentosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDocumentosControllerListar.queryKey = '/api/v1/documentos'

export const mutationDocumentosControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/documentos/{id}/arquivos', pathParams, {}, options)
export const useMutationDocumentosControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/documentos/{id}/arquivos', pathParams, {}, options), config)

export const queryDocumentosControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/documentos/{id}/arquivos', pathParams, queryParams)
export const useQueryDocumentosControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDocumentosControllerListarArquivos.queryKey = '/api/v1/documentos/{id}/arquivos'

export const mutationDocumentosControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/documentos/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationDocumentosControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/documentos/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationDocumentosControllerCadastrarDocumentoConta = (pathParams, options) => mutationFn('post', '/api/v1/documentos/{id}/contas', pathParams, {}, options)
export const useMutationDocumentosControllerCadastrarDocumentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/documentos/{id}/contas', pathParams, {}, options), config)

export const queryDocumentosControllerListarModeloDocumentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/documentos/{id}/contas', pathParams, queryParams)
export const useQueryDocumentosControllerListarModeloDocumentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDocumentosControllerListarModeloDocumentoContas.queryKey = '/api/v1/documentos/{id}/contas'

export const queryDocumentosControllerObterDocumentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/documentos/{id}/contas/{contaId}', pathParams)
export const useQueryDocumentosControllerObterDocumentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryDocumentosControllerObterDocumentoContaPorId.queryKey = '/api/v1/documentos/{id}/contas/{contaId}'

export const mutationDocumentosControllerAtualizarModeloDocumentoConta = (pathParams, options) => mutationFn('put', '/api/v1/documentos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationDocumentosControllerAtualizarModeloDocumentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/documentos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryDocumentosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/documentos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryDocumentosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryDocumentosControllerListarContasDisponiveis.queryKey = '/api/v1/documentos/{id}/contas-disponiveis'

export const queryDocumentosTagsControllerObterEntidadesOrigens = (queryParams, options) => queryFn(options)('/api/v1/documentos-tags/entidades-origens', queryParams)
export const useQueryDocumentosTagsControllerObterEntidadesOrigens = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos-tags/entidades-origens', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDocumentosTagsControllerObterEntidadesOrigens.queryKey = '/api/v1/documentos-tags/entidades-origens'

export const queryDocumentosTagsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/documentos-tags', queryParams)
export const useQueryDocumentosTagsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos-tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDocumentosTagsControllerListar.queryKey = '/api/v1/documentos-tags'

export const queryDocumentosTagsControllerListarPorEntidadeOrigemChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/documentos-tags/{entidadeOrigemChave}', pathParams, queryParams)
export const useQueryDocumentosTagsControllerListarPorEntidadeOrigemChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/documentos-tags/{entidadeOrigemChave}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.entidadeOrigemChave, ...config }
})
useQueryDocumentosTagsControllerListarPorEntidadeOrigemChave.queryKey = '/api/v1/documentos-tags/{entidadeOrigemChave}'

export const queryDREControllerBuscarExtratoServicos = (queryParams, options) => queryFn(options)('/api/v1/dre/plano-contas', queryParams)
export const useQueryDREControllerBuscarExtratoServicos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/dre/plano-contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryDREControllerBuscarExtratoServicos.queryKey = '/api/v1/dre/plano-contas'

export const mutationEmbalagensControllerAtualizarEmbalagem = (pathParams, options) => mutationFn('put', '/api/v1/embalagens/{id}', pathParams, {}, options)
export const useMutationEmbalagensControllerAtualizarEmbalagem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/embalagens/{id}', pathParams, {}, options), config)

export const mutationEmbalagensControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/embalagens/{id}', pathParams, {}, options)
export const useMutationEmbalagensControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/embalagens/{id}', pathParams, {}, options), config)

export const queryEmbalagensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/embalagens/{id}', pathParams)
export const useQueryEmbalagensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/embalagens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEmbalagensControllerObterPorId.queryKey = '/api/v1/embalagens/{id}'

export const queryEmitentesRecibosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/emitentes-recibo', queryParams)
export const useQueryEmitentesRecibosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/emitentes-recibo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEmitentesRecibosControllerListar.queryKey = '/api/v1/emitentes-recibo'

export const queryEmitentesRecibosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/emitentes-recibo/{id}', pathParams)
export const useQueryEmitentesRecibosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/emitentes-recibo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEmitentesRecibosControllerObterPorId.queryKey = '/api/v1/emitentes-recibo/{id}'

export const queryEnderecosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/enderecos/{id}', pathParams)
export const useQueryEnderecosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/enderecos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEnderecosControllerObterPorId.queryKey = '/api/v1/enderecos/{id}'

export const mutationEnderecosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/enderecos/{id}', pathParams, {}, options)
export const useMutationEnderecosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/enderecos/{id}', pathParams, {}, options), config)

export const mutationEnderecosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/enderecos/{id}', pathParams, {}, options)
export const useMutationEnderecosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/enderecos/{id}', pathParams, {}, options), config)

export const queryEnumeradoresControllerListar = (pathParams, queryParams, options) => queryFn(options)('/api/v1/enumeradores/{nomeEnumerador}', pathParams, queryParams)
export const useQueryEnumeradoresControllerListar = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/enumeradores/{nomeEnumerador}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.nomeEnumerador, ...config }
})
useQueryEnumeradoresControllerListar.queryKey = '/api/v1/enumeradores/{nomeEnumerador}'

export const queryEquipesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/equipes/{id}', pathParams)
export const useQueryEquipesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/equipes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEquipesControllerObterPorId.queryKey = '/api/v1/equipes/{id}'

export const mutationEquipesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/equipes/{id}', pathParams, {}, options)
export const useMutationEquipesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/equipes/{id}', pathParams, {}, options), config)

export const mutationEquipesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/equipes/{id}', pathParams, {}, options)
export const useMutationEquipesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/equipes/{id}', pathParams, {}, options), config)

export const queryEquipesControllerListarEquipeIntegrantes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/equipes/{id}/integrantes', pathParams, queryParams)
export const useQueryEquipesControllerListarEquipeIntegrantes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/equipes/{id}/integrantes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEquipesControllerListarEquipeIntegrantes.queryKey = '/api/v1/equipes/{id}/integrantes'

export const mutationEquipesControllerCadastrarEquipeIntegrante = (pathParams, options) => mutationFn('post', '/api/v1/equipes/{id}/integrantes', pathParams, {}, options)
export const useMutationEquipesControllerCadastrarEquipeIntegrante = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/equipes/{id}/integrantes', pathParams, {}, options), config)

export const mutationEquipesControllerDeletarEquipeIntegrante = (pathParams, options) => mutationFn('delete', '/api/v1/equipes/{id}/integrantes/{equipeIntegranteId}', pathParams, {}, options)
export const useMutationEquipesControllerDeletarEquipeIntegrante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/equipes/{id}/integrantes/{equipeIntegranteId}', pathParams, {}, options), config)

export const queryEscolaridadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/escolaridades', queryParams)
export const useQueryEscolaridadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/escolaridades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEscolaridadesControllerBuscar.queryKey = '/api/v1/escolaridades'

export const queryEscolaridadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/escolaridades/{id}', pathParams)
export const useQueryEscolaridadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/escolaridades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEscolaridadesControllerObterPorId.queryKey = '/api/v1/escolaridades/{id}'

export const queryEscolaridadesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/escolaridades/{chave}', pathParams)
export const useQueryEscolaridadesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/escolaridades/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryEscolaridadesControllerObterPorChave.queryKey = '/api/v1/escolaridades/{chave}'

export const mutationEspecialidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/especialidades', {}, {}, options)
export const useMutationEspecialidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/especialidades', {}, {}, options), config)

export const queryEspecialidadesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/especialidades', queryParams)
export const useQueryEspecialidadesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEspecialidadesControllerListar.queryKey = '/api/v1/especialidades'

export const mutationEspecialidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/especialidades/{id}', pathParams, {}, options)
export const useMutationEspecialidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/especialidades/{id}', pathParams, {}, options), config)

export const mutationEspecialidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/especialidades/{id}', pathParams, {}, options)
export const useMutationEspecialidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/especialidades/{id}', pathParams, {}, options), config)

export const queryEspecialidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/especialidades/{id}', pathParams)
export const useQueryEspecialidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEspecialidadesControllerObterPorId.queryKey = '/api/v1/especialidades/{id}'

export const queryEspecialidadesControllerListarSubespecialidades = (pathParams, queryParams, options) => queryFn(options)('/api/v1/especialidades/{id}/subespecialidades', pathParams, queryParams)
export const useQueryEspecialidadesControllerListarSubespecialidades = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}/subespecialidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEspecialidadesControllerListarSubespecialidades.queryKey = '/api/v1/especialidades/{id}/subespecialidades'

export const mutationEspecialidadesControllerCadastrarCargoConta = (pathParams, options) => mutationFn('post', '/api/v1/especialidades/{id}/contas', pathParams, {}, options)
export const useMutationEspecialidadesControllerCadastrarCargoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/especialidades/{id}/contas', pathParams, {}, options), config)

export const queryEspecialidadesControllerListarEspecialidadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/especialidades/{id}/contas', pathParams, queryParams)
export const useQueryEspecialidadesControllerListarEspecialidadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEspecialidadesControllerListarEspecialidadeContas.queryKey = '/api/v1/especialidades/{id}/contas'

export const queryEspecialidadesControllerObterEspecialidadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/especialidades/{id}/contas/{contaId}', pathParams)
export const useQueryEspecialidadesControllerObterEspecialidadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryEspecialidadesControllerObterEspecialidadeContaPorId.queryKey = '/api/v1/especialidades/{id}/contas/{contaId}'

export const mutationEspecialidadesControllerAtualizarEspecialidadesConta = (pathParams, options) => mutationFn('put', '/api/v1/especialidades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationEspecialidadesControllerAtualizarEspecialidadesConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/especialidades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryEspecialidadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/especialidades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryEspecialidadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEspecialidadesControllerListarContasDisponiveis.queryKey = '/api/v1/especialidades/{id}/contas-disponiveis'

export const queryEspecialidadesControllerObterEspecialidadesFuncionarios = (queryParams, options) => queryFn(options)('/api/v1/especialidades/funcionarios', queryParams)
export const useQueryEspecialidadesControllerObterEspecialidadesFuncionarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/especialidades/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEspecialidadesControllerObterEspecialidadesFuncionarios.queryKey = '/api/v1/especialidades/funcionarios'

export const queryEstadosCivisControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/estados-civis', queryParams)
export const useQueryEstadosCivisControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEstadosCivisControllerBuscar.queryKey = '/api/v1/estados-civis'

export const queryEstadosCivisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/estados-civis/{id}', pathParams)
export const useQueryEstadosCivisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEstadosCivisControllerObterPorId.queryKey = '/api/v1/estados-civis/{id}'

export const queryEstadosCivisControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/estados-civis/{chave}', pathParams)
export const useQueryEstadosCivisControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estados-civis/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryEstadosCivisControllerObterPorChave.queryKey = '/api/v1/estados-civis/{chave}'

export const queryEstoquesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/estoque', queryParams)
export const useQueryEstoquesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estoque', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEstoquesControllerListar.queryKey = '/api/v1/estoque'

export const queryEstoquesControllerBuscarEstoquePorId = (pathParams, options) => queryFn(options)('/api/v1/estoque/{id}', pathParams)
export const useQueryEstoquesControllerBuscarEstoquePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estoque/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEstoquesControllerBuscarEstoquePorId.queryKey = '/api/v1/estoque/{id}'

export const mutationEstoquesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/estoque/{id}', pathParams, {}, options)
export const useMutationEstoquesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/estoque/{id}', pathParams, {}, options), config)

export const queryEstoquesControllerBuscarLocaisComEstoque = (pathParams, queryParams, options) => queryFn(options)('/api/v1/estoque/{id}/locais-com-estoque', pathParams, queryParams)
export const useQueryEstoquesControllerBuscarLocaisComEstoque = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/estoque/{id}/locais-com-estoque', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEstoquesControllerBuscarLocaisComEstoque.queryKey = '/api/v1/estoque/{id}/locais-com-estoque'

export const queryEstoquesControllerObterIndicadoresEstoque = (options) => queryFn(options)('/api/v1/estoque/indicadores')
export const useQueryEstoquesControllerObterIndicadoresEstoque = (config, options) => useQuery({
  queryKey: ['/api/v1/estoque/indicadores'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEstoquesControllerObterIndicadoresEstoque.queryKey = '/api/v1/estoque/indicadores'

export const mutationEstoquesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/estoque/{estoqueId}', pathParams, {}, options)
export const useMutationEstoquesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/estoque/{estoqueId}', pathParams, {}, options), config)

export const queryEtapasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/etapas', queryParams)
export const useQueryEtapasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etapas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEtapasControllerBuscar.queryKey = '/api/v1/etapas'

export const mutationEtapasControllerCadastrar = (options) => mutationFn('post', '/api/v1/etapas', {}, {}, options)
export const useMutationEtapasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/etapas', {}, {}, options), config)

export const queryEtapasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/etapas/{id}', pathParams)
export const useQueryEtapasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etapas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEtapasControllerObterPorId.queryKey = '/api/v1/etapas/{id}'

export const mutationEtapasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/etapas/{id}', pathParams, {}, options)
export const useMutationEtapasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/etapas/{id}', pathParams, {}, options), config)

export const mutationEtapasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/etapas/{id}', pathParams, {}, options)
export const useMutationEtapasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/etapas/{id}', pathParams, {}, options), config)

export const queryEtapasControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/etapas/{chave}', pathParams)
export const useQueryEtapasControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etapas/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryEtapasControllerObterPorChave.queryKey = '/api/v1/etapas/{chave}'

export const queryEtapasControllerObterAnosFaixaDaEtapa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/etapas/{id}/anos-faixa', pathParams, queryParams)
export const useQueryEtapasControllerObterAnosFaixaDaEtapa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etapas/{id}/anos-faixa', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEtapasControllerObterAnosFaixaDaEtapa.queryKey = '/api/v1/etapas/{id}/anos-faixa'

export const mutationEtapasControllerCadastrarEtapaConta = (pathParams, options) => mutationFn('post', '/api/v1/etapas/{id}/contas', pathParams, {}, options)
export const useMutationEtapasControllerCadastrarEtapaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/etapas/{id}/contas', pathParams, {}, options), config)

export const queryEtapasControllerListarEtapaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/etapas/{id}/contas', pathParams, queryParams)
export const useQueryEtapasControllerListarEtapaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etapas/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEtapasControllerListarEtapaContas.queryKey = '/api/v1/etapas/{id}/contas'

export const queryEtapasControllerObterEtapaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/etapas/{id}/contas/{contaId}', pathParams)
export const useQueryEtapasControllerObterEtapaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etapas/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryEtapasControllerObterEtapaContaPorId.queryKey = '/api/v1/etapas/{id}/contas/{contaId}'

export const mutationEtapasControllerAtualizarEtapaConta = (pathParams, options) => mutationFn('put', '/api/v1/etapas/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationEtapasControllerAtualizarEtapaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/etapas/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryEtapasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/etapas/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryEtapasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etapas/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEtapasControllerListarContasDisponiveis.queryKey = '/api/v1/etapas/{id}/contas-disponiveis'

export const queryEtapasControllerListarAnosLetivosPorEtapa = (pathParams, options) => queryFn(options)('/api/v1/etapas/{id}/anos-letivos', pathParams)
export const useQueryEtapasControllerListarAnosLetivosPorEtapa = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etapas/{id}/anos-letivos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEtapasControllerListarAnosLetivosPorEtapa.queryKey = '/api/v1/etapas/{id}/anos-letivos'

export const queryEtniasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/etnias', queryParams)
export const useQueryEtniasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etnias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryEtniasControllerBuscar.queryKey = '/api/v1/etnias'

export const queryEtniasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/etnias/{id}', pathParams)
export const useQueryEtniasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etnias/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryEtniasControllerObterPorId.queryKey = '/api/v1/etnias/{id}'

export const queryEtniasControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/etnias/{chave}', pathParams)
export const useQueryEtniasControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/etnias/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryEtniasControllerObterPorChave.queryKey = '/api/v1/etnias/{chave}'

export const queryExtratoServicosControllerBuscarExtratoServicos = (queryParams, options) => queryFn(options)('/api/v1/extrato-servicos', queryParams)
export const useQueryExtratoServicosControllerBuscarExtratoServicos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/extrato-servicos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryExtratoServicosControllerBuscarExtratoServicos.queryKey = '/api/v1/extrato-servicos'

export const mutationFabricantesControllerCadastrar = (options) => mutationFn('post', '/api/v1/geral/fabricantes', {}, {}, options)
export const useMutationFabricantesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/geral/fabricantes', {}, {}, options), config)

export const queryFabricantesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/geral/fabricantes', queryParams)
export const useQueryFabricantesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFabricantesControllerBuscar.queryKey = '/api/v1/geral/fabricantes'

export const mutationFabricantesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/geral/fabricantes/{id}', pathParams, {}, options)
export const useMutationFabricantesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/geral/fabricantes/{id}', pathParams, {}, options), config)

export const mutationFabricantesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/geral/fabricantes/{id}', pathParams, {}, options)
export const useMutationFabricantesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/geral/fabricantes/{id}', pathParams, {}, options), config)

export const queryFabricantesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/geral/fabricantes/{id}', pathParams)
export const useQueryFabricantesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFabricantesControllerObterPorId.queryKey = '/api/v1/geral/fabricantes/{id}'

export const mutationFabricantesControllerCadastrarFabricanteConta = (pathParams, options) => mutationFn('post', '/api/v1/geral/fabricantes/{id}/contas', pathParams, {}, options)
export const useMutationFabricantesControllerCadastrarFabricanteConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/geral/fabricantes/{id}/contas', pathParams, {}, options), config)

export const queryFabricantesControllerListarFabricanteContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/geral/fabricantes/{id}/contas', pathParams, queryParams)
export const useQueryFabricantesControllerListarFabricanteContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFabricantesControllerListarFabricanteContas.queryKey = '/api/v1/geral/fabricantes/{id}/contas'

export const queryFabricantesControllerObterFabricanteContaPorId = (pathParams, options) => queryFn(options)('/api/v1/geral/fabricantes/{id}/contas/{contaId}', pathParams)
export const useQueryFabricantesControllerObterFabricanteContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFabricantesControllerObterFabricanteContaPorId.queryKey = '/api/v1/geral/fabricantes/{id}/contas/{contaId}'

export const mutationFabricantesControllerAtualizarFabricanteConta = (pathParams, options) => mutationFn('put', '/api/v1/geral/fabricantes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFabricantesControllerAtualizarFabricanteConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/geral/fabricantes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFabricantesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/geral/fabricantes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFabricantesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/geral/fabricantes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFabricantesControllerListarContasDisponiveis.queryKey = '/api/v1/geral/fabricantes/{id}/contas-disponiveis'

export const queryFaseFunilControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fases-funis/{id}', pathParams)
export const useQueryFaseFunilControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-funis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaseFunilControllerObterPorId.queryKey = '/api/v1/fases-funis/{id}'

export const mutationFaseFunilControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/fases-funis/{id}', pathParams, {}, options)
export const useMutationFaseFunilControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fases-funis/{id}', pathParams, {}, options), config)

export const mutationFaseFunilControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/fases-funis/{id}', pathParams, {}, options)
export const useMutationFaseFunilControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fases-funis/{id}', pathParams, {}, options), config)

export const queryFaseFunilControllerListaPreTarefasPorFaseFunilId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fases-funis/{id}/templates-tarefa', pathParams, queryParams)
export const useQueryFaseFunilControllerListaPreTarefasPorFaseFunilId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-funis/{id}/templates-tarefa', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaseFunilControllerListaPreTarefasPorFaseFunilId.queryKey = '/api/v1/fases-funis/{id}/templates-tarefa'

export const mutationFaseFunilControllerCadastrarTemplateTarefa = (pathParams, options) => mutationFn('post', '/api/v1/fases-funis/{id}/templates-tarefa', pathParams, {}, options)
export const useMutationFaseFunilControllerCadastrarTemplateTarefa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/fases-funis/{id}/templates-tarefa', pathParams, {}, options), config)

export const queryFaseFunilTemplatesTarefaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/fase-funil-templates-tarefa', queryParams)
export const useQueryFaseFunilTemplatesTarefaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fase-funil-templates-tarefa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaseFunilTemplatesTarefaControllerBuscar.queryKey = '/api/v1/fase-funil-templates-tarefa'

export const queryFaseFunilTemplatesTarefaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fase-funil-templates-tarefa/{id}', pathParams)
export const useQueryFaseFunilTemplatesTarefaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fase-funil-templates-tarefa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaseFunilTemplatesTarefaControllerObterPorId.queryKey = '/api/v1/fase-funil-templates-tarefa/{id}'

export const mutationFaseFunilTemplatesTarefaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/fase-funil-templates-tarefa/{id}', pathParams, {}, options)
export const useMutationFaseFunilTemplatesTarefaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fase-funil-templates-tarefa/{id}', pathParams, {}, options), config)

export const mutationFaseFunilTemplatesTarefaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/fase-funil-templates-tarefa/{id}', pathParams, {}, options)
export const useMutationFaseFunilTemplatesTarefaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fase-funil-templates-tarefa/{id}', pathParams, {}, options), config)

export const queryFaseTarefaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/fases-tarefa', queryParams)
export const useQueryFaseTarefaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-tarefa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaseTarefaControllerListar.queryKey = '/api/v1/fases-tarefa'

export const queryFaseTarefaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fases-tarefa/{id}', pathParams)
export const useQueryFaseTarefaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-tarefa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaseTarefaControllerObterPorId.queryKey = '/api/v1/fases-tarefa/{id}'

export const queryFaseTarefaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/fases-tarefa/{chave}', pathParams)
export const useQueryFaseTarefaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fases-tarefa/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryFaseTarefaControllerObterPorChave.queryKey = '/api/v1/fases-tarefa/{chave}'

export const queryFaturasControllerObterStatus = (queryParams, options) => queryFn(options)('/api/v1/faturas-retaguarda/status', queryParams)
export const useQueryFaturasControllerObterStatus = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/status', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterStatus.queryKey = '/api/v1/faturas-retaguarda/status'

export const queryFaturasControllerObterServicosFiltraveisListagem = (options) => queryFn(options)('/api/v1/faturas-retaguarda/filtros-listagem')
export const useQueryFaturasControllerObterServicosFiltraveisListagem = (config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/filtros-listagem'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterServicosFiltraveisListagem.queryKey = '/api/v1/faturas-retaguarda/filtros-listagem'

export const queryFaturasControllerObterFaturas = (queryParams, options) => queryFn(options)('/api/v1/faturas-retaguarda', queryParams)
export const useQueryFaturasControllerObterFaturas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerObterFaturas.queryKey = '/api/v1/faturas-retaguarda'

export const queryFaturasControllerObterFaturaPorId = (pathParams, options) => queryFn(options)('/api/v1/faturas-retaguarda/{id}', pathParams)
export const useQueryFaturasControllerObterFaturaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFaturasControllerObterFaturaPorId.queryKey = '/api/v1/faturas-retaguarda/{id}'

export const queryFaturasControllerListarOutrosServicos = (options) => queryFn(options)('/api/v1/faturas-retaguarda/outros-servicos')
export const useQueryFaturasControllerListarOutrosServicos = (config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/outros-servicos'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerListarOutrosServicos.queryKey = '/api/v1/faturas-retaguarda/outros-servicos'

export const queryFaturasControllerListarFares = (queryParams, options) => queryFn(options)('/api/v1/faturas-retaguarda/fares', queryParams)
export const useQueryFaturasControllerListarFares = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/faturas-retaguarda/fares', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFaturasControllerListarFares.queryKey = '/api/v1/faturas-retaguarda/fares'

export const mutationFeriadoEventoPeriodosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/feriados-eventos-periodos/{id}', pathParams, {}, options)
export const useMutationFeriadoEventoPeriodosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/feriados-eventos-periodos/{id}', pathParams, {}, options), config)

export const mutationFeriadoEventoPeriodosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/feriados-eventos-periodos/{id}', pathParams, {}, options)
export const useMutationFeriadoEventoPeriodosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/feriados-eventos-periodos/{id}', pathParams, {}, options), config)

export const queryFeriadoEventoPeriodosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/feriados-eventos-periodos/{id}', pathParams)
export const useQueryFeriadoEventoPeriodosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-eventos-periodos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadoEventoPeriodosControllerObterPorId.queryKey = '/api/v1/feriados-eventos-periodos/{id}'

export const mutationFeriadosEventosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/feriados-e-eventos/{id}', pathParams, {}, options)
export const useMutationFeriadosEventosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/feriados-e-eventos/{id}', pathParams, {}, options), config)

export const mutationFeriadosEventosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/feriados-e-eventos/{id}', pathParams, {}, options)
export const useMutationFeriadosEventosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/feriados-e-eventos/{id}', pathParams, {}, options), config)

export const queryFeriadosEventosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/feriados-e-eventos/{id}', pathParams)
export const useQueryFeriadosEventosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosControllerObterPorId.queryKey = '/api/v1/feriados-e-eventos/{id}'

export const queryFeriadosEventosControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/feriados-e-eventos/buscar-detalhado/{id}', pathParams)
export const useQueryFeriadosEventosControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosControllerObterPorIdDetalhado.queryKey = '/api/v1/feriados-e-eventos/buscar-detalhado/{id}'

export const queryFeriadosEventosControllerBuscarTodosOsFeriadosEventos = (queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos', queryParams)
export const useQueryFeriadosEventosControllerBuscarTodosOsFeriadosEventos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFeriadosEventosControllerBuscarTodosOsFeriadosEventos.queryKey = '/api/v1/feriados-e-eventos'

export const mutationFeriadosEventosControllerCadastrarFeriadoEventoPeriodo = (pathParams, options) => mutationFn('post', '/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo', pathParams, {}, options)
export const useMutationFeriadosEventosControllerCadastrarFeriadoEventoPeriodo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo', pathParams, {}, options), config)

export const queryFeriadosEventosControllerBuscarPeriodos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo', pathParams, queryParams)
export const useQueryFeriadosEventosControllerBuscarPeriodos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosControllerBuscarPeriodos.queryKey = '/api/v1/feriados-e-eventos/{id}/feriados-e-eventos-periodo'

export const mutationFeriadosEventosEducacaoControllerAtualizar = (pathParams, queryParams, options) => mutationFn('put', '/api/v1/feriados-e-eventos-educacao/{id}', pathParams, queryParams, options)
export const useMutationFeriadosEventosEducacaoControllerAtualizar = (pathParams, queryParams, config, options) => useMutation(mutationFn('put', '/api/v1/feriados-e-eventos-educacao/{id}', pathParams, queryParams, options), config)

export const mutationFeriadosEventosEducacaoControllerDeletar = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/feriados-e-eventos-educacao/{id}', pathParams, queryParams, options)
export const useMutationFeriadosEventosEducacaoControllerDeletar = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/feriados-e-eventos-educacao/{id}', pathParams, queryParams, options), config)

export const queryFeriadosEventosEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/feriados-e-eventos-educacao/{id}', pathParams)
export const useQueryFeriadosEventosEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos-educacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosEducacaoControllerObterPorId.queryKey = '/api/v1/feriados-e-eventos-educacao/{id}'

export const mutationFeriadosEventosEducacaoControllerCancelar = (pathParams, queryParams, options) => mutationFn('put', '/api/v1/feriados-e-eventos-educacao/cancelar/{id}', pathParams, queryParams, options)
export const useMutationFeriadosEventosEducacaoControllerCancelar = (pathParams, queryParams, config, options) => useMutation(mutationFn('put', '/api/v1/feriados-e-eventos-educacao/cancelar/{id}', pathParams, queryParams, options), config)

export const mutationFeriadosEventosEducacaoControllerCadastrarFeriadoEventoPeriodo = (pathParams, options) => mutationFn('post', '/api/v1/feriados-e-eventos-educacao/{id}/feriados-e-eventos-periodo', pathParams, {}, options)
export const useMutationFeriadosEventosEducacaoControllerCadastrarFeriadoEventoPeriodo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/feriados-e-eventos-educacao/{id}/feriados-e-eventos-periodo', pathParams, {}, options), config)

export const mutationFeriadosEventosEducacaoControllerDeletarFeriadoEventoPeriodos = (pathParams, options) => mutationFn('delete', '/api/v1/feriados-e-eventos-educacao/feriados-e-eventos-periodo/{id}', pathParams, {}, options)
export const useMutationFeriadosEventosEducacaoControllerDeletarFeriadoEventoPeriodos = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/feriados-e-eventos-educacao/feriados-e-eventos-periodo/{id}', pathParams, {}, options), config)

export const queryFeriadosEventosEducacaoControllerListaFeriadoEventoPeriodos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos-educacao/feriados-e-eventos-periodo/{id}', pathParams, queryParams)
export const useQueryFeriadosEventosEducacaoControllerListaFeriadoEventoPeriodos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos-educacao/feriados-e-eventos-periodo/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosEducacaoControllerListaFeriadoEventoPeriodos.queryKey = '/api/v1/feriados-e-eventos-educacao/feriados-e-eventos-periodo/{id}'

export const queryFeriadosEventosEducacaoControllerListaTipoPeriodos = (queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos-educacao/tipo-periodos', queryParams)
export const useQueryFeriadosEventosEducacaoControllerListaTipoPeriodos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos-educacao/tipo-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFeriadosEventosEducacaoControllerListaTipoPeriodos.queryKey = '/api/v1/feriados-e-eventos-educacao/tipo-periodos'

export const queryFeriadosEventosEducacaoControllerBuscarTodosOsFeriadosEventosEducacao = (queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos-educacao/feriados-evento-educacao', queryParams)
export const useQueryFeriadosEventosEducacaoControllerBuscarTodosOsFeriadosEventosEducacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos-educacao/feriados-evento-educacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFeriadosEventosEducacaoControllerBuscarTodosOsFeriadosEventosEducacao.queryKey = '/api/v1/feriados-e-eventos-educacao/feriados-evento-educacao'

export const queryFeriadosEventosEducacaoControllerObterFeriadosEventosEducacaoApp = (queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos-educacao/feriados-e-eventos-app', queryParams)
export const useQueryFeriadosEventosEducacaoControllerObterFeriadosEventosEducacaoApp = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos-educacao/feriados-e-eventos-app', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFeriadosEventosEducacaoControllerObterFeriadosEventosEducacaoApp.queryKey = '/api/v1/feriados-e-eventos-educacao/feriados-e-eventos-app'

export const queryFeriadosEventosEducacaoControllerObterTotaisResponsaveisPorFeriadoEventoEducacaoId = (pathParams, options) => queryFn(options)('/api/v1/feriados-e-eventos-educacao/{id}/totais-responsaveis', pathParams)
export const useQueryFeriadosEventosEducacaoControllerObterTotaisResponsaveisPorFeriadoEventoEducacaoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos-educacao/{id}/totais-responsaveis', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosEducacaoControllerObterTotaisResponsaveisPorFeriadoEventoEducacaoId.queryKey = '/api/v1/feriados-e-eventos-educacao/{id}/totais-responsaveis'

export const queryFeriadosEventosEducacaoControllerListar = (pathParams, queryParams, options) => queryFn(options)('/api/v1/feriados-e-eventos-educacao/{id}/responsaveis', pathParams, queryParams)
export const useQueryFeriadosEventosEducacaoControllerListar = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/feriados-e-eventos-educacao/{id}/responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFeriadosEventosEducacaoControllerListar.queryKey = '/api/v1/feriados-e-eventos-educacao/{id}/responsaveis'

export const mutationFinalidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/finalidades', {}, {}, options)
export const useMutationFinalidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/finalidades', {}, {}, options), config)

export const queryFinalidadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/finalidades', queryParams)
export const useQueryFinalidadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFinalidadesControllerBuscar.queryKey = '/api/v1/finalidades'

export const mutationFinalidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/finalidades/{id}', pathParams, {}, options)
export const useMutationFinalidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/finalidades/{id}', pathParams, {}, options), config)

export const mutationFinalidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/finalidades/{id}', pathParams, {}, options)
export const useMutationFinalidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/finalidades/{id}', pathParams, {}, options), config)

export const queryFinalidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/finalidades/{id}', pathParams)
export const useQueryFinalidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinalidadesControllerObterPorId.queryKey = '/api/v1/finalidades/{id}'

export const queryFinalidadesControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/finalidades/buscar-detalhado/{id}', pathParams)
export const useQueryFinalidadesControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinalidadesControllerObterPorIdDetalhado.queryKey = '/api/v1/finalidades/buscar-detalhado/{id}'

export const mutationFinalidadesControllerCadastrarFinalidadeConta = (pathParams, options) => mutationFn('post', '/api/v1/finalidades/{id}/contas', pathParams, {}, options)
export const useMutationFinalidadesControllerCadastrarFinalidadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/finalidades/{id}/contas', pathParams, {}, options), config)

export const queryFinalidadesControllerListarFinalidadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/finalidades/{id}/contas', pathParams, queryParams)
export const useQueryFinalidadesControllerListarFinalidadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinalidadesControllerListarFinalidadeContas.queryKey = '/api/v1/finalidades/{id}/contas'

export const queryFinalidadesControllerObterFinalidadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/finalidades/{id}/contas/{contaId}', pathParams)
export const useQueryFinalidadesControllerObterFinalidadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFinalidadesControllerObterFinalidadeContaPorId.queryKey = '/api/v1/finalidades/{id}/contas/{contaId}'

export const mutationFinalidadesControllerAtualizarFinalidadeConta = (pathParams, options) => mutationFn('put', '/api/v1/finalidades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFinalidadesControllerAtualizarFinalidadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/finalidades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFinalidadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/finalidades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFinalidadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/finalidades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFinalidadesControllerListarContasDisponiveis.queryKey = '/api/v1/finalidades/{id}/contas-disponiveis'

export const queryFluxoAutomacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/fluxoautomacao', queryParams)
export const useQueryFluxoAutomacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fluxoautomacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFluxoAutomacaoControllerBuscar.queryKey = '/api/v1/fluxoautomacao'

export const mutationFluxoAutomacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/fluxoautomacao', {}, {}, options)
export const useMutationFluxoAutomacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/fluxoautomacao', {}, {}, options), config)

export const queryFluxoAutomacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fluxoautomacao/{id}', pathParams)
export const useQueryFluxoAutomacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fluxoautomacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFluxoAutomacaoControllerObterPorId.queryKey = '/api/v1/fluxoautomacao/{id}'

export const mutationFluxoAutomacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/fluxoautomacao/{id}', pathParams, {}, options)
export const useMutationFluxoAutomacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fluxoautomacao/{id}', pathParams, {}, options), config)

export const mutationFluxoAutomacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/fluxoautomacao/{id}', pathParams, {}, options)
export const useMutationFluxoAutomacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fluxoautomacao/{id}', pathParams, {}, options), config)

export const queryFormasLancamentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/formas-lancamento', queryParams)
export const useQueryFormasLancamentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-lancamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFormasLancamentoControllerBuscar.queryKey = '/api/v1/formas-lancamento'

export const queryFormasLancamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formas-lancamento/{id}', pathParams)
export const useQueryFormasLancamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-lancamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasLancamentoControllerObterPorId.queryKey = '/api/v1/formas-lancamento/{id}'

export const queryFormasLancamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/formas-lancamento/{chave}', pathParams)
export const useQueryFormasLancamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-lancamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryFormasLancamentoControllerObterPorChave.queryKey = '/api/v1/formas-lancamento/{chave}'

export const mutationFormasPagamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/formas-de-pagamento', {}, {}, options)
export const useMutationFormasPagamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/formas-de-pagamento', {}, {}, options), config)

export const queryFormasPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/formas-de-pagamento', queryParams)
export const useQueryFormasPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFormasPagamentoControllerListar.queryKey = '/api/v1/formas-de-pagamento'

export const queryFormasPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}', pathParams)
export const useQueryFormasPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerObterPorId.queryKey = '/api/v1/formas-de-pagamento/{id}'

export const mutationFormasPagamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/formas-de-pagamento/{id}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/formas-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationFormasPagamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/formas-de-pagamento/{id}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/formas-de-pagamento/{id}', pathParams, {}, options), config)

export const mutationFormasPagamentoControllerCadastrarFormaPagamentoConta = (pathParams, options) => mutationFn('post', '/api/v1/formas-de-pagamento/{id}/contas', pathParams, {}, options)
export const useMutationFormasPagamentoControllerCadastrarFormaPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/formas-de-pagamento/{id}/contas', pathParams, {}, options), config)

export const queryFormasPagamentoControllerListarFormaPagamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/contas', pathParams, queryParams)
export const useQueryFormasPagamentoControllerListarFormaPagamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerListarFormaPagamentoContas.queryKey = '/api/v1/formas-de-pagamento/{id}/contas'

export const queryFormasPagamentoControllerObterFormaPagamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/contas/{contaId}', pathParams)
export const useQueryFormasPagamentoControllerObterFormaPagamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFormasPagamentoControllerObterFormaPagamentoContaPorId.queryKey = '/api/v1/formas-de-pagamento/{id}/contas/{contaId}'

export const mutationFormasPagamentoControllerAtualizarFormaPagamentoConta = (pathParams, options) => mutationFn('put', '/api/v1/formas-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFormasPagamentoControllerAtualizarFormaPagamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/formas-de-pagamento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFormasPagamentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFormasPagamentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerListarContasDisponiveis.queryKey = '/api/v1/formas-de-pagamento/{id}/contas-disponiveis'

export const queryFormasPagamentoControllerObterPorFormaPagamentoId = (pathParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/obter-config-link-pagamento', pathParams)
export const useQueryFormasPagamentoControllerObterPorFormaPagamentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/obter-config-link-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerObterPorFormaPagamentoId.queryKey = '/api/v1/formas-de-pagamento/{id}/obter-config-link-pagamento'

export const queryFormasPagamentoControllerListarCondicoesPagamentoRecorrentePorFormaPagamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/formas-de-pagamento/{id}/condicao-pagamento-recorrentes', pathParams, queryParams)
export const useQueryFormasPagamentoControllerListarCondicoesPagamentoRecorrentePorFormaPagamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formas-de-pagamento/{id}/condicao-pagamento-recorrentes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormasPagamentoControllerListarCondicoesPagamentoRecorrentePorFormaPagamento.queryKey = '/api/v1/formas-de-pagamento/{id}/condicao-pagamento-recorrentes'

export const queryFormulaAvaliacaoCondicoesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-condicoes', queryParams)
export const useQueryFormulaAvaliacaoCondicoesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-condicoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFormulaAvaliacaoCondicoesControllerBuscar.queryKey = '/api/v1/formulas-avaliacao-condicoes'

export const queryFormulaAvaliacaoCondicoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-condicoes/{id}', pathParams)
export const useQueryFormulaAvaliacaoCondicoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-condicoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormulaAvaliacaoCondicoesControllerObterPorId.queryKey = '/api/v1/formulas-avaliacao-condicoes/{id}'

export const queryFormulaAvaliacaoCondicoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-condicoes/{chave}', pathParams)
export const useQueryFormulaAvaliacaoCondicoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-condicoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryFormulaAvaliacaoCondicoesControllerObterPorChave.queryKey = '/api/v1/formulas-avaliacao-condicoes/{chave}'

export const queryFormulaAvaliacaoOperacoesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-operacoes', queryParams)
export const useQueryFormulaAvaliacaoOperacoesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-operacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFormulaAvaliacaoOperacoesControllerBuscar.queryKey = '/api/v1/formulas-avaliacao-operacoes'

export const queryFormulaAvaliacaoOperacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-operacoes/{id}', pathParams)
export const useQueryFormulaAvaliacaoOperacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-operacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormulaAvaliacaoOperacoesControllerObterPorId.queryKey = '/api/v1/formulas-avaliacao-operacoes/{id}'

export const queryFormulaAvaliacaoOperacoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-operacoes/{chave}', pathParams)
export const useQueryFormulaAvaliacaoOperacoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-operacoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryFormulaAvaliacaoOperacoesControllerObterPorChave.queryKey = '/api/v1/formulas-avaliacao-operacoes/{chave}'

export const queryFormulaAvaliacaoVariaveisControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-variaveis', queryParams)
export const useQueryFormulaAvaliacaoVariaveisControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-variaveis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFormulaAvaliacaoVariaveisControllerBuscar.queryKey = '/api/v1/formulas-avaliacao-variaveis'

export const queryFormulaAvaliacaoVariaveisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-variaveis/{id}', pathParams)
export const useQueryFormulaAvaliacaoVariaveisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-variaveis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormulaAvaliacaoVariaveisControllerObterPorId.queryKey = '/api/v1/formulas-avaliacao-variaveis/{id}'

export const queryFormulaAvaliacaoVariaveisControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/formulas-avaliacao-variaveis/{chave}', pathParams)
export const useQueryFormulaAvaliacaoVariaveisControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacao-variaveis/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryFormulaAvaliacaoVariaveisControllerObterPorChave.queryKey = '/api/v1/formulas-avaliacao-variaveis/{chave}'

export const queryFormularioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formularios/{id}', pathParams)
export const useQueryFormularioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formularios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormularioControllerObterPorId.queryKey = '/api/v1/formularios/{id}'

export const queryFormulasAvaliacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/formulas-avaliacoes/{id}', pathParams)
export const useQueryFormulasAvaliacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/formulas-avaliacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFormulasAvaliacoesControllerObterPorId.queryKey = '/api/v1/formulas-avaliacoes/{id}'

export const mutationFormulasAvaliacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/formulas-avaliacoes/{id}', pathParams, {}, options)
export const useMutationFormulasAvaliacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/formulas-avaliacoes/{id}', pathParams, {}, options), config)

export const mutationFormulasAvaliacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/formulas-avaliacoes/{id}', pathParams, {}, options)
export const useMutationFormulasAvaliacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/formulas-avaliacoes/{id}', pathParams, {}, options), config)

export const mutationFornecedoresControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/fornecedores/{id}', pathParams, {}, options)
export const useMutationFornecedoresControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fornecedores/{id}', pathParams, {}, options), config)

export const queryFornecedoresControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/fornecedores/{id}', pathParams)
export const useQueryFornecedoresControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerObterPorId.queryKey = '/api/v1/fornecedores/{id}'

export const mutationFornecedoresControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/fornecedores/{id}', pathParams, {}, options)
export const useMutationFornecedoresControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fornecedores/{id}', pathParams, {}, options), config)

export const queryFornecedoresControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/fornecedores', queryParams)
export const useQueryFornecedoresControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFornecedoresControllerBuscar.queryKey = '/api/v1/fornecedores'

export const mutationFornecedoresControllerCadastrar = (options) => mutationFn('post', '/api/v1/fornecedores', {}, {}, options)
export const useMutationFornecedoresControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/fornecedores', {}, {}, options), config)

export const mutationFornecedoresControllerCadastrarFornecedorTag = (pathParams, options) => mutationFn('post', '/api/v1/fornecedores/{id}/tags', pathParams, {}, options)
export const useMutationFornecedoresControllerCadastrarFornecedorTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/fornecedores/{id}/tags', pathParams, {}, options), config)

export const queryFornecedoresControllerListarTagsFornecedor = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/tags', pathParams, queryParams)
export const useQueryFornecedoresControllerListarTagsFornecedor = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarTagsFornecedor.queryKey = '/api/v1/fornecedores/{id}/tags'

export const mutationFornecedoresControllerDeletarFornecedorTag = (pathParams, options) => mutationFn('delete', '/api/v1/fornecedores/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationFornecedoresControllerDeletarFornecedorTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/fornecedores/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryFornecedoresControllerListarFornecedoresTags = (queryParams, options) => queryFn(options)('/api/v1/fornecedores/tags', queryParams)
export const useQueryFornecedoresControllerListarFornecedoresTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFornecedoresControllerListarFornecedoresTags.queryKey = '/api/v1/fornecedores/tags'

export const mutationFornecedoresControllerCadastrarFornecedorConta = (pathParams, options) => mutationFn('post', '/api/v1/fornecedores/{id}/contas', pathParams, {}, options)
export const useMutationFornecedoresControllerCadastrarFornecedorConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/fornecedores/{id}/contas', pathParams, {}, options), config)

export const queryFornecedoresControllerListarFornecedorContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/contas', pathParams, queryParams)
export const useQueryFornecedoresControllerListarFornecedorContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarFornecedorContas.queryKey = '/api/v1/fornecedores/{id}/contas'

export const queryFornecedoresControllerObterFornecedorContaPorId = (pathParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/contas/{contaId}', pathParams)
export const useQueryFornecedoresControllerObterFornecedorContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFornecedoresControllerObterFornecedorContaPorId.queryKey = '/api/v1/fornecedores/{id}/contas/{contaId}'

export const mutationFornecedoresControllerAtualizarFornecedorConta = (pathParams, options) => mutationFn('put', '/api/v1/fornecedores/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFornecedoresControllerAtualizarFornecedorConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/fornecedores/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFornecedoresControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFornecedoresControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarContasDisponiveis.queryKey = '/api/v1/fornecedores/{id}/contas-disponiveis'

export const queryFornecedoresControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryFornecedoresControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarTagsDisponiveis.queryKey = '/api/v1/fornecedores/{id}/tags-disponiveis'

export const queryFornecedoresControllerListarOrcamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/orcamentos-entrada', pathParams, queryParams)
export const useQueryFornecedoresControllerListarOrcamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/orcamentos-entrada', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarOrcamentos.queryKey = '/api/v1/fornecedores/{id}/orcamentos-entrada'

export const queryFornecedoresControllerListarPedidosCompra = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/pedidos-compra', pathParams, queryParams)
export const useQueryFornecedoresControllerListarPedidosCompra = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/pedidos-compra', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarPedidosCompra.queryKey = '/api/v1/fornecedores/{id}/pedidos-compra'

export const queryFornecedoresControllerListarTitulos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/fornecedores/{id}/titulos', pathParams, queryParams)
export const useQueryFornecedoresControllerListarTitulos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fornecedores/{id}/titulos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFornecedoresControllerListarTitulos.queryKey = '/api/v1/fornecedores/{id}/titulos'

export const queryFuncionalidadeControllerListar = (queryParams, options) => queryFn(options)('/api/v1/funcionalidade', queryParams)
export const useQueryFuncionalidadeControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionalidade', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionalidadeControllerListar.queryKey = '/api/v1/funcionalidade'

export const queryFuncionalidadeTagControllerListar = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionalidade-tag/{funcionalidadeId}', pathParams, queryParams)
export const useQueryFuncionalidadeTagControllerListar = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionalidade-tag/{funcionalidadeId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.funcionalidadeId, ...config }
})
useQueryFuncionalidadeTagControllerListar.queryKey = '/api/v1/funcionalidade-tag/{funcionalidadeId}'

export const queryFuncionarioDisciplinasControllerObterFuncionarioDisciplinaPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionario-disciplinas/{id}', pathParams)
export const useQueryFuncionarioDisciplinasControllerObterFuncionarioDisciplinaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-disciplinas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioDisciplinasControllerObterFuncionarioDisciplinaPorId.queryKey = '/api/v1/funcionario-disciplinas/{id}'

export const mutationFuncionarioDisciplinasControllerDeletarFuncionarioDisciplina = (pathParams, options) => mutationFn('delete', '/api/v1/funcionario-disciplinas/{id}', pathParams, {}, options)
export const useMutationFuncionarioDisciplinasControllerDeletarFuncionarioDisciplina = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionario-disciplinas/{id}', pathParams, {}, options), config)

export const queryFuncionarioDisciplinasControllerObterFuncionarioDisciplinaPorFuncionarioId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionario-disciplinas/{id}/funcionarios', pathParams, queryParams)
export const useQueryFuncionarioDisciplinasControllerObterFuncionarioDisciplinaPorFuncionarioId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-disciplinas/{id}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioDisciplinasControllerObterFuncionarioDisciplinaPorFuncionarioId.queryKey = '/api/v1/funcionario-disciplinas/{id}/funcionarios'

export const queryFuncionarioHabilitacoesControllerObterFuncionarioHabilitacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionario-habilitacoes/{id}', pathParams)
export const useQueryFuncionarioHabilitacoesControllerObterFuncionarioHabilitacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-habilitacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioHabilitacoesControllerObterFuncionarioHabilitacaoPorId.queryKey = '/api/v1/funcionario-habilitacoes/{id}'

export const mutationFuncionarioHabilitacoesControllerDeletarFuncionarioHabilitacao = (pathParams, options) => mutationFn('delete', '/api/v1/funcionario-habilitacoes/{id}', pathParams, {}, options)
export const useMutationFuncionarioHabilitacoesControllerDeletarFuncionarioHabilitacao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionario-habilitacoes/{id}', pathParams, {}, options), config)

export const mutationFuncionarioHabilitacoesControllerCadastrarFuncionarioDisciplina = (pathParams, options) => mutationFn('post', '/api/v1/funcionario-habilitacoes/{id}/disciplinas', pathParams, {}, options)
export const useMutationFuncionarioHabilitacoesControllerCadastrarFuncionarioDisciplina = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionario-habilitacoes/{id}/disciplinas', pathParams, {}, options), config)

export const queryFuncionarioHabilitacoesControllerListarFuncionarioDisciplinas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionario-habilitacoes/{id}/disciplinas', pathParams, queryParams)
export const useQueryFuncionarioHabilitacoesControllerListarFuncionarioDisciplinas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-habilitacoes/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioHabilitacoesControllerListarFuncionarioDisciplinas.queryKey = '/api/v1/funcionario-habilitacoes/{id}/disciplinas'

export const queryFuncionarioHorariosEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionario-horarios-educacao/{id}', pathParams)
export const useQueryFuncionarioHorariosEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionario-horarios-educacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionarioHorariosEducacaoControllerObterPorId.queryKey = '/api/v1/funcionario-horarios-educacao/{id}'

export const mutationFuncionarioHorariosEducacaoControllerAtualizar = (pathParams, queryParams, options) => mutationFn('put', '/api/v1/funcionario-horarios-educacao/{id}', pathParams, queryParams, options)
export const useMutationFuncionarioHorariosEducacaoControllerAtualizar = (pathParams, queryParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcionario-horarios-educacao/{id}', pathParams, queryParams, options), config)

export const mutationFuncionarioHorariosEducacaoControllerAtualizarPorDiaSemana = (pathParams, queryParams, options) => mutationFn('put', '/api/v1/funcionario-horarios-educacao/{funcionarioEducacaoId}/dia-semana', pathParams, queryParams, options)
export const useMutationFuncionarioHorariosEducacaoControllerAtualizarPorDiaSemana = (pathParams, queryParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcionario-horarios-educacao/{funcionarioEducacaoId}/dia-semana', pathParams, queryParams, options), config)

export const mutationFuncionariosControllerCadastrarFuncionarioHabilitacao = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/habilitacoes', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioHabilitacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/habilitacoes', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioHabilitacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/habilitacoes', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioHabilitacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/habilitacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioHabilitacoes.queryKey = '/api/v1/funcionarios/{id}/habilitacoes'

export const mutationFuncionariosControllerCadastrarFuncionarioAutorizado = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/autorizados-ocorrencia', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioAutorizado = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/autorizados-ocorrencia', pathParams, {}, options), config)

export const mutationFuncionariosControllerAtualizarFuncionarioAutorizadoOcorrencia = (pathParams, options) => mutationFn('put', '/api/v1/funcionarios/{id}/autorizados-ocorrencia', pathParams, {}, options)
export const useMutationFuncionariosControllerAtualizarFuncionarioAutorizadoOcorrencia = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcionarios/{id}/autorizados-ocorrencia', pathParams, {}, options), config)

export const mutationFuncionariosControllerDeletarFuncionarioAutorizadoOcorrenciaPorId = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}/autorizados-ocorrencia', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletarFuncionarioAutorizadoOcorrenciaPorId = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}/autorizados-ocorrencia', pathParams, {}, options), config)

export const queryFuncionariosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/funcionarios/autorizados-ocorrencia', queryParams)
export const useQueryFuncionariosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/autorizados-ocorrencia', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionariosControllerListar.queryKey = '/api/v1/funcionarios/autorizados-ocorrencia'

export const queryFuncionariosControllerObterFuncionarioAutorizadoOcorrenciaPorFuncionarioId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{funcionarioId}/autorizados-ocorrencia', pathParams)
export const useQueryFuncionariosControllerObterFuncionarioAutorizadoOcorrenciaPorFuncionarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{funcionarioId}/autorizados-ocorrencia', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.funcionarioId, ...config }
})
useQueryFuncionariosControllerObterFuncionarioAutorizadoOcorrenciaPorFuncionarioId.queryKey = '/api/v1/funcionarios/{funcionarioId}/autorizados-ocorrencia'

export const mutationFuncionariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/funcionarios', {}, {}, options)
export const useMutationFuncionariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios', {}, {}, options), config)

export const queryFuncionariosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/funcionarios', queryParams)
export const useQueryFuncionariosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionariosControllerBuscar.queryKey = '/api/v1/funcionarios'

export const queryFuncionariosControllerListarFuncionarioDisciplinas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/etapas/{etapaId}/disciplinas', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioDisciplinas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/etapas/{etapaId}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.etapaId, ...config }
})
useQueryFuncionariosControllerListarFuncionarioDisciplinas.queryKey = '/api/v1/funcionarios/{id}/etapas/{etapaId}/disciplinas'

export const mutationFuncionariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}', pathParams, {}, options), config)

export const mutationFuncionariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/funcionarios/{id}', pathParams, {}, options)
export const useMutationFuncionariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcionarios/{id}', pathParams, {}, options), config)

export const queryFuncionariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}', pathParams)
export const useQueryFuncionariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerObterPorId.queryKey = '/api/v1/funcionarios/{id}'

export const queryFuncionariosControllerListarHorarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/horarios', pathParams, queryParams)
export const useQueryFuncionariosControllerListarHorarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/horarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarHorarios.queryKey = '/api/v1/funcionarios/{id}/horarios'

export const queryFuncionariosControllerListarHorariosAgrupadosDiaSemana = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/horarios-agrupado', pathParams, queryParams)
export const useQueryFuncionariosControllerListarHorariosAgrupadosDiaSemana = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/horarios-agrupado', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarHorariosAgrupadosDiaSemana.queryKey = '/api/v1/funcionarios/{id}/horarios-agrupado'

export const mutationFuncionariosControllerCadastrarHorario = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/horario', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarHorario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/horario', pathParams, {}, options), config)

export const mutationFuncionariosControllerCadastrarFuncionarioTag = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/tags', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/tags', pathParams, {}, options), config)

export const queryFuncionariosControllerListarTagsFuncionario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/tags', pathParams, queryParams)
export const useQueryFuncionariosControllerListarTagsFuncionario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarTagsFuncionario.queryKey = '/api/v1/funcionarios/{id}/tags'

export const mutationFuncionariosControllerDeletarFuncionarioTag = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}/tag/{tagId}', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletarFuncionarioTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}/tag/{tagId}', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionariosTags = (queryParams, options) => queryFn(options)('/api/v1/funcionarios/tags', queryParams)
export const useQueryFuncionariosControllerListarFuncionariosTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncionariosControllerListarFuncionariosTags.queryKey = '/api/v1/funcionarios/tags'

export const queryFuncionariosControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryFuncionariosControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarTagsDisponiveis.queryKey = '/api/v1/funcionarios/{id}/tags-disponiveis'

export const mutationFuncionariosControllerCadastrarFuncionarioConta = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/contas', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/contas', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/contas', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioContas.queryKey = '/api/v1/funcionarios/{id}/contas'

export const queryFuncionariosControllerObterFuncionarioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/contas/{contaId}', pathParams)
export const useQueryFuncionariosControllerObterFuncionarioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFuncionariosControllerObterFuncionarioContaPorId.queryKey = '/api/v1/funcionarios/{id}/contas/{contaId}'

export const mutationFuncionariosControllerAtualizarFuncionarioConta = (pathParams, options) => mutationFn('put', '/api/v1/funcionarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFuncionariosControllerAtualizarFuncionarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcionarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFuncionariosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFuncionariosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarContasDisponiveis.queryKey = '/api/v1/funcionarios/{id}/contas-disponiveis'

export const mutationFuncionariosControllerCadastrarFuncionarioFuncaoConta = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/funcoes', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioFuncaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/funcoes', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioFuncoesConta = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/funcoes', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioFuncoesConta = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/funcoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioFuncoesConta.queryKey = '/api/v1/funcionarios/{id}/funcoes'

export const queryFuncionariosControllerObterFuncionarioFuncaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/funcoes/{funcaoId}', pathParams)
export const useQueryFuncionariosControllerObterFuncionarioFuncaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/funcoes/{funcaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.funcaoId, ...config }
})
useQueryFuncionariosControllerObterFuncionarioFuncaoContaPorId.queryKey = '/api/v1/funcionarios/{id}/funcoes/{funcaoId}'

export const mutationFuncionariosControllerDeletarFuncionarioFuncaoConta = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}/funcoes/{funcaoId}', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletarFuncionarioFuncaoConta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}/funcoes/{funcaoId}', pathParams, {}, options), config)

export const queryFuncionariosControllerObterCalendarioPorId = (pathParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/calendario', pathParams)
export const useQueryFuncionariosControllerObterCalendarioPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/calendario', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerObterCalendarioPorId.queryKey = '/api/v1/funcionarios/{id}/calendario'

export const mutationFuncionariosControllerCadastrarFuncionarioComissao = (pathParams, options) => mutationFn('post', '/api/v1/funcionarios/{id}/comissoes', pathParams, {}, options)
export const useMutationFuncionariosControllerCadastrarFuncionarioComissao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcionarios/{id}/comissoes', pathParams, {}, options), config)

export const queryFuncionariosControllerListarFuncionarioComissoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcionarios/{id}/comissoes', pathParams, queryParams)
export const useQueryFuncionariosControllerListarFuncionarioComissoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcionarios/{id}/comissoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncionariosControllerListarFuncionarioComissoes.queryKey = '/api/v1/funcionarios/{id}/comissoes'

export const mutationFuncionariosControllerDeletarFuncionarioComissao = (pathParams, options) => mutationFn('delete', '/api/v1/funcionarios/{id}/comissoes/{comissaoId}', pathParams, {}, options)
export const useMutationFuncionariosControllerDeletarFuncionarioComissao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcionarios/{id}/comissoes/{comissaoId}', pathParams, {}, options), config)

export const mutationFuncoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/funcoes', {}, {}, options)
export const useMutationFuncoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/funcoes', {}, {}, options), config)

export const queryFuncoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/funcoes', queryParams)
export const useQueryFuncoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFuncoesControllerListar.queryKey = '/api/v1/funcoes'

export const queryFuncoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/funcoes/{id}', pathParams)
export const useQueryFuncoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncoesControllerObterPorId.queryKey = '/api/v1/funcoes/{id}'

export const mutationFuncoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/funcoes/{id}', pathParams, {}, options)
export const useMutationFuncoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcoes/{id}', pathParams, {}, options), config)

export const mutationFuncoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/funcoes/{id}', pathParams, {}, options)
export const useMutationFuncoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/funcoes/{id}', pathParams, {}, options), config)

export const mutationFuncoesControllerCadastrarFuncaoConta = (pathParams, options) => mutationFn('post', '/api/v1/funcoes/{id}/contas', pathParams, {}, options)
export const useMutationFuncoesControllerCadastrarFuncaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/funcoes/{id}/contas', pathParams, {}, options), config)

export const queryFuncoesControllerListarFuncaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcoes/{id}/contas', pathParams, queryParams)
export const useQueryFuncoesControllerListarFuncaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncoesControllerListarFuncaoContas.queryKey = '/api/v1/funcoes/{id}/contas'

export const queryFuncoesControllerObterFuncaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/funcoes/{id}/contas/{contaId}', pathParams)
export const useQueryFuncoesControllerObterFuncaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryFuncoesControllerObterFuncaoContaPorId.queryKey = '/api/v1/funcoes/{id}/contas/{contaId}'

export const mutationFuncoesControllerAtualizarFuncaoConta = (pathParams, options) => mutationFn('put', '/api/v1/funcoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationFuncoesControllerAtualizarFuncaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/funcoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryFuncoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/funcoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryFuncoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/funcoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryFuncoesControllerListarContasDisponiveis.queryKey = '/api/v1/funcoes/{id}/contas-disponiveis'

export const queryFusosHorariosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/fusos-horarios', queryParams)
export const useQueryFusosHorariosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/fusos-horarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryFusosHorariosControllerBuscar.queryKey = '/api/v1/fusos-horarios'

export const queryGradesCurricularesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/grades-curriculares', queryParams)
export const useQueryGradesCurricularesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grades-curriculares', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGradesCurricularesControllerBuscar.queryKey = '/api/v1/grades-curriculares'

export const mutationGradesCurricularesControllerCadastrar = (options) => mutationFn('post', '/api/v1/grades-curriculares', {}, {}, options)
export const useMutationGradesCurricularesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grades-curriculares', {}, {}, options), config)

export const queryGradesCurricularesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grades-curriculares/{id}', pathParams)
export const useQueryGradesCurricularesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grades-curriculares/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesControllerObterPorId.queryKey = '/api/v1/grades-curriculares/{id}'

export const mutationGradesCurricularesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grades-curriculares/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grades-curriculares/{id}', pathParams, {}, options), config)

export const mutationGradesCurricularesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grades-curriculares/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grades-curriculares/{id}', pathParams, {}, options), config)

export const queryGradesCurricularesControllerBuscarSimulados = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grades-curriculares/{id}/simulados', pathParams, queryParams)
export const useQueryGradesCurricularesControllerBuscarSimulados = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grades-curriculares/{id}/simulados', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesControllerBuscarSimulados.queryKey = '/api/v1/grades-curriculares/{id}/simulados'

export const mutationGradesCurricularesControllerCadastrarSimulado = (pathParams, options) => mutationFn('post', '/api/v1/grades-curriculares/{id}/simulados', pathParams, {}, options)
export const useMutationGradesCurricularesControllerCadastrarSimulado = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grades-curriculares/{id}/simulados', pathParams, {}, options), config)

export const mutationGradesCurricularesControllerCadastrarGradeCurricularConta = (pathParams, options) => mutationFn('post', '/api/v1/grades-curriculares/{id}/contas', pathParams, {}, options)
export const useMutationGradesCurricularesControllerCadastrarGradeCurricularConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grades-curriculares/{id}/contas', pathParams, {}, options), config)

export const queryGradesCurricularesControllerListarGradeCurricularContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grades-curriculares/{id}/contas', pathParams, queryParams)
export const useQueryGradesCurricularesControllerListarGradeCurricularContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grades-curriculares/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesControllerListarGradeCurricularContas.queryKey = '/api/v1/grades-curriculares/{id}/contas'

export const queryGradesCurricularesControllerObterGradeCurricularContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grades-curriculares/{id}/contas/{contaId}', pathParams)
export const useQueryGradesCurricularesControllerObterGradeCurricularContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grades-curriculares/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGradesCurricularesControllerObterGradeCurricularContaPorId.queryKey = '/api/v1/grades-curriculares/{id}/contas/{contaId}'

export const mutationGradesCurricularesControllerAtualizarGradeCurricularConta = (pathParams, options) => mutationFn('put', '/api/v1/grades-curriculares/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGradesCurricularesControllerAtualizarGradeCurricularConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grades-curriculares/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGradesCurricularesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grades-curriculares/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGradesCurricularesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grades-curriculares/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesControllerListarContasDisponiveis.queryKey = '/api/v1/grades-curriculares/{id}/contas-disponiveis'

export const mutationGradesCurricularesControllerCadastrarGradeCurriclarDisciplina = (pathParams, options) => mutationFn('post', '/api/v1/grades-curriculares/{id}/disciplinas', pathParams, {}, options)
export const useMutationGradesCurricularesControllerCadastrarGradeCurriclarDisciplina = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grades-curriculares/{id}/disciplinas', pathParams, {}, options), config)

export const queryGradesCurricularesControllerListarGradeCurricularDisciplinas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grades-curriculares/{id}/disciplinas', pathParams, queryParams)
export const useQueryGradesCurricularesControllerListarGradeCurricularDisciplinas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grades-curriculares/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesControllerListarGradeCurricularDisciplinas.queryKey = '/api/v1/grades-curriculares/{id}/disciplinas'

export const mutationGradesCurricularesDisciplinasControllerAtualizarGradeCurriclarDisciplina = (pathParams, options) => mutationFn('put', '/api/v1/grade-curricular-disciplinas/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerAtualizarGradeCurriclarDisciplina = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grade-curricular-disciplinas/{id}', pathParams, {}, options), config)

export const mutationGradesCurricularesDisciplinasControllerDeletarGradeCurricularDisciplina = (pathParams, options) => mutationFn('delete', '/api/v1/grade-curricular-disciplinas/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerDeletarGradeCurricularDisciplina = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grade-curricular-disciplinas/{id}', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasControllerObterGradeCurricularDisciplina = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}', pathParams)
export const useQueryGradesCurricularesDisciplinasControllerObterGradeCurricularDisciplina = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerObterGradeCurricularDisciplina.queryKey = '/api/v1/grade-curricular-disciplinas/{id}'

export const mutationGradesCurricularesDisciplinasControllerCadastrarAtividadePratica = (pathParams, options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/atividades-praticas', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerCadastrarAtividadePratica = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/atividades-praticas', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasControllerListarAtividadesPratica = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/atividades-praticas', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasControllerListarAtividadesPratica = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/atividades-praticas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerListarAtividadesPratica.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/atividades-praticas'

export const mutationGradesCurricularesDisciplinasControllerCadastrarBibliografiaBasica = (pathParams, options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/bibliografias-basicas', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerCadastrarBibliografiaBasica = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/bibliografias-basicas', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasControllerListarBibliografiaBasica = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/bibliografias-basicas', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasControllerListarBibliografiaBasica = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/bibliografias-basicas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerListarBibliografiaBasica.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/bibliografias-basicas'

export const mutationGradesCurricularesDisciplinasControllerCadastrarBibliografiaComplementar = (pathParams, options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/bibliografias-complementares', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerCadastrarBibliografiaComplementar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/bibliografias-complementares', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasControllerListarBibliografiaComplementar = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/bibliografias-complementares', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasControllerListarBibliografiaComplementar = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/bibliografias-complementares', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerListarBibliografiaComplementar.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/bibliografias-complementares'

export const mutationGradesCurricularesDisciplinasControllerCadastrarCriteriosAvaliacao = (pathParams, options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/criterios-avaliacao', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerCadastrarCriteriosAvaliacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/criterios-avaliacao', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasControllerListarCriteriosAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/criterios-avaliacao', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasControllerListarCriteriosAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/criterios-avaliacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerListarCriteriosAvaliacao.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/criterios-avaliacao'

export const mutationGradesCurricularesDisciplinasControllerCadastrarPlanosAula = (pathParams, options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/planos-de-aula', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerCadastrarPlanosAula = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/planos-de-aula', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasControllerListarPlanosAula = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/planos-de-aula', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasControllerListarPlanosAula = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/planos-de-aula', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerListarPlanosAula.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/planos-de-aula'

export const queryGradesCurricularesDisciplinasControllerObterPlanoEnsinoPorGradeCurricularDisciplinaId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/planos-ensino', pathParams)
export const useQueryGradesCurricularesDisciplinasControllerObterPlanoEnsinoPorGradeCurricularDisciplinaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/planos-ensino', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerObterPlanoEnsinoPorGradeCurricularDisciplinaId.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/planos-ensino'

export const mutationGradesCurricularesDisciplinasControllerCadastrarRecursoDidatico = (pathParams, options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/recursos-didaticos', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerCadastrarRecursoDidatico = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/recursos-didaticos', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasControllerListarRecursoDidatico = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/recursos-didaticos', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasControllerListarRecursoDidatico = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/recursos-didaticos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerListarRecursoDidatico.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/recursos-didaticos'

export const mutationGradesCurricularesDisciplinasControllerCadastrarTecnicaEnsino = (pathParams, options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/tecnicas-ensino', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasControllerCadastrarTecnicaEnsino = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas/{id}/tecnicas-ensino', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasControllerListarTecnicaEnsino = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/tecnicas-ensino', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasControllerListarTecnicaEnsino = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/tecnicas-ensino', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerListarTecnicaEnsino.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/tecnicas-ensino'

export const queryGradesCurricularesDisciplinasControllerObterNumeroAulaPlanoAulaGradeCurricular = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/numero-aula', pathParams)
export const useQueryGradesCurricularesDisciplinasControllerObterNumeroAulaPlanoAulaGradeCurricular = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/numero-aula', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerObterNumeroAulaPlanoAulaGradeCurricular.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/numero-aula'

export const queryGradesCurricularesDisciplinasControllerListarModelosAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas/{id}/modelos-avaliacao', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasControllerListarModelosAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas/{id}/modelos-avaliacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasControllerListarModelosAvaliacao.queryKey = '/api/v1/grade-curricular-disciplinas/{id}/modelos-avaliacao'

export const queryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPorId.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}'

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarAssinante = (pathParams, options) => mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarAssinante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPeriodos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/periodos', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPeriodos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/periodos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPeriodos.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/periodos'

export const queryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterFormulas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/formulas', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterFormulas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/formulas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoControllerObterFormulas.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/formulas'

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarGradeCurricularDisciplinaModeloAvaliacaoPeriodoGruposPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-grupos', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarGradeCurricularDisciplinaModeloAvaliacaoPeriodoGruposPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-grupos', pathParams, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarGradeCurricularDisciplinaModeloAvaliacaoPeriodoAvaliacoesPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-avaliacoes', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarGradeCurricularDisciplinaModeloAvaliacaoPeriodoAvaliacoesPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-avaliacoes', pathParams, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarGradeCurricularDisciplinaModeloAvaliacaoPeriodoConceitosPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-conceitos', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarGradeCurricularDisciplinaModeloAvaliacaoPeriodoConceitosPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-conceitos', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}', pathParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterPorId.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}'

export const queryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterGrupos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/grupos', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterGrupos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/grupos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterGrupos.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/grupos'

export const queryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterConceitos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/conceitos', pathParams, queryParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterConceitos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/conceitos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterConceitos.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/conceitos'

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerCadastrarModeloAvaliacaoPeriodoConceito = (options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito', {}, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerCadastrarModeloAvaliacaoPeriodoConceito = (config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito', {}, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerAtualizarModeloAvaliacaoPeriodoConceito = (pathParams, options) => mutationFn('put', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerAtualizarModeloAvaliacaoPeriodoConceito = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/{id}', pathParams, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerDeletarModeloAvaliacaoPeriodoConceito = (pathParams, options) => mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerDeletarModeloAvaliacaoPeriodoConceito = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/{id}', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/conceitos-identicos-por-periodos', queryParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/conceitos-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/conceitos-identicos-por-periodos'

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerCadastrarModeloAvaliacaoPeriodoDetalhe = (options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe', {}, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerCadastrarModeloAvaliacaoPeriodoDetalhe = (config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe', {}, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}', pathParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterPorId.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}'

export const queryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos', queryParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos'

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerCadastrarModeloAvaliacaoPeriodoFormula = (options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula', {}, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerCadastrarModeloAvaliacaoPeriodoFormula = (config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula', {}, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerDeletarModeloAvaliacaoPeriodoFormula = (pathParams, options) => mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerDeletarModeloAvaliacaoPeriodoFormula = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerObterPorId.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}'

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerCadastrarModeloAvaliacaoPeriodoGrupo = (options) => mutationFn('post', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo', {}, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerCadastrarModeloAvaliacaoPeriodoGrupo = (config, options) => useMutation(mutationFn('post', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo', {}, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerAtualizarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('put', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerAtualizarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/{id}', pathParams, {}, options), config)

export const mutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/{id}', pathParams, {}, options)
export const useMutationGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/{id}', pathParams, {}, options), config)

export const queryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/grupos-identicos-por-periodos', queryParams)
export const useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/grupos-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo.queryKey = '/api/v1/grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/grupos-identicos-por-periodos'

export const queryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterLista = (queryParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplina-sistema-avaliacao', queryParams)
export const useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterLista = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplina-sistema-avaliacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterLista.queryKey = '/api/v1/grade-curricular-disciplina-sistema-avaliacao'

export const queryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}', pathParams)
export const useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterPorId.queryKey = '/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}'

export const queryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoArredondamentoPorId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/arredondamento', pathParams)
export const useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoArredondamentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/arredondamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoArredondamentoPorId.queryKey = '/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/arredondamento'

export const queryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoRecuperacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/recuperacao', pathParams)
export const useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoRecuperacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/recuperacao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoRecuperacaoPorId.queryKey = '/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/recuperacao'

export const queryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoExameFinalPorId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/exame-final', pathParams)
export const useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoExameFinalPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/exame-final', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterGradeCurricularDisciplinaSistemaAvaliacaoExameFinalPorId.queryKey = '/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/exame-final'

export const queryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterConfiguracaoNotaParcialPorGradeCurricularDisciplinaSistemaAvaliacaoId = (pathParams, options) => queryFn(options)('/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/nota-parcial', pathParams)
export const useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterConfiguracaoNotaParcialPorGradeCurricularDisciplinaSistemaAvaliacaoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/nota-parcial', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGradesCurricularesDisciplinasSistemaAvaliacaoControllerObterConfiguracaoNotaParcialPorGradeCurricularDisciplinaSistemaAvaliacaoId.queryKey = '/api/v1/grade-curricular-disciplina-sistema-avaliacao/{id}/nota-parcial'

export const queryGrauInteressesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/grau-interesses', queryParams)
export const useQueryGrauInteressesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGrauInteressesControllerBuscar.queryKey = '/api/v1/grau-interesses'

export const mutationGrauInteressesControllerCadastrar = (options) => mutationFn('post', '/api/v1/grau-interesses', {}, {}, options)
export const useMutationGrauInteressesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grau-interesses', {}, {}, options), config)

export const queryGrauInteressesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grau-interesses/{id}', pathParams)
export const useQueryGrauInteressesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauInteressesControllerObterPorId.queryKey = '/api/v1/grau-interesses/{id}'

export const mutationGrauInteressesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grau-interesses/{id}', pathParams, {}, options)
export const useMutationGrauInteressesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grau-interesses/{id}', pathParams, {}, options), config)

export const mutationGrauInteressesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grau-interesses/{id}', pathParams, {}, options)
export const useMutationGrauInteressesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grau-interesses/{id}', pathParams, {}, options), config)

export const mutationGrauInteressesControllerCadastrarGrauInteresseConta = (pathParams, options) => mutationFn('post', '/api/v1/grau-interesses/{id}/contas', pathParams, {}, options)
export const useMutationGrauInteressesControllerCadastrarGrauInteresseConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grau-interesses/{id}/contas', pathParams, {}, options), config)

export const queryGrauInteressesControllerListarGrauInteresseContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grau-interesses/{id}/contas', pathParams, queryParams)
export const useQueryGrauInteressesControllerListarGrauInteresseContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauInteressesControllerListarGrauInteresseContas.queryKey = '/api/v1/grau-interesses/{id}/contas'

export const queryGrauInteressesControllerObterGrauInteresseContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grau-interesses/{id}/contas/{contaId}', pathParams)
export const useQueryGrauInteressesControllerObterGrauInteresseContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGrauInteressesControllerObterGrauInteresseContaPorId.queryKey = '/api/v1/grau-interesses/{id}/contas/{contaId}'

export const mutationGrauInteressesControllerAtualizarGrauInteresseConta = (pathParams, options) => mutationFn('put', '/api/v1/grau-interesses/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGrauInteressesControllerAtualizarGrauInteresseConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grau-interesses/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGrauInteressesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grau-interesses/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGrauInteressesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-interesses/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauInteressesControllerListarContasDisponiveis.queryKey = '/api/v1/grau-interesses/{id}/contas-disponiveis'

export const queryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial = (options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais')
export const useQueryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial = (config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterListadeGrausNecessidadeEspecial.queryKey = '/api/v1/grau-de-necessidades-especiais'

export const queryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId = (pathParams, options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais/{id}', pathParams)
export const useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorId.queryKey = '/api/v1/grau-de-necessidades-especiais/{id}'

export const queryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave = (pathParams, options) => queryFn(options)('/api/v1/grau-de-necessidades-especiais/{chave}', pathParams)
export const useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grau-de-necessidades-especiais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryGrauNecessidadeEspecialControllerObterGrauNecessidadeEspecialPorChave.queryKey = '/api/v1/grau-de-necessidades-especiais/{chave}'

export const queryGruposAvaliacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/grupos-avaliacao', queryParams)
export const useQueryGruposAvaliacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-avaliacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposAvaliacaoControllerBuscar.queryKey = '/api/v1/grupos-avaliacao'

export const mutationGruposAvaliacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-avaliacao', {}, {}, options)
export const useMutationGruposAvaliacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-avaliacao', {}, {}, options), config)

export const queryGruposAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-avaliacao/{id}', pathParams)
export const useQueryGruposAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposAvaliacaoControllerObterPorId.queryKey = '/api/v1/grupos-avaliacao/{id}'

export const mutationGruposAvaliacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grupos-avaliacao/{id}', pathParams, {}, options)
export const useMutationGruposAvaliacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-avaliacao/{id}', pathParams, {}, options), config)

export const mutationGruposAvaliacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-avaliacao/{id}', pathParams, {}, options)
export const useMutationGruposAvaliacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-avaliacao/{id}', pathParams, {}, options), config)

export const mutationGruposAvaliacaoControllerCadastrarGrupoAvaliacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-avaliacao/{id}/contas', pathParams, {}, options)
export const useMutationGruposAvaliacaoControllerCadastrarGrupoAvaliacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-avaliacao/{id}/contas', pathParams, {}, options), config)

export const queryGruposAvaliacaoControllerListarGrupoAvaliacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-avaliacao/{id}/contas', pathParams, queryParams)
export const useQueryGruposAvaliacaoControllerListarGrupoAvaliacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-avaliacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposAvaliacaoControllerListarGrupoAvaliacaoContas.queryKey = '/api/v1/grupos-avaliacao/{id}/contas'

export const queryGruposAvaliacaoControllerObterGrupoAvaliacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-avaliacao/{id}/contas/{contaId}', pathParams)
export const useQueryGruposAvaliacaoControllerObterGrupoAvaliacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-avaliacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposAvaliacaoControllerObterGrupoAvaliacaoContaPorId.queryKey = '/api/v1/grupos-avaliacao/{id}/contas/{contaId}'

export const mutationGruposAvaliacaoControllerAtualizarGrupoAvaliacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-avaliacao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposAvaliacaoControllerAtualizarGrupoAvaliacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-avaliacao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposAvaliacaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-avaliacao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposAvaliacaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-avaliacao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposAvaliacaoControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-avaliacao/{id}/contas-disponiveis'

export const queryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizado = (queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado', queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizado.queryKey = '/api/v1/grupos-cadastro-personalizado'

export const mutationGruposCadastroPersonalizadoControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-cadastro-personalizado', {}, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-cadastro-personalizado', {}, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}', pathParams)
export const useQueryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoPorId.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}'

export const mutationGruposCadastroPersonalizadoControllerAtualizarGrupoCadastroPersonalizado = (pathParams, options) => mutationFn('put', '/api/v1/grupos-cadastro-personalizado/{id}', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerAtualizarGrupoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-cadastro-personalizado/{id}', pathParams, {}, options), config)

export const mutationGruposCadastroPersonalizadoControllerDeletarGrupoCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-cadastro-personalizado/{id}', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerDeletarGrupoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-cadastro-personalizado/{id}', pathParams, {}, options), config)

export const mutationGruposCadastroPersonalizadoControllerCadastrarCampoCadastroPersonalizado = (pathParams, options) => mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/campos', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerCadastrarCampoCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/campos', pathParams, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerListarCamposGrupoCadastroPersonalizado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/campos', pathParams, queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarCamposGrupoCadastroPersonalizado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/campos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarCamposGrupoCadastroPersonalizado.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/campos'

export const mutationGruposCadastroPersonalizadoControllerAtualizarOrdemCampos = (pathParams, options) => mutationFn('patch', '/api/v1/grupos-cadastro-personalizado/{id}/campos/ordenacao', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerAtualizarOrdemCampos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/grupos-cadastro-personalizado/{id}/campos/ordenacao', pathParams, {}, options), config)

export const mutationGruposCadastroPersonalizadoControllerCadastrarGrupoCadastroPersonalizadoConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/contas', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerCadastrarGrupoCadastroPersonalizadoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/contas', pathParams, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizadoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/contas', pathParams, queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizadoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarGrupoCadastroPersonalizadoContas.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/contas'

export const queryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}', pathParams)
export const useQueryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposCadastroPersonalizadoControllerObterGrupoCadastroPersonalizadoContaPorId.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}'

export const mutationGruposCadastroPersonalizadoControllerAtualizarGrupoCadastroPersonalizadoConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerAtualizarGrupoCadastroPersonalizadoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-cadastro-personalizado/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/contas-disponiveis'

export const mutationGruposCadastroPersonalizadoControllerCadastrarEntidade = (pathParams, options) => mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/entidades', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerCadastrarEntidade = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-cadastro-personalizado/{id}/entidades', pathParams, {}, options), config)

export const queryGruposCadastroPersonalizadoControllerListarGruposEntidadeCadastroPersonalizado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-cadastro-personalizado/{id}/entidades', pathParams, queryParams)
export const useQueryGruposCadastroPersonalizadoControllerListarGruposEntidadeCadastroPersonalizado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-cadastro-personalizado/{id}/entidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposCadastroPersonalizadoControllerListarGruposEntidadeCadastroPersonalizado.queryKey = '/api/v1/grupos-cadastro-personalizado/{id}/entidades'

export const mutationGruposCadastroPersonalizadoControllerDeletarGrupoEntidadeCadastroPersonalizado = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-cadastro-personalizado/{id}/entidades/{grupoEntidadeCadastroPersonalizadoId}', pathParams, {}, options)
export const useMutationGruposCadastroPersonalizadoControllerDeletarGrupoEntidadeCadastroPersonalizado = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-cadastro-personalizado/{id}/entidades/{grupoEntidadeCadastroPersonalizadoId}', pathParams, {}, options), config)

export const mutationGruposDocumentosControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-de-documentos', {}, {}, options)
export const useMutationGruposDocumentosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-documentos', {}, {}, options), config)

export const queryGruposDocumentosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/grupos-de-documentos', queryParams)
export const useQueryGruposDocumentosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposDocumentosControllerBuscar.queryKey = '/api/v1/grupos-de-documentos'

export const mutationGruposDocumentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-documentos/{id}', pathParams, {}, options)
export const useMutationGruposDocumentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-documentos/{id}', pathParams, {}, options), config)

export const queryGruposDocumentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}', pathParams)
export const useQueryGruposDocumentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposDocumentosControllerObterPorId.queryKey = '/api/v1/grupos-de-documentos/{id}'

export const mutationGruposDocumentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-de-documentos/{id}', pathParams, {}, options)
export const useMutationGruposDocumentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-de-documentos/{id}', pathParams, {}, options), config)

export const mutationGruposDocumentosControllerCadastrarGrupoDocumentoConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-de-documentos/{id}/contas', pathParams, {}, options)
export const useMutationGruposDocumentosControllerCadastrarGrupoDocumentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-documentos/{id}/contas', pathParams, {}, options), config)

export const queryGruposDocumentosControllerListarGrupoDocumentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}/contas', pathParams, queryParams)
export const useQueryGruposDocumentosControllerListarGrupoDocumentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposDocumentosControllerListarGrupoDocumentoContas.queryKey = '/api/v1/grupos-de-documentos/{id}/contas'

export const queryGruposDocumentosControllerObterGrupoDocumentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}/contas/{contaId}', pathParams)
export const useQueryGruposDocumentosControllerObterGrupoDocumentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposDocumentosControllerObterGrupoDocumentoContaPorId.queryKey = '/api/v1/grupos-de-documentos/{id}/contas/{contaId}'

export const mutationGruposDocumentosControllerAtualizarGrupoDocumentoConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-documentos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposDocumentosControllerAtualizarGrupoDocumentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-documentos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposDocumentosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposDocumentosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposDocumentosControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-de-documentos/{id}/contas-disponiveis'

export const mutationGruposDocumentosControllerCadastrarDocumento = (pathParams, options) => mutationFn('post', '/api/v1/grupos-de-documentos/{id}/documentos', pathParams, {}, options)
export const useMutationGruposDocumentosControllerCadastrarDocumento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-documentos/{id}/documentos', pathParams, {}, options), config)

export const queryGruposDocumentosControllerListarDocumentoPorGrupoDocumento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/{id}/documentos', pathParams, queryParams)
export const useQueryGruposDocumentosControllerListarDocumentoPorGrupoDocumento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/{id}/documentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposDocumentosControllerListarDocumentoPorGrupoDocumento.queryKey = '/api/v1/grupos-de-documentos/{id}/documentos'

export const queryGruposDocumentosControllerObterDocumentoPorIdGrupoDocumento = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-documentos/documentos/{docId}', pathParams)
export const useQueryGruposDocumentosControllerObterDocumentoPorIdGrupoDocumento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-documentos/documentos/{docId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.docId, ...config }
})
useQueryGruposDocumentosControllerObterDocumentoPorIdGrupoDocumento.queryKey = '/api/v1/grupos-de-documentos/documentos/{docId}'

export const mutationGruposDocumentosControllerDeletarDocumentoGrupoDocumentos = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-de-documentos/{id}/documentos/{docId}', pathParams, {}, options)
export const useMutationGruposDocumentosControllerDeletarDocumentoGrupoDocumentos = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-de-documentos/{id}/documentos/{docId}', pathParams, {}, options), config)

export const mutationGruposProfissionalControllerCadastrar = (options) => mutationFn('post', '/api/v1/grupos-de-profissional', {}, {}, options)
export const useMutationGruposProfissionalControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-profissional', {}, {}, options), config)

export const queryGruposProfissionalControllerListar = (queryParams, options) => queryFn(options)('/api/v1/grupos-de-profissional', queryParams)
export const useQueryGruposProfissionalControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryGruposProfissionalControllerListar.queryKey = '/api/v1/grupos-de-profissional'

export const queryGruposProfissionalControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-profissional/{id}', pathParams)
export const useQueryGruposProfissionalControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProfissionalControllerObterPorId.queryKey = '/api/v1/grupos-de-profissional/{id}'

export const mutationGruposProfissionalControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-profissional/{id}', pathParams, {}, options)
export const useMutationGruposProfissionalControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-profissional/{id}', pathParams, {}, options), config)

export const mutationGruposProfissionalControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/grupos-de-profissional/{id}', pathParams, {}, options)
export const useMutationGruposProfissionalControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/grupos-de-profissional/{id}', pathParams, {}, options), config)

export const mutationGruposProfissionalControllerCadastrarGrupoProfissionalConta = (pathParams, options) => mutationFn('post', '/api/v1/grupos-de-profissional/{id}/contas', pathParams, {}, options)
export const useMutationGruposProfissionalControllerCadastrarGrupoProfissionalConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/grupos-de-profissional/{id}/contas', pathParams, {}, options), config)

export const queryGruposProfissionalControllerListarGrupoProfissionalContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-profissional/{id}/contas', pathParams, queryParams)
export const useQueryGruposProfissionalControllerListarGrupoProfissionalContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProfissionalControllerListarGrupoProfissionalContas.queryKey = '/api/v1/grupos-de-profissional/{id}/contas'

export const queryGruposProfissionalControllerObterGrupoProfissionalContaPorId = (pathParams, options) => queryFn(options)('/api/v1/grupos-de-profissional/{id}/contas/{contaId}', pathParams)
export const useQueryGruposProfissionalControllerObterGrupoProfissionalContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryGruposProfissionalControllerObterGrupoProfissionalContaPorId.queryKey = '/api/v1/grupos-de-profissional/{id}/contas/{contaId}'

export const mutationGruposProfissionalControllerAtualizarGrupoProfissionalConta = (pathParams, options) => mutationFn('put', '/api/v1/grupos-de-profissional/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationGruposProfissionalControllerAtualizarGrupoProfissionalConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/grupos-de-profissional/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryGruposProfissionalControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/grupos-de-profissional/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryGruposProfissionalControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/grupos-de-profissional/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryGruposProfissionalControllerListarContasDisponiveis.queryKey = '/api/v1/grupos-de-profissional/{id}/contas-disponiveis'

export const queryHabilidadesEnsinoFundamentalControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-fundamental', queryParams)
export const useQueryHabilidadesEnsinoFundamentalControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-fundamental', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryHabilidadesEnsinoFundamentalControllerBuscar.queryKey = '/api/v1/habilidades-do-ensino-fundamental'

export const mutationHabilidadesEnsinoFundamentalControllerCadastrar = (options) => mutationFn('post', '/api/v1/habilidades-do-ensino-fundamental', {}, {}, options)
export const useMutationHabilidadesEnsinoFundamentalControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/habilidades-do-ensino-fundamental', {}, {}, options), config)

export const queryHabilidadesEnsinoFundamentalControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-fundamental/{id}', pathParams)
export const useQueryHabilidadesEnsinoFundamentalControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-fundamental/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHabilidadesEnsinoFundamentalControllerObterPorId.queryKey = '/api/v1/habilidades-do-ensino-fundamental/{id}'

export const mutationHabilidadesEnsinoFundamentalControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/habilidades-do-ensino-fundamental/{id}', pathParams, {}, options)
export const useMutationHabilidadesEnsinoFundamentalControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/habilidades-do-ensino-fundamental/{id}', pathParams, {}, options), config)

export const mutationHabilidadesEnsinoFundamentalControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/habilidades-do-ensino-fundamental/{id}', pathParams, {}, options)
export const useMutationHabilidadesEnsinoFundamentalControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/habilidades-do-ensino-fundamental/{id}', pathParams, {}, options), config)

export const mutationHabilidadesEnsinoFundamentalControllerCadastrarHabilidadeEnsinoFundamentalConta = (pathParams, options) => mutationFn('post', '/api/v1/habilidades-do-ensino-fundamental/{id}/contas', pathParams, {}, options)
export const useMutationHabilidadesEnsinoFundamentalControllerCadastrarHabilidadeEnsinoFundamentalConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/habilidades-do-ensino-fundamental/{id}/contas', pathParams, {}, options), config)

export const queryHabilidadesEnsinoFundamentalControllerListarHabilidadeEnsinoFundamentalContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-fundamental/{id}/contas', pathParams, queryParams)
export const useQueryHabilidadesEnsinoFundamentalControllerListarHabilidadeEnsinoFundamentalContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-fundamental/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHabilidadesEnsinoFundamentalControllerListarHabilidadeEnsinoFundamentalContas.queryKey = '/api/v1/habilidades-do-ensino-fundamental/{id}/contas'

export const queryHabilidadesEnsinoFundamentalControllerObterHabilidadeEnsinoFundamentalContaPorId = (pathParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-fundamental/{id}/contas/{contaId}', pathParams)
export const useQueryHabilidadesEnsinoFundamentalControllerObterHabilidadeEnsinoFundamentalContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-fundamental/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryHabilidadesEnsinoFundamentalControllerObterHabilidadeEnsinoFundamentalContaPorId.queryKey = '/api/v1/habilidades-do-ensino-fundamental/{id}/contas/{contaId}'

export const mutationHabilidadesEnsinoFundamentalControllerAtualizarHabilidadeEnsinoFundamentalConta = (pathParams, options) => mutationFn('put', '/api/v1/habilidades-do-ensino-fundamental/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationHabilidadesEnsinoFundamentalControllerAtualizarHabilidadeEnsinoFundamentalConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/habilidades-do-ensino-fundamental/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryHabilidadesEnsinoFundamentalControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-fundamental/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryHabilidadesEnsinoFundamentalControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-fundamental/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHabilidadesEnsinoFundamentalControllerListarContasDisponiveis.queryKey = '/api/v1/habilidades-do-ensino-fundamental/{id}/contas-disponiveis'

export const mutationHabilidadesEnsinoFundamentalControllerCadastrarHabilidadeEnsinoFundamentalAnoFaixa = (pathParams, options) => mutationFn('post', '/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas', pathParams, {}, options)
export const useMutationHabilidadesEnsinoFundamentalControllerCadastrarHabilidadeEnsinoFundamentalAnoFaixa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas', pathParams, {}, options), config)

export const queryHabilidadesEnsinoFundamentalControllerListarHabilidadeEnsinoFundamentalAnosFaixas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas', pathParams, queryParams)
export const useQueryHabilidadesEnsinoFundamentalControllerListarHabilidadeEnsinoFundamentalAnosFaixas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHabilidadesEnsinoFundamentalControllerListarHabilidadeEnsinoFundamentalAnosFaixas.queryKey = '/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas'

export const queryHabilidadesEnsinoFundamentalControllerObterHabilidadeEnsinoFundamentalAnoFaixaPorId = (pathParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas/{anoFaixaId}', pathParams)
export const useQueryHabilidadesEnsinoFundamentalControllerObterHabilidadeEnsinoFundamentalAnoFaixaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas/{anoFaixaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.anoFaixaId, ...config }
})
useQueryHabilidadesEnsinoFundamentalControllerObterHabilidadeEnsinoFundamentalAnoFaixaPorId.queryKey = '/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas/{anoFaixaId}'

export const mutationHabilidadesEnsinoFundamentalControllerDeletarHabilidadeEnsinoFundamentalAnoFaixa = (pathParams, options) => mutationFn('delete', '/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas/{anoFaixaId}', pathParams, {}, options)
export const useMutationHabilidadesEnsinoFundamentalControllerDeletarHabilidadeEnsinoFundamentalAnoFaixa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/habilidades-do-ensino-fundamental/{id}/anos-faixas/{anoFaixaId}', pathParams, {}, options), config)

export const queryHabilidadesEnsinoMedioControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-medio', queryParams)
export const useQueryHabilidadesEnsinoMedioControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-medio', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryHabilidadesEnsinoMedioControllerBuscar.queryKey = '/api/v1/habilidades-do-ensino-medio'

export const mutationHabilidadesEnsinoMedioControllerCadastrar = (options) => mutationFn('post', '/api/v1/habilidades-do-ensino-medio', {}, {}, options)
export const useMutationHabilidadesEnsinoMedioControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/habilidades-do-ensino-medio', {}, {}, options), config)

export const queryHabilidadesEnsinoMedioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-medio/{id}', pathParams)
export const useQueryHabilidadesEnsinoMedioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-medio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHabilidadesEnsinoMedioControllerObterPorId.queryKey = '/api/v1/habilidades-do-ensino-medio/{id}'

export const mutationHabilidadesEnsinoMedioControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/habilidades-do-ensino-medio/{id}', pathParams, {}, options)
export const useMutationHabilidadesEnsinoMedioControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/habilidades-do-ensino-medio/{id}', pathParams, {}, options), config)

export const mutationHabilidadesEnsinoMedioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/habilidades-do-ensino-medio/{id}', pathParams, {}, options)
export const useMutationHabilidadesEnsinoMedioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/habilidades-do-ensino-medio/{id}', pathParams, {}, options), config)

export const mutationHabilidadesEnsinoMedioControllerCadastrarHabilidadeEnsinoMedioConta = (pathParams, options) => mutationFn('post', '/api/v1/habilidades-do-ensino-medio/{id}/contas', pathParams, {}, options)
export const useMutationHabilidadesEnsinoMedioControllerCadastrarHabilidadeEnsinoMedioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/habilidades-do-ensino-medio/{id}/contas', pathParams, {}, options), config)

export const queryHabilidadesEnsinoMedioControllerListarHabilidadeEnsinoMedioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-medio/{id}/contas', pathParams, queryParams)
export const useQueryHabilidadesEnsinoMedioControllerListarHabilidadeEnsinoMedioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-medio/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHabilidadesEnsinoMedioControllerListarHabilidadeEnsinoMedioContas.queryKey = '/api/v1/habilidades-do-ensino-medio/{id}/contas'

export const queryHabilidadesEnsinoMedioControllerObterHabilidadeEnsinoMedioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-medio/{id}/contas/{contaId}', pathParams)
export const useQueryHabilidadesEnsinoMedioControllerObterHabilidadeEnsinoMedioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-medio/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryHabilidadesEnsinoMedioControllerObterHabilidadeEnsinoMedioContaPorId.queryKey = '/api/v1/habilidades-do-ensino-medio/{id}/contas/{contaId}'

export const mutationHabilidadesEnsinoMedioControllerAtualizarHabilidadeEnsinoMedioConta = (pathParams, options) => mutationFn('put', '/api/v1/habilidades-do-ensino-medio/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationHabilidadesEnsinoMedioControllerAtualizarHabilidadeEnsinoMedioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/habilidades-do-ensino-medio/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryHabilidadesEnsinoMedioControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-medio/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryHabilidadesEnsinoMedioControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-medio/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHabilidadesEnsinoMedioControllerListarContasDisponiveis.queryKey = '/api/v1/habilidades-do-ensino-medio/{id}/contas-disponiveis'

export const mutationHabilidadesEnsinoMedioControllerCadastrarHabilidadeEnsinoMedioAnoFaixa = (pathParams, options) => mutationFn('post', '/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas', pathParams, {}, options)
export const useMutationHabilidadesEnsinoMedioControllerCadastrarHabilidadeEnsinoMedioAnoFaixa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas', pathParams, {}, options), config)

export const queryHabilidadesEnsinoMedioControllerListarHabilidadeEnsinoMedioAnosFaixas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas', pathParams, queryParams)
export const useQueryHabilidadesEnsinoMedioControllerListarHabilidadeEnsinoMedioAnosFaixas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHabilidadesEnsinoMedioControllerListarHabilidadeEnsinoMedioAnosFaixas.queryKey = '/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas'

export const queryHabilidadesEnsinoMedioControllerObterHabilidadeEnsinoMedioAnoFaixaPorId = (pathParams, options) => queryFn(options)('/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas/{anoFaixaId}', pathParams)
export const useQueryHabilidadesEnsinoMedioControllerObterHabilidadeEnsinoMedioAnoFaixaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas/{anoFaixaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.anoFaixaId, ...config }
})
useQueryHabilidadesEnsinoMedioControllerObterHabilidadeEnsinoMedioAnoFaixaPorId.queryKey = '/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas/{anoFaixaId}'

export const mutationHabilidadesEnsinoMedioControllerDeletarHabilidadeEnsinoMedioAnoFaixa = (pathParams, options) => mutationFn('delete', '/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas/{anoFaixaId}', pathParams, {}, options)
export const useMutationHabilidadesEnsinoMedioControllerDeletarHabilidadeEnsinoMedioAnoFaixa = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/habilidades-do-ensino-medio/{id}/anos-faixas/{anoFaixaId}', pathParams, {}, options), config)

export const queryHistoricoContatosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/historico-contatos/{id}', pathParams)
export const useQueryHistoricoContatosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/historico-contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHistoricoContatosControllerObterPorId.queryKey = '/api/v1/historico-contatos/{id}'

export const mutationHistoricosEscolaresControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/historicos-escolares/{id}', pathParams, {}, options)
export const useMutationHistoricosEscolaresControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/historicos-escolares/{id}', pathParams, {}, options), config)

export const mutationHistoricosEscolaresControllerAtualizarHistoricoEscolar = (pathParams, options) => mutationFn('put', '/api/v1/historicos-escolares/{id}', pathParams, {}, options)
export const useMutationHistoricosEscolaresControllerAtualizarHistoricoEscolar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/historicos-escolares/{id}', pathParams, {}, options), config)

export const queryHistoricosEscolaresControllerBuscarHistoricoEscolar = (pathParams, options) => queryFn(options)('/api/v1/historicos-escolares/{id}', pathParams)
export const useQueryHistoricosEscolaresControllerBuscarHistoricoEscolar = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/historicos-escolares/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHistoricosEscolaresControllerBuscarHistoricoEscolar.queryKey = '/api/v1/historicos-escolares/{id}'

export const mutationHistoricosEscolaresControllerAtualizarHistoricoEscolarFuncionalidade = (pathParams, options) => mutationFn('put', '/api/v1/historicos-escolares/{id}/historicos-escolares-funcionalidade', pathParams, {}, options)
export const useMutationHistoricosEscolaresControllerAtualizarHistoricoEscolarFuncionalidade = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/historicos-escolares/{id}/historicos-escolares-funcionalidade', pathParams, {}, options), config)

export const queryHistoricosEscolaresControllerObterHistoricoEscolarFuncionalidade = (pathParams, options) => queryFn(options)('/api/v1/historicos-escolares/{id}/historicos-escolares-funcionalidade', pathParams)
export const useQueryHistoricosEscolaresControllerObterHistoricoEscolarFuncionalidade = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/historicos-escolares/{id}/historicos-escolares-funcionalidade', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHistoricosEscolaresControllerObterHistoricoEscolarFuncionalidade.queryKey = '/api/v1/historicos-escolares/{id}/historicos-escolares-funcionalidade'

export const mutationHistoricosEscolaresControllerCadastrarHistoricoEscolarDisciplina = (pathParams, options) => mutationFn('post', '/api/v1/historicos-escolares/{id}/historicos-escolares-disciplina', pathParams, {}, options)
export const useMutationHistoricosEscolaresControllerCadastrarHistoricoEscolarDisciplina = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/historicos-escolares/{id}/historicos-escolares-disciplina', pathParams, {}, options), config)

export const queryHistoricosEscolaresControllerBuscarHistoricoEscolarDisciplinaLista = (pathParams, queryParams, options) => queryFn(options)('/api/v1/historicos-escolares/{id}/historicos-escolares-disciplina', pathParams, queryParams)
export const useQueryHistoricosEscolaresControllerBuscarHistoricoEscolarDisciplinaLista = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/historicos-escolares/{id}/historicos-escolares-disciplina', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryHistoricosEscolaresControllerBuscarHistoricoEscolarDisciplinaLista.queryKey = '/api/v1/historicos-escolares/{id}/historicos-escolares-disciplina'

export const mutationHistoricosEscolaresDisciplinaControllerAtualizarHistoricoEscolardisciplina = (pathParams, options) => mutationFn('put', '/api/v1/historicos-escolares-disciplina/{id}', pathParams, {}, options)
export const useMutationHistoricosEscolaresDisciplinaControllerAtualizarHistoricoEscolardisciplina = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/historicos-escolares-disciplina/{id}', pathParams, {}, options), config)

export const mutationHistoricosEscolaresDisciplinaControllerDeletarHistoricoEscolarDisciplina = (pathParams, options) => mutationFn('delete', '/api/v1/historicos-escolares-disciplina/{id}', pathParams, {}, options)
export const useMutationHistoricosEscolaresDisciplinaControllerDeletarHistoricoEscolarDisciplina = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/historicos-escolares-disciplina/{id}', pathParams, {}, options), config)

export const queryImagensSistemasControllerObterLista = (queryParams, options) => queryFn(options)('/api/v1/imagens-sistemas', queryParams)
export const useQueryImagensSistemasControllerObterLista = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/imagens-sistemas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryImagensSistemasControllerObterLista.queryKey = '/api/v1/imagens-sistemas'

export const queryInstituicoesBancariaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/instituicoes-bancaria', queryParams)
export const useQueryInstituicoesBancariaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/instituicoes-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryInstituicoesBancariaControllerListar.queryKey = '/api/v1/instituicoes-bancaria'

export const queryInstituicoesBancariaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/instituicoes-bancaria/{id}', pathParams)
export const useQueryInstituicoesBancariaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/instituicoes-bancaria/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryInstituicoesBancariaControllerObterPorId.queryKey = '/api/v1/instituicoes-bancaria/{id}'

export const queryInstituicoesBancariaControllerListarInstituicoesBancariasMovimentacoes = (queryParams, options) => queryFn(options)('/api/v1/instituicoes-bancaria/movimentacoes', queryParams)
export const useQueryInstituicoesBancariaControllerListarInstituicoesBancariasMovimentacoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/instituicoes-bancaria/movimentacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryInstituicoesBancariaControllerListarInstituicoesBancariasMovimentacoes.queryKey = '/api/v1/instituicoes-bancaria/movimentacoes'

export const queryIntegracaoTiposControllerListar = (queryParams, options) => queryFn(options)('/api/v1/integracao-tipos', queryParams)
export const useQueryIntegracaoTiposControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracao-tipos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryIntegracaoTiposControllerListar.queryKey = '/api/v1/integracao-tipos'

export const queryIntegracaoTiposControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/integracao-tipos/{id}', pathParams)
export const useQueryIntegracaoTiposControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracao-tipos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryIntegracaoTiposControllerObterPorId.queryKey = '/api/v1/integracao-tipos/{id}'

export const queryIntegracaoTiposControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/integracao-tipos/{chave}', pathParams)
export const useQueryIntegracaoTiposControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracao-tipos/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryIntegracaoTiposControllerObterPorChave.queryKey = '/api/v1/integracao-tipos/{chave}'

export const queryIntegracoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/integracoes', queryParams)
export const useQueryIntegracoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryIntegracoesControllerListar.queryKey = '/api/v1/integracoes'

export const queryIntegracoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/integracoes/{id}', pathParams)
export const useQueryIntegracoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryIntegracoesControllerObterPorId.queryKey = '/api/v1/integracoes/{id}'

export const queryIntegracoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/integracoes/{chave}', pathParams)
export const useQueryIntegracoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/integracoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryIntegracoesControllerObterPorChave.queryKey = '/api/v1/integracoes/{chave}'

export const queryIntervalosAvaliacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/intervalos-avaliacao', queryParams)
export const useQueryIntervalosAvaliacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/intervalos-avaliacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryIntervalosAvaliacaoControllerBuscar.queryKey = '/api/v1/intervalos-avaliacao'

export const queryIntervalosAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/intervalos-avaliacao/{id}', pathParams)
export const useQueryIntervalosAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/intervalos-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryIntervalosAvaliacaoControllerObterPorId.queryKey = '/api/v1/intervalos-avaliacao/{id}'

export const queryIntervalosAvaliacaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/intervalos-avaliacao/{chave}', pathParams)
export const useQueryIntervalosAvaliacaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/intervalos-avaliacao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryIntervalosAvaliacaoControllerObterPorChave.queryKey = '/api/v1/intervalos-avaliacao/{chave}'

export const queryItensControllerBuscarItensEducacao = (queryParams, options) => queryFn(options)('/api/v1/itens/itensEducacao', queryParams)
export const useQueryItensControllerBuscarItensEducacao = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/itensEducacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerBuscarItensEducacao.queryKey = '/api/v1/itens/itensEducacao'

export const mutationItensControllerCadastrarItemGradeCurricular = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/item-grade-curricular', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemGradeCurricular = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/item-grade-curricular', pathParams, {}, options), config)

export const queryItensControllerObterItemGradeCurricularPorItemId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}/itens-grades-curriculares', pathParams)
export const useQueryItensControllerObterItemGradeCurricularPorItemId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/itens-grades-curriculares', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterItemGradeCurricularPorItemId.queryKey = '/api/v1/itens/{id}/itens-grades-curriculares'

export const queryItensControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/itens', queryParams)
export const useQueryItensControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerBuscar.queryKey = '/api/v1/itens'

export const queryItensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}', pathParams)
export const useQueryItensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterPorId.queryKey = '/api/v1/itens/{id}'

export const queryItensControllerObterPorEan = (pathParams, options) => queryFn(options)('/api/v1/itens/{ean}', pathParams)
export const useQueryItensControllerObterPorEan = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{ean}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.ean, ...config }
})
useQueryItensControllerObterPorEan.queryKey = '/api/v1/itens/{ean}'

export const queryItensControllerObterPorCodigo = (pathParams, options) => queryFn(options)('/api/v1/itens/{codigo}/listar', pathParams)
export const useQueryItensControllerObterPorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{codigo}/listar', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigo, ...config }
})
useQueryItensControllerObterPorCodigo.queryKey = '/api/v1/itens/{codigo}/listar'

export const queryItensControllerObterSimplificadoPorId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}/simplificado', pathParams)
export const useQueryItensControllerObterSimplificadoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/simplificado', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterSimplificadoPorId.queryKey = '/api/v1/itens/{id}/simplificado'

export const queryItensControllerBuscarHistoricoDePrecos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/historico-precos', pathParams, queryParams)
export const useQueryItensControllerBuscarHistoricoDePrecos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/historico-precos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerBuscarHistoricoDePrecos.queryKey = '/api/v1/itens/{id}/historico-precos'

export const queryItensControllerCalcularPrecoTotalItem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/calcular-preco-total-itens', pathParams, queryParams)
export const useQueryItensControllerCalcularPrecoTotalItem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/calcular-preco-total-itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerCalcularPrecoTotalItem.queryKey = '/api/v1/itens/{id}/calcular-preco-total-itens'

export const queryItensControllerObterPreco = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/obter-preco', pathParams, queryParams)
export const useQueryItensControllerObterPreco = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/obter-preco', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterPreco.queryKey = '/api/v1/itens/{id}/obter-preco'

export const queryItensControllerListarItemPrecos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/precos', pathParams, queryParams)
export const useQueryItensControllerListarItemPrecos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/precos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarItemPrecos.queryKey = '/api/v1/itens/{id}/precos'

export const mutationItensControllerCadastrarItemPreco = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/precos', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemPreco = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/precos', pathParams, {}, options), config)

export const mutationItensControllerAtualizarPrecoDeVenda = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/atualizar-preco', pathParams, {}, options)
export const useMutationItensControllerAtualizarPrecoDeVenda = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/atualizar-preco', pathParams, {}, options), config)

export const mutationItensControllerCadastrarEmbalagem = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/embalagem', pathParams, {}, options)
export const useMutationItensControllerCadastrarEmbalagem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/embalagem', pathParams, {}, options), config)

export const queryItensControllerListarEmbalagens = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/embalagens', pathParams, queryParams)
export const useQueryItensControllerListarEmbalagens = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/embalagens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarEmbalagens.queryKey = '/api/v1/itens/{id}/embalagens'

export const mutationItensControllerCadastrarItemComposicao = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/item-composicao', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemComposicao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/item-composicao', pathParams, {}, options), config)

export const queryItensControllerObterItensComposicaoPorItemId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/itens-composicao', pathParams, queryParams)
export const useQueryItensControllerObterItensComposicaoPorItemId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/itens-composicao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterItensComposicaoPorItemId.queryKey = '/api/v1/itens/{id}/itens-composicao'

export const mutationItensControllerCadastrarItemConta = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/contas', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/contas', pathParams, {}, options), config)

export const queryItensControllerListarItemContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/contas', pathParams, queryParams)
export const useQueryItensControllerListarItemContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarItemContas.queryKey = '/api/v1/itens/{id}/contas'

export const queryItensControllerObterItemContaPorId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}/contas/{contaId}', pathParams)
export const useQueryItensControllerObterItemContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryItensControllerObterItemContaPorId.queryKey = '/api/v1/itens/{id}/contas/{contaId}'

export const mutationItensControllerAtualizarItemConta = (pathParams, options) => mutationFn('put', '/api/v1/itens/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationItensControllerAtualizarItemConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryItensControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryItensControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarContasDisponiveis.queryKey = '/api/v1/itens/{id}/contas-disponiveis'

export const mutationItensControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/arquivos', pathParams, {}, options)
export const useMutationItensControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/arquivos', pathParams, {}, options), config)

export const queryItensControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/arquivos', pathParams, queryParams)
export const useQueryItensControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarArquivos.queryKey = '/api/v1/itens/{id}/arquivos'

export const mutationItensControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/itens/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationItensControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationItensControllerCadastrarAvatar = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/avatar', pathParams, {}, options)
export const useMutationItensControllerCadastrarAvatar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/avatar', pathParams, {}, options), config)

export const mutationItensControllerDeletarAvatar = (pathParams, options) => mutationFn('delete', '/api/v1/itens/{id}/avatar', pathParams, {}, options)
export const useMutationItensControllerDeletarAvatar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens/{id}/avatar', pathParams, {}, options), config)

export const mutationItensControllerCadastrarItemTag = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/tags', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/tags', pathParams, {}, options), config)

export const queryItensControllerListarTagsItem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/tags', pathParams, queryParams)
export const useQueryItensControllerListarTagsItem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarTagsItem.queryKey = '/api/v1/itens/{id}/tags'

export const mutationItensControllerDeletarItemTag = (pathParams, options) => mutationFn('delete', '/api/v1/itens/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationItensControllerDeletarItemTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryItensControllerListarItensTags = (queryParams, options) => queryFn(options)('/api/v1/itens/tags', queryParams)
export const useQueryItensControllerListarItensTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerListarItensTags.queryKey = '/api/v1/itens/tags'

export const queryItensControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryItensControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarTagsDisponiveis.queryKey = '/api/v1/itens/{id}/tags-disponiveis'

export const queryItensControllerListarItemTributacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/tributacoes', pathParams, queryParams)
export const useQueryItensControllerListarItemTributacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/tributacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarItemTributacoes.queryKey = '/api/v1/itens/{id}/tributacoes'

export const mutationItensControllerCadastrarItemTributacao = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/tributacoes', pathParams, {}, options)
export const useMutationItensControllerCadastrarItemTributacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/tributacoes', pathParams, {}, options), config)

export const queryItensControllerObterTributacaoPadraoPorItemId = (pathParams, options) => queryFn(options)('/api/v1/itens/{id}/tributacao-padrao', pathParams)
export const useQueryItensControllerObterTributacaoPadraoPorItemId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/tributacao-padrao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerObterTributacaoPadraoPorItemId.queryKey = '/api/v1/itens/{id}/tributacao-padrao'

export const queryItensControllerListarEquipes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens/{id}/equipes', pathParams, queryParams)
export const useQueryItensControllerListarEquipes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/{id}/equipes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensControllerListarEquipes.queryKey = '/api/v1/itens/{id}/equipes'

export const mutationItensControllerCadastrarEquipe = (pathParams, options) => mutationFn('post', '/api/v1/itens/{id}/equipes', pathParams, {}, options)
export const useMutationItensControllerCadastrarEquipe = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens/{id}/equipes', pathParams, {}, options), config)

export const queryItensControllerObterItensFuncionarios = (queryParams, options) => queryFn(options)('/api/v1/itens/funcionarios', queryParams)
export const useQueryItensControllerObterItensFuncionarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerObterItensFuncionarios.queryKey = '/api/v1/itens/funcionarios'

export const queryItensControllerObterEquipamentosFuncionarios = (queryParams, options) => queryFn(options)('/api/v1/itens/equipamentos/funcionarios', queryParams)
export const useQueryItensControllerObterEquipamentosFuncionarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens/equipamentos/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryItensControllerObterEquipamentosFuncionarios.queryKey = '/api/v1/itens/equipamentos/funcionarios'

export const queryItensComposicaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-composicao/{id}', pathParams)
export const useQueryItensComposicaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-composicao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensComposicaoControllerObterPorId.queryKey = '/api/v1/itens-composicao/{id}'

export const mutationItensComposicaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-composicao/{id}', pathParams, {}, options)
export const useMutationItensComposicaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-composicao/{id}', pathParams, {}, options), config)

export const mutationItensComposicaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-composicao/{id}', pathParams, {}, options)
export const useMutationItensComposicaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-composicao/{id}', pathParams, {}, options), config)

export const queryItensGradesCurricularesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-grades-curriculares/{id}', pathParams)
export const useQueryItensGradesCurricularesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-grades-curriculares/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensGradesCurricularesControllerObterPorId.queryKey = '/api/v1/itens-grades-curriculares/{id}'

export const mutationItensOrcamentoEntradaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-entrada/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoEntradaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-entrada/{id}', pathParams, {}, options), config)

export const mutationItensOrcamentoEntradaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-entrada/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoEntradaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-entrada/{id}', pathParams, {}, options), config)

export const queryItensOrcamentoEntradaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-entrada/{id}', pathParams)
export const useQueryItensOrcamentoEntradaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-entrada/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoEntradaControllerObterPorId.queryKey = '/api/v1/itens-orcamento-entrada/{id}'

export const mutationItensOrcamentoSaidaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida/{id}', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerPrecificar = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/precificar', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerPrecificar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/precificar', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerObterCondicaoPagamentoItemPorItemOrcamentoId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento', pathParams)
export const useQueryItensOrcamentoSaidaControllerObterCondicaoPagamentoItemPorItemOrcamentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaControllerObterCondicaoPagamentoItemPorItemOrcamentoId.queryKey = '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento'

export const mutationItensOrcamentoSaidaControllerCadastrarCondicaoPagamentoItem = (pathParams, options) => mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerCadastrarCondicaoPagamentoItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerObterCondicaoItemPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams)
export const useQueryItensOrcamentoSaidaControllerObterCondicaoItemPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.condicaoPagamentoItemId, ...config }
})
useQueryItensOrcamentoSaidaControllerObterCondicaoItemPorId.queryKey = '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}'

export const mutationItensOrcamentoSaidaControllerAtualizarCondicaoPagamentoItem = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerAtualizarCondicaoPagamentoItem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerDeletarCondicaoItem = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerDeletarCondicaoItem = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerObterPossiveisResponsaveisCondicaoPorClienteId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/condicoes-pagamento/possiveis-responsaveis/{clienteId}', pathParams, queryParams)
export const useQueryItensOrcamentoSaidaControllerObterPossiveisResponsaveisCondicaoPorClienteId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/condicoes-pagamento/possiveis-responsaveis/{clienteId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.clienteId, ...config }
})
useQueryItensOrcamentoSaidaControllerObterPossiveisResponsaveisCondicaoPorClienteId.queryKey = '/api/v1/itens-orcamento-saida/condicoes-pagamento/possiveis-responsaveis/{clienteId}'

export const queryItensOrcamentoSaidaControllerCalcularParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas', pathParams, queryParams)
export const useQueryItensOrcamentoSaidaControllerCalcularParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.condicaoPagamentoItemId, ...config }
})
useQueryItensOrcamentoSaidaControllerCalcularParcelas.queryKey = '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas'

export const mutationItensOrcamentoSaidaControllerAtualizarParcela = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/parcelas', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerAtualizarParcela = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/parcelas', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerCadastrarCondicaoPagamentoItemDesconto = (pathParams, options) => mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerCadastrarCondicaoPagamentoItemDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerDeletarCondicaoPagamentoItemDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos/{tipoDescontoChave}/{configuracaoDescontoId}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerDeletarCondicaoPagamentoItemDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos/{tipoDescontoChave}/{configuracaoDescontoId}', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaControllerCadastrarConvenio = (pathParams, options) => mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/convenio', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerCadastrarConvenio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/convenio', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerListarConveniosPorItemOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/convenios', pathParams, queryParams)
export const useQueryItensOrcamentoSaidaControllerListarConveniosPorItemOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/convenios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaControllerListarConveniosPorItemOrcamentoId.queryKey = '/api/v1/itens-orcamento-saida/{id}/convenios'

export const mutationItensOrcamentoSaidaControllerCadastrarDesconto = (pathParams, options) => mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/desconto', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaControllerCadastrarDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-orcamento-saida/{id}/desconto', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaControllerListarDescontosPorItemOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida/{id}/descontos', pathParams, queryParams)
export const useQueryItensOrcamentoSaidaControllerListarDescontosPorItemOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaControllerListarDescontosPorItemOrcamentoId.queryKey = '/api/v1/itens-orcamento-saida/{id}/descontos'

export const queryItensOrcamentoSaidaCondicaoParcelaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida-condicao-parcela/{id}', pathParams)
export const useQueryItensOrcamentoSaidaCondicaoParcelaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida-condicao-parcela/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaCondicaoParcelaControllerObterPorId.queryKey = '/api/v1/itens-orcamento-saida-condicao-parcela/{id}'

export const queryItensOrcamentoSaidaConvenioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida-convenio/{id}', pathParams)
export const useQueryItensOrcamentoSaidaConvenioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida-convenio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaConvenioControllerObterPorId.queryKey = '/api/v1/itens-orcamento-saida-convenio/{id}'

export const mutationItensOrcamentoSaidaConvenioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida-convenio/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaConvenioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida-convenio/{id}', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaDescontoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida-desconto/{id}', pathParams)
export const useQueryItensOrcamentoSaidaDescontoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida-desconto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaDescontoControllerObterPorId.queryKey = '/api/v1/itens-orcamento-saida-desconto/{id}'

export const mutationItensOrcamentoSaidaDescontoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-orcamento-saida-desconto/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaDescontoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-orcamento-saida-desconto/{id}', pathParams, {}, options), config)

export const mutationItensOrcamentoSaidaEducacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-orcamento-saida-educacao/{id}', pathParams, {}, options)
export const useMutationItensOrcamentoSaidaEducacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-orcamento-saida-educacao/{id}', pathParams, {}, options), config)

export const queryItensOrcamentoSaidaEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-orcamento-saida-educacao/{id}', pathParams)
export const useQueryItensOrcamentoSaidaEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-orcamento-saida-educacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensOrcamentoSaidaEducacaoControllerObterPorId.queryKey = '/api/v1/itens-orcamento-saida-educacao/{id}'

export const mutationItensPedidoCompraControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-pedido-compra/{id}', pathParams, {}, options)
export const useMutationItensPedidoCompraControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-pedido-compra/{id}', pathParams, {}, options), config)

export const mutationItensPedidoCompraControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-pedido-compra/{id}', pathParams, {}, options)
export const useMutationItensPedidoCompraControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-pedido-compra/{id}', pathParams, {}, options), config)

export const queryItensPedidoCompraControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-pedido-compra/{id}', pathParams)
export const useQueryItensPedidoCompraControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-compra/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPedidoCompraControllerObterPorId.queryKey = '/api/v1/itens-pedido-compra/{id}'

export const mutationItensPedidoVendaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-pedido-venda/{id}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-pedido-venda/{id}', pathParams, {}, options), config)

export const queryItensPedidoVendaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-pedido-venda/{id}', pathParams)
export const useQueryItensPedidoVendaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-venda/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPedidoVendaControllerObterPorId.queryKey = '/api/v1/itens-pedido-venda/{id}'

export const mutationItensPedidoVendaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-pedido-venda/{id}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-pedido-venda/{id}', pathParams, {}, options), config)

export const queryItensPedidoVendaControllerObterCondicaoItemPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams)
export const useQueryItensPedidoVendaControllerObterCondicaoItemPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.condicaoPagamentoItemId, ...config }
})
useQueryItensPedidoVendaControllerObterCondicaoItemPorId.queryKey = '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}'

export const mutationItensPedidoVendaControllerAtualizarCondicaoPagamentoItem = (pathParams, options) => mutationFn('put', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerAtualizarCondicaoPagamentoItem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options), config)

export const mutationItensPedidoVendaControllerDeletarCondicaoItem = (pathParams, options) => mutationFn('delete', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerDeletarCondicaoItem = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}', pathParams, {}, options), config)

export const mutationItensPedidoVendaControllerCadastrarCondicaoPagamentoItem = (pathParams, options) => mutationFn('post', '/api/v1/itens-pedido-venda/{id}/condicao-pagamento', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerCadastrarCondicaoPagamentoItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-pedido-venda/{id}/condicao-pagamento', pathParams, {}, options), config)

export const queryItensPedidoVendaControllerCalcularParcelas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas', pathParams, queryParams)
export const useQueryItensPedidoVendaControllerCalcularParcelas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.condicaoPagamentoItemId, ...config }
})
useQueryItensPedidoVendaControllerCalcularParcelas.queryKey = '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/calcular-parcelas'

export const queryItensPedidoVendaControllerObterCondicaoPagamentoItemPorItemPedidoVendaId = (pathParams, options) => queryFn(options)('/api/v1/itens-pedido-venda/{id}/condicoes-pagamento', pathParams)
export const useQueryItensPedidoVendaControllerObterCondicaoPagamentoItemPorItemPedidoVendaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-pedido-venda/{id}/condicoes-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPedidoVendaControllerObterCondicaoPagamentoItemPorItemPedidoVendaId.queryKey = '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento'

export const mutationItensPedidoVendaControllerCadastrarCondicaoPagamentoItemDesconto = (pathParams, options) => mutationFn('post', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerCadastrarCondicaoPagamentoItemDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos', pathParams, {}, options), config)

export const mutationItensPedidoVendaControllerDeletarCondicaoPagamentoItemDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos/{tipoDescontoChave}/{configuracaoDescontoId}', pathParams, {}, options)
export const useMutationItensPedidoVendaControllerDeletarCondicaoPagamentoItemDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-pedido-venda/{id}/condicoes-pagamento/{condicaoPagamentoItemId}/descontos/{tipoDescontoChave}/{configuracaoDescontoId}', pathParams, {}, options), config)

export const queryItensPrecosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-precos/{id}', pathParams)
export const useQueryItensPrecosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerObterPorId.queryKey = '/api/v1/itens-precos/{id}'

export const mutationItensPrecosControllerAtualizarPreco = (pathParams, options) => mutationFn('put', '/api/v1/itens-precos/{id}', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarPreco = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-precos/{id}', pathParams, {}, options), config)

export const mutationItensPrecosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-precos/{id}', pathParams, {}, options)
export const useMutationItensPrecosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-precos/{id}', pathParams, {}, options), config)

export const mutationItensPrecosControllerCadastrarItemPrecoConta = (pathParams, options) => mutationFn('post', '/api/v1/itens-precos/{id}/contas', pathParams, {}, options)
export const useMutationItensPrecosControllerCadastrarItemPrecoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-precos/{id}/contas', pathParams, {}, options), config)

export const queryItensPrecosControllerListarItemPrecoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-precos/{id}/contas', pathParams, queryParams)
export const useQueryItensPrecosControllerListarItemPrecoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarItemPrecoContas.queryKey = '/api/v1/itens-precos/{id}/contas'

export const queryItensPrecosControllerObterItemPrecoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/itens-precos/{id}/contas/{contaId}', pathParams)
export const useQueryItensPrecosControllerObterItemPrecoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryItensPrecosControllerObterItemPrecoContaPorId.queryKey = '/api/v1/itens-precos/{id}/contas/{contaId}'

export const mutationItensPrecosControllerAtualizarItemPrecoConta = (pathParams, options) => mutationFn('put', '/api/v1/itens-precos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationItensPrecosControllerAtualizarItemPrecoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-precos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryItensPrecosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-precos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryItensPrecosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarContasDisponiveis.queryKey = '/api/v1/itens-precos/{id}/contas-disponiveis'

export const mutationItensPrecosControllerCadastrarItemPrecoCondicaoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/itens-precos/{id}/condicao-pagamentos', pathParams, {}, options)
export const useMutationItensPrecosControllerCadastrarItemPrecoCondicaoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/itens-precos/{id}/condicao-pagamentos', pathParams, {}, options), config)

export const queryItensPrecosControllerListarItemPrecosCondicaoPagamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/itens-precos/{id}/condicao-pagamentos', pathParams, queryParams)
export const useQueryItensPrecosControllerListarItemPrecosCondicaoPagamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/itens-precos/{id}/condicao-pagamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryItensPrecosControllerListarItemPrecosCondicaoPagamentos.queryKey = '/api/v1/itens-precos/{id}/condicao-pagamentos'

export const mutationItensPrecosCondicaoPagamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-precos-condicao-pagamentos/{id}', pathParams, {}, options)
export const useMutationItensPrecosCondicaoPagamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-precos-condicao-pagamentos/{id}', pathParams, {}, options), config)

export const mutationItensTributacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/itens-tributacoes/{id}', pathParams, {}, options)
export const useMutationItensTributacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/itens-tributacoes/{id}', pathParams, {}, options), config)

export const mutationItensTributacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/itens-tributacoes/{id}', pathParams, {}, options)
export const useMutationItensTributacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/itens-tributacoes/{id}', pathParams, {}, options), config)

export const mutationLocaisEstoqueControllerCadastrar = (options) => mutationFn('post', '/api/v1/locais-de-estoque', {}, {}, options)
export const useMutationLocaisEstoqueControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/locais-de-estoque', {}, {}, options), config)

export const queryLocaisEstoqueControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/locais-de-estoque', queryParams)
export const useQueryLocaisEstoqueControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocaisEstoqueControllerBuscar.queryKey = '/api/v1/locais-de-estoque'

export const mutationLocaisEstoqueControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/locais-de-estoque/{id}', pathParams, {}, options)
export const useMutationLocaisEstoqueControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-de-estoque/{id}', pathParams, {}, options), config)

export const mutationLocaisEstoqueControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/locais-de-estoque/{id}', pathParams, {}, options)
export const useMutationLocaisEstoqueControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/locais-de-estoque/{id}', pathParams, {}, options), config)

export const queryLocaisEstoqueControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}', pathParams)
export const useQueryLocaisEstoqueControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisEstoqueControllerObterPorId.queryKey = '/api/v1/locais-de-estoque/{id}'

export const queryLocaisEstoqueControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}/detalhes', pathParams)
export const useQueryLocaisEstoqueControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}/detalhes', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisEstoqueControllerObterPorIdDetalhado.queryKey = '/api/v1/locais-de-estoque/{id}/detalhes'

export const mutationLocaisEstoqueControllerCadastrarLocalEstoqueConta = (pathParams, options) => mutationFn('post', '/api/v1/locais-de-estoque/{id}/contas', pathParams, {}, options)
export const useMutationLocaisEstoqueControllerCadastrarLocalEstoqueConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/locais-de-estoque/{id}/contas', pathParams, {}, options), config)

export const queryLocaisEstoqueControllerListarLocalEstoqueContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}/contas', pathParams, queryParams)
export const useQueryLocaisEstoqueControllerListarLocalEstoqueContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisEstoqueControllerListarLocalEstoqueContas.queryKey = '/api/v1/locais-de-estoque/{id}/contas'

export const queryLocaisEstoqueControllerObterLocalEstoqueContaPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}/contas/{contaId}', pathParams)
export const useQueryLocaisEstoqueControllerObterLocalEstoqueContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryLocaisEstoqueControllerObterLocalEstoqueContaPorId.queryKey = '/api/v1/locais-de-estoque/{id}/contas/{contaId}'

export const mutationLocaisEstoqueControllerAtualizarLocalEstoqueConta = (pathParams, options) => mutationFn('put', '/api/v1/locais-de-estoque/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationLocaisEstoqueControllerAtualizarLocalEstoqueConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-de-estoque/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryLocaisEstoqueControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/locais-de-estoque/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryLocaisEstoqueControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-de-estoque/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisEstoqueControllerListarContasDisponiveis.queryKey = '/api/v1/locais-de-estoque/{id}/contas-disponiveis'

export const queryLocaisRealizacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/locais-realizacao', queryParams)
export const useQueryLocaisRealizacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocaisRealizacaoControllerBuscar.queryKey = '/api/v1/locais-realizacao'

export const mutationLocaisRealizacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/locais-realizacao', {}, {}, options)
export const useMutationLocaisRealizacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/locais-realizacao', {}, {}, options), config)

export const queryLocaisRealizacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-realizacao/{id}', pathParams)
export const useQueryLocaisRealizacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisRealizacaoControllerObterPorId.queryKey = '/api/v1/locais-realizacao/{id}'

export const mutationLocaisRealizacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/locais-realizacao/{id}', pathParams, {}, options)
export const useMutationLocaisRealizacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-realizacao/{id}', pathParams, {}, options), config)

export const mutationLocaisRealizacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/locais-realizacao/{id}', pathParams, {}, options)
export const useMutationLocaisRealizacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/locais-realizacao/{id}', pathParams, {}, options), config)

export const mutationLocaisRealizacaoControllerCadastrarLocalRealizacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/locais-realizacao/{id}/contas', pathParams, {}, options)
export const useMutationLocaisRealizacaoControllerCadastrarLocalRealizacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/locais-realizacao/{id}/contas', pathParams, {}, options), config)

export const queryLocaisRealizacaoControllerListarLocalRealizacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/locais-realizacao/{id}/contas', pathParams, queryParams)
export const useQueryLocaisRealizacaoControllerListarLocalRealizacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisRealizacaoControllerListarLocalRealizacaoContas.queryKey = '/api/v1/locais-realizacao/{id}/contas'

export const queryLocaisRealizacaoControllerObterLocalRealizacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/locais-realizacao/{id}/contas/{contaId}', pathParams)
export const useQueryLocaisRealizacaoControllerObterLocalRealizacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryLocaisRealizacaoControllerObterLocalRealizacaoContaPorId.queryKey = '/api/v1/locais-realizacao/{id}/contas/{contaId}'

export const mutationLocaisRealizacaoControllerAtualizarLocalRealizacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/locais-realizacao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationLocaisRealizacaoControllerAtualizarLocalRealizacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/locais-realizacao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryLocaisRealizacaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/locais-realizacao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryLocaisRealizacaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/locais-realizacao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocaisRealizacaoControllerListarContasDisponiveis.queryKey = '/api/v1/locais-realizacao/{id}/contas-disponiveis'

export const queryLocalidadesControllerBuscarPaises = (queryParams, options) => queryFn(options)('/api/v1/localidades/paises', queryParams)
export const useQueryLocalidadesControllerBuscarPaises = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/paises', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarPaises.queryKey = '/api/v1/localidades/paises'

export const queryLocalidadesControllerBuscarEstados = (queryParams, options) => queryFn(options)('/api/v1/localidades/estados', queryParams)
export const useQueryLocalidadesControllerBuscarEstados = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarEstados.queryKey = '/api/v1/localidades/estados'

export const queryLocalidadesControllerBuscarCidades = (queryParams, options) => queryFn(options)('/api/v1/localidades/cidades', queryParams)
export const useQueryLocalidadesControllerBuscarCidades = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/cidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryLocalidadesControllerBuscarCidades.queryKey = '/api/v1/localidades/cidades'

export const queryLocalidadesControllerBuscarEstadosPorPais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/paises/{id}/estados', pathParams, queryParams)
export const useQueryLocalidadesControllerBuscarEstadosPorPais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/paises/{id}/estados', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocalidadesControllerBuscarEstadosPorPais.queryKey = '/api/v1/localidades/paises/{id}/estados'

export const queryLocalidadesControllerBuscarCidadesPorEstado = (pathParams, queryParams, options) => queryFn(options)('/api/v1/localidades/estados/{id}/cidades', pathParams, queryParams)
export const useQueryLocalidadesControllerBuscarCidadesPorEstado = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/localidades/estados/{id}/cidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLocalidadesControllerBuscarCidadesPorEstado.queryKey = '/api/v1/localidades/estados/{id}/cidades'

export const queryLotesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/lotes/{id}', pathParams)
export const useQueryLotesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/lotes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryLotesControllerObterPorId.queryKey = '/api/v1/lotes/{id}'

export const mutationLotesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/lotes/{id}', pathParams, {}, options)
export const useMutationLotesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/lotes/{id}', pathParams, {}, options), config)

export const mutationLotesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/lotes/{id}', pathParams, {}, options)
export const useMutationLotesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/lotes/{id}', pathParams, {}, options), config)

export const queryMailControllerListar = (queryParams, options) => queryFn(options)('/api/v1/mail', queryParams)
export const useQueryMailControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mail', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMailControllerListar.queryKey = '/api/v1/mail'

export const mutationMailControllerCadastrar = (options) => mutationFn('post', '/api/v1/mail', {}, {}, options)
export const useMutationMailControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/mail', {}, {}, options), config)

export const queryMailControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/mail/{id}', pathParams)
export const useQueryMailControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mail/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMailControllerObterPorId.queryKey = '/api/v1/mail/{id}'

export const queryMailTemplatesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/mail-templates', queryParams)
export const useQueryMailTemplatesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mail-templates', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMailTemplatesControllerListar.queryKey = '/api/v1/mail-templates'

export const mutationMailTemplatesControllerCadastrar = (options) => mutationFn('post', '/api/v1/mail-templates', {}, {}, options)
export const useMutationMailTemplatesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/mail-templates', {}, {}, options), config)

export const mutationMailTemplatesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/mail-templates/{id}', pathParams, {}, options)
export const useMutationMailTemplatesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/mail-templates/{id}', pathParams, {}, options), config)

export const mutationMailTemplatesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/mail-templates/{id}', pathParams, {}, options)
export const useMutationMailTemplatesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/mail-templates/{id}', pathParams, {}, options), config)

export const queryMailTemplatesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/mail-templates/{id}', pathParams)
export const useQueryMailTemplatesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/mail-templates/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMailTemplatesControllerObterPorId.queryKey = '/api/v1/mail-templates/{id}'

export const mutationMetasConfiguracaoComissaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/metas-configuracao-de-comissao/{id}', pathParams, {}, options)
export const useMutationMetasConfiguracaoComissaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/metas-configuracao-de-comissao/{id}', pathParams, {}, options), config)

export const queryMetasConfiguracaoComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/metas-configuracao-de-comissao/{id}', pathParams)
export const useQueryMetasConfiguracaoComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/metas-configuracao-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMetasConfiguracaoComissaoControllerObterPorId.queryKey = '/api/v1/metas-configuracao-de-comissao/{id}'

export const mutationMetasConfiguracaoComissaoControllerDeletarMeta = (pathParams, options) => mutationFn('delete', '/api/v1/metas-configuracao-de-comissao/{id}', pathParams, {}, options)
export const useMutationMetasConfiguracaoComissaoControllerDeletarMeta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/metas-configuracao-de-comissao/{id}', pathParams, {}, options), config)

export const queryModalidadesTurmaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/modalidades-de-turma', queryParams)
export const useQueryModalidadesTurmaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modalidades-de-turma', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryModalidadesTurmaControllerListar.queryKey = '/api/v1/modalidades-de-turma'

export const mutationModalidadesTurmaControllerCadastrar = (options) => mutationFn('post', '/api/v1/modalidades-de-turma', {}, {}, options)
export const useMutationModalidadesTurmaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/modalidades-de-turma', {}, {}, options), config)

export const queryModalidadesTurmaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/modalidades-de-turma/{id}', pathParams)
export const useQueryModalidadesTurmaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modalidades-de-turma/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModalidadesTurmaControllerObterPorId.queryKey = '/api/v1/modalidades-de-turma/{id}'

export const mutationModalidadesTurmaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/modalidades-de-turma/{id}', pathParams, {}, options)
export const useMutationModalidadesTurmaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modalidades-de-turma/{id}', pathParams, {}, options), config)

export const mutationModalidadesTurmaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/modalidades-de-turma/{id}', pathParams, {}, options)
export const useMutationModalidadesTurmaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modalidades-de-turma/{id}', pathParams, {}, options), config)

export const queryModalidadesTurmaControllerListarContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modalidades-de-turma/{id}/contas', pathParams, queryParams)
export const useQueryModalidadesTurmaControllerListarContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modalidades-de-turma/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModalidadesTurmaControllerListarContas.queryKey = '/api/v1/modalidades-de-turma/{id}/contas'

export const mutationModalidadesTurmaControllerCadastrarConta = (pathParams, options) => mutationFn('post', '/api/v1/modalidades-de-turma/{id}/contas', pathParams, {}, options)
export const useMutationModalidadesTurmaControllerCadastrarConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modalidades-de-turma/{id}/contas', pathParams, {}, options), config)

export const queryModalidadesTurmaControllerObterContaPorId = (pathParams, options) => queryFn(options)('/api/v1/modalidades-de-turma/{id}/contas/{contaId}', pathParams)
export const useQueryModalidadesTurmaControllerObterContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modalidades-de-turma/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryModalidadesTurmaControllerObterContaPorId.queryKey = '/api/v1/modalidades-de-turma/{id}/contas/{contaId}'

export const mutationModalidadesTurmaControllerAtualizarConta = (pathParams, options) => mutationFn('put', '/api/v1/modalidades-de-turma/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationModalidadesTurmaControllerAtualizarConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modalidades-de-turma/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryModalidadesTurmaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/modalidades-de-turma/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryModalidadesTurmaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modalidades-de-turma/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModalidadesTurmaControllerListarContasDisponiveis.queryKey = '/api/v1/modalidades-de-turma/{id}/contas-disponiveis'

export const mutationModeloDiarioInfantilControllerCadastrar = (options) => mutationFn('post', '/api/v1/modelo-diario-infantil', {}, {}, options)
export const useMutationModeloDiarioInfantilControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/modelo-diario-infantil', {}, {}, options), config)

export const queryModeloDiarioInfantilControllerListarModelosDiario = (queryParams, options) => queryFn(options)('/api/v1/modelo-diario-infantil', queryParams)
export const useQueryModeloDiarioInfantilControllerListarModelosDiario = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-diario-infantil', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryModeloDiarioInfantilControllerListarModelosDiario.queryKey = '/api/v1/modelo-diario-infantil'

export const mutationModeloDiarioInfantilControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/modelo-diario-infantil/{id}', pathParams, {}, options)
export const useMutationModeloDiarioInfantilControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/modelo-diario-infantil/{id}', pathParams, {}, options), config)

export const mutationModeloDiarioInfantilControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/modelo-diario-infantil/{id}', pathParams, {}, options)
export const useMutationModeloDiarioInfantilControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/modelo-diario-infantil/{id}', pathParams, {}, options), config)

export const queryModeloDiarioInfantilControllerObterModelosDiarioPorId = (pathParams, options) => queryFn(options)('/api/v1/modelo-diario-infantil/{id}', pathParams)
export const useQueryModeloDiarioInfantilControllerObterModelosDiarioPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-diario-infantil/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryModeloDiarioInfantilControllerObterModelosDiarioPorId.queryKey = '/api/v1/modelo-diario-infantil/{id}'

export const mutationModeloDiarioInfantilControllerCopiarModeloDiario = (pathParams, options) => mutationFn('post', '/api/v1/modelo-diario-infantil/{id}/copiar-modelo-diario', pathParams, {}, options)
export const useMutationModeloDiarioInfantilControllerCopiarModeloDiario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/modelo-diario-infantil/{id}/copiar-modelo-diario', pathParams, {}, options), config)

export const queryModeloDiarioInfantilControllerListarTipoCampoModeloDiario = (queryParams, options) => queryFn(options)('/api/v1/modelo-diario-infantil/tipo-campo-modelo-diario', queryParams)
export const useQueryModeloDiarioInfantilControllerListarTipoCampoModeloDiario = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/modelo-diario-infantil/tipo-campo-modelo-diario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryModeloDiarioInfantilControllerListarTipoCampoModeloDiario.queryKey = '/api/v1/modelo-diario-infantil/tipo-campo-modelo-diario'

export const queryMotivosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/motivos', queryParams)
export const useQueryMotivosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosControllerBuscar.queryKey = '/api/v1/motivos'

export const mutationMotivosControllerCadastrar = (options) => mutationFn('post', '/api/v1/motivos', {}, {}, options)
export const useMutationMotivosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/motivos', {}, {}, options), config)

export const queryMotivosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos/{id}', pathParams)
export const useQueryMotivosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosControllerObterPorId.queryKey = '/api/v1/motivos/{id}'

export const mutationMotivosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/motivos/{id}', pathParams, {}, options)
export const useMutationMotivosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/motivos/{id}', pathParams, {}, options), config)

export const mutationMotivosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/motivos/{id}', pathParams, {}, options)
export const useMutationMotivosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/motivos/{id}', pathParams, {}, options), config)

export const mutationMotivosControllerCadastrarMotivoConta = (pathParams, options) => mutationFn('post', '/api/v1/motivos/{id}/contas', pathParams, {}, options)
export const useMutationMotivosControllerCadastrarMotivoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/motivos/{id}/contas', pathParams, {}, options), config)

export const queryMotivosControllerListarMotivoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/motivos/{id}/contas', pathParams, queryParams)
export const useQueryMotivosControllerListarMotivoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosControllerListarMotivoContas.queryKey = '/api/v1/motivos/{id}/contas'

export const queryMotivosControllerObterMotivoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos/{id}/contas/{contaId}', pathParams)
export const useQueryMotivosControllerObterMotivoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryMotivosControllerObterMotivoContaPorId.queryKey = '/api/v1/motivos/{id}/contas/{contaId}'

export const mutationMotivosControllerAtualizarMotivoConta = (pathParams, options) => mutationFn('put', '/api/v1/motivos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationMotivosControllerAtualizarMotivoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/motivos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryMotivosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/motivos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryMotivosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosControllerListarContasDisponiveis.queryKey = '/api/v1/motivos/{id}/contas-disponiveis'

export const queryMotivosCancelamentoControllerListarModulos = (queryParams, options) => queryFn(options)('/api/v1/motivos-cancelamento/modulos', queryParams)
export const useQueryMotivosCancelamentoControllerListarModulos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento/modulos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosCancelamentoControllerListarModulos.queryKey = '/api/v1/motivos-cancelamento/modulos'

export const queryMotivosCancelamentoControllerObterModuloPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos-cancelamento/modulos/{id}', pathParams)
export const useQueryMotivosCancelamentoControllerObterModuloPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento/modulos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosCancelamentoControllerObterModuloPorId.queryKey = '/api/v1/motivos-cancelamento/modulos/{id}'

export const queryMotivosCancelamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/motivos-cancelamento', queryParams)
export const useQueryMotivosCancelamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosCancelamentoControllerListar.queryKey = '/api/v1/motivos-cancelamento'

export const mutationMotivosCancelamentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/motivos-cancelamento', {}, {}, options)
export const useMutationMotivosCancelamentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/motivos-cancelamento', {}, {}, options), config)

export const queryMotivosCancelamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos-cancelamento/{id}', pathParams)
export const useQueryMotivosCancelamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosCancelamentoControllerObterPorId.queryKey = '/api/v1/motivos-cancelamento/{id}'

export const mutationMotivosCancelamentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/motivos-cancelamento/{id}', pathParams, {}, options)
export const useMutationMotivosCancelamentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/motivos-cancelamento/{id}', pathParams, {}, options), config)

export const mutationMotivosCancelamentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/motivos-cancelamento/{id}', pathParams, {}, options)
export const useMutationMotivosCancelamentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/motivos-cancelamento/{id}', pathParams, {}, options), config)

export const queryMotivosCancelamentoControllerObterPorModulo = (queryParams, options) => queryFn(options)('/api/v1/motivos-cancelamento/listar-por-modulo', queryParams)
export const useQueryMotivosCancelamentoControllerObterPorModulo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-cancelamento/listar-por-modulo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosCancelamentoControllerObterPorModulo.queryKey = '/api/v1/motivos-cancelamento/listar-por-modulo'

export const queryMotivosNotificacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/motivos-notificacao', queryParams)
export const useQueryMotivosNotificacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-notificacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosNotificacaoControllerListar.queryKey = '/api/v1/motivos-notificacao'

export const queryMotivosNotificacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/motivos-notificacao/{id}', pathParams)
export const useQueryMotivosNotificacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-notificacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMotivosNotificacaoControllerObterPorId.queryKey = '/api/v1/motivos-notificacao/{id}'

export const queryMotivosNotificacaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/motivos-notificacao/{chave}', pathParams)
export const useQueryMotivosNotificacaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-notificacao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryMotivosNotificacaoControllerObterPorChave.queryKey = '/api/v1/motivos-notificacao/{chave}'

export const queryMotivosRevogacaoTermoAceiteLgpdControlerControllerListar = (queryParams, options) => queryFn(options)('/api/v1/motivos-revogacao-termo-lgpd', queryParams)
export const useQueryMotivosRevogacaoTermoAceiteLgpdControlerControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/motivos-revogacao-termo-lgpd', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryMotivosRevogacaoTermoAceiteLgpdControlerControllerListar.queryKey = '/api/v1/motivos-revogacao-termo-lgpd'

export const queryMovimentosCaixaControllerObterMovimentoPorId = (pathParams, options) => queryFn(options)('/api/v1/movimentos-de-caixa/{id}', pathParams)
export const useQueryMovimentosCaixaControllerObterMovimentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/movimentos-de-caixa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMovimentosCaixaControllerObterMovimentoPorId.queryKey = '/api/v1/movimentos-de-caixa/{id}'

export const queryMovimentosCaixaControllerObterMovimentoTituloPorId = (pathParams, options) => queryFn(options)('/api/v1/movimentos-de-caixa/{id}/movimento-titulo', pathParams)
export const useQueryMovimentosCaixaControllerObterMovimentoTituloPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/movimentos-de-caixa/{id}/movimento-titulo', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMovimentosCaixaControllerObterMovimentoTituloPorId.queryKey = '/api/v1/movimentos-de-caixa/{id}/movimento-titulo'

export const queryMovimentosCaixaControllerObterBaixaPorMovimento = (pathParams, options) => queryFn(options)('/api/v1/movimentos-de-caixa/{id}/baixa', pathParams)
export const useQueryMovimentosCaixaControllerObterBaixaPorMovimento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/movimentos-de-caixa/{id}/baixa', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryMovimentosCaixaControllerObterBaixaPorMovimento.queryKey = '/api/v1/movimentos-de-caixa/{id}/baixa'

export const queryNacionalidadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/nacionalidades', queryParams)
export const useQueryNacionalidadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNacionalidadesControllerBuscar.queryKey = '/api/v1/nacionalidades'

export const mutationNacionalidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/nacionalidades', {}, {}, options)
export const useMutationNacionalidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/nacionalidades', {}, {}, options), config)

export const queryNacionalidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/nacionalidades/{id}', pathParams)
export const useQueryNacionalidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNacionalidadesControllerObterPorId.queryKey = '/api/v1/nacionalidades/{id}'

export const mutationNacionalidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/nacionalidades/{id}', pathParams, {}, options)
export const useMutationNacionalidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/nacionalidades/{id}', pathParams, {}, options), config)

export const mutationNacionalidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/nacionalidades/{id}', pathParams, {}, options)
export const useMutationNacionalidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/nacionalidades/{id}', pathParams, {}, options), config)

export const mutationNacionalidadesControllerCadastrarNacionalidadeConta = (pathParams, options) => mutationFn('post', '/api/v1/nacionalidades/{id}/contas', pathParams, {}, options)
export const useMutationNacionalidadesControllerCadastrarNacionalidadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/nacionalidades/{id}/contas', pathParams, {}, options), config)

export const queryNacionalidadesControllerListarNacionalidadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/nacionalidades/{id}/contas', pathParams, queryParams)
export const useQueryNacionalidadesControllerListarNacionalidadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNacionalidadesControllerListarNacionalidadeContas.queryKey = '/api/v1/nacionalidades/{id}/contas'

export const queryNacionalidadesControllerObterNacionalidadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/nacionalidades/{id}/contas/{contaId}', pathParams)
export const useQueryNacionalidadesControllerObterNacionalidadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryNacionalidadesControllerObterNacionalidadeContaPorId.queryKey = '/api/v1/nacionalidades/{id}/contas/{contaId}'

export const mutationNacionalidadesControllerAtualizarNacionalidadeConta = (pathParams, options) => mutationFn('put', '/api/v1/nacionalidades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationNacionalidadesControllerAtualizarNacionalidadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/nacionalidades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryNacionalidadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/nacionalidades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryNacionalidadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/nacionalidades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNacionalidadesControllerListarContasDisponiveis.queryKey = '/api/v1/nacionalidades/{id}/contas-disponiveis'

export const queryNaturezasCondicaoPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/naturezas-condicao-de-pagamento', queryParams)
export const useQueryNaturezasCondicaoPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/naturezas-condicao-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNaturezasCondicaoPagamentoControllerListar.queryKey = '/api/v1/naturezas-condicao-de-pagamento'

export const queryNaturezasCondicaoPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/naturezas-condicao-de-pagamento/{id}', pathParams)
export const useQueryNaturezasCondicaoPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/naturezas-condicao-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNaturezasCondicaoPagamentoControllerObterPorId.queryKey = '/api/v1/naturezas-condicao-de-pagamento/{id}'

export const queryNaturezasCondicaoPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/naturezas-condicao-de-pagamento/{chave}', pathParams)
export const useQueryNaturezasCondicaoPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/naturezas-condicao-de-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryNaturezasCondicaoPagamentoControllerObterPorChave.queryKey = '/api/v1/naturezas-condicao-de-pagamento/{chave}'

export const queryNaturezasPlanoContaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/naturezas-plano-conta', queryParams)
export const useQueryNaturezasPlanoContaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/naturezas-plano-conta', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNaturezasPlanoContaControllerListar.queryKey = '/api/v1/naturezas-plano-conta'

export const mutationNCMsControllerCadastrar = (options) => mutationFn('post', '/api/v1/ncms', {}, {}, options)
export const useMutationNCMsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/ncms', {}, {}, options), config)

export const queryNCMsControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/ncms', queryParams)
export const useQueryNCMsControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ncms', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNCMsControllerBuscar.queryKey = '/api/v1/ncms'

export const mutationNCMsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/ncms/{id}', pathParams, {}, options)
export const useMutationNCMsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/ncms/{id}', pathParams, {}, options), config)

export const mutationNCMsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/ncms/{id}', pathParams, {}, options)
export const useMutationNCMsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/ncms/{id}', pathParams, {}, options), config)

export const queryNCMsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/ncms/{id}', pathParams)
export const useQueryNCMsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ncms/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNCMsControllerObterPorId.queryKey = '/api/v1/ncms/{id}'

export const queryNCMsControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/ncms/{id}/detalhes', pathParams)
export const useQueryNCMsControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ncms/{id}/detalhes', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNCMsControllerObterPorIdDetalhado.queryKey = '/api/v1/ncms/{id}/detalhes'

export const queryNecessidadesEspeciaisControllerListarNecessidadesEspeciais = (queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais', queryParams)
export const useQueryNecessidadesEspeciaisControllerListarNecessidadesEspeciais = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNecessidadesEspeciaisControllerListarNecessidadesEspeciais.queryKey = '/api/v1/necessidades-especiais'

export const mutationNecessidadesEspeciaisControllerCadastrar = (options) => mutationFn('post', '/api/v1/necessidades-especiais', {}, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais', {}, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}', pathParams)
export const useQueryNecessidadesEspeciaisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterPorId.queryKey = '/api/v1/necessidades-especiais/{id}'

export const mutationNecessidadesEspeciaisControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialTipo = (pathParams, options) => mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-tipos', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialTipo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-tipos', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos', pathParams)
export const useQueryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterTiposNecessidadesEspeciaisPorNecessidadeEspecialId.queryKey = '/api/v1/necessidades-especiais/{id}/necessidade-especial-tipos'

export const mutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialTipo = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}/tipo-necessidade-especial/{tipoNecessidadeEspecialId}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialTipo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}/tipo-necessidade-especial/{tipoNecessidadeEspecialId}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialrecurso = (pathParams, options) => mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-recursos', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialrecurso = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais/{id}/necessidades-especiais-recursos', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos', pathParams, queryParams)
export const useQueryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerObterRecursosNecessidadesEspeciaisPorNecessidadeEspecialId.queryKey = '/api/v1/necessidades-especiais/{id}/necessidade-especial-recursos'

export const mutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialRecurso = (pathParams, options) => mutationFn('delete', '/api/v1/necessidades-especiais/{id}/recurso-necessidade-especial/{recursoNecessidadeEspecialId}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerDeletarNecessidadeEspecialRecurso = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/necessidades-especiais/{id}/recurso-necessidade-especial/{recursoNecessidadeEspecialId}', pathParams, {}, options), config)

export const mutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialConta = (pathParams, options) => mutationFn('post', '/api/v1/necessidades-especiais/{id}/contas', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerCadastrarNecessidadeEspecialConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/necessidades-especiais/{id}/contas', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerListarNecessidadeEspecialContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/contas', pathParams, queryParams)
export const useQueryNecessidadesEspeciaisControllerListarNecessidadeEspecialContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerListarNecessidadeEspecialContas.queryKey = '/api/v1/necessidades-especiais/{id}/contas'

export const queryNecessidadesEspeciaisControllerObterNecessidadeEspecialContaPorId = (pathParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/contas/{contaId}', pathParams)
export const useQueryNecessidadesEspeciaisControllerObterNecessidadeEspecialContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryNecessidadesEspeciaisControllerObterNecessidadeEspecialContaPorId.queryKey = '/api/v1/necessidades-especiais/{id}/contas/{contaId}'

export const mutationNecessidadesEspeciaisControllerAtualizarNecessidadeEspecialConta = (pathParams, options) => mutationFn('put', '/api/v1/necessidades-especiais/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationNecessidadesEspeciaisControllerAtualizarNecessidadeEspecialConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/necessidades-especiais/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryNecessidadesEspeciaisControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/necessidades-especiais/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryNecessidadesEspeciaisControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/necessidades-especiais/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNecessidadesEspeciaisControllerListarContasDisponiveis.queryKey = '/api/v1/necessidades-especiais/{id}/contas-disponiveis'

export const queryNumeroMaxParcelaLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/numero-max-parcela-link-pagamento', queryParams)
export const useQueryNumeroMaxParcelaLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/numero-max-parcela-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryNumeroMaxParcelaLinkPagamentoControllerListar.queryKey = '/api/v1/numero-max-parcela-link-pagamento'

export const queryNumeroMaxParcelaLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/numero-max-parcela-link-pagamento/{id}', pathParams)
export const useQueryNumeroMaxParcelaLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/numero-max-parcela-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryNumeroMaxParcelaLinkPagamentoControllerObterPorId.queryKey = '/api/v1/numero-max-parcela-link-pagamento/{id}'

export const queryNumeroMaxParcelaLinkPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/numero-max-parcela-link-pagamento/{chave}', pathParams)
export const useQueryNumeroMaxParcelaLinkPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/numero-max-parcela-link-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryNumeroMaxParcelaLinkPagamentoControllerObterPorChave.queryKey = '/api/v1/numero-max-parcela-link-pagamento/{chave}'

export const queryObjetivosAprendizagemDesenvolvimentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/objetivos-aprendizagem-desenvolvimento', queryParams)
export const useQueryObjetivosAprendizagemDesenvolvimentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetivos-aprendizagem-desenvolvimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryObjetivosAprendizagemDesenvolvimentoControllerBuscar.queryKey = '/api/v1/objetivos-aprendizagem-desenvolvimento'

export const mutationObjetivosAprendizagemDesenvolvimentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/objetivos-aprendizagem-desenvolvimento', {}, {}, options)
export const useMutationObjetivosAprendizagemDesenvolvimentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/objetivos-aprendizagem-desenvolvimento', {}, {}, options), config)

export const queryObjetivosAprendizagemDesenvolvimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/objetivos-aprendizagem-desenvolvimento/{id}', pathParams)
export const useQueryObjetivosAprendizagemDesenvolvimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetivos-aprendizagem-desenvolvimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryObjetivosAprendizagemDesenvolvimentoControllerObterPorId.queryKey = '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}'

export const mutationObjetivosAprendizagemDesenvolvimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}', pathParams, {}, options)
export const useMutationObjetivosAprendizagemDesenvolvimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}', pathParams, {}, options), config)

export const mutationObjetivosAprendizagemDesenvolvimentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}', pathParams, {}, options)
export const useMutationObjetivosAprendizagemDesenvolvimentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}', pathParams, {}, options), config)

export const mutationObjetivosAprendizagemDesenvolvimentoControllerCadastrarObjetivoAprendizagemDesenvolvimentoConta = (pathParams, options) => mutationFn('post', '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas', pathParams, {}, options)
export const useMutationObjetivosAprendizagemDesenvolvimentoControllerCadastrarObjetivoAprendizagemDesenvolvimentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas', pathParams, {}, options), config)

export const queryObjetivosAprendizagemDesenvolvimentoControllerListarObjetivoAprendizagemDesenvolvimentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas', pathParams, queryParams)
export const useQueryObjetivosAprendizagemDesenvolvimentoControllerListarObjetivoAprendizagemDesenvolvimentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryObjetivosAprendizagemDesenvolvimentoControllerListarObjetivoAprendizagemDesenvolvimentoContas.queryKey = '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas'

export const queryObjetivosAprendizagemDesenvolvimentoControllerObterObjetivoAprendizagemDesenvolvimentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas/{contaId}', pathParams)
export const useQueryObjetivosAprendizagemDesenvolvimentoControllerObterObjetivoAprendizagemDesenvolvimentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryObjetivosAprendizagemDesenvolvimentoControllerObterObjetivoAprendizagemDesenvolvimentoContaPorId.queryKey = '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas/{contaId}'

export const mutationObjetivosAprendizagemDesenvolvimentoControllerAtualizarObjetivoAprendizagemDesenvolvimentoConta = (pathParams, options) => mutationFn('put', '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationObjetivosAprendizagemDesenvolvimentoControllerAtualizarObjetivoAprendizagemDesenvolvimentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryObjetivosAprendizagemDesenvolvimentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryObjetivosAprendizagemDesenvolvimentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryObjetivosAprendizagemDesenvolvimentoControllerListarContasDisponiveis.queryKey = '/api/v1/objetivos-aprendizagem-desenvolvimento/{id}/contas-disponiveis'

export const queryObjetosConhecimentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/objetos-de-conhecimento', queryParams)
export const useQueryObjetosConhecimentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetos-de-conhecimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryObjetosConhecimentoControllerBuscar.queryKey = '/api/v1/objetos-de-conhecimento'

export const mutationObjetosConhecimentoControllerCadastrar = (options) => mutationFn('post', '/api/v1/objetos-de-conhecimento', {}, {}, options)
export const useMutationObjetosConhecimentoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/objetos-de-conhecimento', {}, {}, options), config)

export const queryObjetosConhecimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/objetos-de-conhecimento/{id}', pathParams)
export const useQueryObjetosConhecimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetos-de-conhecimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryObjetosConhecimentoControllerObterPorId.queryKey = '/api/v1/objetos-de-conhecimento/{id}'

export const mutationObjetosConhecimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/objetos-de-conhecimento/{id}', pathParams, {}, options)
export const useMutationObjetosConhecimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/objetos-de-conhecimento/{id}', pathParams, {}, options), config)

export const mutationObjetosConhecimentoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/objetos-de-conhecimento/{id}', pathParams, {}, options)
export const useMutationObjetosConhecimentoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/objetos-de-conhecimento/{id}', pathParams, {}, options), config)

export const mutationObjetosConhecimentoControllerCadastrarObjetoConhecimentoConta = (pathParams, options) => mutationFn('post', '/api/v1/objetos-de-conhecimento/{id}/contas', pathParams, {}, options)
export const useMutationObjetosConhecimentoControllerCadastrarObjetoConhecimentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/objetos-de-conhecimento/{id}/contas', pathParams, {}, options), config)

export const queryObjetosConhecimentoControllerListarObjetoConhecimentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/objetos-de-conhecimento/{id}/contas', pathParams, queryParams)
export const useQueryObjetosConhecimentoControllerListarObjetoConhecimentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetos-de-conhecimento/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryObjetosConhecimentoControllerListarObjetoConhecimentoContas.queryKey = '/api/v1/objetos-de-conhecimento/{id}/contas'

export const queryObjetosConhecimentoControllerObterObjetoConhecimentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/objetos-de-conhecimento/{id}/contas/{contaId}', pathParams)
export const useQueryObjetosConhecimentoControllerObterObjetoConhecimentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetos-de-conhecimento/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryObjetosConhecimentoControllerObterObjetoConhecimentoContaPorId.queryKey = '/api/v1/objetos-de-conhecimento/{id}/contas/{contaId}'

export const mutationObjetosConhecimentoControllerAtualizarObjetoConhecimentoConta = (pathParams, options) => mutationFn('put', '/api/v1/objetos-de-conhecimento/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationObjetosConhecimentoControllerAtualizarObjetoConhecimentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/objetos-de-conhecimento/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryObjetosConhecimentoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/objetos-de-conhecimento/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryObjetosConhecimentoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetos-de-conhecimento/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryObjetosConhecimentoControllerListarContasDisponiveis.queryKey = '/api/v1/objetos-de-conhecimento/{id}/contas-disponiveis'

export const mutationObjetosConhecimentoControllerCadastrarObjetoConhecimentoHabilidadeEnsinoFundamental = (pathParams, options) => mutationFn('post', '/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental', pathParams, {}, options)
export const useMutationObjetosConhecimentoControllerCadastrarObjetoConhecimentoHabilidadeEnsinoFundamental = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental', pathParams, {}, options), config)

export const queryObjetosConhecimentoControllerListarObjetoConhecimentoHabilidadesEnsinoFundamental = (pathParams, queryParams, options) => queryFn(options)('/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental', pathParams, queryParams)
export const useQueryObjetosConhecimentoControllerListarObjetoConhecimentoHabilidadesEnsinoFundamental = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryObjetosConhecimentoControllerListarObjetoConhecimentoHabilidadesEnsinoFundamental.queryKey = '/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental'

export const queryObjetosConhecimentoControllerObterObjetoConhecimentoHabilidadeEnsinoFundamentalPorId = (pathParams, options) => queryFn(options)('/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams)
export const useQueryObjetosConhecimentoControllerObterObjetoConhecimentoHabilidadeEnsinoFundamentalPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.habilidadeEnsinoFundamentalId, ...config }
})
useQueryObjetosConhecimentoControllerObterObjetoConhecimentoHabilidadeEnsinoFundamentalPorId.queryKey = '/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}'

export const mutationObjetosConhecimentoControllerDeletarObjetoConhecimentoHabilidadeEnsinoFundamental = (pathParams, options) => mutationFn('delete', '/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams, {}, options)
export const useMutationObjetosConhecimentoControllerDeletarObjetoConhecimentoHabilidadeEnsinoFundamental = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/objetos-de-conhecimento/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams, {}, options), config)

export const mutationOcorrenciasControllerCadastrar = (options) => mutationFn('post', '/api/v1/ocorrencias', {}, {}, options)
export const useMutationOcorrenciasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/ocorrencias', {}, {}, options), config)

export const queryOcorrenciasControllerListarOcorrencias = (queryParams, options) => queryFn(options)('/api/v1/ocorrencias', queryParams)
export const useQueryOcorrenciasControllerListarOcorrencias = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ocorrencias', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOcorrenciasControllerListarOcorrencias.queryKey = '/api/v1/ocorrencias'

export const queryOcorrenciasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/ocorrencias/{id}', pathParams)
export const useQueryOcorrenciasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ocorrencias/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOcorrenciasControllerObterPorId.queryKey = '/api/v1/ocorrencias/{id}'

export const mutationOcorrenciasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/ocorrencias/{id}', pathParams, {}, options)
export const useMutationOcorrenciasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/ocorrencias/{id}', pathParams, {}, options), config)

export const mutationOcorrenciasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/ocorrencias/{id}', pathParams, {}, options)
export const useMutationOcorrenciasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/ocorrencias/{id}', pathParams, {}, options), config)

export const mutationOcorrenciasControllerNotificarOcorrenciaPorEmail = (pathParams, options) => mutationFn('post', '/api/v1/ocorrencias/{id}/notificacao-email', pathParams, {}, options)
export const useMutationOcorrenciasControllerNotificarOcorrenciaPorEmail = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/ocorrencias/{id}/notificacao-email', pathParams, {}, options), config)

export const mutationOcorrenciasControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/ocorrencias/{id}/arquivos', pathParams, {}, options)
export const useMutationOcorrenciasControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/ocorrencias/{id}/arquivos', pathParams, {}, options), config)

export const queryOcorrenciasControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/ocorrencias/{id}/arquivos', pathParams, queryParams)
export const useQueryOcorrenciasControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ocorrencias/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOcorrenciasControllerListarArquivos.queryKey = '/api/v1/ocorrencias/{id}/arquivos'

export const mutationOcorrenciasControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/ocorrencias/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationOcorrenciasControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/ocorrencias/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationOcorrenciasControllerCadastrarTagOcorrencia = (pathParams, options) => mutationFn('post', '/api/v1/ocorrencias/{id}/tags', pathParams, {}, options)
export const useMutationOcorrenciasControllerCadastrarTagOcorrencia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/ocorrencias/{id}/tags', pathParams, {}, options), config)

export const queryOcorrenciasControllerListarTagsOcorrencia = (pathParams, queryParams, options) => queryFn(options)('/api/v1/ocorrencias/{id}/tags', pathParams, queryParams)
export const useQueryOcorrenciasControllerListarTagsOcorrencia = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ocorrencias/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOcorrenciasControllerListarTagsOcorrencia.queryKey = '/api/v1/ocorrencias/{id}/tags'

export const mutationOcorrenciasControllerDeletarTagOcorrencia = (pathParams, options) => mutationFn('delete', '/api/v1/ocorrencias/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationOcorrenciasControllerDeletarTagOcorrencia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/ocorrencias/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryOcorrenciasControllerListarTagsDisponiveisOcorrencia = (pathParams, queryParams, options) => queryFn(options)('/api/v1/ocorrencias/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryOcorrenciasControllerListarTagsDisponiveisOcorrencia = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ocorrencias/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOcorrenciasControllerListarTagsDisponiveisOcorrencia.queryKey = '/api/v1/ocorrencias/{id}/tags-disponiveis'

export const queryOpcaoPagamentoLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/opcao-pagamento-link-pagamento', queryParams)
export const useQueryOpcaoPagamentoLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/opcao-pagamento-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOpcaoPagamentoLinkPagamentoControllerListar.queryKey = '/api/v1/opcao-pagamento-link-pagamento'

export const queryOpcaoPagamentoLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/opcao-pagamento-link-pagamento/{id}', pathParams)
export const useQueryOpcaoPagamentoLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/opcao-pagamento-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpcaoPagamentoLinkPagamentoControllerObterPorId.queryKey = '/api/v1/opcao-pagamento-link-pagamento/{id}'

export const queryOpcaoPagamentoLinkPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/opcao-pagamento-link-pagamento/{chave}', pathParams)
export const useQueryOpcaoPagamentoLinkPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/opcao-pagamento-link-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryOpcaoPagamentoLinkPagamentoControllerObterPorChave.queryKey = '/api/v1/opcao-pagamento-link-pagamento/{chave}'

export const mutationOpenBankingControllerCriarLinkConsentimentoOpenBanking = (options) => mutationFn('post', '/api/v1/open-banking/criar-link-consentimento', {}, {}, options)
export const useMutationOpenBankingControllerCriarLinkConsentimentoOpenBanking = (config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/criar-link-consentimento', {}, {}, options), config)

export const queryOpenBankingControllerObterExtratosBancariosOpenBanking = (pathParams, queryParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios', pathParams, queryParams)
export const useQueryOpenBankingControllerObterExtratosBancariosOpenBanking = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerObterExtratosBancariosOpenBanking.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios'

export const queryOpenBankingControllerObterExtratoPorId = (pathParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios/{extratoId}', pathParams)
export const useQueryOpenBankingControllerObterExtratoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios/{extratoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.extratoId, ...config }
})
useQueryOpenBankingControllerObterExtratoPorId.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/extratos-bancarios/{extratoId}'

export const queryOpenBankingControllerObterSaldosBancariosOpenBanking = (queryParams, options) => queryFn(options)('/api/v1/open-banking/saldo-bancarios', queryParams)
export const useQueryOpenBankingControllerObterSaldosBancariosOpenBanking = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/saldo-bancarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOpenBankingControllerObterSaldosBancariosOpenBanking.queryKey = '/api/v1/open-banking/saldo-bancarios'

export const queryOpenBankingControllerObterMovimentacoesBancariasOpenBanking = (queryParams, options) => queryFn(options)('/api/v1/open-banking/movimentacao-bancaria', queryParams)
export const useQueryOpenBankingControllerObterMovimentacoesBancariasOpenBanking = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/movimentacao-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOpenBankingControllerObterMovimentacoesBancariasOpenBanking.queryKey = '/api/v1/open-banking/movimentacao-bancaria'

export const queryOpenBankingControllerEmitirComprovantesBancariosOpenBanking = (pathParams, options) => queryFn(options)('/api/v1/open-banking/comprovante-bancario/{id}', pathParams)
export const useQueryOpenBankingControllerEmitirComprovantesBancariosOpenBanking = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/comprovante-bancario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.Id, ...config }
})
useQueryOpenBankingControllerEmitirComprovantesBancariosOpenBanking.queryKey = '/api/v1/open-banking/comprovante-bancario/{id}'

export const mutationOpenBankingControllerCadastrarContatoOpenBanking = (options) => mutationFn('post', '/api/v1/open-banking/contato', {}, {}, options)
export const useMutationOpenBankingControllerCadastrarContatoOpenBanking = (config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/contato', {}, {}, options), config)

export const queryOpenBankingControllerObterContatosBancariosOpenBanking = (pathParams, queryParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/contatos', pathParams, queryParams)
export const useQueryOpenBankingControllerObterContatosBancariosOpenBanking = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/contatos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerObterContatosBancariosOpenBanking.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/contatos'

export const queryOpenBankingControllerObterContatosBancariosOpenBankingPorId = (pathParams, options) => queryFn(options)('/api/v1/open-banking/contatos/{id}', pathParams)
export const useQueryOpenBankingControllerObterContatosBancariosOpenBankingPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerObterContatosBancariosOpenBankingPorId.queryKey = '/api/v1/open-banking/contatos/{id}'

export const queryOpenBankingControllerObterContatosContasBancariasOpenBanking = (pathParams, queryParams, options) => queryFn(options)('/api/v1/open-banking/contatos/{id}/conta', pathParams, queryParams)
export const useQueryOpenBankingControllerObterContatosContasBancariasOpenBanking = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contatos/{id}/conta', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerObterContatosContasBancariasOpenBanking.queryKey = '/api/v1/open-banking/contatos/{id}/conta'

export const mutationOpenBankingControllerCadastrarContatosContasBancariasOpenBanking = (pathParams, options) => mutationFn('post', '/api/v1/open-banking/contatos/{id}/conta', pathParams, {}, options)
export const useMutationOpenBankingControllerCadastrarContatosContasBancariasOpenBanking = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/contatos/{id}/conta', pathParams, {}, options), config)

export const mutationOpenBankingControllerSolicitarTransferencia = (options) => mutationFn('post', '/api/v1/open-banking/transferencia', {}, {}, options)
export const useMutationOpenBankingControllerSolicitarTransferencia = (config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/transferencia', {}, {}, options), config)

export const mutationOpenBankingControllerSolicitarPagamento = (options) => mutationFn('post', '/api/v1/open-banking/pagamento', {}, {}, options)
export const useMutationOpenBankingControllerSolicitarPagamento = (config, options) => useMutation(mutationFn('post', '/api/v1/open-banking/pagamento', {}, {}, options), config)

export const queryOpenBankingControllerListarTransacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/transacoes', pathParams, queryParams)
export const useQueryOpenBankingControllerListarTransacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/transacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOpenBankingControllerListarTransacoes.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/transacoes'

export const queryOpenBankingControllerObterTransacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/open-banking/contas-bancarias/{id}/transacoes/{transacaoId}', pathParams)
export const useQueryOpenBankingControllerObterTransacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/open-banking/contas-bancarias/{id}/transacoes/{transacaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.transacaoId, ...config }
})
useQueryOpenBankingControllerObterTransacaoPorId.queryKey = '/api/v1/open-banking/contas-bancarias/{id}/transacoes/{transacaoId}'

export const mutationOperacoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/operacoes', {}, {}, options)
export const useMutationOperacoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/operacoes', {}, {}, options), config)

export const queryOperacoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/operacoes', queryParams)
export const useQueryOperacoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperacoesControllerListar.queryKey = '/api/v1/operacoes'

export const queryOperacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/operacoes/{id}', pathParams)
export const useQueryOperacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesControllerObterPorId.queryKey = '/api/v1/operacoes/{id}'

export const mutationOperacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/operacoes/{id}', pathParams, {}, options)
export const useMutationOperacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/operacoes/{id}', pathParams, {}, options), config)

export const mutationOperacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/operacoes/{id}', pathParams, {}, options)
export const useMutationOperacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/operacoes/{id}', pathParams, {}, options), config)

export const mutationOperacoesControllerCopiar = (pathParams, options) => mutationFn('post', '/api/v1/operacoes/{operacaoId}', pathParams, {}, options)
export const useMutationOperacoesControllerCopiar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/operacoes/{operacaoId}', pathParams, {}, options), config)

export const mutationOperacoesControllerCadastrarOperacaoTag = (pathParams, options) => mutationFn('post', '/api/v1/operacoes/{id}/tags', pathParams, {}, options)
export const useMutationOperacoesControllerCadastrarOperacaoTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/operacoes/{id}/tags', pathParams, {}, options), config)

export const queryOperacoesControllerListarTagsOperacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/operacoes/{id}/tags', pathParams, queryParams)
export const useQueryOperacoesControllerListarTagsOperacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesControllerListarTagsOperacao.queryKey = '/api/v1/operacoes/{id}/tags'

export const mutationOperacoesControllerDeletarOperacaoTag = (pathParams, options) => mutationFn('delete', '/api/v1/operacoes/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationOperacoesControllerDeletarOperacaoTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/operacoes/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryOperacoesControllerListarOperacoesTags = (queryParams, options) => queryFn(options)('/api/v1/operacoes/tags', queryParams)
export const useQueryOperacoesControllerListarOperacoesTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperacoesControllerListarOperacoesTags.queryKey = '/api/v1/operacoes/tags'

export const mutationOperacoesControllerCadastrarOperacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/operacoes/{id}/contas', pathParams, {}, options)
export const useMutationOperacoesControllerCadastrarOperacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/operacoes/{id}/contas', pathParams, {}, options), config)

export const queryOperacoesControllerListarOperacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/operacoes/{id}/contas', pathParams, queryParams)
export const useQueryOperacoesControllerListarOperacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesControllerListarOperacaoContas.queryKey = '/api/v1/operacoes/{id}/contas'

export const queryOperacoesControllerObterOperacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/operacoes/{id}/contas/{contaId}', pathParams)
export const useQueryOperacoesControllerObterOperacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryOperacoesControllerObterOperacaoContaPorId.queryKey = '/api/v1/operacoes/{id}/contas/{contaId}'

export const mutationOperacoesControllerAtualizarOperacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/operacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationOperacoesControllerAtualizarOperacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/operacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryOperacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/operacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryOperacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperacoesControllerListarContasDisponiveis.queryKey = '/api/v1/operacoes/{id}/contas-disponiveis'

export const queryOperacoesControllerListarOperacoesCaixa = (queryParams, options) => queryFn(options)('/api/v1/operacoes/caixa', queryParams)
export const useQueryOperacoesControllerListarOperacoesCaixa = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operacoes/caixa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperacoesControllerListarOperacoesCaixa.queryKey = '/api/v1/operacoes/caixa'

export const queryOperadorasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/operadoras', queryParams)
export const useQueryOperadorasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operadoras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOperadorasControllerBuscar.queryKey = '/api/v1/operadoras'

export const mutationOperadorasControllerCadastrar = (options) => mutationFn('post', '/api/v1/operadoras', {}, {}, options)
export const useMutationOperadorasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/operadoras', {}, {}, options), config)

export const queryOperadorasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/operadoras/{id}', pathParams)
export const useQueryOperadorasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/operadoras/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOperadorasControllerObterPorId.queryKey = '/api/v1/operadoras/{id}'

export const mutationOperadorasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/operadoras/{id}', pathParams, {}, options)
export const useMutationOperadorasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/operadoras/{id}', pathParams, {}, options), config)

export const mutationOperadorasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/operadoras/{id}', pathParams, {}, options)
export const useMutationOperadorasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/operadoras/{id}', pathParams, {}, options), config)

export const queryOportunidadeItensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidade-itens/{id}', pathParams)
export const useQueryOportunidadeItensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidade-itens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadeItensControllerObterPorId.queryKey = '/api/v1/oportunidade-itens/{id}'

export const mutationOportunidadeItensControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidade-itens/{id}', pathParams, {}, options)
export const useMutationOportunidadeItensControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidade-itens/{id}', pathParams, {}, options), config)

export const mutationOportunidadeItensControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidade-itens/{id}', pathParams, {}, options)
export const useMutationOportunidadeItensControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidade-itens/{id}', pathParams, {}, options), config)

export const queryOportunidadeResponsaveisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidades-responsaveis/{id}', pathParams)
export const useQueryOportunidadeResponsaveisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades-responsaveis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadeResponsaveisControllerObterPorId.queryKey = '/api/v1/oportunidades-responsaveis/{id}'

export const mutationOportunidadeResponsaveisControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades-responsaveis/{id}', pathParams, {}, options)
export const useMutationOportunidadeResponsaveisControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades-responsaveis/{id}', pathParams, {}, options), config)

export const mutationOportunidadeResponsaveisControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades-responsaveis/{id}', pathParams, {}, options)
export const useMutationOportunidadeResponsaveisControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades-responsaveis/{id}', pathParams, {}, options), config)

export const queryOportunidadesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/oportunidades', queryParams)
export const useQueryOportunidadesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOportunidadesControllerListar.queryKey = '/api/v1/oportunidades'

export const mutationOportunidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/oportunidades', {}, {}, options)
export const useMutationOportunidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades', {}, {}, options), config)

export const queryOportunidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidades/{id}', pathParams)
export const useQueryOportunidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerObterPorId.queryKey = '/api/v1/oportunidades/{id}'

export const mutationOportunidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades/{id}', pathParams, {}, options)
export const useMutationOportunidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades/{id}', pathParams, {}, options), config)

export const mutationOportunidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades/{id}', pathParams, {}, options)
export const useMutationOportunidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades/{id}', pathParams, {}, options), config)

export const mutationOportunidadesControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/arquivos', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/arquivos', pathParams, {}, options), config)

export const queryOportunidadesControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/arquivos', pathParams, queryParams)
export const useQueryOportunidadesControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarArquivos.queryKey = '/api/v1/oportunidades/{id}/arquivos'

export const mutationOportunidadesControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationOportunidadesControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const queryOportunidadesControllerListarResponsaveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/oportunidade-responsaveis', pathParams, queryParams)
export const useQueryOportunidadesControllerListarResponsaveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/oportunidade-responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarResponsaveis.queryKey = '/api/v1/oportunidades/{id}/oportunidade-responsaveis'

export const mutationOportunidadesControllerCadastrarResponsavel = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/oportunidade-responsaveis', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarResponsavel = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/oportunidade-responsaveis', pathParams, {}, options), config)

export const queryOportunidadesControllerListarTags = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/tags', pathParams, queryParams)
export const useQueryOportunidadesControllerListarTags = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarTags.queryKey = '/api/v1/oportunidades/{id}/tags'

export const mutationOportunidadesControllerCadastrarTag = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/tags', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/tags', pathParams, {}, options), config)

export const mutationOportunidadesControllerDeletarTag = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationOportunidadesControllerDeletarTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryOportunidadesControllerListarTagsDispoiniveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryOportunidadesControllerListarTagsDispoiniveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarTagsDispoiniveis.queryKey = '/api/v1/oportunidades/{id}/tags-disponiveis'

export const queryOportunidadesControllerListarTarefas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/tarefas', pathParams, queryParams)
export const useQueryOportunidadesControllerListarTarefas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/tarefas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarTarefas.queryKey = '/api/v1/oportunidades/{id}/tarefas'

export const mutationOportunidadesControllerCadastrarTarefa = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/tarefas', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarTarefa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/tarefas', pathParams, {}, options), config)

export const queryOportunidadesControllerListarItens = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/itens', pathParams, queryParams)
export const useQueryOportunidadesControllerListarItens = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarItens.queryKey = '/api/v1/oportunidades/{id}/itens'

export const mutationOportunidadesControllerCadastrarItens = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/itens', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarItens = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/itens', pathParams, {}, options), config)

export const queryOportunidadesControllerListarComentarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/comentarios', pathParams, queryParams)
export const useQueryOportunidadesControllerListarComentarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/comentarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarComentarios.queryKey = '/api/v1/oportunidades/{id}/comentarios'

export const mutationOportunidadesControllerCadastrarComentarios = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/comentarios', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarComentarios = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/comentarios', pathParams, {}, options), config)

export const queryOportunidadesControllerListarDescontos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/descontos', pathParams, queryParams)
export const useQueryOportunidadesControllerListarDescontos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarDescontos.queryKey = '/api/v1/oportunidades/{id}/descontos'

export const mutationOportunidadesControllerCadastrarDesconto = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades/{id}/descontos', pathParams, {}, options)
export const useMutationOportunidadesControllerCadastrarDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades/{id}/descontos', pathParams, {}, options), config)

export const mutationOportunidadesControllerAtualizarFaseFunil = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades/{id}/mudar-fase', pathParams, {}, options)
export const useMutationOportunidadesControllerAtualizarFaseFunil = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades/{id}/mudar-fase', pathParams, {}, options), config)

export const queryOportunidadesControllerListarHistorico = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades/{id}/historico', pathParams, queryParams)
export const useQueryOportunidadesControllerListarHistorico = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades/{id}/historico', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesControllerListarHistorico.queryKey = '/api/v1/oportunidades/{id}/historico'

export const queryOportunidadesComentariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidade-comentarios/{id}', pathParams)
export const useQueryOportunidadesComentariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidade-comentarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesComentariosControllerObterPorId.queryKey = '/api/v1/oportunidade-comentarios/{id}'

export const mutationOportunidadesComentariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidade-comentarios/{id}', pathParams, {}, options)
export const useMutationOportunidadesComentariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidade-comentarios/{id}', pathParams, {}, options), config)

export const mutationOportunidadesComentariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidade-comentarios/{id}', pathParams, {}, options)
export const useMutationOportunidadesComentariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidade-comentarios/{id}', pathParams, {}, options), config)

export const queryOportunidadesDescontosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidades-descontos/{id}', pathParams)
export const useQueryOportunidadesDescontosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades-descontos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesDescontosControllerObterPorId.queryKey = '/api/v1/oportunidades-descontos/{id}'

export const mutationOportunidadesDescontosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades-descontos/{id}', pathParams, {}, options)
export const useMutationOportunidadesDescontosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades-descontos/{id}', pathParams, {}, options), config)

export const queryOportunidadesTarefasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidades-tarefas/{id}', pathParams)
export const useQueryOportunidadesTarefasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades-tarefas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesTarefasControllerObterPorId.queryKey = '/api/v1/oportunidades-tarefas/{id}'

export const mutationOportunidadesTarefasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades-tarefas/{id}', pathParams, {}, options)
export const useMutationOportunidadesTarefasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades-tarefas/{id}', pathParams, {}, options), config)

export const mutationOportunidadesTarefasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidades-tarefas/{id}', pathParams, {}, options)
export const useMutationOportunidadesTarefasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidades-tarefas/{id}', pathParams, {}, options), config)

export const mutationOportunidadesTarefasControllerAtualizarConclusao = (pathParams, options) => mutationFn('put', '/api/v1/oportunidades-tarefas/{id}/conclusao', pathParams, {}, options)
export const useMutationOportunidadesTarefasControllerAtualizarConclusao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidades-tarefas/{id}/conclusao', pathParams, {}, options), config)

export const queryOportunidadesTarefasControllerBuscarCheckList = (pathParams, queryParams, options) => queryFn(options)('/api/v1/oportunidades-tarefas/{id}/check-lists', pathParams, queryParams)
export const useQueryOportunidadesTarefasControllerBuscarCheckList = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidades-tarefas/{id}/check-lists', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadesTarefasControllerBuscarCheckList.queryKey = '/api/v1/oportunidades-tarefas/{id}/check-lists'

export const mutationOportunidadesTarefasControllerCadastrarCheckList = (pathParams, options) => mutationFn('post', '/api/v1/oportunidades-tarefas/{id}/check-lists', pathParams, {}, options)
export const useMutationOportunidadesTarefasControllerCadastrarCheckList = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/oportunidades-tarefas/{id}/check-lists', pathParams, {}, options), config)

export const queryOportunidadeTarefaCheckListsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams)
export const useQueryOportunidadeTarefaCheckListsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOportunidadeTarefaCheckListsControllerObterPorId.queryKey = '/api/v1/oportunidade-tarefa-check-lists/{id}'

export const mutationOportunidadeTarefaCheckListsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationOportunidadeTarefaCheckListsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationOportunidadeTarefaCheckListsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationOportunidadeTarefaCheckListsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/oportunidade-tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationOrcamentoPagamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/orcamento-pagamentos/{id}', pathParams, {}, options)
export const useMutationOrcamentoPagamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamento-pagamentos/{id}', pathParams, {}, options), config)

export const mutationOrcamentoPagamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/orcamento-pagamentos/{id}', pathParams, {}, options)
export const useMutationOrcamentoPagamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/orcamento-pagamentos/{id}', pathParams, {}, options), config)

export const queryOrcamentoPagamentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/orcamento-pagamentos/{id}', pathParams)
export const useQueryOrcamentoPagamentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamento-pagamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentoPagamentosControllerObterPorId.queryKey = '/api/v1/orcamento-pagamentos/{id}'

export const queryOrcamentoPagamentosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/orcamento-pagamentos', queryParams)
export const useQueryOrcamentoPagamentosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamento-pagamentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentoPagamentosControllerBuscar.queryKey = '/api/v1/orcamento-pagamentos'

export const mutationOrcamentosEntradaControllerCadastrar = (options) => mutationFn('post', '/api/v1/orcamentos-entrada', {}, {}, options)
export const useMutationOrcamentosEntradaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-entrada', {}, {}, options), config)

export const queryOrcamentosEntradaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-entrada', queryParams)
export const useQueryOrcamentosEntradaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-entrada', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosEntradaControllerBuscar.queryKey = '/api/v1/orcamentos-entrada'

export const mutationOrcamentosEntradaControllerCadastrarPedidoCompraPorOrcamentoEntradaId = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-entrada/{id}/gerar-pedido', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerCadastrarPedidoCompraPorOrcamentoEntradaId = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-entrada/{id}/gerar-pedido', pathParams, {}, options), config)

export const mutationOrcamentosEntradaControllerCadastrarItem = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-entrada/{id}/itens', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerCadastrarItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-entrada/{id}/itens', pathParams, {}, options), config)

export const queryOrcamentosEntradaControllerBuscarItensPorOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orcamentos-entrada/{id}/itens', pathParams, queryParams)
export const useQueryOrcamentosEntradaControllerBuscarItensPorOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-entrada/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosEntradaControllerBuscarItensPorOrcamentoId.queryKey = '/api/v1/orcamentos-entrada/{id}/itens'

export const mutationOrcamentosEntradaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-entrada/{id}', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-entrada/{id}', pathParams, {}, options), config)

export const mutationOrcamentosEntradaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/orcamentos-entrada/{id}', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/orcamentos-entrada/{id}', pathParams, {}, options), config)

export const queryOrcamentosEntradaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/orcamentos-entrada/{id}', pathParams)
export const useQueryOrcamentosEntradaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-entrada/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosEntradaControllerObterPorId.queryKey = '/api/v1/orcamentos-entrada/{id}'

export const mutationOrcamentosEntradaControllerReprovar = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-entrada/{id}/reprovar', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerReprovar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-entrada/{id}/reprovar', pathParams, {}, options), config)

export const queryOrcamentosEntradaControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-entrada/listar-filtros', queryParams)
export const useQueryOrcamentosEntradaControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-entrada/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosEntradaControllerListarPorFiltros.queryKey = '/api/v1/orcamentos-entrada/listar-filtros'

export const mutationOrcamentosEntradaControllerCadastrarOrcamentoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-entrada/{id}/pagamento', pathParams, {}, options)
export const useMutationOrcamentosEntradaControllerCadastrarOrcamentoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-entrada/{id}/pagamento', pathParams, {}, options), config)

export const mutationOrcamentosSaidaControllerCadastrarConvenio = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-saida/{id}/convenio', pathParams, {}, options)
export const useMutationOrcamentosSaidaControllerCadastrarConvenio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida/{id}/convenio', pathParams, {}, options), config)

export const queryOrcamentosSaidaControllerListarConvenios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/convenios', pathParams, queryParams)
export const useQueryOrcamentosSaidaControllerListarConvenios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/convenios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaControllerListarConvenios.queryKey = '/api/v1/orcamentos-saida/{id}/convenios'

export const mutationOrcamentosSaidaControllerReprovar = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-saida/{id}/reprovar', pathParams, {}, options)
export const useMutationOrcamentosSaidaControllerReprovar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-saida/{id}/reprovar', pathParams, {}, options), config)

export const queryOrcamentosSaidaControllerObterValorTotalParcelamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/valor-total-parcelamento', pathParams, queryParams)
export const useQueryOrcamentosSaidaControllerObterValorTotalParcelamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/valor-total-parcelamento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaControllerObterValorTotalParcelamento.queryKey = '/api/v1/orcamentos-saida/{id}/valor-total-parcelamento'

export const mutationOrcamentosSaidaControllerCadastrarOrcamentoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-saida/{id}/pagamento', pathParams, {}, options)
export const useMutationOrcamentosSaidaControllerCadastrarOrcamentoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida/{id}/pagamento', pathParams, {}, options), config)

export const mutationOrcamentosSaidaEducacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/orcamentos-saida', {}, {}, options)
export const useMutationOrcamentosSaidaEducacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida', {}, {}, options), config)

export const queryOrcamentosSaidaEducacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida', queryParams)
export const useQueryOrcamentosSaidaEducacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosSaidaEducacaoControllerListar.queryKey = '/api/v1/orcamentos-saida'

export const mutationOrcamentosSaidaEducacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/orcamentos-saida/{id}', pathParams, {}, options)
export const useMutationOrcamentosSaidaEducacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/orcamentos-saida/{id}', pathParams, {}, options), config)

export const mutationOrcamentosSaidaEducacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-saida/{id}', pathParams, {}, options)
export const useMutationOrcamentosSaidaEducacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-saida/{id}', pathParams, {}, options), config)

export const queryOrcamentosSaidaEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}', pathParams)
export const useQueryOrcamentosSaidaEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaEducacaoControllerObterPorId.queryKey = '/api/v1/orcamentos-saida/{id}'

export const mutationOrcamentosSaidaEducacaoControllerCadastrarItemOrcamentoSaida = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-saida/{id}/item', pathParams, {}, options)
export const useMutationOrcamentosSaidaEducacaoControllerCadastrarItemOrcamentoSaida = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida/{id}/item', pathParams, {}, options), config)

export const mutationOrcamentosSaidaEducacaoControllerAtualizarItemOrcamentoSaida = (pathParams, options) => mutationFn('put', '/api/v1/orcamentos-saida/{id}/item/{itemId}', pathParams, {}, options)
export const useMutationOrcamentosSaidaEducacaoControllerAtualizarItemOrcamentoSaida = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/orcamentos-saida/{id}/item/{itemId}', pathParams, {}, options), config)

export const queryOrcamentosSaidaEducacaoControllerListarPorFiltrosComExtras = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/filtros-extras', queryParams)
export const useQueryOrcamentosSaidaEducacaoControllerListarPorFiltrosComExtras = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/filtros-extras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosSaidaEducacaoControllerListarPorFiltrosComExtras.queryKey = '/api/v1/orcamentos-saida/filtros-extras'

export const queryOrcamentosSaidaEducacaoControllerBuscarItensPorOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/itens', pathParams, queryParams)
export const useQueryOrcamentosSaidaEducacaoControllerBuscarItensPorOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaEducacaoControllerBuscarItensPorOrcamentoId.queryKey = '/api/v1/orcamentos-saida/{id}/itens'

export const mutationOrcamentosSaidaEducacaoControllerCadastrarPedidoVendaEducacaoPorOrcamentoSaidaId = (pathParams, options) => mutationFn('post', '/api/v1/orcamentos-saida/{id}/gerar-pedido', pathParams, {}, options)
export const useMutationOrcamentosSaidaEducacaoControllerCadastrarPedidoVendaEducacaoPorOrcamentoSaidaId = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/orcamentos-saida/{id}/gerar-pedido', pathParams, {}, options), config)

export const queryOrcamentosSaidaEducacaoControllerObterPedidoVendaEducacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/pedidos/{pedidoVendaEducacaoId}', pathParams)
export const useQueryOrcamentosSaidaEducacaoControllerObterPedidoVendaEducacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/pedidos/{pedidoVendaEducacaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.pedidoVendaEducacaoId, ...config }
})
useQueryOrcamentosSaidaEducacaoControllerObterPedidoVendaEducacaoPorId.queryKey = '/api/v1/orcamentos-saida/{id}/pedidos/{pedidoVendaEducacaoId}'

export const queryOrcamentosSaidaEducacaoControllerObterPedidoVendaEducacaoPorOrcamentoId = (pathParams, options) => queryFn(options)('/api/v1/orcamentos-saida/{id}/pedido', pathParams)
export const useQueryOrcamentosSaidaEducacaoControllerObterPedidoVendaEducacaoPorOrcamentoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/{id}/pedido', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrcamentosSaidaEducacaoControllerObterPedidoVendaEducacaoPorOrcamentoId.queryKey = '/api/v1/orcamentos-saida/{id}/pedido'

export const queryOrcamentosSaidaEducacaoControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/orcamentos-saida/listar-filtros', queryParams)
export const useQueryOrcamentosSaidaEducacaoControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/orcamentos-saida/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrcamentosSaidaEducacaoControllerListarPorFiltros.queryKey = '/api/v1/orcamentos-saida/listar-filtros'

export const queryOrigemOportunidadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/origem-oportunidades', queryParams)
export const useQueryOrigemOportunidadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrigemOportunidadesControllerBuscar.queryKey = '/api/v1/origem-oportunidades'

export const mutationOrigemOportunidadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/origem-oportunidades', {}, {}, options)
export const useMutationOrigemOportunidadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/origem-oportunidades', {}, {}, options), config)

export const queryOrigemOportunidadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/origem-oportunidades/{id}', pathParams)
export const useQueryOrigemOportunidadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigemOportunidadesControllerObterPorId.queryKey = '/api/v1/origem-oportunidades/{id}'

export const mutationOrigemOportunidadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/origem-oportunidades/{id}', pathParams, {}, options)
export const useMutationOrigemOportunidadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/origem-oportunidades/{id}', pathParams, {}, options), config)

export const mutationOrigemOportunidadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/origem-oportunidades/{id}', pathParams, {}, options)
export const useMutationOrigemOportunidadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/origem-oportunidades/{id}', pathParams, {}, options), config)

export const mutationOrigemOportunidadesControllerCadastrarOrigemOportunidadeConta = (pathParams, options) => mutationFn('post', '/api/v1/origem-oportunidades/{id}/contas', pathParams, {}, options)
export const useMutationOrigemOportunidadesControllerCadastrarOrigemOportunidadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/origem-oportunidades/{id}/contas', pathParams, {}, options), config)

export const queryOrigemOportunidadesControllerListarOrigemOportunidadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/origem-oportunidades/{id}/contas', pathParams, queryParams)
export const useQueryOrigemOportunidadesControllerListarOrigemOportunidadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigemOportunidadesControllerListarOrigemOportunidadeContas.queryKey = '/api/v1/origem-oportunidades/{id}/contas'

export const queryOrigemOportunidadesControllerObterOrigemOportunidadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/origem-oportunidades/{id}/contas/{contaId}', pathParams)
export const useQueryOrigemOportunidadesControllerObterOrigemOportunidadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryOrigemOportunidadesControllerObterOrigemOportunidadeContaPorId.queryKey = '/api/v1/origem-oportunidades/{id}/contas/{contaId}'

export const mutationOrigemOportunidadesControllerAtualizarOrigemOportunidadeConta = (pathParams, options) => mutationFn('put', '/api/v1/origem-oportunidades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationOrigemOportunidadesControllerAtualizarOrigemOportunidadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/origem-oportunidades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryOrigemOportunidadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/origem-oportunidades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryOrigemOportunidadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origem-oportunidades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigemOportunidadesControllerListarContasDisponiveis.queryKey = '/api/v1/origem-oportunidades/{id}/contas-disponiveis'

export const queryOrigensDocumentoSaidaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/origens-de-documento-de-saida', queryParams)
export const useQueryOrigensDocumentoSaidaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origens-de-documento-de-saida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryOrigensDocumentoSaidaControllerBuscar.queryKey = '/api/v1/origens-de-documento-de-saida'

export const queryOrigensDocumentoSaidaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/origens-de-documento-de-saida/{id}', pathParams)
export const useQueryOrigensDocumentoSaidaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origens-de-documento-de-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryOrigensDocumentoSaidaControllerObterPorId.queryKey = '/api/v1/origens-de-documento-de-saida/{id}'

export const queryOrigensDocumentoSaidaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/origens-de-documento-de-saida/{chave}', pathParams)
export const useQueryOrigensDocumentoSaidaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/origens-de-documento-de-saida/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryOrigensDocumentoSaidaControllerObterPorChave.queryKey = '/api/v1/origens-de-documento-de-saida/{chave}'

export const queryPainelAppControllerObterIndicadores = (options) => queryFn(options)('/api/v1/painel-app/indicadores')
export const useQueryPainelAppControllerObterIndicadores = (config, options) => useQuery({
  queryKey: ['/api/v1/painel-app/indicadores'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPainelAppControllerObterIndicadores.queryKey = '/api/v1/painel-app/indicadores'

export const queryPainelAppControllerObterAnosComIndicadoresUsosModulos = (options) => queryFn(options)('/api/v1/painel-app/anos-com-indicadores-usos-modulos')
export const useQueryPainelAppControllerObterAnosComIndicadoresUsosModulos = (config, options) => useQuery({
  queryKey: ['/api/v1/painel-app/anos-com-indicadores-usos-modulos'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPainelAppControllerObterAnosComIndicadoresUsosModulos.queryKey = '/api/v1/painel-app/anos-com-indicadores-usos-modulos'

export const queryPainelAppControllerObterIndicadoresUsosModulos = (queryParams, options) => queryFn(options)('/api/v1/painel-app/indicadores-usos-modulos', queryParams)
export const useQueryPainelAppControllerObterIndicadoresUsosModulos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/painel-app/indicadores-usos-modulos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPainelAppControllerObterIndicadoresUsosModulos.queryKey = '/api/v1/painel-app/indicadores-usos-modulos'

export const mutationPedidoPagamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/pedido-pagamentos/{id}', pathParams, {}, options)
export const useMutationPedidoPagamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedido-pagamentos/{id}', pathParams, {}, options), config)

export const mutationPedidoPagamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/pedido-pagamentos/{id}', pathParams, {}, options)
export const useMutationPedidoPagamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pedido-pagamentos/{id}', pathParams, {}, options), config)

export const queryPedidoPagamentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/pedido-pagamentos/{id}', pathParams)
export const useQueryPedidoPagamentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedido-pagamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidoPagamentosControllerObterPorId.queryKey = '/api/v1/pedido-pagamentos/{id}'

export const queryPedidoPagamentosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/pedido-pagamentos', queryParams)
export const useQueryPedidoPagamentosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedido-pagamentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidoPagamentosControllerBuscar.queryKey = '/api/v1/pedido-pagamentos'

export const mutationPedidosCompraControllerCadastrar = (options) => mutationFn('post', '/api/v1/pedidos-compra', {}, {}, options)
export const useMutationPedidosCompraControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-compra', {}, {}, options), config)

export const queryPedidosCompraControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/pedidos-compra', queryParams)
export const useQueryPedidosCompraControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-compra', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosCompraControllerBuscar.queryKey = '/api/v1/pedidos-compra'

export const mutationPedidosCompraControllerCadastrarItem = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-compra/{id}/itens', pathParams, {}, options)
export const useMutationPedidosCompraControllerCadastrarItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-compra/{id}/itens', pathParams, {}, options), config)

export const queryPedidosCompraControllerBuscarItensPorOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pedidos-compra/{id}/itens', pathParams, queryParams)
export const useQueryPedidosCompraControllerBuscarItensPorOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-compra/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosCompraControllerBuscarItensPorOrcamentoId.queryKey = '/api/v1/pedidos-compra/{id}/itens'

export const mutationPedidosCompraControllerCadastrarTitulo = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-compra/{id}/titulos', pathParams, {}, options)
export const useMutationPedidosCompraControllerCadastrarTitulo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-compra/{id}/titulos', pathParams, {}, options), config)

export const mutationPedidosCompraControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-compra/{id}', pathParams, {}, options)
export const useMutationPedidosCompraControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-compra/{id}', pathParams, {}, options), config)

export const mutationPedidosCompraControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/pedidos-compra/{id}', pathParams, {}, options)
export const useMutationPedidosCompraControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pedidos-compra/{id}', pathParams, {}, options), config)

export const queryPedidosCompraControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/pedidos-compra/{id}', pathParams)
export const useQueryPedidosCompraControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-compra/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosCompraControllerObterPorId.queryKey = '/api/v1/pedidos-compra/{id}'

export const mutationPedidosCompraControllerCancelar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-compra/{id}/cancelar', pathParams, {}, options)
export const useMutationPedidosCompraControllerCancelar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-compra/{id}/cancelar', pathParams, {}, options), config)

export const mutationPedidosCompraControllerEstornar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-compra/{id}/estornar', pathParams, {}, options)
export const useMutationPedidosCompraControllerEstornar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-compra/{id}/estornar', pathParams, {}, options), config)

export const queryPedidosCompraControllerBuscarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/pedidos-compra/listar-filtros', queryParams)
export const useQueryPedidosCompraControllerBuscarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-compra/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosCompraControllerBuscarPorFiltros.queryKey = '/api/v1/pedidos-compra/listar-filtros'

export const mutationPedidosCompraControllerCadastrarPedidoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-compra/{id}/pagamento', pathParams, {}, options)
export const useMutationPedidosCompraControllerCadastrarPedidoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-compra/{id}/pagamento', pathParams, {}, options), config)

export const mutationPedidosVendaControllerEmitirNfse = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}/emitir-nfse', pathParams, {}, options)
export const useMutationPedidosVendaControllerEmitirNfse = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}/emitir-nfse', pathParams, {}, options), config)

export const mutationPedidosVendaControllerEmitirNfseTitulo = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}/emitir-nfse-titulo', pathParams, {}, options)
export const useMutationPedidosVendaControllerEmitirNfseTitulo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}/emitir-nfse-titulo', pathParams, {}, options), config)

export const mutationPedidosVendaControllerCancelar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}/cancelar', pathParams, {}, options)
export const useMutationPedidosVendaControllerCancelar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}/cancelar', pathParams, {}, options), config)

export const mutationPedidosVendaControllerEstornar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}/estornar', pathParams, {}, options)
export const useMutationPedidosVendaControllerEstornar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}/estornar', pathParams, {}, options), config)

export const mutationPedidosVendaControllerCadastrarPedidoPagamento = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/pagamento', pathParams, {}, options)
export const useMutationPedidosVendaControllerCadastrarPedidoPagamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/pagamento', pathParams, {}, options), config)

export const queryPedidosVendaControllerObterValorTotalParcelamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pedidos-venda/{id}/valor-total-parcelamento', pathParams, queryParams)
export const useQueryPedidosVendaControllerObterValorTotalParcelamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/{id}/valor-total-parcelamento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosVendaControllerObterValorTotalParcelamento.queryKey = '/api/v1/pedidos-venda/{id}/valor-total-parcelamento'

export const mutationPedidosVendaEducacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/pedidos-venda', {}, {}, options)
export const useMutationPedidosVendaEducacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda', {}, {}, options), config)

export const queryPedidosVendaEducacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/pedidos-venda', queryParams)
export const useQueryPedidosVendaEducacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosVendaEducacaoControllerListar.queryKey = '/api/v1/pedidos-venda'

export const mutationPedidosVendaEducacaoControllerCadastrarItemPedidoVenda = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/item', pathParams, {}, options)
export const useMutationPedidosVendaEducacaoControllerCadastrarItemPedidoVenda = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/item', pathParams, {}, options), config)

export const mutationPedidosVendaEducacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/pedidos-venda/{id}', pathParams, {}, options)
export const useMutationPedidosVendaEducacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pedidos-venda/{id}', pathParams, {}, options), config)

export const mutationPedidosVendaEducacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/pedidos-venda/{id}', pathParams, {}, options)
export const useMutationPedidosVendaEducacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pedidos-venda/{id}', pathParams, {}, options), config)

export const queryPedidosVendaEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/pedidos-venda/{id}', pathParams)
export const useQueryPedidosVendaEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosVendaEducacaoControllerObterPorId.queryKey = '/api/v1/pedidos-venda/{id}'

export const queryPedidosVendaEducacaoControllerListarPorFiltrosComExtras = (queryParams, options) => queryFn(options)('/api/v1/pedidos-venda/filtros-extras', queryParams)
export const useQueryPedidosVendaEducacaoControllerListarPorFiltrosComExtras = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/filtros-extras', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosVendaEducacaoControllerListarPorFiltrosComExtras.queryKey = '/api/v1/pedidos-venda/filtros-extras'

export const queryPedidosVendaEducacaoControllerBuscarItensPorOrcamentoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pedidos-venda/{id}/itens', pathParams, queryParams)
export const useQueryPedidosVendaEducacaoControllerBuscarItensPorOrcamentoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPedidosVendaEducacaoControllerBuscarItensPorOrcamentoId.queryKey = '/api/v1/pedidos-venda/{id}/itens'

export const queryPedidosVendaEducacaoControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/pedidos-venda/listar-filtros', queryParams)
export const useQueryPedidosVendaEducacaoControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pedidos-venda/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPedidosVendaEducacaoControllerListarPorFiltros.queryKey = '/api/v1/pedidos-venda/listar-filtros'

export const mutationPedidosVendaEducacaoControllerCadastrarTitulo = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/titulos', pathParams, {}, options)
export const useMutationPedidosVendaEducacaoControllerCadastrarTitulo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/titulos', pathParams, {}, options), config)

export const mutationPedidosVendaEducacaoControllerCadastrarAssinaturaRecorrenciaTitulo = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/titulos-recorrencia', pathParams, {}, options)
export const useMutationPedidosVendaEducacaoControllerCadastrarAssinaturaRecorrenciaTitulo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/titulos-recorrencia', pathParams, {}, options), config)

export const mutationPedidosVendaEducacaoControllerCancelarAssinaturaPlano = (pathParams, options) => mutationFn('post', '/api/v1/pedidos-venda/{id}/cancelar-assinatura', pathParams, {}, options)
export const useMutationPedidosVendaEducacaoControllerCancelarAssinaturaPlano = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pedidos-venda/{id}/cancelar-assinatura', pathParams, {}, options), config)

export const queryPerfisClienteControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/perfis-cliente/{id}', pathParams)
export const useQueryPerfisClienteControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/perfis-cliente/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPerfisClienteControllerObterPorId.queryKey = '/api/v1/perfis-cliente/{id}'

export const queryPerfisClienteControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/perfis-cliente/{chave}', pathParams)
export const useQueryPerfisClienteControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/perfis-cliente/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryPerfisClienteControllerObterPorChave.queryKey = '/api/v1/perfis-cliente/{chave}'

export const queryPerfisClienteControllerListar = (options) => queryFn(options)('/api/v1/perfis-cliente')
export const useQueryPerfisClienteControllerListar = (config, options) => useQuery({
  queryKey: ['/api/v1/perfis-cliente'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPerfisClienteControllerListar.queryKey = '/api/v1/perfis-cliente'

export const queryPeriodosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/periodos', queryParams)
export const useQueryPeriodosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPeriodosControllerListar.queryKey = '/api/v1/periodos'

export const queryPeriodosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/periodos/{id}', pathParams)
export const useQueryPeriodosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/periodos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPeriodosControllerObterPorId.queryKey = '/api/v1/periodos/{id}'

export const queryPeriodosControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/periodos/{chave}', pathParams)
export const useQueryPeriodosControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/periodos/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryPeriodosControllerObterPorChave.queryKey = '/api/v1/periodos/{chave}'

export const queryPeriodosControllerObterPorCodigo = (pathParams, options) => queryFn(options)('/api/v1/periodos/{codigo}', pathParams)
export const useQueryPeriodosControllerObterPorCodigo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/periodos/{codigo}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.codigo, ...config }
})
useQueryPeriodosControllerObterPorCodigo.queryKey = '/api/v1/periodos/{codigo}'

export const queryPermissoesControllerListarRecursos = (queryParams, options) => queryFn(options)('/api/v1/permissoes/recursos', queryParams)
export const useQueryPermissoesControllerListarRecursos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/recursos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPermissoesControllerListarRecursos.queryKey = '/api/v1/permissoes/recursos'

export const queryPermissoesControllerListarPerfis = (queryParams, options) => queryFn(options)('/api/v1/permissoes/perfis', queryParams)
export const useQueryPermissoesControllerListarPerfis = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPermissoesControllerListarPerfis.queryKey = '/api/v1/permissoes/perfis'

export const mutationPermissoesControllerCadastrarPerfil = (options) => mutationFn('post', '/api/v1/permissoes/perfis', {}, {}, options)
export const useMutationPermissoesControllerCadastrarPerfil = (config, options) => useMutation(mutationFn('post', '/api/v1/permissoes/perfis', {}, {}, options), config)

export const queryPermissoesControllerObterPerfilPorId = (pathParams, options) => queryFn(options)('/api/v1/permissoes/perfis/{id}', pathParams)
export const useQueryPermissoesControllerObterPerfilPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPermissoesControllerObterPerfilPorId.queryKey = '/api/v1/permissoes/perfis/{id}'

export const mutationPermissoesControllerAtualizarPerfil = (pathParams, options) => mutationFn('put', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options)
export const useMutationPermissoesControllerAtualizarPerfil = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options), config)

export const mutationPermissoesControllerDeletarPerfil = (pathParams, options) => mutationFn('delete', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options)
export const useMutationPermissoesControllerDeletarPerfil = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/permissoes/perfis/{id}', pathParams, {}, options), config)

export const queryPermissoesControllerListarUsuarioPerfil = (pathParams, queryParams, options) => queryFn(options)('/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams)
export const useQueryPermissoesControllerListarUsuarioPerfil = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPermissoesControllerListarUsuarioPerfil.queryKey = '/api/v1/permissoes/perfis/{id}/usuarios'

export const mutationPermissoesControllerCadastrarUsuarioPerfil = (pathParams, queryParams, options) => mutationFn('post', '/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams, options)
export const useMutationPermissoesControllerCadastrarUsuarioPerfil = (pathParams, queryParams, config, options) => useMutation(mutationFn('post', '/api/v1/permissoes/perfis/{id}/usuarios', pathParams, queryParams, options), config)

export const queryPermissoesControllerObterUsuarioPerfilPorUsuarioId = (pathParams, options) => queryFn(options)('/api/v1/permissoes/perfis/usuarios/{usuarioId}', pathParams)
export const useQueryPermissoesControllerObterUsuarioPerfilPorUsuarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/permissoes/perfis/usuarios/{usuarioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.usuarioId, ...config }
})
useQueryPermissoesControllerObterUsuarioPerfilPorUsuarioId.queryKey = '/api/v1/permissoes/perfis/usuarios/{usuarioId}'

export const mutationPermissoesControllerRemoverUsuarioPerfil = (pathParams, options) => mutationFn('delete', '/api/v1/permissoes/perfis/{id}/usuarios/{usuarioId}', pathParams, {}, options)
export const useMutationPermissoesControllerRemoverUsuarioPerfil = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/permissoes/perfis/{id}/usuarios/{usuarioId}', pathParams, {}, options), config)

export const queryPessoasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/pessoas', queryParams)
export const useQueryPessoasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPessoasControllerBuscar.queryKey = '/api/v1/pessoas'

export const queryPessoasControllerBuscarContatos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/contatos', pathParams, queryParams)
export const useQueryPessoasControllerBuscarContatos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/contatos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerBuscarContatos.queryKey = '/api/v1/pessoas/{id}/contatos'

export const mutationPessoasControllerCadastrarContato = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/contatos', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarContato = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/contatos', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarUsuario = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/usuario', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarUsuario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/usuario', pathParams, {}, options), config)

export const queryPessoasControllerBuscarEnderecos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/enderecos', pathParams, queryParams)
export const useQueryPessoasControllerBuscarEnderecos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/enderecos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerBuscarEnderecos.queryKey = '/api/v1/pessoas/{id}/enderecos'

export const mutationPessoasControllerCadastrarEndereco = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/enderecos', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarEndereco = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/enderecos', pathParams, {}, options), config)

export const queryPessoasControllerBuscarDependentes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/dependentes', pathParams, queryParams)
export const useQueryPessoasControllerBuscarDependentes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/dependentes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerBuscarDependentes.queryKey = '/api/v1/pessoas/{id}/dependentes'

export const mutationPessoasControllerCadastrarDependentePessoa = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/dependentes', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarDependentePessoa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/dependentes', pathParams, {}, options), config)

export const mutationPessoasControllerDeletarDependente = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/dependentes/{dependenteId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarDependente = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/dependentes/{dependenteId}', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarEmail = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/emails', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarEmail = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/emails', pathParams, {}, options), config)

export const queryPessoasControllerListarEmails = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/emails', pathParams, queryParams)
export const useQueryPessoasControllerListarEmails = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/emails', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarEmails.queryKey = '/api/v1/pessoas/{id}/emails'

export const mutationPessoasControllerDeletarEmail = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/email/{emailId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarEmail = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/email/{emailId}', pathParams, {}, options), config)

export const queryPessoasControllerObterEmailPorId = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/email/{emailId}', pathParams)
export const useQueryPessoasControllerObterEmailPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/email/{emailId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.emailId, ...config }
})
useQueryPessoasControllerObterEmailPorId.queryKey = '/api/v1/pessoas/{id}/email/{emailId}'

export const mutationPessoasControllerCadastrarRedeSocial = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/redes-sociais', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarRedeSocial = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/redes-sociais', pathParams, {}, options), config)

export const queryPessoasControllerListarRedesSocial = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/redes-sociais', pathParams, queryParams)
export const useQueryPessoasControllerListarRedesSocial = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/redes-sociais', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarRedesSocial.queryKey = '/api/v1/pessoas/{id}/redes-sociais'

export const mutationPessoasControllerDeletarRedeSocial = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/rede-social/{redeSocialId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarRedeSocial = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/rede-social/{redeSocialId}', pathParams, {}, options), config)

export const queryPessoasControllerObterRedeSocialPorId = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/redeSocial/{redeSocialId}', pathParams)
export const useQueryPessoasControllerObterRedeSocialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/redeSocial/{redeSocialId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.redeSocialId, ...config }
})
useQueryPessoasControllerObterRedeSocialPorId.queryKey = '/api/v1/pessoas/{id}/redeSocial/{redeSocialId}'

export const mutationPessoasControllerCadastrarTelefone = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/telefones', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarTelefone = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/telefones', pathParams, {}, options), config)

export const queryPessoasControllerListarTelefones = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/telefones', pathParams, queryParams)
export const useQueryPessoasControllerListarTelefones = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/telefones', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarTelefones.queryKey = '/api/v1/pessoas/{id}/telefones'

export const mutationPessoasControllerDeletarTelefone = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/telefone/{telefoneId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarTelefone = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/telefone/{telefoneId}', pathParams, {}, options), config)

export const queryPessoasControllerObterTelefonePorId = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/telefone/{telefoneId}', pathParams)
export const useQueryPessoasControllerObterTelefonePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/telefone/{telefoneId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.telefoneId, ...config }
})
useQueryPessoasControllerObterTelefonePorId.queryKey = '/api/v1/pessoas/{id}/telefone/{telefoneId}'

export const mutationPessoasControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/arquivos', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/arquivos', pathParams, {}, options), config)

export const queryPessoasControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/arquivos', pathParams, queryParams)
export const useQueryPessoasControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarArquivos.queryKey = '/api/v1/pessoas/{id}/arquivos'

export const mutationPessoasControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarAvatar = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/avatar', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarAvatar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/avatar', pathParams, {}, options), config)

export const mutationPessoasControllerDeletarAvatar = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/avatar', pathParams, {}, options)
export const useMutationPessoasControllerDeletarAvatar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/avatar', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarPessoaPrecaucao = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/precaucao', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaPrecaucao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/precaucao', pathParams, {}, options), config)

export const queryPessoasControllerListarPessoaPrecaucoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/precaucoes', pathParams, queryParams)
export const useQueryPessoasControllerListarPessoaPrecaucoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/precaucoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPessoaPrecaucoes.queryKey = '/api/v1/pessoas/{id}/precaucoes'

export const mutationPessoasControllerDeletarPessoaPrecaucao = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/precaucao/{precaucaoId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaPrecaucao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/precaucao/{precaucaoId}', pathParams, {}, options), config)

export const queryPessoasControllerListarAlergiaMedicamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/alergia-medicamentos', pathParams, queryParams)
export const useQueryPessoasControllerListarAlergiaMedicamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/alergia-medicamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarAlergiaMedicamentos.queryKey = '/api/v1/pessoas/{id}/alergia-medicamentos'

export const mutationPessoasControllerCadastrarPessoaAlergia = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/alergia', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaAlergia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/alergia', pathParams, {}, options), config)

export const queryPessoasControllerListarPessoaAlergias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/alergias', pathParams, queryParams)
export const useQueryPessoasControllerListarPessoaAlergias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/alergias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPessoaAlergias.queryKey = '/api/v1/pessoas/{id}/alergias'

export const mutationPessoasControllerDeletarPessoaAlergia = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/alergia/{alergiaId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaAlergia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/alergia/{alergiaId}', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarPessoaMedicamentoContinuo = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/medicamento-continuo', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaMedicamentoContinuo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/medicamento-continuo', pathParams, {}, options), config)

export const queryPessoasControllerListarPessoaMedicamentoContinuos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/medicamento-continuos', pathParams, queryParams)
export const useQueryPessoasControllerListarPessoaMedicamentoContinuos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/medicamento-continuos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPessoaMedicamentoContinuos.queryKey = '/api/v1/pessoas/{id}/medicamento-continuos'

export const mutationPessoasControllerDeletarPessoaMedicamentoContinuo = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/medicamento-continuo/{itemId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaMedicamentoContinuo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/medicamento-continuo/{itemId}', pathParams, {}, options), config)

export const mutationPessoasControllerCadastrarPessoaNecessidadeEspecial = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/necessidade-especial', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaNecessidadeEspecial = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/necessidade-especial', pathParams, {}, options), config)

export const queryPessoasControllerListarPessoaNecessidadesEspeciais = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/necessidades-especiais', pathParams, queryParams)
export const useQueryPessoasControllerListarPessoaNecessidadesEspeciais = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/necessidades-especiais', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPessoaNecessidadesEspeciais.queryKey = '/api/v1/pessoas/{id}/necessidades-especiais'

export const mutationPessoasControllerDeletarPessoaNecessidadeEspecial = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/necessidades-especiais/{necessidadeespecialId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaNecessidadeEspecial = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/necessidades-especiais/{necessidadeespecialId}', pathParams, {}, options), config)

export const queryPessoasControllerListarTitulos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/titulos', pathParams, queryParams)
export const useQueryPessoasControllerListarTitulos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/titulos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarTitulos.queryKey = '/api/v1/pessoas/{id}/titulos'

export const queryPessoasControllerListarOrcamentosSaida = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/orcamentos-saida', pathParams, queryParams)
export const useQueryPessoasControllerListarOrcamentosSaida = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/orcamentos-saida', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarOrcamentosSaida.queryKey = '/api/v1/pessoas/{id}/orcamentos-saida'

export const queryPessoasControllerListarPedidosVenda = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/pedidos-venda', pathParams, queryParams)
export const useQueryPessoasControllerListarPedidosVenda = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/pedidos-venda', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarPedidosVenda.queryKey = '/api/v1/pessoas/{id}/pedidos-venda'

export const mutationPessoasControllerCadastrarPessoaTipoSanguineo = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/tipo-sanguineo', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaTipoSanguineo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/tipo-sanguineo', pathParams, {}, options), config)

export const mutationPessoasControllerDeletarPessoaTipoSanguineo = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/tipo-sanguineo', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaTipoSanguineo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/tipo-sanguineo', pathParams, {}, options), config)

export const queryPessoasControllerObterTipoSanguineo = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/tipo-sanguineo', pathParams)
export const useQueryPessoasControllerObterTipoSanguineo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/tipo-sanguineo', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerObterTipoSanguineo.queryKey = '/api/v1/pessoas/{id}/tipo-sanguineo'

export const queryPessoasControllerObterIdadePessoa = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/idade', pathParams)
export const useQueryPessoasControllerObterIdadePessoa = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/idade', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerObterIdadePessoa.queryKey = '/api/v1/pessoas/{id}/idade'

export const queryPessoasControllerListarOportunidade = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/oportunidades', pathParams, queryParams)
export const useQueryPessoasControllerListarOportunidade = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/oportunidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarOportunidade.queryKey = '/api/v1/pessoas/{id}/oportunidades'

export const queryPessoasControllerListarTarefas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/tarefas', pathParams, queryParams)
export const useQueryPessoasControllerListarTarefas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/tarefas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarTarefas.queryKey = '/api/v1/pessoas/{id}/tarefas'

export const queryPessoasControllerListarResponsavelTarefaOportunidade = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/taferas-oportunidades', pathParams, queryParams)
export const useQueryPessoasControllerListarResponsavelTarefaOportunidade = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/taferas-oportunidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerListarResponsavelTarefaOportunidade.queryKey = '/api/v1/pessoas/{id}/taferas-oportunidades'

export const queryPessoasControllerListarPessoasResponsaveis = (queryParams, options) => queryFn(options)('/api/v1/pessoas/responsaveis', queryParams)
export const useQueryPessoasControllerListarPessoasResponsaveis = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/responsaveis', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPessoasControllerListarPessoasResponsaveis.queryKey = '/api/v1/pessoas/responsaveis'

export const queryPessoasControllerListarPessoasAlunos = (queryParams, options) => queryFn(options)('/api/v1/pessoas/alunos', queryParams)
export const useQueryPessoasControllerListarPessoasAlunos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/alunos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPessoasControllerListarPessoasAlunos.queryKey = '/api/v1/pessoas/alunos'

export const mutationPessoasControllerCadastrarPessoaIntegracao = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{id}/integracoes', pathParams, {}, options)
export const useMutationPessoasControllerCadastrarPessoaIntegracao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{id}/integracoes', pathParams, {}, options), config)

export const queryPessoasControllerObterIntegracoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{id}/integracoes', pathParams, queryParams)
export const useQueryPessoasControllerObterIntegracoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/integracoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPessoasControllerObterIntegracoes.queryKey = '/api/v1/pessoas/{id}/integracoes'

export const queryPessoasControllerBuscarPessoaIntegracao = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams)
export const useQueryPessoasControllerBuscarPessoaIntegracao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.pessoaIntegracaoId, ...config }
})
useQueryPessoasControllerBuscarPessoaIntegracao.queryKey = '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}'

export const mutationPessoasControllerAtualizarPessoaIntegracao = (pathParams, options) => mutationFn('put', '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams, {}, options)
export const useMutationPessoasControllerAtualizarPessoaIntegracao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams, {}, options), config)

export const mutationPessoasControllerDeletarPessoaIntegracao = (pathParams, options) => mutationFn('delete', '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams, {}, options)
export const useMutationPessoasControllerDeletarPessoaIntegracao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/pessoas/{id}/integracoes/{pessoaIntegracaoId}', pathParams, {}, options), config)

export const queryPessoasControllerBuscarPessoaIntegracaoPorChave = (pathParams, options) => queryFn(options)('/api/v1/pessoas/{id}/integracoes/{chave}', pathParams)
export const useQueryPessoasControllerBuscarPessoaIntegracaoPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{id}/integracoes/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.chave, ...config }
})
useQueryPessoasControllerBuscarPessoaIntegracaoPorChave.queryKey = '/api/v1/pessoas/{id}/integracoes/{chave}'

export const mutationPessoasControllerAtualizarPessoaIntegracaoAtributo = (pathParams, options) => mutationFn('put', '/api/v1/pessoas/{pessoaId}/integracoes/{pessoaIntegracaoId}/atributo/{atributoId}', pathParams, {}, options)
export const useMutationPessoasControllerAtualizarPessoaIntegracaoAtributo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/pessoas/{pessoaId}/integracoes/{pessoaIntegracaoId}/atributo/{atributoId}', pathParams, {}, options), config)

export const queryPessoasControllerListarHistoricoPorPessoaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/pessoas/{pessoaId}/historico-contato', pathParams, queryParams)
export const useQueryPessoasControllerListarHistoricoPorPessoaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/pessoas/{pessoaId}/historico-contato', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.pessoaId, ...config }
})
useQueryPessoasControllerListarHistoricoPorPessoaId.queryKey = '/api/v1/pessoas/{pessoaId}/historico-contato'

export const mutationPessoasControllerRetornaLinkWhatsapp = (pathParams, options) => mutationFn('post', '/api/v1/pessoas/{pessoaId}/whatsapp', pathParams, {}, options)
export const useMutationPessoasControllerRetornaLinkWhatsapp = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/pessoas/{pessoaId}/whatsapp', pathParams, {}, options), config)

export const queryPlanosAulaControllerObterPlanoAula = (pathParams, options) => queryFn(options)('/api/v1/planos-aula/{id}', pathParams)
export const useQueryPlanosAulaControllerObterPlanoAula = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-aula/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosAulaControllerObterPlanoAula.queryKey = '/api/v1/planos-aula/{id}'

export const mutationPlanosAulaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/planos-aula/{id}', pathParams, {}, options)
export const useMutationPlanosAulaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-aula/{id}', pathParams, {}, options), config)

export const mutationPlanosAulaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/planos-aula/{id}', pathParams, {}, options)
export const useMutationPlanosAulaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-aula/{id}', pathParams, {}, options), config)

export const queryPlanosAulaControllerListarPlanoAulaConteudos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-aula/{id}/conteudos', pathParams, queryParams)
export const useQueryPlanosAulaControllerListarPlanoAulaConteudos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-aula/{id}/conteudos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosAulaControllerListarPlanoAulaConteudos.queryKey = '/api/v1/planos-aula/{id}/conteudos'

export const mutationPlanosAulaControllerCadastrarPlanoAulaConteudo = (pathParams, options) => mutationFn('post', '/api/v1/planos-aula/{id}/conteudo', pathParams, {}, options)
export const useMutationPlanosAulaControllerCadastrarPlanoAulaConteudo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-aula/{id}/conteudo', pathParams, {}, options), config)

export const queryPlanosAulaControllerListarPlanoAulaHabilidades = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-aula/{id}/habilidades', pathParams, queryParams)
export const useQueryPlanosAulaControllerListarPlanoAulaHabilidades = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-aula/{id}/habilidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosAulaControllerListarPlanoAulaHabilidades.queryKey = '/api/v1/planos-aula/{id}/habilidades'

export const mutationPlanosAulaControllerCadastrarPlanoAulaHabilidade = (pathParams, options) => mutationFn('post', '/api/v1/planos-aula/{id}/habilidade', pathParams, {}, options)
export const useMutationPlanosAulaControllerCadastrarPlanoAulaHabilidade = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-aula/{id}/habilidade', pathParams, {}, options), config)

export const queryPlanosAulaControllerListarPlanoAulaRecursos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-aula/{id}/recursos', pathParams, queryParams)
export const useQueryPlanosAulaControllerListarPlanoAulaRecursos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-aula/{id}/recursos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosAulaControllerListarPlanoAulaRecursos.queryKey = '/api/v1/planos-aula/{id}/recursos'

export const mutationPlanosAulaControllerCadastrarPlanoAulaRecurso = (pathParams, options) => mutationFn('post', '/api/v1/planos-aula/{id}/recurso', pathParams, {}, options)
export const useMutationPlanosAulaControllerCadastrarPlanoAulaRecurso = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-aula/{id}/recurso', pathParams, {}, options), config)

export const mutationPlanosAulaHabilidadeControllerDeletarPlanoAulaHabilidade = (pathParams, options) => mutationFn('delete', '/api/v1/planos-aula-habilidades/{id}', pathParams, {}, options)
export const useMutationPlanosAulaHabilidadeControllerDeletarPlanoAulaHabilidade = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-aula-habilidades/{id}', pathParams, {}, options), config)

export const mutationPlanosAulaRecursosControllerAtualizarPlanoAulaRecurso = (pathParams, options) => mutationFn('put', '/api/v1/planos-aula-recursos/{id}', pathParams, {}, options)
export const useMutationPlanosAulaRecursosControllerAtualizarPlanoAulaRecurso = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-aula-recursos/{id}', pathParams, {}, options), config)

export const mutationPlanosAulaRecursosControllerDeletarPlanoAulaRecurso = (pathParams, options) => mutationFn('delete', '/api/v1/planos-aula-recursos/{id}', pathParams, {}, options)
export const useMutationPlanosAulaRecursosControllerDeletarPlanoAulaRecurso = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-aula-recursos/{id}', pathParams, {}, options), config)

export const queryPlanosAulaRecursosControllerObterPlanoAulaRecursoPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-aula-recursos/{id}', pathParams)
export const useQueryPlanosAulaRecursosControllerObterPlanoAulaRecursoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-aula-recursos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosAulaRecursosControllerObterPlanoAulaRecursoPorId.queryKey = '/api/v1/planos-aula-recursos/{id}'

export const mutationPlanosContaControllerCadastrar = (options) => mutationFn('post', '/api/v1/planos-de-conta', {}, {}, options)
export const useMutationPlanosContaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/planos-de-conta', {}, {}, options), config)

export const queryPlanosContaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/planos-de-conta', queryParams)
export const useQueryPlanosContaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPlanosContaControllerListar.queryKey = '/api/v1/planos-de-conta'

export const queryPlanosContaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-de-conta/{id}', pathParams)
export const useQueryPlanosContaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosContaControllerObterPorId.queryKey = '/api/v1/planos-de-conta/{id}'

export const mutationPlanosContaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/planos-de-conta/{id}', pathParams, {}, options)
export const useMutationPlanosContaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-de-conta/{id}', pathParams, {}, options), config)

export const mutationPlanosContaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/planos-de-conta/{id}', pathParams, {}, options)
export const useMutationPlanosContaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-de-conta/{id}', pathParams, {}, options), config)

export const queryPlanosContaControllerListarContasContabeisCredito = (queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/contas-contabeis-credito', queryParams)
export const useQueryPlanosContaControllerListarContasContabeisCredito = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/contas-contabeis-credito', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPlanosContaControllerListarContasContabeisCredito.queryKey = '/api/v1/planos-de-conta/contas-contabeis-credito'

export const queryPlanosContaControllerListarContasContabeisDebito = (queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/contas-contabeis-debito', queryParams)
export const useQueryPlanosContaControllerListarContasContabeisDebito = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/contas-contabeis-debito', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPlanosContaControllerListarContasContabeisDebito.queryKey = '/api/v1/planos-de-conta/contas-contabeis-debito'

export const queryPlanosContaControllerObterResultadosContaCaixa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/{contaId}/DRE', pathParams, queryParams)
export const useQueryPlanosContaControllerObterResultadosContaCaixa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{contaId}/DRE', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.contaId, ...config }
})
useQueryPlanosContaControllerObterResultadosContaCaixa.queryKey = '/api/v1/planos-de-conta/{contaId}/DRE'

export const mutationPlanosContaControllerCadastrarPlanoContaConta = (pathParams, options) => mutationFn('post', '/api/v1/planos-de-conta/{id}/contas', pathParams, {}, options)
export const useMutationPlanosContaControllerCadastrarPlanoContaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-de-conta/{id}/contas', pathParams, {}, options), config)

export const queryPlanosContaControllerListarPlanoContaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/{id}/contas', pathParams, queryParams)
export const useQueryPlanosContaControllerListarPlanoContaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosContaControllerListarPlanoContaContas.queryKey = '/api/v1/planos-de-conta/{id}/contas'

export const queryPlanosContaControllerObterPlanoContaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-de-conta/{id}/contas/{contaId}', pathParams)
export const useQueryPlanosContaControllerObterPlanoContaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryPlanosContaControllerObterPlanoContaContaPorId.queryKey = '/api/v1/planos-de-conta/{id}/contas/{contaId}'

export const mutationPlanosContaControllerAtualizarPlanoContaConta = (pathParams, options) => mutationFn('put', '/api/v1/planos-de-conta/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationPlanosContaControllerAtualizarPlanoContaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-de-conta/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryPlanosContaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-de-conta/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryPlanosContaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-de-conta/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosContaControllerListarContasDisponiveis.queryKey = '/api/v1/planos-de-conta/{id}/contas-disponiveis'

export const queryPlanosEnsinoControllerObterPlanoEnsino = (pathParams, options) => queryFn(options)('/api/v1/planos-ensino/{id}', pathParams)
export const useQueryPlanosEnsinoControllerObterPlanoEnsino = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoControllerObterPlanoEnsino.queryKey = '/api/v1/planos-ensino/{id}'

export const mutationPlanosEnsinoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/planos-ensino/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-ensino/{id}', pathParams, {}, options), config)

export const mutationPlanosEnsinoControllerCadastrarPlanosEnsinoEmenta = (pathParams, options) => mutationFn('post', '/api/v1/planos-ensino/{id}/ementas', pathParams, {}, options)
export const useMutationPlanosEnsinoControllerCadastrarPlanosEnsinoEmenta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-ensino/{id}/ementas', pathParams, {}, options), config)

export const queryPlanosEnsinoControllerListarPlanosEnsinoEmentas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-ensino/{id}/ementas', pathParams, queryParams)
export const useQueryPlanosEnsinoControllerListarPlanosEnsinoEmentas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino/{id}/ementas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoControllerListarPlanosEnsinoEmentas.queryKey = '/api/v1/planos-ensino/{id}/ementas'

export const mutationPlanosEnsinoControllerCadastrarPlanosEnsinoFormaAvaliacao = (pathParams, options) => mutationFn('post', '/api/v1/planos-ensino/{id}/formas-avaliacao', pathParams, {}, options)
export const useMutationPlanosEnsinoControllerCadastrarPlanosEnsinoFormaAvaliacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-ensino/{id}/formas-avaliacao', pathParams, {}, options), config)

export const queryPlanosEnsinoControllerListarPlanosEnsinoFormaAvaliacaos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-ensino/{id}/formas-avaliacao', pathParams, queryParams)
export const useQueryPlanosEnsinoControllerListarPlanosEnsinoFormaAvaliacaos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino/{id}/formas-avaliacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoControllerListarPlanosEnsinoFormaAvaliacaos.queryKey = '/api/v1/planos-ensino/{id}/formas-avaliacao'

export const mutationPlanosEnsinoControllerCadastrarPlanosEnsinoBibliografia = (pathParams, options) => mutationFn('post', '/api/v1/planos-ensino/{id}/bibliografias', pathParams, {}, options)
export const useMutationPlanosEnsinoControllerCadastrarPlanosEnsinoBibliografia = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-ensino/{id}/bibliografias', pathParams, {}, options), config)

export const queryPlanosEnsinoControllerListarPlanosEnsinoBibliografias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-ensino/{id}/bibliografias', pathParams, queryParams)
export const useQueryPlanosEnsinoControllerListarPlanosEnsinoBibliografias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino/{id}/bibliografias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoControllerListarPlanosEnsinoBibliografias.queryKey = '/api/v1/planos-ensino/{id}/bibliografias'

export const mutationPlanosEnsinoControllerCadastrarPlanosEnsinoConteudoProgramatico = (pathParams, options) => mutationFn('post', '/api/v1/planos-ensino/{id}/conteudos-programatico', pathParams, {}, options)
export const useMutationPlanosEnsinoControllerCadastrarPlanosEnsinoConteudoProgramatico = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/planos-ensino/{id}/conteudos-programatico', pathParams, {}, options), config)

export const queryPlanosEnsinoControllerListarPlanosEnsinoConteudoProgramaticos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/planos-ensino/{id}/conteudos-programatico', pathParams, queryParams)
export const useQueryPlanosEnsinoControllerListarPlanosEnsinoConteudoProgramaticos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino/{id}/conteudos-programatico', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoControllerListarPlanosEnsinoConteudoProgramaticos.queryKey = '/api/v1/planos-ensino/{id}/conteudos-programatico'

export const queryPlanosEnsinoBibliografiasControllerObterPlanoEnsinoBibliografiaPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-ensino-bibliografias/{id}', pathParams)
export const useQueryPlanosEnsinoBibliografiasControllerObterPlanoEnsinoBibliografiaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino-bibliografias/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoBibliografiasControllerObterPlanoEnsinoBibliografiaPorId.queryKey = '/api/v1/planos-ensino-bibliografias/{id}'

export const mutationPlanosEnsinoBibliografiasControllerAtualizarPlanoEnsinoBibliografia = (pathParams, options) => mutationFn('put', '/api/v1/planos-ensino-bibliografias/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoBibliografiasControllerAtualizarPlanoEnsinoBibliografia = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-ensino-bibliografias/{id}', pathParams, {}, options), config)

export const mutationPlanosEnsinoBibliografiasControllerDeletarPlanoEnsinoBibliografia = (pathParams, options) => mutationFn('delete', '/api/v1/planos-ensino-bibliografias/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoBibliografiasControllerDeletarPlanoEnsinoBibliografia = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-ensino-bibliografias/{id}', pathParams, {}, options), config)

export const queryPlanosEnsinoConteudosProgramaticoControllerObterPlanoEnsinoConteudoProgramaticoPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-ensino-conteudos-programatico/{id}', pathParams)
export const useQueryPlanosEnsinoConteudosProgramaticoControllerObterPlanoEnsinoConteudoProgramaticoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino-conteudos-programatico/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoConteudosProgramaticoControllerObterPlanoEnsinoConteudoProgramaticoPorId.queryKey = '/api/v1/planos-ensino-conteudos-programatico/{id}'

export const mutationPlanosEnsinoConteudosProgramaticoControllerAtualizarPlanoEnsinoConteudoProgramatico = (pathParams, options) => mutationFn('put', '/api/v1/planos-ensino-conteudos-programatico/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoConteudosProgramaticoControllerAtualizarPlanoEnsinoConteudoProgramatico = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-ensino-conteudos-programatico/{id}', pathParams, {}, options), config)

export const mutationPlanosEnsinoConteudosProgramaticoControllerDeletarPlanoEnsinoConteudoProgramatico = (pathParams, options) => mutationFn('delete', '/api/v1/planos-ensino-conteudos-programatico/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoConteudosProgramaticoControllerDeletarPlanoEnsinoConteudoProgramatico = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-ensino-conteudos-programatico/{id}', pathParams, {}, options), config)

export const queryPlanosEnsinoEmentasControllerObterPlanoEnsinoEmentaPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-ensino-ementas/{id}', pathParams)
export const useQueryPlanosEnsinoEmentasControllerObterPlanoEnsinoEmentaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino-ementas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoEmentasControllerObterPlanoEnsinoEmentaPorId.queryKey = '/api/v1/planos-ensino-ementas/{id}'

export const mutationPlanosEnsinoEmentasControllerAtualizarPlanoEnsinoEmenta = (pathParams, options) => mutationFn('put', '/api/v1/planos-ensino-ementas/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoEmentasControllerAtualizarPlanoEnsinoEmenta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-ensino-ementas/{id}', pathParams, {}, options), config)

export const mutationPlanosEnsinoEmentasControllerDeletarPlanoEnsinoEmenta = (pathParams, options) => mutationFn('delete', '/api/v1/planos-ensino-ementas/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoEmentasControllerDeletarPlanoEnsinoEmenta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-ensino-ementas/{id}', pathParams, {}, options), config)

export const queryPlanosEnsinoFormasAvaliacaoControllerObterPlanoEnsinoFormaAvaliacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/planos-ensino-formas-avaliacao/{id}', pathParams)
export const useQueryPlanosEnsinoFormasAvaliacaoControllerObterPlanoEnsinoFormaAvaliacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/planos-ensino-formas-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPlanosEnsinoFormasAvaliacaoControllerObterPlanoEnsinoFormaAvaliacaoPorId.queryKey = '/api/v1/planos-ensino-formas-avaliacao/{id}'

export const mutationPlanosEnsinoFormasAvaliacaoControllerAtualizarPlanoEnsinoFormaAvaliacao = (pathParams, options) => mutationFn('put', '/api/v1/planos-ensino-formas-avaliacao/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoFormasAvaliacaoControllerAtualizarPlanoEnsinoFormaAvaliacao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/planos-ensino-formas-avaliacao/{id}', pathParams, {}, options), config)

export const mutationPlanosEnsinoFormasAvaliacaoControllerDeletarPlanoEnsinoFormaAvaliacao = (pathParams, options) => mutationFn('delete', '/api/v1/planos-ensino-formas-avaliacao/{id}', pathParams, {}, options)
export const useMutationPlanosEnsinoFormasAvaliacaoControllerDeletarPlanoEnsinoFormaAvaliacao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/planos-ensino-formas-avaliacao/{id}', pathParams, {}, options), config)

export const queryPraticasLinguagemControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/praticas-de-linguagem', queryParams)
export const useQueryPraticasLinguagemControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/praticas-de-linguagem', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPraticasLinguagemControllerBuscar.queryKey = '/api/v1/praticas-de-linguagem'

export const mutationPraticasLinguagemControllerCadastrar = (options) => mutationFn('post', '/api/v1/praticas-de-linguagem', {}, {}, options)
export const useMutationPraticasLinguagemControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/praticas-de-linguagem', {}, {}, options), config)

export const queryPraticasLinguagemControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/praticas-de-linguagem/{id}', pathParams)
export const useQueryPraticasLinguagemControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/praticas-de-linguagem/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPraticasLinguagemControllerObterPorId.queryKey = '/api/v1/praticas-de-linguagem/{id}'

export const mutationPraticasLinguagemControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/praticas-de-linguagem/{id}', pathParams, {}, options)
export const useMutationPraticasLinguagemControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/praticas-de-linguagem/{id}', pathParams, {}, options), config)

export const mutationPraticasLinguagemControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/praticas-de-linguagem/{id}', pathParams, {}, options)
export const useMutationPraticasLinguagemControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/praticas-de-linguagem/{id}', pathParams, {}, options), config)

export const mutationPraticasLinguagemControllerCadastrarPraticaLinguagemConta = (pathParams, options) => mutationFn('post', '/api/v1/praticas-de-linguagem/{id}/contas', pathParams, {}, options)
export const useMutationPraticasLinguagemControllerCadastrarPraticaLinguagemConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/praticas-de-linguagem/{id}/contas', pathParams, {}, options), config)

export const queryPraticasLinguagemControllerListarPraticaLinguagemContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/praticas-de-linguagem/{id}/contas', pathParams, queryParams)
export const useQueryPraticasLinguagemControllerListarPraticaLinguagemContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/praticas-de-linguagem/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPraticasLinguagemControllerListarPraticaLinguagemContas.queryKey = '/api/v1/praticas-de-linguagem/{id}/contas'

export const queryPraticasLinguagemControllerObterPraticaLinguagemContaPorId = (pathParams, options) => queryFn(options)('/api/v1/praticas-de-linguagem/{id}/contas/{contaId}', pathParams)
export const useQueryPraticasLinguagemControllerObterPraticaLinguagemContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/praticas-de-linguagem/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryPraticasLinguagemControllerObterPraticaLinguagemContaPorId.queryKey = '/api/v1/praticas-de-linguagem/{id}/contas/{contaId}'

export const mutationPraticasLinguagemControllerAtualizarPraticaLinguagemConta = (pathParams, options) => mutationFn('put', '/api/v1/praticas-de-linguagem/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationPraticasLinguagemControllerAtualizarPraticaLinguagemConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/praticas-de-linguagem/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryPraticasLinguagemControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/praticas-de-linguagem/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryPraticasLinguagemControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/praticas-de-linguagem/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPraticasLinguagemControllerListarContasDisponiveis.queryKey = '/api/v1/praticas-de-linguagem/{id}/contas-disponiveis'

export const mutationPraticasLinguagemControllerCadastrarPraticaLinguagemHabilidadeEnsinoFundamental = (pathParams, options) => mutationFn('post', '/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental', pathParams, {}, options)
export const useMutationPraticasLinguagemControllerCadastrarPraticaLinguagemHabilidadeEnsinoFundamental = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental', pathParams, {}, options), config)

export const queryPraticasLinguagemControllerListarPraticaLinguagemHabilidadesEnsinoFundamental = (pathParams, queryParams, options) => queryFn(options)('/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental', pathParams, queryParams)
export const useQueryPraticasLinguagemControllerListarPraticaLinguagemHabilidadesEnsinoFundamental = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPraticasLinguagemControllerListarPraticaLinguagemHabilidadesEnsinoFundamental.queryKey = '/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental'

export const queryPraticasLinguagemControllerObterPraticaLinguagemHabilidadeEnsinoFundamentalPorId = (pathParams, options) => queryFn(options)('/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams)
export const useQueryPraticasLinguagemControllerObterPraticaLinguagemHabilidadeEnsinoFundamentalPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.habilidadeEnsinoFundamentalId, ...config }
})
useQueryPraticasLinguagemControllerObterPraticaLinguagemHabilidadeEnsinoFundamentalPorId.queryKey = '/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}'

export const mutationPraticasLinguagemControllerDeletarPraticaLinguagemHabilidadeEnsinoFundamental = (pathParams, options) => mutationFn('delete', '/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams, {}, options)
export const useMutationPraticasLinguagemControllerDeletarPraticaLinguagemHabilidadeEnsinoFundamental = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/praticas-de-linguagem/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams, {}, options), config)

export const mutationPrecaucaoControllerCadastrarPrecaucao = (options) => mutationFn('post', '/api/v1/precaucoes', {}, {}, options)
export const useMutationPrecaucaoControllerCadastrarPrecaucao = (config, options) => useMutation(mutationFn('post', '/api/v1/precaucoes', {}, {}, options), config)

export const queryPrecaucaoControllerListarPrecaucoes = (queryParams, options) => queryFn(options)('/api/v1/precaucoes', queryParams)
export const useQueryPrecaucaoControllerListarPrecaucoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precaucoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPrecaucaoControllerListarPrecaucoes.queryKey = '/api/v1/precaucoes'

export const mutationPrecaucaoControllerDeletarPrecaucao = (pathParams, options) => mutationFn('delete', '/api/v1/precaucoes/{id}', pathParams, {}, options)
export const useMutationPrecaucaoControllerDeletarPrecaucao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/precaucoes/{id}', pathParams, {}, options), config)

export const mutationPrecaucaoControllerCadastrarPrecaucaoConta = (pathParams, options) => mutationFn('post', '/api/v1/precaucoes/{id}/contas', pathParams, {}, options)
export const useMutationPrecaucaoControllerCadastrarPrecaucaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/precaucoes/{id}/contas', pathParams, {}, options), config)

export const queryPrecaucaoControllerListarPrecaucaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precaucoes/{id}/contas', pathParams, queryParams)
export const useQueryPrecaucaoControllerListarPrecaucaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precaucoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecaucaoControllerListarPrecaucaoContas.queryKey = '/api/v1/precaucoes/{id}/contas'

export const queryPrecaucaoControllerObterPrecaucaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/precaucoes/{id}/contas/{contaId}', pathParams)
export const useQueryPrecaucaoControllerObterPrecaucaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precaucoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryPrecaucaoControllerObterPrecaucaoContaPorId.queryKey = '/api/v1/precaucoes/{id}/contas/{contaId}'

export const mutationPrecaucaoControllerAtualizarPrecaucaoConta = (pathParams, options) => mutationFn('put', '/api/v1/precaucoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationPrecaucaoControllerAtualizarPrecaucaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/precaucoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryPrecaucaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precaucoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryPrecaucaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precaucoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecaucaoControllerListarContasDisponiveis.queryKey = '/api/v1/precaucoes/{id}/contas-disponiveis'

export const mutationPrecificacoesControllerCadastrarCustoVariavelPrecificacao = (pathParams, options) => mutationFn('post', '/api/v1/precificacoes/{id}/custo-variavel', pathParams, {}, options)
export const useMutationPrecificacoesControllerCadastrarCustoVariavelPrecificacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/precificacoes/{id}/custo-variavel', pathParams, {}, options), config)

export const mutationPrecificacoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/precificacoes', {}, {}, options)
export const useMutationPrecificacoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/precificacoes', {}, {}, options), config)

export const queryPrecificacoesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/precificacoes', queryParams)
export const useQueryPrecificacoesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryPrecificacoesControllerBuscar.queryKey = '/api/v1/precificacoes'

export const mutationPrecificacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/precificacoes/{id}', pathParams, {}, options)
export const useMutationPrecificacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/precificacoes/{id}', pathParams, {}, options), config)

export const mutationPrecificacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/precificacoes/{id}', pathParams, {}, options)
export const useMutationPrecificacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/precificacoes/{id}', pathParams, {}, options), config)

export const queryPrecificacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/{id}', pathParams)
export const useQueryPrecificacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerObterPorId.queryKey = '/api/v1/precificacoes/{id}'

export const mutationPrecificacoesControllerDeletarCustoVariavelPrecificacao = (pathParams, options) => mutationFn('delete', '/api/v1/precificacoes/custo-variavel/{id}', pathParams, {}, options)
export const useMutationPrecificacoesControllerDeletarCustoVariavelPrecificacao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/precificacoes/custo-variavel/{id}', pathParams, {}, options), config)

export const queryPrecificacoesControllerObterCustoVariavelPrecificacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/custo-variavel/{id}', pathParams)
export const useQueryPrecificacoesControllerObterCustoVariavelPrecificacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/custo-variavel/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerObterCustoVariavelPrecificacaoPorId.queryKey = '/api/v1/precificacoes/custo-variavel/{id}'

export const queryPrecificacoesControllerBuscarCustosVariaveisPrecificacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/custos-variaveis', pathParams, queryParams)
export const useQueryPrecificacoesControllerBuscarCustosVariaveisPrecificacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/custos-variaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerBuscarCustosVariaveisPrecificacao.queryKey = '/api/v1/precificacoes/{id}/custos-variaveis'

export const queryPrecificacoesControllerBuscarCustosVariaveisDisponiveisPrecificacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/custos-variaveis-disponiveis', pathParams, queryParams)
export const useQueryPrecificacoesControllerBuscarCustosVariaveisDisponiveisPrecificacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/custos-variaveis-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerBuscarCustosVariaveisDisponiveisPrecificacao.queryKey = '/api/v1/precificacoes/{id}/custos-variaveis-disponiveis'

export const queryPrecificacoesControllerObterPorIdSimplififiado = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/simplificado', pathParams)
export const useQueryPrecificacoesControllerObterPorIdSimplififiado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/simplificado', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerObterPorIdSimplififiado.queryKey = '/api/v1/precificacoes/{id}/simplificado'

export const queryPrecificacoesControllerObterHistoricoPrecoItemPorId = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/historico-preco/{id}', pathParams)
export const useQueryPrecificacoesControllerObterHistoricoPrecoItemPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/historico-preco/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerObterHistoricoPrecoItemPorId.queryKey = '/api/v1/precificacoes/historico-preco/{id}'

export const mutationPrecificacoesControllerAtualizarPrecos = (options) => mutationFn('post', '/api/v1/precificacoes/atualizar-precos', {}, {}, options)
export const useMutationPrecificacoesControllerAtualizarPrecos = (config, options) => useMutation(mutationFn('post', '/api/v1/precificacoes/atualizar-precos', {}, {}, options), config)

export const mutationPrecificacoesControllerCadastrarPrecificacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/precificacoes/{id}/contas', pathParams, {}, options)
export const useMutationPrecificacoesControllerCadastrarPrecificacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/precificacoes/{id}/contas', pathParams, {}, options), config)

export const queryPrecificacoesControllerListarPrecificacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/contas', pathParams, queryParams)
export const useQueryPrecificacoesControllerListarPrecificacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerListarPrecificacaoContas.queryKey = '/api/v1/precificacoes/{id}/contas'

export const queryPrecificacoesControllerObterPrecificacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/contas/{contaId}', pathParams)
export const useQueryPrecificacoesControllerObterPrecificacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryPrecificacoesControllerObterPrecificacaoContaPorId.queryKey = '/api/v1/precificacoes/{id}/contas/{contaId}'

export const mutationPrecificacoesControllerAtualizarPrecificacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/precificacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationPrecificacoesControllerAtualizarPrecificacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/precificacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryPrecificacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/precificacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryPrecificacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/precificacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryPrecificacoesControllerListarContasDisponiveis.queryKey = '/api/v1/precificacoes/{id}/contas-disponiveis'

export const mutationProdutosControllerAtualizarPrecoDeVenda = (pathParams, options) => mutationFn('post', '/api/v1/produtos/{id}/atualizar-preco', pathParams, {}, options)
export const useMutationProdutosControllerAtualizarPrecoDeVenda = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/produtos/{id}/atualizar-preco', pathParams, {}, options), config)

export const mutationProdutosControllerCadastrar = (options) => mutationFn('post', '/api/v1/produtos', {}, {}, options)
export const useMutationProdutosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/produtos', {}, {}, options), config)

export const queryProdutosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/produtos', queryParams)
export const useQueryProdutosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/produtos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryProdutosControllerBuscar.queryKey = '/api/v1/produtos'

export const mutationProdutosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/produtos/{id}', pathParams, {}, options)
export const useMutationProdutosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/produtos/{id}', pathParams, {}, options), config)

export const mutationProdutosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/produtos/{id}', pathParams, {}, options)
export const useMutationProdutosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/produtos/{id}', pathParams, {}, options), config)

export const queryProdutosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/produtos/{id}', pathParams)
export const useQueryProdutosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/produtos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryProdutosControllerObterPorId.queryKey = '/api/v1/produtos/{id}'

export const queryQuestionariosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/questionarios', queryParams)
export const useQueryQuestionariosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryQuestionariosControllerListar.queryKey = '/api/v1/questionarios'

export const mutationQuestionariosControllerCadastrar = (options) => mutationFn('post', '/api/v1/questionarios', {}, {}, options)
export const useMutationQuestionariosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/questionarios', {}, {}, options), config)

export const queryQuestionariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/questionarios/{id}', pathParams)
export const useQueryQuestionariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryQuestionariosControllerObterPorId.queryKey = '/api/v1/questionarios/{id}'

export const mutationQuestionariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/questionarios/{id}', pathParams, {}, options)
export const useMutationQuestionariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/questionarios/{id}', pathParams, {}, options), config)

export const mutationQuestionariosControllerDeletarQuestionario = (pathParams, options) => mutationFn('delete', '/api/v1/questionarios/{id}', pathParams, {}, options)
export const useMutationQuestionariosControllerDeletarQuestionario = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/questionarios/{id}', pathParams, {}, options), config)

export const queryQuestionariosControllerObterCadernoPorQuestionarioId = (pathParams, options) => queryFn(options)('/api/v1/questionarios/{id}/caderno', pathParams)
export const useQueryQuestionariosControllerObterCadernoPorQuestionarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}/caderno', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryQuestionariosControllerObterCadernoPorQuestionarioId.queryKey = '/api/v1/questionarios/{id}/caderno'

export const queryQuestionariosControllerListarFormularios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/questionarios/{questionarioId}/formularios', pathParams, queryParams)
export const useQueryQuestionariosControllerListarFormularios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{questionarioId}/formularios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.questionarioId, ...config }
})
useQueryQuestionariosControllerListarFormularios.queryKey = '/api/v1/questionarios/{questionarioId}/formularios'

export const mutationQuestionariosControllerCadastrarFormularioResposta = (pathParams, options) => mutationFn('post', '/api/v1/questionarios/{questionarioId}/formularios', pathParams, {}, options)
export const useMutationQuestionariosControllerCadastrarFormularioResposta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/questionarios/{questionarioId}/formularios', pathParams, {}, options), config)

export const queryQuestionariosControllerListarBoardFormularios = (pathParams, options) => queryFn(options)('/api/v1/questionarios/{questionarioId}/formularios-dashboard', pathParams)
export const useQueryQuestionariosControllerListarBoardFormularios = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{questionarioId}/formularios-dashboard', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.questionarioId, ...config }
})
useQueryQuestionariosControllerListarBoardFormularios.queryKey = '/api/v1/questionarios/{questionarioId}/formularios-dashboard'

export const queryQuestionariosControllerListarTextosRespostas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/questionarios/{questionarioId}/textos-respostas/{perguntaId}', pathParams, queryParams)
export const useQueryQuestionariosControllerListarTextosRespostas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{questionarioId}/textos-respostas/{perguntaId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.questionarioId && pathParams.perguntaId, ...config }
})
useQueryQuestionariosControllerListarTextosRespostas.queryKey = '/api/v1/questionarios/{questionarioId}/textos-respostas/{perguntaId}'

export const queryQuestionariosControllerListarQuestionarioContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/questionarios/{id}/contas', pathParams, queryParams)
export const useQueryQuestionariosControllerListarQuestionarioContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryQuestionariosControllerListarQuestionarioContas.queryKey = '/api/v1/questionarios/{id}/contas'

export const mutationQuestionariosControllerCadastrarQuestionarioConta = (pathParams, options) => mutationFn('post', '/api/v1/questionarios/{id}/contas', pathParams, {}, options)
export const useMutationQuestionariosControllerCadastrarQuestionarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/questionarios/{id}/contas', pathParams, {}, options), config)

export const queryQuestionariosControllerObterQuestionarioContaPorId = (pathParams, options) => queryFn(options)('/api/v1/questionarios/{id}/contas/{contaId}', pathParams)
export const useQueryQuestionariosControllerObterQuestionarioContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryQuestionariosControllerObterQuestionarioContaPorId.queryKey = '/api/v1/questionarios/{id}/contas/{contaId}'

export const mutationQuestionariosControllerAtualizarQuestionarioConta = (pathParams, options) => mutationFn('put', '/api/v1/questionarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationQuestionariosControllerAtualizarQuestionarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/questionarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryQuestionariosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/questionarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryQuestionariosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/questionarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryQuestionariosControllerListarContasDisponiveis.queryKey = '/api/v1/questionarios/{id}/contas-disponiveis'

export const queryRamosAtividadeControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/ramos-atividade', queryParams)
export const useQueryRamosAtividadeControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryRamosAtividadeControllerBuscar.queryKey = '/api/v1/ramos-atividade'

export const mutationRamosAtividadeControllerCadastrar = (options) => mutationFn('post', '/api/v1/ramos-atividade', {}, {}, options)
export const useMutationRamosAtividadeControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/ramos-atividade', {}, {}, options), config)

export const queryRamosAtividadeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/ramos-atividade/{id}', pathParams)
export const useQueryRamosAtividadeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRamosAtividadeControllerObterPorId.queryKey = '/api/v1/ramos-atividade/{id}'

export const mutationRamosAtividadeControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/ramos-atividade/{id}', pathParams, {}, options)
export const useMutationRamosAtividadeControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/ramos-atividade/{id}', pathParams, {}, options), config)

export const mutationRamosAtividadeControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/ramos-atividade/{id}', pathParams, {}, options)
export const useMutationRamosAtividadeControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/ramos-atividade/{id}', pathParams, {}, options), config)

export const mutationRamosAtividadeControllerCadastrarRamoAtividadeConta = (pathParams, options) => mutationFn('post', '/api/v1/ramos-atividade/{id}/contas', pathParams, {}, options)
export const useMutationRamosAtividadeControllerCadastrarRamoAtividadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/ramos-atividade/{id}/contas', pathParams, {}, options), config)

export const queryRamosAtividadeControllerListarRamoAtividadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/ramos-atividade/{id}/contas', pathParams, queryParams)
export const useQueryRamosAtividadeControllerListarRamoAtividadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRamosAtividadeControllerListarRamoAtividadeContas.queryKey = '/api/v1/ramos-atividade/{id}/contas'

export const queryRamosAtividadeControllerObterRamoAtividadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/ramos-atividade/{id}/contas/{contaId}', pathParams)
export const useQueryRamosAtividadeControllerObterRamoAtividadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryRamosAtividadeControllerObterRamoAtividadeContaPorId.queryKey = '/api/v1/ramos-atividade/{id}/contas/{contaId}'

export const mutationRamosAtividadeControllerAtualizarRamoAtividadeConta = (pathParams, options) => mutationFn('put', '/api/v1/ramos-atividade/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationRamosAtividadeControllerAtualizarRamoAtividadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/ramos-atividade/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryRamosAtividadeControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/ramos-atividade/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryRamosAtividadeControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/ramos-atividade/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRamosAtividadeControllerListarContasDisponiveis.queryKey = '/api/v1/ramos-atividade/{id}/contas-disponiveis'

export const mutationRecursosDidaticosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/recurso-didatico/{id}', pathParams, {}, options)
export const useMutationRecursosDidaticosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recurso-didatico/{id}', pathParams, {}, options), config)

export const queryRecursosDidaticosControllerObterRecursoDidatico = (pathParams, options) => queryFn(options)('/api/v1/recurso-didatico/{id}', pathParams)
export const useQueryRecursosDidaticosControllerObterRecursoDidatico = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recurso-didatico/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRecursosDidaticosControllerObterRecursoDidatico.queryKey = '/api/v1/recurso-didatico/{id}'

export const queryRecursosNecessidadeEspecialControllerListarRecursoNecessidadesEspeciais = (queryParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial', queryParams)
export const useQueryRecursosNecessidadeEspecialControllerListarRecursoNecessidadesEspeciais = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryRecursosNecessidadeEspecialControllerListarRecursoNecessidadesEspeciais.queryKey = '/api/v1/recursos-necessidade-especial'

export const mutationRecursosNecessidadeEspecialControllerCadastrar = (options) => mutationFn('post', '/api/v1/recursos-necessidade-especial', {}, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/recursos-necessidade-especial', {}, {}, options), config)

export const queryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialPorId = (pathParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial/{id}', pathParams)
export const useQueryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialPorId.queryKey = '/api/v1/recursos-necessidade-especial/{id}'

export const mutationRecursosNecessidadeEspecialControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/recursos-necessidade-especial/{id}', pathParams, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/recursos-necessidade-especial/{id}', pathParams, {}, options), config)

export const mutationRecursosNecessidadeEspecialControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/recursos-necessidade-especial/{id}', pathParams, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/recursos-necessidade-especial/{id}', pathParams, {}, options), config)

export const mutationRecursosNecessidadeEspecialControllerCadastrarRecursoNecessidadeEspecialConta = (pathParams, options) => mutationFn('post', '/api/v1/recursos-necessidade-especial/{id}/contas', pathParams, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerCadastrarRecursoNecessidadeEspecialConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/recursos-necessidade-especial/{id}/contas', pathParams, {}, options), config)

export const queryRecursosNecessidadeEspecialControllerListarRecursoNecessidadeEspecialContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial/{id}/contas', pathParams, queryParams)
export const useQueryRecursosNecessidadeEspecialControllerListarRecursoNecessidadeEspecialContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRecursosNecessidadeEspecialControllerListarRecursoNecessidadeEspecialContas.queryKey = '/api/v1/recursos-necessidade-especial/{id}/contas'

export const queryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialContaPorId = (pathParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}', pathParams)
export const useQueryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryRecursosNecessidadeEspecialControllerObterRecursoNecessidadeEspecialContaPorId.queryKey = '/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}'

export const mutationRecursosNecessidadeEspecialControllerAtualizarRecursoNecessidadeEspecialConta = (pathParams, options) => mutationFn('put', '/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationRecursosNecessidadeEspecialControllerAtualizarRecursoNecessidadeEspecialConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/recursos-necessidade-especial/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryRecursosNecessidadeEspecialControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/recursos-necessidade-especial/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryRecursosNecessidadeEspecialControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/recursos-necessidade-especial/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryRecursosNecessidadeEspecialControllerListarContasDisponiveis.queryKey = '/api/v1/recursos-necessidade-especial/{id}/contas-disponiveis'

export const queryResponsaveisServicoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/responsaveis-servico/{id}', pathParams)
export const useQueryResponsaveisServicoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/responsaveis-servico/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryResponsaveisServicoControllerObterPorId.queryKey = '/api/v1/responsaveis-servico/{id}'

export const mutationResponsaveisServicoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/responsaveis-servico/{id}', pathParams, {}, options)
export const useMutationResponsaveisServicoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/responsaveis-servico/{id}', pathParams, {}, options), config)

export const querySalaEducacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/salas-educacao', queryParams)
export const useQuerySalaEducacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas-educacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySalaEducacaoControllerBuscar.queryKey = '/api/v1/salas-educacao'

export const mutationSalaEducacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/salas-educacao', {}, {}, options)
export const useMutationSalaEducacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/salas-educacao', {}, {}, options), config)

export const querySalaEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/salas-educacao/{id}', pathParams)
export const useQuerySalaEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas-educacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalaEducacaoControllerObterPorId.queryKey = '/api/v1/salas-educacao/{id}'

export const mutationSalaEducacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/salas-educacao/{id}', pathParams, {}, options)
export const useMutationSalaEducacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/salas-educacao/{id}', pathParams, {}, options), config)

export const mutationSalaEducacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/salas-educacao/{id}', pathParams, {}, options)
export const useMutationSalaEducacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/salas-educacao/{id}', pathParams, {}, options), config)

export const querySalaEducacaoControllerListarHorariosFuncionamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/salas-educacao/{id}/horarios-funcionamento', pathParams, queryParams)
export const useQuerySalaEducacaoControllerListarHorariosFuncionamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas-educacao/{id}/horarios-funcionamento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalaEducacaoControllerListarHorariosFuncionamento.queryKey = '/api/v1/salas-educacao/{id}/horarios-funcionamento'

export const mutationSalaEducacaoControllerCadastrarHorariosFuncionamento = (pathParams, options) => mutationFn('post', '/api/v1/salas-educacao/{id}/horario-funcionamento', pathParams, {}, options)
export const useMutationSalaEducacaoControllerCadastrarHorariosFuncionamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/salas-educacao/{id}/horario-funcionamento', pathParams, {}, options), config)

export const querySalaHorarioFuncionamentoEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/sala-horarios-funcionamento-educacao/{id}', pathParams)
export const useQuerySalaHorarioFuncionamentoEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sala-horarios-funcionamento-educacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalaHorarioFuncionamentoEducacaoControllerObterPorId.queryKey = '/api/v1/sala-horarios-funcionamento-educacao/{id}'

export const mutationSalaHorarioFuncionamentoEducacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/sala-horarios-funcionamento-educacao/{id}', pathParams, {}, options)
export const useMutationSalaHorarioFuncionamentoEducacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sala-horarios-funcionamento-educacao/{id}', pathParams, {}, options), config)

export const mutationSalaHorarioFuncionamentoEducacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/sala-horarios-funcionamento-educacao/{id}', pathParams, {}, options)
export const useMutationSalaHorarioFuncionamentoEducacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/sala-horarios-funcionamento-educacao/{id}', pathParams, {}, options), config)

export const querySalasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/salas', queryParams)
export const useQuerySalasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySalasControllerBuscar.queryKey = '/api/v1/salas'

export const mutationSalasControllerCadastrar = (options) => mutationFn('post', '/api/v1/salas', {}, {}, options)
export const useMutationSalasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/salas', {}, {}, options), config)

export const querySalasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/salas/{id}', pathParams)
export const useQuerySalasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalasControllerObterPorId.queryKey = '/api/v1/salas/{id}'

export const mutationSalasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/salas/{id}', pathParams, {}, options)
export const useMutationSalasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/salas/{id}', pathParams, {}, options), config)

export const mutationSalasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/salas/{id}', pathParams, {}, options)
export const useMutationSalasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/salas/{id}', pathParams, {}, options), config)

export const mutationSalasControllerCadastrarSalaConta = (pathParams, options) => mutationFn('post', '/api/v1/salas/{id}/contas', pathParams, {}, options)
export const useMutationSalasControllerCadastrarSalaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/salas/{id}/contas', pathParams, {}, options), config)

export const querySalasControllerListarSalaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/salas/{id}/contas', pathParams, queryParams)
export const useQuerySalasControllerListarSalaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalasControllerListarSalaContas.queryKey = '/api/v1/salas/{id}/contas'

export const querySalasControllerObterSalaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/salas/{id}/contas/{contaId}', pathParams)
export const useQuerySalasControllerObterSalaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySalasControllerObterSalaContaPorId.queryKey = '/api/v1/salas/{id}/contas/{contaId}'

export const mutationSalasControllerAtualizarSalaConta = (pathParams, options) => mutationFn('put', '/api/v1/salas/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSalasControllerAtualizarSalaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/salas/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySalasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/salas/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySalasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalasControllerListarContasDisponiveis.queryKey = '/api/v1/salas/{id}/contas-disponiveis'

export const mutationSalasControllerCadastrarSalaEquipamento = (pathParams, options) => mutationFn('post', '/api/v1/salas/{id}/equipamentos', pathParams, {}, options)
export const useMutationSalasControllerCadastrarSalaEquipamento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/salas/{id}/equipamentos', pathParams, {}, options), config)

export const querySalasControllerListarSalaEquipamentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/salas/{id}/equipamentos', pathParams, queryParams)
export const useQuerySalasControllerListarSalaEquipamentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/equipamentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySalasControllerListarSalaEquipamentos.queryKey = '/api/v1/salas/{id}/equipamentos'

export const querySalasControllerObterSalaEquipamentoPorId = (pathParams, options) => queryFn(options)('/api/v1/salas/{id}/equipamento/{equipamentoId}', pathParams)
export const useQuerySalasControllerObterSalaEquipamentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/{id}/equipamento/{equipamentoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.equipamentoId, ...config }
})
useQuerySalasControllerObterSalaEquipamentoPorId.queryKey = '/api/v1/salas/{id}/equipamento/{equipamentoId}'

export const mutationSalasControllerAtualizarSalaEquipamento = (pathParams, options) => mutationFn('put', '/api/v1/salas/{id}/equipamentos/{equipamentoId}', pathParams, {}, options)
export const useMutationSalasControllerAtualizarSalaEquipamento = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/salas/{id}/equipamentos/{equipamentoId}', pathParams, {}, options), config)

export const mutationSalasControllerDeletarSalaEquipamento = (pathParams, options) => mutationFn('delete', '/api/v1/salas/{id}equipamento/{equipamentoId}', pathParams, {}, options)
export const useMutationSalasControllerDeletarSalaEquipamento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/salas/{id}equipamento/{equipamentoId}', pathParams, {}, options), config)

export const querySalasControllerObterSalasFuncionarios = (queryParams, options) => queryFn(options)('/api/v1/salas/funcionarios', queryParams)
export const useQuerySalasControllerObterSalasFuncionarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/salas/funcionarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySalasControllerObterSalasFuncionarios.queryKey = '/api/v1/salas/funcionarios'

export const querySegmentacoesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/segmentacoes', queryParams)
export const useQuerySegmentacoesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySegmentacoesControllerBuscar.queryKey = '/api/v1/segmentacoes'

export const mutationSegmentacoesControllerCadastrar = (options) => mutationFn('post', '/api/v1/segmentacoes', {}, {}, options)
export const useMutationSegmentacoesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/segmentacoes', {}, {}, options), config)

export const querySegmentacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/segmentacoes/{id}', pathParams)
export const useQuerySegmentacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySegmentacoesControllerObterPorId.queryKey = '/api/v1/segmentacoes/{id}'

export const mutationSegmentacoesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/segmentacoes/{id}', pathParams, {}, options)
export const useMutationSegmentacoesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/segmentacoes/{id}', pathParams, {}, options), config)

export const mutationSegmentacoesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/segmentacoes/{id}', pathParams, {}, options)
export const useMutationSegmentacoesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/segmentacoes/{id}', pathParams, {}, options), config)

export const mutationSegmentacoesControllerCadastrarSegmentacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/segmentacoes/{id}/contas', pathParams, {}, options)
export const useMutationSegmentacoesControllerCadastrarSegmentacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/segmentacoes/{id}/contas', pathParams, {}, options), config)

export const querySegmentacoesControllerListarSegmentacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/segmentacoes/{id}/contas', pathParams, queryParams)
export const useQuerySegmentacoesControllerListarSegmentacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySegmentacoesControllerListarSegmentacaoContas.queryKey = '/api/v1/segmentacoes/{id}/contas'

export const querySegmentacoesControllerObterSegmentacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/segmentacoes/{id}/contas/{contaId}', pathParams)
export const useQuerySegmentacoesControllerObterSegmentacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySegmentacoesControllerObterSegmentacaoContaPorId.queryKey = '/api/v1/segmentacoes/{id}/contas/{contaId}'

export const mutationSegmentacoesControllerAtualizarSegmentacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/segmentacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSegmentacoesControllerAtualizarSegmentacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/segmentacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySegmentacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/segmentacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySegmentacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySegmentacoesControllerListarContasDisponiveis.queryKey = '/api/v1/segmentacoes/{id}/contas-disponiveis'

export const querySegmentacoesContatosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/segmentacoes-contatos', queryParams)
export const useQuerySegmentacoesContatosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes-contatos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySegmentacoesContatosControllerBuscar.queryKey = '/api/v1/segmentacoes-contatos'

export const mutationSegmentacoesContatosControllerCadastrar = (options) => mutationFn('post', '/api/v1/segmentacoes-contatos', {}, {}, options)
export const useMutationSegmentacoesContatosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/segmentacoes-contatos', {}, {}, options), config)

export const querySegmentacoesContatosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/segmentacoes-contatos/{id}', pathParams)
export const useQuerySegmentacoesContatosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/segmentacoes-contatos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySegmentacoesContatosControllerObterPorId.queryKey = '/api/v1/segmentacoes-contatos/{id}'

export const mutationSegmentacoesContatosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/segmentacoes-contatos/{id}', pathParams, {}, options)
export const useMutationSegmentacoesContatosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/segmentacoes-contatos/{id}', pathParams, {}, options), config)

export const mutationSegmentacoesContatosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/segmentacoes-contatos/{id}', pathParams, {}, options)
export const useMutationSegmentacoesContatosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/segmentacoes-contatos/{id}', pathParams, {}, options), config)

export const querySeriesEquivalentesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/series-equivalentes', queryParams)
export const useQuerySeriesEquivalentesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/series-equivalentes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySeriesEquivalentesControllerBuscar.queryKey = '/api/v1/series-equivalentes'

export const mutationSeriesEquivalentesControllerCadastrar = (options) => mutationFn('post', '/api/v1/series-equivalentes', {}, {}, options)
export const useMutationSeriesEquivalentesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/series-equivalentes', {}, {}, options), config)

export const querySeriesEquivalentesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/series-equivalentes/{id}', pathParams)
export const useQuerySeriesEquivalentesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/series-equivalentes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySeriesEquivalentesControllerObterPorId.queryKey = '/api/v1/series-equivalentes/{id}'

export const mutationSeriesEquivalentesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/series-equivalentes/{id}', pathParams, {}, options)
export const useMutationSeriesEquivalentesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/series-equivalentes/{id}', pathParams, {}, options), config)

export const mutationSeriesEquivalentesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/series-equivalentes/{id}', pathParams, {}, options)
export const useMutationSeriesEquivalentesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/series-equivalentes/{id}', pathParams, {}, options), config)

export const mutationSeriesEquivalentesControllerCadastrarSerieEquivalenteConta = (pathParams, options) => mutationFn('post', '/api/v1/series-equivalentes/{id}/contas', pathParams, {}, options)
export const useMutationSeriesEquivalentesControllerCadastrarSerieEquivalenteConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/series-equivalentes/{id}/contas', pathParams, {}, options), config)

export const querySeriesEquivalentesControllerListarSerieEquivalenteContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/series-equivalentes/{id}/contas', pathParams, queryParams)
export const useQuerySeriesEquivalentesControllerListarSerieEquivalenteContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/series-equivalentes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySeriesEquivalentesControllerListarSerieEquivalenteContas.queryKey = '/api/v1/series-equivalentes/{id}/contas'

export const querySeriesEquivalentesControllerObterSerieEquivalenteContaPorId = (pathParams, options) => queryFn(options)('/api/v1/series-equivalentes/{id}/contas/{contaId}', pathParams)
export const useQuerySeriesEquivalentesControllerObterSerieEquivalenteContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/series-equivalentes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySeriesEquivalentesControllerObterSerieEquivalenteContaPorId.queryKey = '/api/v1/series-equivalentes/{id}/contas/{contaId}'

export const mutationSeriesEquivalentesControllerAtualizarSerieEquivalenteConta = (pathParams, options) => mutationFn('put', '/api/v1/series-equivalentes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSeriesEquivalentesControllerAtualizarSerieEquivalenteConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/series-equivalentes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySeriesEquivalentesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/series-equivalentes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySeriesEquivalentesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/series-equivalentes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySeriesEquivalentesControllerListarContasDisponiveis.queryKey = '/api/v1/series-equivalentes/{id}/contas-disponiveis'

export const mutationServicosControllerAtualizarPrecoDeVenda = (pathParams, options) => mutationFn('post', '/api/v1/servicos/{id}/atualizar-preco', pathParams, {}, options)
export const useMutationServicosControllerAtualizarPrecoDeVenda = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/servicos/{id}/atualizar-preco', pathParams, {}, options), config)

export const mutationServicosControllerCadastrar = (options) => mutationFn('post', '/api/v1/servicos', {}, {}, options)
export const useMutationServicosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/servicos', {}, {}, options), config)

export const queryServicosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/servicos', queryParams)
export const useQueryServicosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryServicosControllerBuscar.queryKey = '/api/v1/servicos'

export const mutationServicosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/servicos/{id}', pathParams, {}, options)
export const useMutationServicosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/servicos/{id}', pathParams, {}, options), config)

export const mutationServicosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/servicos/{id}', pathParams, {}, options)
export const useMutationServicosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/servicos/{id}', pathParams, {}, options), config)

export const queryServicosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/servicos/{id}', pathParams)
export const useQueryServicosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryServicosControllerObterPorId.queryKey = '/api/v1/servicos/{id}'

export const mutationServicosControllerCadastrarResponsavelServico = (pathParams, options) => mutationFn('post', '/api/v1/servicos/{id}/responsaveis', pathParams, {}, options)
export const useMutationServicosControllerCadastrarResponsavelServico = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/servicos/{id}/responsaveis', pathParams, {}, options), config)

export const queryServicosControllerObterResponsavelServicoPorServicoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/servicos/{id}/responsavel', pathParams, queryParams)
export const useQueryServicosControllerObterResponsavelServicoPorServicoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos/{id}/responsavel', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryServicosControllerObterResponsavelServicoPorServicoId.queryKey = '/api/v1/servicos/{id}/responsavel'

export const queryServicosControllerObterServicoAnoFaixaPorServicoId = (pathParams, options) => queryFn(options)('/api/v1/servicos/{id}/ano-faixa', pathParams)
export const useQueryServicosControllerObterServicoAnoFaixaPorServicoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/servicos/{id}/ano-faixa', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryServicosControllerObterServicoAnoFaixaPorServicoId.queryKey = '/api/v1/servicos/{id}/ano-faixa'

export const querySimuladorFormulaControllerSimularFormula = (queryParams, options) => queryFn(options)('/api/v1/simulador-formula', queryParams)
export const useQuerySimuladorFormulaControllerSimularFormula = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/simulador-formula', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySimuladorFormulaControllerSimularFormula.queryKey = '/api/v1/simulador-formula'

export const querySimuladosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/simulados/{id}', pathParams)
export const useQuerySimuladosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/simulados/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySimuladosControllerObterPorId.queryKey = '/api/v1/simulados/{id}'

export const mutationSimuladosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/simulados/{id}', pathParams, {}, options)
export const useMutationSimuladosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/simulados/{id}', pathParams, {}, options), config)

export const mutationSimuladosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/simulados/{id}', pathParams, {}, options)
export const useMutationSimuladosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/simulados/{id}', pathParams, {}, options), config)

export const mutationSimuladosControllerCadastrarSimuladoConta = (pathParams, options) => mutationFn('post', '/api/v1/simulados/{id}/contas', pathParams, {}, options)
export const useMutationSimuladosControllerCadastrarSimuladoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/simulados/{id}/contas', pathParams, {}, options), config)

export const querySimuladosControllerListarSimuladoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/simulados/{id}/contas', pathParams, queryParams)
export const useQuerySimuladosControllerListarSimuladoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/simulados/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySimuladosControllerListarSimuladoContas.queryKey = '/api/v1/simulados/{id}/contas'

export const querySimuladosControllerObterSimuladoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/simulados/{id}/contas/{contaId}', pathParams)
export const useQuerySimuladosControllerObterSimuladoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/simulados/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySimuladosControllerObterSimuladoContaPorId.queryKey = '/api/v1/simulados/{id}/contas/{contaId}'

export const mutationSimuladosControllerAtualizarSimuladoConta = (pathParams, options) => mutationFn('put', '/api/v1/simulados/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSimuladosControllerAtualizarSimuladoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/simulados/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySimuladosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/simulados/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySimuladosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/simulados/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySimuladosControllerListarContasDisponiveis.queryKey = '/api/v1/simulados/{id}/contas-disponiveis'

export const querySinteseSistemaAvaliacaoTurmaControllerObterPorTurmaId = (pathParams, options) => queryFn(options)('/api/v1/sintese-sistemas-avaliacao-turma/{turmaId}', pathParams)
export const useQuerySinteseSistemaAvaliacaoTurmaControllerObterPorTurmaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sintese-sistemas-avaliacao-turma/{turmaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.turmaId, ...config }
})
useQuerySinteseSistemaAvaliacaoTurmaControllerObterPorTurmaId.queryKey = '/api/v1/sintese-sistemas-avaliacao-turma/{turmaId}'

export const mutationSinteseSistemaAvaliacaoTurmaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/sintese-sistemas-avaliacao-turma/{id}', pathParams, {}, options)
export const useMutationSinteseSistemaAvaliacaoTurmaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sintese-sistemas-avaliacao-turma/{id}', pathParams, {}, options), config)

export const querySistemasAvaliacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao', queryParams)
export const useQuerySistemasAvaliacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySistemasAvaliacaoControllerBuscar.queryKey = '/api/v1/sistemas-avaliacao'

export const mutationSistemasAvaliacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/sistemas-avaliacao', {}, {}, options)
export const useMutationSistemasAvaliacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/sistemas-avaliacao', {}, {}, options), config)

export const querySistemasAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}', pathParams)
export const useQuerySistemasAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerObterPorId.queryKey = '/api/v1/sistemas-avaliacao/{id}'

export const mutationSistemasAvaliacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/sistemas-avaliacao/{id}', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sistemas-avaliacao/{id}', pathParams, {}, options), config)

export const mutationSistemasAvaliacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/sistemas-avaliacao/{id}', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/sistemas-avaliacao/{id}', pathParams, {}, options), config)

export const mutationSistemasAvaliacaoControllerCadastrarSistemaAvaliacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/sistemas-avaliacao/{id}/contas', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerCadastrarSistemaAvaliacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/sistemas-avaliacao/{id}/contas', pathParams, {}, options), config)

export const querySistemasAvaliacaoControllerListarSistemaAvaliacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/contas', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListarSistemaAvaliacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListarSistemaAvaliacaoContas.queryKey = '/api/v1/sistemas-avaliacao/{id}/contas'

export const querySistemasAvaliacaoControllerObterSistemaAvaliacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/contas/{contaId}', pathParams)
export const useQuerySistemasAvaliacaoControllerObterSistemaAvaliacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySistemasAvaliacaoControllerObterSistemaAvaliacaoContaPorId.queryKey = '/api/v1/sistemas-avaliacao/{id}/contas/{contaId}'

export const mutationSistemasAvaliacaoControllerAtualizarSistemaAvaliacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerAtualizarSistemaAvaliacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySistemasAvaliacaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListarContasDisponiveis.queryKey = '/api/v1/sistemas-avaliacao/{id}/contas-disponiveis'

export const querySistemasAvaliacaoControllerListarTemplatesAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/templates', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListarTemplatesAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/templates', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListarTemplatesAvaliacao.queryKey = '/api/v1/sistemas-avaliacao/{id}/templates'

export const querySistemasAvaliacaoControllerListarConceitosSistemaAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/conceitos', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListarConceitosSistemaAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/conceitos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListarConceitosSistemaAvaliacao.queryKey = '/api/v1/sistemas-avaliacao/{id}/conceitos'

export const mutationSistemasAvaliacaoControllerCadastrarConceito = (pathParams, options) => mutationFn('post', '/api/v1/sistemas-avaliacao/{id}/conceitos', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerCadastrarConceito = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/sistemas-avaliacao/{id}/conceitos', pathParams, {}, options), config)

export const querySistemasAvaliacaoControllerListarPeriodosAvaliativosSistemaAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/periodos-avaliativos', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListarPeriodosAvaliativosSistemaAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/periodos-avaliativos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListarPeriodosAvaliativosSistemaAvaliacao.queryKey = '/api/v1/sistemas-avaliacao/{id}/periodos-avaliativos'

export const mutationSistemasAvaliacaoControllerCadastrarParecerDescritivo = (pathParams, options) => mutationFn('post', '/api/v1/sistemas-avaliacao/{id}/parecer-descritivo', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerCadastrarParecerDescritivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/sistemas-avaliacao/{id}/parecer-descritivo', pathParams, {}, options), config)

export const querySistemasAvaliacaoControllerListarPareceresDescritivosSistemaAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/parecer-descritivo', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListarPareceresDescritivosSistemaAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/parecer-descritivo', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListarPareceresDescritivosSistemaAvaliacao.queryKey = '/api/v1/sistemas-avaliacao/{id}/parecer-descritivo'

export const querySistemasAvaliacaoControllerObterSistemaAvaliacaoRecuperacao = (pathParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/configuracao-recuperacao', pathParams)
export const useQuerySistemasAvaliacaoControllerObterSistemaAvaliacaoRecuperacao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/configuracao-recuperacao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerObterSistemaAvaliacaoRecuperacao.queryKey = '/api/v1/sistemas-avaliacao/{id}/configuracao-recuperacao'

export const mutationSistemasAvaliacaoControllerAtualizarConfiguracaoRecuperacao = (pathParams, options) => mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/configuracao-recuperacao', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerAtualizarConfiguracaoRecuperacao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/configuracao-recuperacao', pathParams, {}, options), config)

export const querySistemasAvaliacaoControllerObterSistemaAvaliacaoExameFinal = (pathParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/configuracao-exame-final', pathParams)
export const useQuerySistemasAvaliacaoControllerObterSistemaAvaliacaoExameFinal = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/configuracao-exame-final', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerObterSistemaAvaliacaoExameFinal.queryKey = '/api/v1/sistemas-avaliacao/{id}/configuracao-exame-final'

export const mutationSistemasAvaliacaoControllerAtualizarConfiguracaoExameFinal = (pathParams, options) => mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/configuracao-exame-final', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerAtualizarConfiguracaoExameFinal = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/configuracao-exame-final', pathParams, {}, options), config)

export const mutationSistemasAvaliacaoControllerCadastrarModeloAvaliacao = (pathParams, options) => mutationFn('post', '/api/v1/sistemas-avaliacao/{id}/modelo-avaliacao', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerCadastrarModeloAvaliacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/sistemas-avaliacao/{id}/modelo-avaliacao', pathParams, {}, options), config)

export const querySistemasAvaliacaoControllerListarModelosAvaliacaoSistemaAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/modelos-avaliacao', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListarModelosAvaliacaoSistemaAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/modelos-avaliacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListarModelosAvaliacaoSistemaAvaliacao.queryKey = '/api/v1/sistemas-avaliacao/{id}/modelos-avaliacao'

export const querySistemasAvaliacaoControllerObterConfiguracaoNotaParcialPorPorSistemaAvaliacaoId = (pathParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/nota-parcial', pathParams)
export const useQuerySistemasAvaliacaoControllerObterConfiguracaoNotaParcialPorPorSistemaAvaliacaoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/nota-parcial', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerObterConfiguracaoNotaParcialPorPorSistemaAvaliacaoId.queryKey = '/api/v1/sistemas-avaliacao/{id}/nota-parcial'

export const querySistemasAvaliacaoControllerObterSistemaAvaliacaoArredondamento = (pathParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/arredondamento', pathParams)
export const useQuerySistemasAvaliacaoControllerObterSistemaAvaliacaoArredondamento = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/arredondamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerObterSistemaAvaliacaoArredondamento.queryKey = '/api/v1/sistemas-avaliacao/{id}/arredondamento'

export const mutationSistemasAvaliacaoControllerAtualizarConfiguracaoArredondamento = (pathParams, options) => mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/arredondamento', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerAtualizarConfiguracaoArredondamento = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/arredondamento', pathParams, {}, options), config)

export const querySistemasAvaliacaoControllerListarPeriodosSistemaAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/periodos', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListarPeriodosSistemaAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/periodos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListarPeriodosSistemaAvaliacao.queryKey = '/api/v1/sistemas-avaliacao/{id}/periodos'

export const querySistemasAvaliacaoControllerListaTurmasEmAndamentoEEmFormacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-avaliacao/{id}/turmas-andamento-formacao', pathParams, queryParams)
export const useQuerySistemasAvaliacaoControllerListaTurmasEmAndamentoEEmFormacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-avaliacao/{id}/turmas-andamento-formacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasAvaliacaoControllerListaTurmasEmAndamentoEEmFormacao.queryKey = '/api/v1/sistemas-avaliacao/{id}/turmas-andamento-formacao'

export const mutationSistemasAvaliacaoControllerReplicarSistemaAvaliacaoParaTurmasVinculadas = (pathParams, options) => mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/replicar-turmas', pathParams, {}, options)
export const useMutationSistemasAvaliacaoControllerReplicarSistemaAvaliacaoParaTurmasVinculadas = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sistemas-avaliacao/{id}/replicar-turmas', pathParams, {}, options), config)

export const querySistemasEnsinoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/sistemas-ensino', queryParams)
export const useQuerySistemasEnsinoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-ensino', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySistemasEnsinoControllerBuscar.queryKey = '/api/v1/sistemas-ensino'

export const mutationSistemasEnsinoControllerCadastrar = (options) => mutationFn('post', '/api/v1/sistemas-ensino', {}, {}, options)
export const useMutationSistemasEnsinoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/sistemas-ensino', {}, {}, options), config)

export const querySistemasEnsinoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/sistemas-ensino/{id}', pathParams)
export const useQuerySistemasEnsinoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-ensino/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasEnsinoControllerObterPorId.queryKey = '/api/v1/sistemas-ensino/{id}'

export const mutationSistemasEnsinoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/sistemas-ensino/{id}', pathParams, {}, options)
export const useMutationSistemasEnsinoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sistemas-ensino/{id}', pathParams, {}, options), config)

export const mutationSistemasEnsinoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/sistemas-ensino/{id}', pathParams, {}, options)
export const useMutationSistemasEnsinoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/sistemas-ensino/{id}', pathParams, {}, options), config)

export const mutationSistemasEnsinoControllerCadastrarSistemaEnsinoConta = (pathParams, options) => mutationFn('post', '/api/v1/sistemas-ensino/{id}/contas', pathParams, {}, options)
export const useMutationSistemasEnsinoControllerCadastrarSistemaEnsinoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/sistemas-ensino/{id}/contas', pathParams, {}, options), config)

export const querySistemasEnsinoControllerListarSistemaEnsinoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-ensino/{id}/contas', pathParams, queryParams)
export const useQuerySistemasEnsinoControllerListarSistemaEnsinoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-ensino/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasEnsinoControllerListarSistemaEnsinoContas.queryKey = '/api/v1/sistemas-ensino/{id}/contas'

export const querySistemasEnsinoControllerObterSistemaEnsinoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/sistemas-ensino/{id}/contas/{contaId}', pathParams)
export const useQuerySistemasEnsinoControllerObterSistemaEnsinoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-ensino/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySistemasEnsinoControllerObterSistemaEnsinoContaPorId.queryKey = '/api/v1/sistemas-ensino/{id}/contas/{contaId}'

export const mutationSistemasEnsinoControllerAtualizarSistemaEnsinoConta = (pathParams, options) => mutationFn('put', '/api/v1/sistemas-ensino/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSistemasEnsinoControllerAtualizarSistemaEnsinoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sistemas-ensino/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySistemasEnsinoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/sistemas-ensino/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySistemasEnsinoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sistemas-ensino/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySistemasEnsinoControllerListarContasDisponiveis.queryKey = '/api/v1/sistemas-ensino/{id}/contas-disponiveis'

export const querySituacaoPedagogicaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-pedagogica', queryParams)
export const useQuerySituacaoPedagogicaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-pedagogica', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacaoPedagogicaControllerBuscar.queryKey = '/api/v1/situacoes-pedagogica'

export const mutationSituacaoPedagogicaControllerCadastrar = (options) => mutationFn('post', '/api/v1/situacoes-pedagogica', {}, {}, options)
export const useMutationSituacaoPedagogicaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/situacoes-pedagogica', {}, {}, options), config)

export const querySituacaoPedagogicaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-pedagogica/{id}', pathParams)
export const useQuerySituacaoPedagogicaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-pedagogica/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacaoPedagogicaControllerObterPorId.queryKey = '/api/v1/situacoes-pedagogica/{id}'

export const mutationSituacaoPedagogicaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/situacoes-pedagogica/{id}', pathParams, {}, options)
export const useMutationSituacaoPedagogicaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/situacoes-pedagogica/{id}', pathParams, {}, options), config)

export const mutationSituacaoPedagogicaControllerDeletarSituacaoPedagogica = (pathParams, options) => mutationFn('delete', '/api/v1/situacoes-pedagogica/{id}', pathParams, {}, options)
export const useMutationSituacaoPedagogicaControllerDeletarSituacaoPedagogica = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/situacoes-pedagogica/{id}', pathParams, {}, options), config)

export const querySituacaoPedagogicaControllerListarSituacaoPedagogicaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/situacoes-pedagogica/{id}/contas', pathParams, queryParams)
export const useQuerySituacaoPedagogicaControllerListarSituacaoPedagogicaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-pedagogica/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacaoPedagogicaControllerListarSituacaoPedagogicaContas.queryKey = '/api/v1/situacoes-pedagogica/{id}/contas'

export const mutationSituacaoPedagogicaControllerCadastrarSolicitacaoPedagogicaConta = (pathParams, options) => mutationFn('post', '/api/v1/situacoes-pedagogica/{id}/contas', pathParams, {}, options)
export const useMutationSituacaoPedagogicaControllerCadastrarSolicitacaoPedagogicaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/situacoes-pedagogica/{id}/contas', pathParams, {}, options), config)

export const querySituacaoPedagogicaControllerObterSituacaoPedagogicaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-pedagogica/{id}/contas/{contaId}', pathParams)
export const useQuerySituacaoPedagogicaControllerObterSituacaoPedagogicaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-pedagogica/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQuerySituacaoPedagogicaControllerObterSituacaoPedagogicaContaPorId.queryKey = '/api/v1/situacoes-pedagogica/{id}/contas/{contaId}'

export const mutationSituacaoPedagogicaControllerAtualizarSituacaoPedagogicaConta = (pathParams, options) => mutationFn('put', '/api/v1/situacoes-pedagogica/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationSituacaoPedagogicaControllerAtualizarSituacaoPedagogicaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/situacoes-pedagogica/{id}/contas/{contaId}', pathParams, {}, options), config)

export const querySituacaoPedagogicaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/situacoes-pedagogica/{id}/contas-disponiveis', pathParams, queryParams)
export const useQuerySituacaoPedagogicaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-pedagogica/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacaoPedagogicaControllerListarContasDisponiveis.queryKey = '/api/v1/situacoes-pedagogica/{id}/contas-disponiveis'

export const querySituacoesCadastraisControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-cadastrais', queryParams)
export const useQuerySituacoesCadastraisControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-cadastrais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesCadastraisControllerBuscar.queryKey = '/api/v1/situacoes-cadastrais'

export const querySituacoesCadastraisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-cadastrais/{id}', pathParams)
export const useQuerySituacoesCadastraisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-cadastrais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesCadastraisControllerObterPorId.queryKey = '/api/v1/situacoes-cadastrais/{id}'

export const querySituacoesCadastraisControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-cadastrais/{chave}', pathParams)
export const useQuerySituacoesCadastraisControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-cadastrais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesCadastraisControllerObterPorChave.queryKey = '/api/v1/situacoes-cadastrais/{chave}'

export const querySituacoesContratoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-de-contrato', queryParams)
export const useQuerySituacoesContratoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-contrato', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesContratoControllerListar.queryKey = '/api/v1/situacoes-de-contrato'

export const querySituacoesContratoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-de-contrato/{id}', pathParams)
export const useQuerySituacoesContratoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-contrato/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesContratoControllerObterPorId.queryKey = '/api/v1/situacoes-de-contrato/{id}'

export const querySituacoesContratoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-de-contrato/{chave}', pathParams)
export const useQuerySituacoesContratoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-contrato/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesContratoControllerObterPorChave.queryKey = '/api/v1/situacoes-de-contrato/{chave}'

export const querySituacoesContratoControllerListarPorChaves = (queryParams, options) => queryFn(options)('/api/v1/situacoes-de-contrato/listar-por-chaves', queryParams)
export const useQuerySituacoesContratoControllerListarPorChaves = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-contrato/listar-por-chaves', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesContratoControllerListarPorChaves.queryKey = '/api/v1/situacoes-de-contrato/listar-por-chaves'

export const querySituacoesDocumentoEntradaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-documento-entrada', queryParams)
export const useQuerySituacoesDocumentoEntradaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-entrada', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesDocumentoEntradaControllerBuscar.queryKey = '/api/v1/situacoes-documento-entrada'

export const querySituacoesDocumentoEntradaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-documento-entrada/{id}', pathParams)
export const useQuerySituacoesDocumentoEntradaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-entrada/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesDocumentoEntradaControllerObterPorId.queryKey = '/api/v1/situacoes-documento-entrada/{id}'

export const querySituacoesDocumentoEntradaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-documento-entrada/{chave}', pathParams)
export const useQuerySituacoesDocumentoEntradaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-entrada/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesDocumentoEntradaControllerObterPorChave.queryKey = '/api/v1/situacoes-documento-entrada/{chave}'

export const querySituacoesDocumentoSaidaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-documento-saida', queryParams)
export const useQuerySituacoesDocumentoSaidaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-saida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesDocumentoSaidaControllerBuscar.queryKey = '/api/v1/situacoes-documento-saida'

export const querySituacoesDocumentoSaidaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-documento-saida/{id}', pathParams)
export const useQuerySituacoesDocumentoSaidaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-saida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesDocumentoSaidaControllerObterPorId.queryKey = '/api/v1/situacoes-documento-saida/{id}'

export const querySituacoesDocumentoSaidaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-documento-saida/{chave}', pathParams)
export const useQuerySituacoesDocumentoSaidaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-documento-saida/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesDocumentoSaidaControllerObterPorChave.queryKey = '/api/v1/situacoes-documento-saida/{chave}'

export const querySituacoesMovimentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-movimento', queryParams)
export const useQuerySituacoesMovimentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-movimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesMovimentoControllerBuscar.queryKey = '/api/v1/situacoes-movimento'

export const querySituacoesMovimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-movimento/{id}', pathParams)
export const useQuerySituacoesMovimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-movimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesMovimentoControllerObterPorId.queryKey = '/api/v1/situacoes-movimento/{id}'

export const querySituacoesMovimentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-movimento/{chave}', pathParams)
export const useQuerySituacoesMovimentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-movimento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesMovimentoControllerObterPorChave.queryKey = '/api/v1/situacoes-movimento/{chave}'

export const querySituacoesNfseControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-nfse', queryParams)
export const useQuerySituacoesNfseControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-nfse', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesNfseControllerBuscar.queryKey = '/api/v1/situacoes-nfse'

export const querySituacoesNfseControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-nfse/{id}', pathParams)
export const useQuerySituacoesNfseControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-nfse/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesNfseControllerObterPorId.queryKey = '/api/v1/situacoes-nfse/{id}'

export const querySituacoesNfseControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-nfse/{chave}', pathParams)
export const useQuerySituacoesNfseControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-nfse/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesNfseControllerObterPorChave.queryKey = '/api/v1/situacoes-nfse/{chave}'

export const querySituacoesPresencaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-de-presenca', queryParams)
export const useQuerySituacoesPresencaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-presenca', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesPresencaControllerListar.queryKey = '/api/v1/situacoes-de-presenca'

export const querySituacoesPresencaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-de-presenca/{id}', pathParams)
export const useQuerySituacoesPresencaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-presenca/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesPresencaControllerObterPorId.queryKey = '/api/v1/situacoes-de-presenca/{id}'

export const querySituacoesPresencaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-de-presenca/{chave}', pathParams)
export const useQuerySituacoesPresencaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-de-presenca/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesPresencaControllerObterPorChave.queryKey = '/api/v1/situacoes-de-presenca/{chave}'

export const querySituacoesTurmaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/situacoes-da-turma', queryParams)
export const useQuerySituacoesTurmaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-da-turma', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySituacoesTurmaControllerListar.queryKey = '/api/v1/situacoes-da-turma'

export const querySituacoesTurmaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/situacoes-da-turma/{id}', pathParams)
export const useQuerySituacoesTurmaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-da-turma/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySituacoesTurmaControllerObterPorId.queryKey = '/api/v1/situacoes-da-turma/{id}'

export const querySituacoesTurmaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/situacoes-da-turma/{chave}', pathParams)
export const useQuerySituacoesTurmaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/situacoes-da-turma/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQuerySituacoesTurmaControllerObterPorChave.queryKey = '/api/v1/situacoes-da-turma/{chave}'

export const querySmsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/sms', queryParams)
export const useQuerySmsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sms', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySmsControllerListar.queryKey = '/api/v1/sms'

export const mutationSmsControllerCadastrar = (options) => mutationFn('post', '/api/v1/sms', {}, {}, options)
export const useMutationSmsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/sms', {}, {}, options), config)

export const querySmsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/sms/{id}', pathParams)
export const useQuerySmsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sms/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySmsControllerObterPorId.queryKey = '/api/v1/sms/{id}'

export const querySmsTemplatesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/sms-templates', queryParams)
export const useQuerySmsTemplatesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sms-templates', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQuerySmsTemplatesControllerListar.queryKey = '/api/v1/sms-templates'

export const mutationSmsTemplatesControllerCadastrar = (options) => mutationFn('post', '/api/v1/sms-templates', {}, {}, options)
export const useMutationSmsTemplatesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/sms-templates', {}, {}, options), config)

export const mutationSmsTemplatesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/sms-templates/{id}', pathParams, {}, options)
export const useMutationSmsTemplatesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/sms-templates/{id}', pathParams, {}, options), config)

export const mutationSmsTemplatesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/sms-templates/{id}', pathParams, {}, options)
export const useMutationSmsTemplatesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/sms-templates/{id}', pathParams, {}, options), config)

export const querySmsTemplatesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/sms-templates/{id}', pathParams)
export const useQuerySmsTemplatesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/sms-templates/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQuerySmsTemplatesControllerObterPorId.queryKey = '/api/v1/sms-templates/{id}'

export const queryStatusBaixaTituloCartaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/status-baixa-titulo-cartao', queryParams)
export const useQueryStatusBaixaTituloCartaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-baixa-titulo-cartao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryStatusBaixaTituloCartaoControllerBuscar.queryKey = '/api/v1/status-baixa-titulo-cartao'

export const queryStatusBaixaTituloCartaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/status-baixa-titulo-cartao/{id}', pathParams)
export const useQueryStatusBaixaTituloCartaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-baixa-titulo-cartao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryStatusBaixaTituloCartaoControllerObterPorId.queryKey = '/api/v1/status-baixa-titulo-cartao/{id}'

export const queryStatusBaixaTituloCartaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/status-baixa-titulo-cartao/{chave}', pathParams)
export const useQueryStatusBaixaTituloCartaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/status-baixa-titulo-cartao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryStatusBaixaTituloCartaoControllerObterPorChave.queryKey = '/api/v1/status-baixa-titulo-cartao/{chave}'

export const queryStatusOcorrenciasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-status-ocorrencia', queryParams)
export const useQueryStatusOcorrenciasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-status-ocorrencia', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryStatusOcorrenciasControllerListar.queryKey = '/api/v1/tipos-status-ocorrencia'

export const queryStatusOcorrenciasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-status-ocorrencia/{id}', pathParams)
export const useQueryStatusOcorrenciasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-status-ocorrencia/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryStatusOcorrenciasControllerObterPorId.queryKey = '/api/v1/tipos-status-ocorrencia/{id}'

export const queryStatusOcorrenciasControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-status-ocorrencia/{chave}', pathParams)
export const useQueryStatusOcorrenciasControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-status-ocorrencia/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryStatusOcorrenciasControllerObterPorChave.queryKey = '/api/v1/tipos-status-ocorrencia/{chave}'

export const mutationTagsControllerCadastrar = (options) => mutationFn('post', '/api/v1/tags', {}, {}, options)
export const useMutationTagsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tags', {}, {}, options), config)

export const queryTagsControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tags', queryParams)
export const useQueryTagsControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTagsControllerListar.queryKey = '/api/v1/tags'

export const queryTagsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tags/{id}', pathParams)
export const useQueryTagsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTagsControllerObterPorId.queryKey = '/api/v1/tags/{id}'

export const mutationTagsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tags/{id}', pathParams, {}, options)
export const useMutationTagsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tags/{id}', pathParams, {}, options), config)

export const mutationTagsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tags/{id}', pathParams, {}, options)
export const useMutationTagsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tags/{id}', pathParams, {}, options), config)

export const mutationTagsControllerCadastrarTipoTagTag = (pathParams, options) => mutationFn('post', '/api/v1/tags/{id}/tipos-tag', pathParams, {}, options)
export const useMutationTagsControllerCadastrarTipoTagTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tags/{id}/tipos-tag', pathParams, {}, options), config)

export const queryTagsControllerListarTiposTag = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tags/{id}/tipos-tag', pathParams, queryParams)
export const useQueryTagsControllerListarTiposTag = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}/tipos-tag', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTagsControllerListarTiposTag.queryKey = '/api/v1/tags/{id}/tipos-tag'

export const mutationTagsControllerDeletarTipoTagTag = (pathParams, options) => mutationFn('delete', '/api/v1/tags/{id}/tipos-tag/{tipoTagId}', pathParams, {}, options)
export const useMutationTagsControllerDeletarTipoTagTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tags/{id}/tipos-tag/{tipoTagId}', pathParams, {}, options), config)

export const mutationTagsControllerCadastrarTagConta = (pathParams, options) => mutationFn('post', '/api/v1/tags/{id}/contas', pathParams, {}, options)
export const useMutationTagsControllerCadastrarTagConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tags/{id}/contas', pathParams, {}, options), config)

export const queryTagsControllerListarTagContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tags/{id}/contas', pathParams, queryParams)
export const useQueryTagsControllerListarTagContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTagsControllerListarTagContas.queryKey = '/api/v1/tags/{id}/contas'

export const queryTagsControllerObterTagContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tags/{id}/contas/{contaId}', pathParams)
export const useQueryTagsControllerObterTagContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTagsControllerObterTagContaPorId.queryKey = '/api/v1/tags/{id}/contas/{contaId}'

export const mutationTagsControllerAtualizarTagConta = (pathParams, options) => mutationFn('put', '/api/v1/tags/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTagsControllerAtualizarTagConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tags/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTagsControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tags/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTagsControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tags/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTagsControllerListarContasDisponiveis.queryKey = '/api/v1/tags/{id}/contas-disponiveis'

export const queryTarefaCheckListsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tarefa-check-lists/{id}', pathParams)
export const useQueryTarefaCheckListsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefa-check-lists/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefaCheckListsControllerObterPorId.queryKey = '/api/v1/tarefa-check-lists/{id}'

export const mutationTarefaCheckListsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationTarefaCheckListsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationTarefaCheckListsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationTarefaCheckListsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tarefa-check-lists/{id}', pathParams, {}, options), config)

export const queryTarefasControllerObterTarefas = (queryParams, options) => queryFn(options)('/api/v1/tarefas', queryParams)
export const useQueryTarefasControllerObterTarefas = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTarefasControllerObterTarefas.queryKey = '/api/v1/tarefas'

export const mutationTarefasControllerCadastrarTarefa = (options) => mutationFn('post', '/api/v1/tarefas', {}, {}, options)
export const useMutationTarefasControllerCadastrarTarefa = (config, options) => useMutation(mutationFn('post', '/api/v1/tarefas', {}, {}, options), config)

export const queryTarefasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tarefas/{id}', pathParams)
export const useQueryTarefasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefasControllerObterPorId.queryKey = '/api/v1/tarefas/{id}'

export const mutationTarefasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tarefas/{id}', pathParams, {}, options)
export const useMutationTarefasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tarefas/{id}', pathParams, {}, options), config)

export const mutationTarefasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tarefas/{id}', pathParams, {}, options)
export const useMutationTarefasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tarefas/{id}', pathParams, {}, options), config)

export const mutationTarefasControllerAtualizarConclusao = (pathParams, options) => mutationFn('put', '/api/v1/tarefas/{id}/conclusao', pathParams, {}, options)
export const useMutationTarefasControllerAtualizarConclusao = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tarefas/{id}/conclusao', pathParams, {}, options), config)

export const mutationTarefasControllerAtualizarFaseFunil = (pathParams, options) => mutationFn('put', '/api/v1/tarefas/{id}/mudar-fase', pathParams, {}, options)
export const useMutationTarefasControllerAtualizarFaseFunil = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tarefas/{id}/mudar-fase', pathParams, {}, options), config)

export const queryTarefasControllerBuscarCheckList = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tarefas/{id}/check-lists', pathParams, queryParams)
export const useQueryTarefasControllerBuscarCheckList = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/{id}/check-lists', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefasControllerBuscarCheckList.queryKey = '/api/v1/tarefas/{id}/check-lists'

export const mutationTarefasControllerCadastrarCheckList = (pathParams, options) => mutationFn('post', '/api/v1/tarefas/{id}/check-lists', pathParams, {}, options)
export const useMutationTarefasControllerCadastrarCheckList = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tarefas/{id}/check-lists', pathParams, {}, options), config)

export const mutationTarefasControllerCadastrarTarefaTag = (pathParams, options) => mutationFn('post', '/api/v1/tarefas/{id}/tags', pathParams, {}, options)
export const useMutationTarefasControllerCadastrarTarefaTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tarefas/{id}/tags', pathParams, {}, options), config)

export const queryTarefasControllerListarTagsTarefa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tarefas/{id}/tags', pathParams, queryParams)
export const useQueryTarefasControllerListarTagsTarefa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefasControllerListarTagsTarefa.queryKey = '/api/v1/tarefas/{id}/tags'

export const mutationTarefasControllerDeletarTarefaTag = (pathParams, options) => mutationFn('delete', '/api/v1/tarefas/{id}/tag/{tagId}', pathParams, {}, options)
export const useMutationTarefasControllerDeletarTarefaTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tarefas/{id}/tag/{tagId}', pathParams, {}, options), config)

export const queryTarefasControllerListarTarefasTags = (queryParams, options) => queryFn(options)('/api/v1/tarefas/tags', queryParams)
export const useQueryTarefasControllerListarTarefasTags = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/tags', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTarefasControllerListarTarefasTags.queryKey = '/api/v1/tarefas/tags'

export const queryTarefasControllerListarTagsDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tarefas/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryTarefasControllerListarTagsDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tarefas/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTarefasControllerListarTagsDisponiveis.queryKey = '/api/v1/tarefas/{id}/tags-disponiveis'

export const mutationTecnicasEnsinoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tecnicas-ensino/{id}', pathParams, {}, options)
export const useMutationTecnicasEnsinoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tecnicas-ensino/{id}', pathParams, {}, options), config)

export const queryTecnicasEnsinoControllerObterTecnicaEnsino = (pathParams, options) => queryFn(options)('/api/v1/tecnicas-ensino/{id}', pathParams)
export const useQueryTecnicasEnsinoControllerObterTecnicaEnsino = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tecnicas-ensino/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTecnicasEnsinoControllerObterTecnicaEnsino.queryKey = '/api/v1/tecnicas-ensino/{id}'

export const queryTemplateNotificacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/templates-notificacao', queryParams)
export const useQueryTemplateNotificacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplateNotificacaoControllerBuscar.queryKey = '/api/v1/templates-notificacao'

export const mutationTemplateNotificacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/templates-notificacao', {}, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao', {}, {}, options), config)

export const queryTemplateNotificacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}', pathParams)
export const useQueryTemplateNotificacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerObterPorId.queryKey = '/api/v1/templates-notificacao/{id}'

export const mutationTemplateNotificacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/templates-notificacao/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-notificacao/{id}', pathParams, {}, options), config)

export const mutationTemplateNotificacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-notificacao/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-notificacao/{id}', pathParams, {}, options), config)

export const mutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/templates-notificacao/{id}/contas', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao/{id}/contas', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerListarTemplateNotificacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/contas', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarTemplateNotificacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarTemplateNotificacaoContas.queryKey = '/api/v1/templates-notificacao/{id}/contas'

export const queryTemplateNotificacaoControllerObterTemplateNotificacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/contas/{contaId}', pathParams)
export const useQueryTemplateNotificacaoControllerObterTemplateNotificacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTemplateNotificacaoControllerObterTemplateNotificacaoContaPorId.queryKey = '/api/v1/templates-notificacao/{id}/contas/{contaId}'

export const mutationTemplateNotificacaoControllerAtualizarTemplateNotificacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/templates-notificacao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerAtualizarTemplateNotificacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-notificacao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarContasDisponiveis.queryKey = '/api/v1/templates-notificacao/{id}/contas-disponiveis'

export const queryTemplateNotificacaoControllerListarTemplateNotificacaoCanaisEnvio = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/canais-envio', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarTemplateNotificacaoCanaisEnvio = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/canais-envio', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarTemplateNotificacaoCanaisEnvio.queryKey = '/api/v1/templates-notificacao/{id}/canais-envio'

export const mutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoCanalEnvio = (pathParams, options) => mutationFn('post', '/api/v1/templates-notificacao/{id}/canais-envio', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoCanalEnvio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao/{id}/canais-envio', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerListarTemplateNotificacaoCondicoesEnvio = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/condicoes-envio', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarTemplateNotificacaoCondicoesEnvio = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/condicoes-envio', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarTemplateNotificacaoCondicoesEnvio.queryKey = '/api/v1/templates-notificacao/{id}/condicoes-envio'

export const mutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoCondicaoEnvio = (pathParams, options) => mutationFn('post', '/api/v1/templates-notificacao/{id}/condicoes-envio', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoCondicaoEnvio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao/{id}/condicoes-envio', pathParams, {}, options), config)

export const queryTemplateNotificacaoControllerListarTemplateNotificacaoDestinatarios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-notificacao/{id}/destinatarios', pathParams, queryParams)
export const useQueryTemplateNotificacaoControllerListarTemplateNotificacaoDestinatarios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-notificacao/{id}/destinatarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoControllerListarTemplateNotificacaoDestinatarios.queryKey = '/api/v1/templates-notificacao/{id}/destinatarios'

export const mutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoDestinatario = (pathParams, options) => mutationFn('post', '/api/v1/templates-notificacao/{id}/destinatarios', pathParams, {}, options)
export const useMutationTemplateNotificacaoControllerCadastrarTemplateNotificacaoDestinatario = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-notificacao/{id}/destinatarios', pathParams, {}, options), config)

export const queryTemplateNotificacaoCanaisEnvioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/template-notificacao-canais-envio/{id}', pathParams)
export const useQueryTemplateNotificacaoCanaisEnvioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-notificacao-canais-envio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoCanaisEnvioControllerObterPorId.queryKey = '/api/v1/template-notificacao-canais-envio/{id}'

export const mutationTemplateNotificacaoCanaisEnvioControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/template-notificacao-canais-envio/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoCanaisEnvioControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/template-notificacao-canais-envio/{id}', pathParams, {}, options), config)

export const mutationTemplateNotificacaoCanaisEnvioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/template-notificacao-canais-envio/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoCanaisEnvioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/template-notificacao-canais-envio/{id}', pathParams, {}, options), config)

export const queryTemplateNotificacaoCondicoesEnvioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/template-notificacao-condicoes-envio/{id}', pathParams)
export const useQueryTemplateNotificacaoCondicoesEnvioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-notificacao-condicoes-envio/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoCondicoesEnvioControllerObterPorId.queryKey = '/api/v1/template-notificacao-condicoes-envio/{id}'

export const mutationTemplateNotificacaoCondicoesEnvioControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/template-notificacao-condicoes-envio/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoCondicoesEnvioControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/template-notificacao-condicoes-envio/{id}', pathParams, {}, options), config)

export const mutationTemplateNotificacaoCondicoesEnvioControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/template-notificacao-condicoes-envio/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoCondicoesEnvioControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/template-notificacao-condicoes-envio/{id}', pathParams, {}, options), config)

export const queryTemplateNotificacaoDestinatariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/template-notificacao-destinatarios/{id}', pathParams)
export const useQueryTemplateNotificacaoDestinatariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-notificacao-destinatarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateNotificacaoDestinatariosControllerObterPorId.queryKey = '/api/v1/template-notificacao-destinatarios/{id}'

export const mutationTemplateNotificacaoDestinatariosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/template-notificacao-destinatarios/{id}', pathParams, {}, options)
export const useMutationTemplateNotificacaoDestinatariosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/template-notificacao-destinatarios/{id}', pathParams, {}, options), config)

export const queryTemplatesAvaliacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/templates-avaliacao', queryParams)
export const useQueryTemplatesAvaliacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplatesAvaliacaoControllerListar.queryKey = '/api/v1/templates-avaliacao'

export const mutationTemplatesAvaliacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/templates-avaliacao', {}, {}, options)
export const useMutationTemplatesAvaliacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/templates-avaliacao', {}, {}, options), config)

export const queryTemplatesAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}', pathParams)
export const useQueryTemplatesAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesAvaliacaoControllerObterPorId.queryKey = '/api/v1/templates-avaliacao/{id}'

export const mutationTemplatesAvaliacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/templates-avaliacao/{id}', pathParams, {}, options)
export const useMutationTemplatesAvaliacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-avaliacao/{id}', pathParams, {}, options), config)

export const mutationTemplatesAvaliacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-avaliacao/{id}', pathParams, {}, options)
export const useMutationTemplatesAvaliacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-avaliacao/{id}', pathParams, {}, options), config)

export const queryTemplatesAvaliacaoControllerBuscarAvaliacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}/avaliacoes', pathParams, queryParams)
export const useQueryTemplatesAvaliacaoControllerBuscarAvaliacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}/avaliacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesAvaliacaoControllerBuscarAvaliacoes.queryKey = '/api/v1/templates-avaliacao/{id}/avaliacoes'

export const mutationTemplatesAvaliacaoControllerCadastrarAvaliacao = (pathParams, options) => mutationFn('post', '/api/v1/templates-avaliacao/{id}/avaliacoes', pathParams, {}, options)
export const useMutationTemplatesAvaliacaoControllerCadastrarAvaliacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-avaliacao/{id}/avaliacoes', pathParams, {}, options), config)

export const queryTemplatesAvaliacaoControllerListarFormulasAvaliacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}/formulas-avaliacoes', pathParams, queryParams)
export const useQueryTemplatesAvaliacaoControllerListarFormulasAvaliacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}/formulas-avaliacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesAvaliacaoControllerListarFormulasAvaliacoes.queryKey = '/api/v1/templates-avaliacao/{id}/formulas-avaliacoes'

export const queryTemplatesAvaliacaoControllerListarAvaliacaoTipoPeriodosNotaDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}/avaliacoes-disponiveis', pathParams, queryParams)
export const useQueryTemplatesAvaliacaoControllerListarAvaliacaoTipoPeriodosNotaDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}/avaliacoes-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesAvaliacaoControllerListarAvaliacaoTipoPeriodosNotaDisponiveis.queryKey = '/api/v1/templates-avaliacao/{id}/avaliacoes-disponiveis'

export const queryTemplatesAvaliacaoControllerListarValoresTemplatesFinaisDisponiveis = (pathParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}/valores-templates', pathParams)
export const useQueryTemplatesAvaliacaoControllerListarValoresTemplatesFinaisDisponiveis = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}/valores-templates', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesAvaliacaoControllerListarValoresTemplatesFinaisDisponiveis.queryKey = '/api/v1/templates-avaliacao/{id}/valores-templates'

export const queryTemplatesAvaliacaoControllerBuscarArredondamentoNota = (pathParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}/arredondamentos-de-notas', pathParams)
export const useQueryTemplatesAvaliacaoControllerBuscarArredondamentoNota = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}/arredondamentos-de-notas', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesAvaliacaoControllerBuscarArredondamentoNota.queryKey = '/api/v1/templates-avaliacao/{id}/arredondamentos-de-notas'

export const mutationTemplatesAvaliacaoControllerCadastrarArredondamentoNota = (pathParams, options) => mutationFn('post', '/api/v1/templates-avaliacao/{id}/arredondamentos-de-notas', pathParams, {}, options)
export const useMutationTemplatesAvaliacaoControllerCadastrarArredondamentoNota = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-avaliacao/{id}/arredondamentos-de-notas', pathParams, {}, options), config)

export const mutationTemplatesAvaliacaoControllerCadastrarTemplateAvaliacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/templates-avaliacao/{id}/contas', pathParams, {}, options)
export const useMutationTemplatesAvaliacaoControllerCadastrarTemplateAvaliacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-avaliacao/{id}/contas', pathParams, {}, options), config)

export const queryTemplatesAvaliacaoControllerListarTemplateAvaliacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}/contas', pathParams, queryParams)
export const useQueryTemplatesAvaliacaoControllerListarTemplateAvaliacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesAvaliacaoControllerListarTemplateAvaliacaoContas.queryKey = '/api/v1/templates-avaliacao/{id}/contas'

export const queryTemplatesAvaliacaoControllerObterTemplateAvaliacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}/contas/{contaId}', pathParams)
export const useQueryTemplatesAvaliacaoControllerObterTemplateAvaliacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTemplatesAvaliacaoControllerObterTemplateAvaliacaoContaPorId.queryKey = '/api/v1/templates-avaliacao/{id}/contas/{contaId}'

export const mutationTemplatesAvaliacaoControllerAtualizarTemplateAvaliacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/templates-avaliacao/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTemplatesAvaliacaoControllerAtualizarTemplateAvaliacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-avaliacao/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTemplatesAvaliacaoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-avaliacao/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTemplatesAvaliacaoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-avaliacao/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesAvaliacaoControllerListarContasDisponiveis.queryKey = '/api/v1/templates-avaliacao/{id}/contas-disponiveis'

export const mutationTemplatesAvaliacaoControllerAtualizarOrdemCampos = (pathParams, options) => mutationFn('patch', '/api/v1/templates-avaliacao/{id}/avaliacoes/ordenacao', pathParams, {}, options)
export const useMutationTemplatesAvaliacaoControllerAtualizarOrdemCampos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/templates-avaliacao/{id}/avaliacoes/ordenacao', pathParams, {}, options), config)

export const mutationTemplatesComerciaisControllerCadastrarTemplateComercialConta = (pathParams, options) => mutationFn('post', '/api/v1/templates-comerciais/{id}/contas', pathParams, {}, options)
export const useMutationTemplatesComerciaisControllerCadastrarTemplateComercialConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-comerciais/{id}/contas', pathParams, {}, options), config)

export const queryTemplatesComerciaisControllerListarTemplateComercialContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}/contas', pathParams, queryParams)
export const useQueryTemplatesComerciaisControllerListarTemplateComercialContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComerciaisControllerListarTemplateComercialContas.queryKey = '/api/v1/templates-comerciais/{id}/contas'

export const queryTemplatesComerciaisControllerObterTemplateComercialContaPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}/contas/{contaId}', pathParams)
export const useQueryTemplatesComerciaisControllerObterTemplateComercialContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTemplatesComerciaisControllerObterTemplateComercialContaPorId.queryKey = '/api/v1/templates-comerciais/{id}/contas/{contaId}'

export const mutationTemplatesComerciaisControllerAtualizarTemplateComercialConta = (pathParams, options) => mutationFn('put', '/api/v1/templates-comerciais/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTemplatesComerciaisControllerAtualizarTemplateComercialConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-comerciais/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTemplatesComerciaisControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTemplatesComerciaisControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComerciaisControllerListarContasDisponiveis.queryKey = '/api/v1/templates-comerciais/{id}/contas-disponiveis'

export const mutationTemplatesComerciaisEducacaoControllerCadastrar = (options) => mutationFn('post', '/api/v1/templates-comerciais', {}, {}, options)
export const useMutationTemplatesComerciaisEducacaoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/templates-comerciais', {}, {}, options), config)

export const queryTemplatesComerciaisEducacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/templates-comerciais', queryParams)
export const useQueryTemplatesComerciaisEducacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplatesComerciaisEducacaoControllerListar.queryKey = '/api/v1/templates-comerciais'

export const mutationTemplatesComerciaisEducacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/templates-comerciais/{id}', pathParams, {}, options)
export const useMutationTemplatesComerciaisEducacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-comerciais/{id}', pathParams, {}, options), config)

export const mutationTemplatesComerciaisEducacaoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-comerciais/{id}', pathParams, {}, options)
export const useMutationTemplatesComerciaisEducacaoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-comerciais/{id}', pathParams, {}, options), config)

export const queryTemplatesComerciaisEducacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}', pathParams)
export const useQueryTemplatesComerciaisEducacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComerciaisEducacaoControllerObterPorId.queryKey = '/api/v1/templates-comerciais/{id}'

export const queryTemplatesComerciaisEducacaoControllerBuscarTemplateComercimalItem = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-comerciais/{id}/itens', pathParams, queryParams)
export const useQueryTemplatesComerciaisEducacaoControllerBuscarTemplateComercimalItem = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais/{id}/itens', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComerciaisEducacaoControllerBuscarTemplateComercimalItem.queryKey = '/api/v1/templates-comerciais/{id}/itens'

export const mutationTemplatesComerciaisEducacaoControllerCadastrarTemplateComercialItem = (pathParams, options) => mutationFn('post', '/api/v1/templates-comerciais/{id}/itens', pathParams, {}, options)
export const useMutationTemplatesComerciaisEducacaoControllerCadastrarTemplateComercialItem = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-comerciais/{id}/itens', pathParams, {}, options), config)

export const queryTemplatesComercialItensControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-comerciais-itens/{id}', pathParams)
export const useQueryTemplatesComercialItensControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-comerciais-itens/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplatesComercialItensControllerObterPorId.queryKey = '/api/v1/templates-comerciais-itens/{id}'

export const mutationTemplatesComercialItensControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-comerciais-itens/{id}', pathParams, {}, options)
export const useMutationTemplatesComercialItensControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-comerciais-itens/{id}', pathParams, {}, options), config)

export const queryTemplateTarefaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/templates-tarefa', queryParams)
export const useQueryTemplateTarefaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplateTarefaControllerBuscar.queryKey = '/api/v1/templates-tarefa'

export const mutationTemplateTarefaControllerCadastrar = (options) => mutationFn('post', '/api/v1/templates-tarefa', {}, {}, options)
export const useMutationTemplateTarefaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/templates-tarefa', {}, {}, options), config)

export const queryTemplateTarefaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}', pathParams)
export const useQueryTemplateTarefaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaControllerObterPorId.queryKey = '/api/v1/templates-tarefa/{id}'

export const mutationTemplateTarefaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/templates-tarefa/{id}', pathParams, {}, options)
export const useMutationTemplateTarefaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-tarefa/{id}', pathParams, {}, options), config)

export const mutationTemplateTarefaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/templates-tarefa/{id}', pathParams, {}, options)
export const useMutationTemplateTarefaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/templates-tarefa/{id}', pathParams, {}, options), config)

export const queryTemplateTarefaControllerListarCheckListsTarefa = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}/checklists', pathParams, queryParams)
export const useQueryTemplateTarefaControllerListarCheckListsTarefa = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}/checklists', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaControllerListarCheckListsTarefa.queryKey = '/api/v1/templates-tarefa/{id}/checklists'

export const mutationTemplateTarefaControllerCadastrarTemplateTarefaConta = (pathParams, options) => mutationFn('post', '/api/v1/templates-tarefa/{id}/contas', pathParams, {}, options)
export const useMutationTemplateTarefaControllerCadastrarTemplateTarefaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/templates-tarefa/{id}/contas', pathParams, {}, options), config)

export const queryTemplateTarefaControllerListarTemplateTarefaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}/contas', pathParams, queryParams)
export const useQueryTemplateTarefaControllerListarTemplateTarefaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaControllerListarTemplateTarefaContas.queryKey = '/api/v1/templates-tarefa/{id}/contas'

export const queryTemplateTarefaControllerObterTemplateTarefaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}/contas/{contaId}', pathParams)
export const useQueryTemplateTarefaControllerObterTemplateTarefaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTemplateTarefaControllerObterTemplateTarefaContaPorId.queryKey = '/api/v1/templates-tarefa/{id}/contas/{contaId}'

export const mutationTemplateTarefaControllerAtualizarTemplateTarefaConta = (pathParams, options) => mutationFn('put', '/api/v1/templates-tarefa/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTemplateTarefaControllerAtualizarTemplateTarefaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/templates-tarefa/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTemplateTarefaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/templates-tarefa/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTemplateTarefaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/templates-tarefa/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaControllerListarContasDisponiveis.queryKey = '/api/v1/templates-tarefa/{id}/contas-disponiveis'

export const queryTemplateTarefaCheckListsControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/template-tarefa-check-lists', queryParams)
export const useQueryTemplateTarefaCheckListsControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-tarefa-check-lists', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemplateTarefaCheckListsControllerBuscar.queryKey = '/api/v1/template-tarefa-check-lists'

export const mutationTemplateTarefaCheckListsControllerCadastrar = (options) => mutationFn('post', '/api/v1/template-tarefa-check-lists', {}, {}, options)
export const useMutationTemplateTarefaCheckListsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/template-tarefa-check-lists', {}, {}, options), config)

export const queryTemplateTarefaCheckListsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/template-tarefa-check-lists/{id}', pathParams)
export const useQueryTemplateTarefaCheckListsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/template-tarefa-check-lists/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemplateTarefaCheckListsControllerObterPorId.queryKey = '/api/v1/template-tarefa-check-lists/{id}'

export const mutationTemplateTarefaCheckListsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/template-tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationTemplateTarefaCheckListsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/template-tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationTemplateTarefaCheckListsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/template-tarefa-check-lists/{id}', pathParams, {}, options)
export const useMutationTemplateTarefaCheckListsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/template-tarefa-check-lists/{id}', pathParams, {}, options), config)

export const mutationTempoDuracaoAtividadesControllerCadastrar = (options) => mutationFn('post', '/api/v1/tempo-de-duracao-de-atividades', {}, {}, options)
export const useMutationTempoDuracaoAtividadesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tempo-de-duracao-de-atividades', {}, {}, options), config)

export const queryTempoDuracaoAtividadesControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades', queryParams)
export const useQueryTempoDuracaoAtividadesControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTempoDuracaoAtividadesControllerBuscar.queryKey = '/api/v1/tempo-de-duracao-de-atividades'

export const mutationTempoDuracaoAtividadesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams, {}, options)
export const useMutationTempoDuracaoAtividadesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams, {}, options), config)

export const mutationTempoDuracaoAtividadesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams, {}, options)
export const useMutationTempoDuracaoAtividadesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams, {}, options), config)

export const queryTempoDuracaoAtividadesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams)
export const useQueryTempoDuracaoAtividadesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoDuracaoAtividadesControllerObterPorId.queryKey = '/api/v1/tempo-de-duracao-de-atividades/{id}'

export const queryTempoDuracaoAtividadesControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/buscar-detalhado/{id}', pathParams)
export const useQueryTempoDuracaoAtividadesControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoDuracaoAtividadesControllerObterPorIdDetalhado.queryKey = '/api/v1/tempo-de-duracao-de-atividades/buscar-detalhado/{id}'

export const mutationTempoDuracaoAtividadesControllerCadastrarTempoDuracaoAtividadeConta = (pathParams, options) => mutationFn('post', '/api/v1/tempo-de-duracao-de-atividades/{id}/contas', pathParams, {}, options)
export const useMutationTempoDuracaoAtividadesControllerCadastrarTempoDuracaoAtividadeConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tempo-de-duracao-de-atividades/{id}/contas', pathParams, {}, options), config)

export const queryTempoDuracaoAtividadesControllerListarTempoDuracaoAtividadeContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/{id}/contas', pathParams, queryParams)
export const useQueryTempoDuracaoAtividadesControllerListarTempoDuracaoAtividadeContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoDuracaoAtividadesControllerListarTempoDuracaoAtividadeContas.queryKey = '/api/v1/tempo-de-duracao-de-atividades/{id}/contas'

export const queryTempoDuracaoAtividadesControllerObterTempoDuracaoAtividadeContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}', pathParams)
export const useQueryTempoDuracaoAtividadesControllerObterTempoDuracaoAtividadeContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTempoDuracaoAtividadesControllerObterTempoDuracaoAtividadeContaPorId.queryKey = '/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}'

export const mutationTempoDuracaoAtividadesControllerAtualizarTempoDuracaoAtividadeConta = (pathParams, options) => mutationFn('put', '/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTempoDuracaoAtividadesControllerAtualizarTempoDuracaoAtividadeConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tempo-de-duracao-de-atividades/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTempoDuracaoAtividadesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tempo-de-duracao-de-atividades/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTempoDuracaoAtividadesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-duracao-de-atividades/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoDuracaoAtividadesControllerListarContasDisponiveis.queryKey = '/api/v1/tempo-de-duracao-de-atividades/{id}/contas-disponiveis'

export const mutationTempoEventosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tempo-de-eventos/{id}', pathParams, {}, options)
export const useMutationTempoEventosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tempo-de-eventos/{id}', pathParams, {}, options), config)

export const mutationTempoEventosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tempo-de-eventos/{id}', pathParams, {}, options)
export const useMutationTempoEventosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tempo-de-eventos/{id}', pathParams, {}, options), config)

export const queryTempoEventosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-eventos/{id}', pathParams)
export const useQueryTempoEventosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-eventos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoEventosControllerObterPorId.queryKey = '/api/v1/tempo-de-eventos/{id}'

export const queryTempoEventosControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/tempo-de-eventos/buscar-detalhado/{id}', pathParams)
export const useQueryTempoEventosControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-de-eventos/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoEventosControllerObterPorIdDetalhado.queryKey = '/api/v1/tempo-de-eventos/buscar-detalhado/{id}'

export const queryTempoExpiracaoLinkPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tempo-expiracao-link-pagamento', queryParams)
export const useQueryTempoExpiracaoLinkPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-expiracao-link-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTempoExpiracaoLinkPagamentoControllerListar.queryKey = '/api/v1/tempo-expiracao-link-pagamento'

export const queryTempoExpiracaoLinkPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tempo-expiracao-link-pagamento/{id}', pathParams)
export const useQueryTempoExpiracaoLinkPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-expiracao-link-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTempoExpiracaoLinkPagamentoControllerObterPorId.queryKey = '/api/v1/tempo-expiracao-link-pagamento/{id}'

export const queryTempoExpiracaoLinkPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tempo-expiracao-link-pagamento/{chave}', pathParams)
export const useQueryTempoExpiracaoLinkPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempo-expiracao-link-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTempoExpiracaoLinkPagamentoControllerObterPorChave.queryKey = '/api/v1/tempo-expiracao-link-pagamento/{chave}'

export const queryTemposPrevistosRetornoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tempos-previstos-de-retorno', queryParams)
export const useQueryTemposPrevistosRetornoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempos-previstos-de-retorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTemposPrevistosRetornoControllerListar.queryKey = '/api/v1/tempos-previstos-de-retorno'

export const mutationTemposPrevistosRetornoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tempos-previstos-de-retorno', {}, {}, options)
export const useMutationTemposPrevistosRetornoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tempos-previstos-de-retorno', {}, {}, options), config)

export const queryTemposPrevistosRetornoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tempos-previstos-de-retorno/{id}', pathParams)
export const useQueryTemposPrevistosRetornoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tempos-previstos-de-retorno/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTemposPrevistosRetornoControllerObterPorId.queryKey = '/api/v1/tempos-previstos-de-retorno/{id}'

export const mutationTemposPrevistosRetornoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tempos-previstos-de-retorno/{id}', pathParams, {}, options)
export const useMutationTemposPrevistosRetornoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tempos-previstos-de-retorno/{id}', pathParams, {}, options), config)

export const mutationTemposPrevistosRetornoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tempos-previstos-de-retorno/{id}', pathParams, {}, options)
export const useMutationTemposPrevistosRetornoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tempos-previstos-de-retorno/{id}', pathParams, {}, options), config)

export const queryTenantsControllerMeusModulosFeatures = (options) => queryFn(options)('/api/v1/tenants/eu/modulos-features')
export const useQueryTenantsControllerMeusModulosFeatures = (config, options) => useQuery({
  queryKey: ['/api/v1/tenants/eu/modulos-features'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerMeusModulosFeatures.queryKey = '/api/v1/tenants/eu/modulos-features'

export const queryTenantsControllerObterAppLiberado = (options) => queryFn(options)('/api/v1/tenants/eu/app-liberado')
export const useQueryTenantsControllerObterAppLiberado = (config, options) => useQuery({
  queryKey: ['/api/v1/tenants/eu/app-liberado'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTenantsControllerObterAppLiberado.queryKey = '/api/v1/tenants/eu/app-liberado'

export const queryTiposAcrescimoDescontoTituloTituloControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-acrescimo-desconto-titulo', queryParams)
export const useQueryTiposAcrescimoDescontoTituloTituloControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-acrescimo-desconto-titulo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposAcrescimoDescontoTituloTituloControllerListar.queryKey = '/api/v1/tipos-de-acrescimo-desconto-titulo'

export const queryTiposAcrescimoDescontoTituloTituloControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-acrescimo-desconto-titulo/{id}', pathParams)
export const useQueryTiposAcrescimoDescontoTituloTituloControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-acrescimo-desconto-titulo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAcrescimoDescontoTituloTituloControllerObterPorId.queryKey = '/api/v1/tipos-de-acrescimo-desconto-titulo/{id}'

export const queryTiposAcrescimoDescontoTituloTituloControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-acrescimo-desconto-titulo/{chave}', pathParams)
export const useQueryTiposAcrescimoDescontoTituloTituloControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-acrescimo-desconto-titulo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposAcrescimoDescontoTituloTituloControllerObterPorChave.queryKey = '/api/v1/tipos-de-acrescimo-desconto-titulo/{chave}'

export const queryTiposAgendamentosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-agendamentos', queryParams)
export const useQueryTiposAgendamentosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposAgendamentosControllerBuscar.queryKey = '/api/v1/tipos-agendamentos'

export const mutationTiposAgendamentosControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-agendamentos', {}, {}, options)
export const useMutationTiposAgendamentosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-agendamentos', {}, {}, options), config)

export const queryTiposAgendamentosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-agendamentos/{id}', pathParams)
export const useQueryTiposAgendamentosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAgendamentosControllerObterPorId.queryKey = '/api/v1/tipos-agendamentos/{id}'

export const mutationTiposAgendamentosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-agendamentos/{id}', pathParams, {}, options)
export const useMutationTiposAgendamentosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-agendamentos/{id}', pathParams, {}, options), config)

export const mutationTiposAgendamentosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-agendamentos/{id}', pathParams, {}, options)
export const useMutationTiposAgendamentosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-agendamentos/{id}', pathParams, {}, options), config)

export const mutationTiposAgendamentosControllerCadastrarTipoAgendamentoConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-agendamentos/{id}/contas', pathParams, {}, options)
export const useMutationTiposAgendamentosControllerCadastrarTipoAgendamentoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-agendamentos/{id}/contas', pathParams, {}, options), config)

export const queryTiposAgendamentosControllerListarTipoAgendamentoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-agendamentos/{id}/contas', pathParams, queryParams)
export const useQueryTiposAgendamentosControllerListarTipoAgendamentoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAgendamentosControllerListarTipoAgendamentoContas.queryKey = '/api/v1/tipos-agendamentos/{id}/contas'

export const queryTiposAgendamentosControllerObterTipoAgendamentoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-agendamentos/{id}/contas/{contaId}', pathParams)
export const useQueryTiposAgendamentosControllerObterTipoAgendamentoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposAgendamentosControllerObterTipoAgendamentoContaPorId.queryKey = '/api/v1/tipos-agendamentos/{id}/contas/{contaId}'

export const mutationTiposAgendamentosControllerAtualizarTipoAgendamentoConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-agendamentos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposAgendamentosControllerAtualizarTipoAgendamentoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-agendamentos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposAgendamentosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-agendamentos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposAgendamentosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-agendamentos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposAgendamentosControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-agendamentos/{id}/contas-disponiveis'

export const queryTiposArredondamentoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-arredondamento', queryParams)
export const useQueryTiposArredondamentoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-arredondamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposArredondamentoControllerBuscar.queryKey = '/api/v1/tipos-arredondamento'

export const queryTiposArredondamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-arredondamento/{id}', pathParams)
export const useQueryTiposArredondamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-arredondamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposArredondamentoControllerObterPorId.queryKey = '/api/v1/tipos-arredondamento/{id}'

export const queryTiposArredondamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-arredondamento/{chave}', pathParams)
export const useQueryTiposArredondamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-arredondamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposArredondamentoControllerObterPorChave.queryKey = '/api/v1/tipos-arredondamento/{chave}'

export const queryTiposBibliografiaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-bibliografia', queryParams)
export const useQueryTiposBibliografiaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-bibliografia', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposBibliografiaControllerListar.queryKey = '/api/v1/tipos-de-bibliografia'

export const queryTiposBibliografiaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-bibliografia/{id}', pathParams)
export const useQueryTiposBibliografiaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-bibliografia/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposBibliografiaControllerObterPorId.queryKey = '/api/v1/tipos-de-bibliografia/{id}'

export const queryTiposBibliografiaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-bibliografia/{chave}', pathParams)
export const useQueryTiposBibliografiaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-bibliografia/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposBibliografiaControllerObterPorChave.queryKey = '/api/v1/tipos-de-bibliografia/{chave}'

export const queryTiposCalculoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-calculo', queryParams)
export const useQueryTiposCalculoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCalculoControllerListar.queryKey = '/api/v1/tipos-de-calculo'

export const queryTiposCalculoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo/{id}', pathParams)
export const useQueryTiposCalculoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCalculoControllerObterPorId.queryKey = '/api/v1/tipos-de-calculo/{id}'

export const queryTiposCalculoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo/{chave}', pathParams)
export const useQueryTiposCalculoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCalculoControllerObterPorChave.queryKey = '/api/v1/tipos-de-calculo/{chave}'

export const queryTiposCalculoComissaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-de-comissao', queryParams)
export const useQueryTiposCalculoComissaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCalculoComissaoControllerBuscar.queryKey = '/api/v1/tipos-de-calculo-de-comissao'

export const queryTiposCalculoComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-de-comissao/{id}', pathParams)
export const useQueryTiposCalculoComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCalculoComissaoControllerObterPorId.queryKey = '/api/v1/tipos-de-calculo-de-comissao/{id}'

export const queryTiposCalculoComissaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-de-comissao/{chave}', pathParams)
export const useQueryTiposCalculoComissaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-de-comissao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCalculoComissaoControllerObterPorChave.queryKey = '/api/v1/tipos-de-calculo-de-comissao/{chave}'

export const queryTiposCalculoCustoComposicaoItemControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item', queryParams)
export const useQueryTiposCalculoCustoComposicaoItemControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCalculoCustoComposicaoItemControllerBuscar.queryKey = '/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item'

export const queryTiposCalculoCustoComposicaoItemControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{id}', pathParams)
export const useQueryTiposCalculoCustoComposicaoItemControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCalculoCustoComposicaoItemControllerObterPorId.queryKey = '/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{id}'

export const queryTiposCalculoCustoComposicaoItemControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{chave}', pathParams)
export const useQueryTiposCalculoCustoComposicaoItemControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCalculoCustoComposicaoItemControllerObterPorChave.queryKey = '/api/v1/tipos-de-calculo-do-custo-de-composicao-do-item/{chave}'

export const queryTiposCamposControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipo-campo', queryParams)
export const useQueryTiposCamposControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipo-campo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCamposControllerBuscar.queryKey = '/api/v1/tipo-campo'

export const queryTiposClienteControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-cliente', queryParams)
export const useQueryTiposClienteControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-cliente', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposClienteControllerBuscar.queryKey = '/api/v1/tipos-de-cliente'

export const queryTiposClienteControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-cliente/{id}', pathParams)
export const useQueryTiposClienteControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-cliente/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposClienteControllerObterPorId.queryKey = '/api/v1/tipos-de-cliente/{id}'

export const queryTiposClienteControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-cliente/{chave}', pathParams)
export const useQueryTiposClienteControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-cliente/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposClienteControllerObterPorChave.queryKey = '/api/v1/tipos-de-cliente/{chave}'

export const queryTiposComissaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-comissao', queryParams)
export const useQueryTiposComissaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposComissaoControllerBuscar.queryKey = '/api/v1/tipos-de-comissao'

export const queryTiposComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-comissao/{id}', pathParams)
export const useQueryTiposComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposComissaoControllerObterPorId.queryKey = '/api/v1/tipos-de-comissao/{id}'

export const queryTiposComissaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-comissao/{chave}', pathParams)
export const useQueryTiposComissaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-comissao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposComissaoControllerObterPorChave.queryKey = '/api/v1/tipos-de-comissao/{chave}'

export const queryTiposCondicaoPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-condicao-pagamento', queryParams)
export const useQueryTiposCondicaoPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-condicao-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCondicaoPagamentoControllerListar.queryKey = '/api/v1/tipos-de-condicao-pagamento'

export const queryTiposCondicaoPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-condicao-pagamento/{id}', pathParams)
export const useQueryTiposCondicaoPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-condicao-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCondicaoPagamentoControllerObterPorId.queryKey = '/api/v1/tipos-de-condicao-pagamento/{id}'

export const queryTiposCondicaoPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-condicao-pagamento/{chave}', pathParams)
export const useQueryTiposCondicaoPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-condicao-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCondicaoPagamentoControllerObterPorChave.queryKey = '/api/v1/tipos-de-condicao-pagamento/{chave}'

export const queryTiposContaBancariaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-conta-bancaria', queryParams)
export const useQueryTiposContaBancariaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-conta-bancaria', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposContaBancariaControllerListar.queryKey = '/api/v1/tipos-conta-bancaria'

export const mutationTiposContatoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-contato', {}, {}, options)
export const useMutationTiposContatoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-contato', {}, {}, options), config)

export const queryTiposContatoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-contato', queryParams)
export const useQueryTiposContatoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposContatoControllerListar.queryKey = '/api/v1/tipos-de-contato'

export const queryTiposContatoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}', pathParams)
export const useQueryTiposContatoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposContatoControllerObterPorId.queryKey = '/api/v1/tipos-de-contato/{id}'

export const mutationTiposContatoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options)
export const useMutationTiposContatoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options), config)

export const mutationTiposContatoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options)
export const useMutationTiposContatoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-contato/{id}', pathParams, {}, options), config)

export const mutationTiposContatoControllerCadastrarTipoContatoConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-contato/{id}/contas', pathParams, {}, options)
export const useMutationTiposContatoControllerCadastrarTipoContatoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-contato/{id}/contas', pathParams, {}, options), config)

export const queryTiposContatoControllerListarTipoContatoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}/contas', pathParams, queryParams)
export const useQueryTiposContatoControllerListarTipoContatoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposContatoControllerListarTipoContatoContas.queryKey = '/api/v1/tipos-de-contato/{id}/contas'

export const queryTiposContatoControllerObterTipoContatoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}/contas/{contaId}', pathParams)
export const useQueryTiposContatoControllerObterTipoContatoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposContatoControllerObterTipoContatoContaPorId.queryKey = '/api/v1/tipos-de-contato/{id}/contas/{contaId}'

export const mutationTiposContatoControllerAtualizarTipoContatoConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-contato/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposContatoControllerAtualizarTipoContatoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-contato/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposContatoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-contato/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposContatoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contato/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposContatoControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-contato/{id}/contas-disponiveis'

export const queryTiposContratoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-contrato', queryParams)
export const useQueryTiposContratoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contrato', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposContratoControllerListar.queryKey = '/api/v1/tipos-de-contrato'

export const queryTiposContratoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-contrato/{id}', pathParams)
export const useQueryTiposContratoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contrato/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposContratoControllerObterPorId.queryKey = '/api/v1/tipos-de-contrato/{id}'

export const queryTiposContratoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-contrato/{chave}', pathParams)
export const useQueryTiposContratoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-contrato/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposContratoControllerObterPorChave.queryKey = '/api/v1/tipos-de-contrato/{chave}'

export const queryTiposControleFormaPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-controle-da-forma-de-pagamento', queryParams)
export const useQueryTiposControleFormaPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-controle-da-forma-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposControleFormaPagamentoControllerListar.queryKey = '/api/v1/tipos-de-controle-da-forma-de-pagamento'

export const queryTiposControleFormaPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-controle-da-forma-de-pagamento/{id}', pathParams)
export const useQueryTiposControleFormaPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-controle-da-forma-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposControleFormaPagamentoControllerObterPorId.queryKey = '/api/v1/tipos-de-controle-da-forma-de-pagamento/{id}'

export const queryTiposControleFormaPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-controle-da-forma-de-pagamento/{chave}', pathParams)
export const useQueryTiposControleFormaPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-controle-da-forma-de-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposControleFormaPagamentoControllerObterPorChave.queryKey = '/api/v1/tipos-de-controle-da-forma-de-pagamento/{chave}'

export const queryTiposConversaoEmbalagemControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-conversao-de-embalagem', queryParams)
export const useQueryTiposConversaoEmbalagemControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-conversao-de-embalagem', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposConversaoEmbalagemControllerBuscar.queryKey = '/api/v1/tipos-de-conversao-de-embalagem'

export const queryTiposConversaoEmbalagemControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-conversao-de-embalagem/{id}', pathParams)
export const useQueryTiposConversaoEmbalagemControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-conversao-de-embalagem/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposConversaoEmbalagemControllerObterPorId.queryKey = '/api/v1/tipos-de-conversao-de-embalagem/{id}'

export const queryTiposConversaoEmbalagemControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-conversao-de-embalagem/{chave}', pathParams)
export const useQueryTiposConversaoEmbalagemControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-conversao-de-embalagem/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposConversaoEmbalagemControllerObterPorChave.queryKey = '/api/v1/tipos-de-conversao-de-embalagem/{chave}'

export const queryTiposCursoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-curso', queryParams)
export const useQueryTiposCursoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-curso', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCursoControllerBuscar.queryKey = '/api/v1/tipos-de-curso'

export const mutationTiposCursoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-curso', {}, {}, options)
export const useMutationTiposCursoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-curso', {}, {}, options), config)

export const queryTiposCursoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-curso/{id}', pathParams)
export const useQueryTiposCursoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-curso/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCursoControllerObterPorId.queryKey = '/api/v1/tipos-de-curso/{id}'

export const mutationTiposCursoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-curso/{id}', pathParams, {}, options)
export const useMutationTiposCursoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-curso/{id}', pathParams, {}, options), config)

export const mutationTiposCursoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-curso/{id}', pathParams, {}, options)
export const useMutationTiposCursoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-curso/{id}', pathParams, {}, options), config)

export const mutationTiposCursoControllerCadastrarTipoCursoConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-curso/{id}/contas', pathParams, {}, options)
export const useMutationTiposCursoControllerCadastrarTipoCursoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-curso/{id}/contas', pathParams, {}, options), config)

export const queryTiposCursoControllerListarTipoCursoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-curso/{id}/contas', pathParams, queryParams)
export const useQueryTiposCursoControllerListarTipoCursoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-curso/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCursoControllerListarTipoCursoContas.queryKey = '/api/v1/tipos-de-curso/{id}/contas'

export const queryTiposCursoControllerObterTipoCursoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-curso/{id}/contas/{contaId}', pathParams)
export const useQueryTiposCursoControllerObterTipoCursoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-curso/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposCursoControllerObterTipoCursoContaPorId.queryKey = '/api/v1/tipos-de-curso/{id}/contas/{contaId}'

export const mutationTiposCursoControllerAtualizarTipoCursoConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-curso/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposCursoControllerAtualizarTipoCursoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-curso/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposCursoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-curso/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposCursoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-curso/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCursoControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-curso/{id}/contas-disponiveis'

export const queryTiposCustoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-custo', queryParams)
export const useQueryTiposCustoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-custo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposCustoControllerBuscar.queryKey = '/api/v1/tipos-de-custo'

export const queryTiposCustoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-custo/{id}', pathParams)
export const useQueryTiposCustoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-custo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposCustoControllerObterPorId.queryKey = '/api/v1/tipos-de-custo/{id}'

export const queryTiposCustoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-custo/{chave}', pathParams)
export const useQueryTiposCustoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-custo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposCustoControllerObterPorChave.queryKey = '/api/v1/tipos-de-custo/{chave}'

export const queryTiposDependenteControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-dependente', queryParams)
export const useQueryTiposDependenteControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-dependente', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDependenteControllerBuscar.queryKey = '/api/v1/tipos-de-dependente'

export const queryTiposDependenteControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-dependente/{id}', pathParams)
export const useQueryTiposDependenteControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-dependente/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDependenteControllerObterPorId.queryKey = '/api/v1/tipos-de-dependente/{id}'

export const queryTiposDependenteControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-dependente/{chave}', pathParams)
export const useQueryTiposDependenteControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-dependente/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposDependenteControllerObterPorChave.queryKey = '/api/v1/tipos-de-dependente/{chave}'

export const queryTiposDescontoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-desconto', queryParams)
export const useQueryTiposDescontoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-desconto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDescontoControllerListar.queryKey = '/api/v1/tipos-de-desconto'

export const queryTiposDescontoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-desconto/{id}', pathParams)
export const useQueryTiposDescontoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-desconto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDescontoControllerObterPorId.queryKey = '/api/v1/tipos-de-desconto/{id}'

export const queryTiposDescontoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-desconto/{chave}', pathParams)
export const useQueryTiposDescontoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-desconto/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposDescontoControllerObterPorChave.queryKey = '/api/v1/tipos-de-desconto/{chave}'

export const queryTiposDisciplinaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-disciplina', queryParams)
export const useQueryTiposDisciplinaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-disciplina', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDisciplinaControllerBuscar.queryKey = '/api/v1/tipos-de-disciplina'

export const mutationTiposDisciplinaControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-disciplina', {}, {}, options)
export const useMutationTiposDisciplinaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-disciplina', {}, {}, options), config)

export const queryTiposDisciplinaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-disciplina/{id}', pathParams)
export const useQueryTiposDisciplinaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-disciplina/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDisciplinaControllerObterPorId.queryKey = '/api/v1/tipos-de-disciplina/{id}'

export const mutationTiposDisciplinaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-disciplina/{id}', pathParams, {}, options)
export const useMutationTiposDisciplinaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-disciplina/{id}', pathParams, {}, options), config)

export const mutationTiposDisciplinaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-disciplina/{id}', pathParams, {}, options)
export const useMutationTiposDisciplinaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-disciplina/{id}', pathParams, {}, options), config)

export const mutationTiposDisciplinaControllerCadastrarTipoDisciplinaConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-disciplina/{id}/contas', pathParams, {}, options)
export const useMutationTiposDisciplinaControllerCadastrarTipoDisciplinaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-disciplina/{id}/contas', pathParams, {}, options), config)

export const queryTiposDisciplinaControllerListarTipoDisciplinaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-disciplina/{id}/contas', pathParams, queryParams)
export const useQueryTiposDisciplinaControllerListarTipoDisciplinaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-disciplina/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDisciplinaControllerListarTipoDisciplinaContas.queryKey = '/api/v1/tipos-de-disciplina/{id}/contas'

export const queryTiposDisciplinaControllerObterTipoDisciplinaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-disciplina/{id}/contas/{contaId}', pathParams)
export const useQueryTiposDisciplinaControllerObterTipoDisciplinaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-disciplina/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposDisciplinaControllerObterTipoDisciplinaContaPorId.queryKey = '/api/v1/tipos-de-disciplina/{id}/contas/{contaId}'

export const mutationTiposDisciplinaControllerAtualizarTipoDisciplinaConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-disciplina/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposDisciplinaControllerAtualizarTipoDisciplinaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-disciplina/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposDisciplinaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-disciplina/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposDisciplinaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-disciplina/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDisciplinaControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-disciplina/{id}/contas-disponiveis'

export const queryTiposDocumentoControllerObterListaDeTipoDocumento = (options) => queryFn(options)('/api/v1/tipos-de-documento')
export const useQueryTiposDocumentoControllerObterListaDeTipoDocumento = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documento'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDocumentoControllerObterListaDeTipoDocumento.queryKey = '/api/v1/tipos-de-documento'

export const queryTiposDocumentoControllerObterTipoDocumentoPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-documento/{id}', pathParams)
export const useQueryTiposDocumentoControllerObterTipoDocumentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDocumentoControllerObterTipoDocumentoPorId.queryKey = '/api/v1/tipos-de-documento/{id}'

export const queryTiposDocumentosFiscaisControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-documentos-fiscais', queryParams)
export const useQueryTiposDocumentosFiscaisControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documentos-fiscais', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposDocumentosFiscaisControllerListar.queryKey = '/api/v1/tipos-de-documentos-fiscais'

export const queryTiposDocumentosFiscaisControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-documentos-fiscais/{id}', pathParams)
export const useQueryTiposDocumentosFiscaisControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documentos-fiscais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposDocumentosFiscaisControllerObterPorId.queryKey = '/api/v1/tipos-de-documentos-fiscais/{id}'

export const queryTiposDocumentosFiscaisControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-documentos-fiscais/{chave}', pathParams)
export const useQueryTiposDocumentosFiscaisControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-documentos-fiscais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposDocumentosFiscaisControllerObterPorChave.queryKey = '/api/v1/tipos-de-documentos-fiscais/{chave}'

export const queryTiposEmailControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-email', queryParams)
export const useQueryTiposEmailControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-email', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposEmailControllerListar.queryKey = '/api/v1/tipos-de-email'

export const queryTiposEnderecoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-endereco', queryParams)
export const useQueryTiposEnderecoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-endereco', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposEnderecoControllerBuscar.queryKey = '/api/v1/tipos-de-endereco'

export const queryTiposEntidadeControllerObterListaTiposEntidades = (options) => queryFn(options)('/api/v1/tipos-entidades')
export const useQueryTiposEntidadeControllerObterListaTiposEntidades = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposEntidadeControllerObterListaTiposEntidades.queryKey = '/api/v1/tipos-entidades'

export const queryTiposEntidadeControllerObterTipoEntidadesPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-entidades/{id}', pathParams)
export const useQueryTiposEntidadeControllerObterTipoEntidadesPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposEntidadeControllerObterTipoEntidadesPorId.queryKey = '/api/v1/tipos-entidades/{id}'

export const queryTiposEntidadeControllerObterTipoEntidadesPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-entidades/{chave}', pathParams)
export const useQueryTiposEntidadeControllerObterTipoEntidadesPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposEntidadeControllerObterTipoEntidadesPorChave.queryKey = '/api/v1/tipos-entidades/{chave}'

export const queryTiposEntidadeControllerObterCamposPorChaveTipoEntidade = (pathParams, options) => queryFn(options)('/api/v1/tipos-entidades/{chave}/campos', pathParams)
export const useQueryTiposEntidadeControllerObterCamposPorChaveTipoEntidade = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades/{chave}/campos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposEntidadeControllerObterCamposPorChaveTipoEntidade.queryKey = '/api/v1/tipos-entidades/{chave}/campos'

export const queryTiposEntidadeControllerListarValoresPorTipoEntidadeChaveGrupoCadastroPersonalizadoId = (pathParams, options) => queryFn(options)('/api/v1/tipos-entidades/{chave}/{id}', pathParams)
export const useQueryTiposEntidadeControllerListarValoresPorTipoEntidadeChaveGrupoCadastroPersonalizadoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-entidades/{chave}/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave && pathParams.id, ...config }
})
useQueryTiposEntidadeControllerListarValoresPorTipoEntidadeChaveGrupoCadastroPersonalizadoId.queryKey = '/api/v1/tipos-entidades/{chave}/{id}'

export const mutationTiposEntidadeControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-entidades/{chave}/{id}', pathParams, {}, options)
export const useMutationTiposEntidadeControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-entidades/{chave}/{id}', pathParams, {}, options), config)

export const queryTiposFaltaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-falta', queryParams)
export const useQueryTiposFaltaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-falta', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposFaltaControllerListar.queryKey = '/api/v1/tipos-de-falta'

export const queryTiposFaltaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-falta/{id}', pathParams)
export const useQueryTiposFaltaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-falta/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposFaltaControllerObterPorId.queryKey = '/api/v1/tipos-de-falta/{id}'

export const queryTiposFaltaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-falta/{chave}', pathParams)
export const useQueryTiposFaltaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-falta/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposFaltaControllerObterPorChave.queryKey = '/api/v1/tipos-de-falta/{chave}'

export const queryTiposGruposDestinatarioNotificacoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-grupos-destinatarios', queryParams)
export const useQueryTiposGruposDestinatarioNotificacoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-grupos-destinatarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposGruposDestinatarioNotificacoesControllerListar.queryKey = '/api/v1/tipos-grupos-destinatarios'

export const queryTiposGruposDestinatarioNotificacoesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-grupos-destinatarios/{id}', pathParams)
export const useQueryTiposGruposDestinatarioNotificacoesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-grupos-destinatarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposGruposDestinatarioNotificacoesControllerObterPorId.queryKey = '/api/v1/tipos-grupos-destinatarios/{id}'

export const queryTiposGruposDestinatarioNotificacoesControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-grupos-destinatarios/{chave}', pathParams)
export const useQueryTiposGruposDestinatarioNotificacoesControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-grupos-destinatarios/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposGruposDestinatarioNotificacoesControllerObterPorChave.queryKey = '/api/v1/tipos-grupos-destinatarios/{chave}'

export const queryTiposLogradouroControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-logradouro', queryParams)
export const useQueryTiposLogradouroControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-logradouro', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposLogradouroControllerBuscar.queryKey = '/api/v1/tipos-de-logradouro'

export const queryTiposLogradouroControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-logradouro/{id}', pathParams)
export const useQueryTiposLogradouroControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-logradouro/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposLogradouroControllerObterPorId.queryKey = '/api/v1/tipos-de-logradouro/{id}'

export const queryTiposLogradouroControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-logradouro/{chave}', pathParams)
export const useQueryTiposLogradouroControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-logradouro/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposLogradouroControllerObterPorChave.queryKey = '/api/v1/tipos-de-logradouro/{chave}'

export const queryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais = (options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais')
export const useQueryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterListaDeTiposNecessidadesEspeciais.queryKey = '/api/v1/tipos-de-necessidades-especiais'

export const queryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais/{id}', pathParams)
export const useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorId.queryKey = '/api/v1/tipos-de-necessidades-especiais/{id}'

export const queryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-necessidades-especiais/{chave}', pathParams)
export const useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-necessidades-especiais/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposNecessidadesEspeciaisControllerObterTipoNecessidadeEspecialPorChave.queryKey = '/api/v1/tipos-de-necessidades-especiais/{chave}'

export const queryTiposOcorrenciaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-ocorrencia', queryParams)
export const useQueryTiposOcorrenciaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-ocorrencia', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposOcorrenciaControllerBuscar.queryKey = '/api/v1/tipos-ocorrencia'

export const mutationTiposOcorrenciaControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-ocorrencia', {}, {}, options)
export const useMutationTiposOcorrenciaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-ocorrencia', {}, {}, options), config)

export const queryTiposOcorrenciaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-ocorrencia/{id}', pathParams)
export const useQueryTiposOcorrenciaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-ocorrencia/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposOcorrenciaControllerObterPorId.queryKey = '/api/v1/tipos-ocorrencia/{id}'

export const mutationTiposOcorrenciaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-ocorrencia/{id}', pathParams, {}, options)
export const useMutationTiposOcorrenciaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-ocorrencia/{id}', pathParams, {}, options), config)

export const mutationTiposOcorrenciaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-ocorrencia/{id}', pathParams, {}, options)
export const useMutationTiposOcorrenciaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-ocorrencia/{id}', pathParams, {}, options), config)

export const mutationTiposOcorrenciaControllerCadastrarTipoOcorrenciaConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-ocorrencia/{id}/contas', pathParams, {}, options)
export const useMutationTiposOcorrenciaControllerCadastrarTipoOcorrenciaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-ocorrencia/{id}/contas', pathParams, {}, options), config)

export const queryTiposOcorrenciaControllerListarTipoOcorrenciaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-ocorrencia/{id}/contas', pathParams, queryParams)
export const useQueryTiposOcorrenciaControllerListarTipoOcorrenciaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-ocorrencia/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposOcorrenciaControllerListarTipoOcorrenciaContas.queryKey = '/api/v1/tipos-ocorrencia/{id}/contas'

export const queryTiposOcorrenciaControllerObterTipoOcorrenciaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-ocorrencia/{id}/contas/{contaId}', pathParams)
export const useQueryTiposOcorrenciaControllerObterTipoOcorrenciaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-ocorrencia/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposOcorrenciaControllerObterTipoOcorrenciaContaPorId.queryKey = '/api/v1/tipos-ocorrencia/{id}/contas/{contaId}'

export const mutationTiposOcorrenciaControllerAtualizarTipoOcorrenciaConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-ocorrencia/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposOcorrenciaControllerAtualizarTipoOcorrenciaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-ocorrencia/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposOcorrenciaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-ocorrencia/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposOcorrenciaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-ocorrencia/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposOcorrenciaControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-ocorrencia/{id}/contas-disponiveis'

export const queryTiposOperacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-operacao', queryParams)
export const useQueryTiposOperacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-operacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposOperacaoControllerListar.queryKey = '/api/v1/tipos-operacao'

export const queryTiposPagamentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento', queryParams)
export const useQueryTiposPagamentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPagamentoControllerListar.queryKey = '/api/v1/tipos-de-pagamento'

export const queryTiposPagamentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento/{id}', pathParams)
export const useQueryTiposPagamentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPagamentoControllerObterPorId.queryKey = '/api/v1/tipos-de-pagamento/{id}'

export const queryTiposPagamentoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento/{chave}', pathParams)
export const useQueryTiposPagamentoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposPagamentoControllerObterPorChave.queryKey = '/api/v1/tipos-de-pagamento/{chave}'

export const queryTiposPagamentoComissaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento-de-comissao', queryParams)
export const useQueryTiposPagamentoComissaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPagamentoComissaoControllerBuscar.queryKey = '/api/v1/tipos-de-pagamento-de-comissao'

export const queryTiposPagamentoComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento-de-comissao/{id}', pathParams)
export const useQueryTiposPagamentoComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPagamentoComissaoControllerObterPorId.queryKey = '/api/v1/tipos-de-pagamento-de-comissao/{id}'

export const queryTiposPagamentoComissaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pagamento-de-comissao/{chave}', pathParams)
export const useQueryTiposPagamentoComissaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pagamento-de-comissao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposPagamentoComissaoControllerObterPorChave.queryKey = '/api/v1/tipos-de-pagamento-de-comissao/{chave}'

export const queryTiposPerguntaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-pergunta', queryParams)
export const useQueryTiposPerguntaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pergunta', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPerguntaControllerListar.queryKey = '/api/v1/tipos-de-pergunta'

export const queryTiposPerguntaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pergunta/{id}', pathParams)
export const useQueryTiposPerguntaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pergunta/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPerguntaControllerObterPorId.queryKey = '/api/v1/tipos-de-pergunta/{id}'

export const queryTiposPerguntaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pergunta/{chave}', pathParams)
export const useQueryTiposPerguntaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pergunta/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposPerguntaControllerObterPorChave.queryKey = '/api/v1/tipos-de-pergunta/{chave}'

export const mutationTiposPeriodoControllerCadastrarTipoPeriodo = (queryParams, options) => mutationFn('post', '/api/v1/tipos-periodo', {}, queryParams, options)
export const useMutationTiposPeriodoControllerCadastrarTipoPeriodo = (queryParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-periodo', {}, queryParams, options), config)

export const mutationTiposPeriodoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-periodo/{id}', pathParams, {}, options)
export const useMutationTiposPeriodoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-periodo/{id}', pathParams, {}, options), config)

export const queryTiposPeriodoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-periodo/{id}', pathParams)
export const useQueryTiposPeriodoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-periodo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPeriodoControllerObterPorId.queryKey = '/api/v1/tipos-periodo/{id}'

export const mutationTiposPeriodoControllerDeletarTipoPeriodoPorId = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-periodo/{id}', pathParams, {}, options)
export const useMutationTiposPeriodoControllerDeletarTipoPeriodoPorId = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-periodo/{id}', pathParams, {}, options), config)

export const queryTiposPessoaControllerObterListaDeTipoPessoa = (options) => queryFn(options)('/api/v1/tipos-de-pessoa')
export const useQueryTiposPessoaControllerObterListaDeTipoPessoa = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pessoa'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPessoaControllerObterListaDeTipoPessoa.queryKey = '/api/v1/tipos-de-pessoa'

export const queryTiposPessoaControllerObterTipoPessoaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-pessoa/{id}', pathParams)
export const useQueryTiposPessoaControllerObterTipoPessoaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-pessoa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPessoaControllerObterTipoPessoaPorId.queryKey = '/api/v1/tipos-de-pessoa/{id}'

export const queryTiposPontuacaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-pontuacao', queryParams)
export const useQueryTiposPontuacaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-pontuacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposPontuacaoControllerBuscar.queryKey = '/api/v1/tipos-pontuacao'

export const queryTiposPontuacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-pontuacao/{id}', pathParams)
export const useQueryTiposPontuacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-pontuacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposPontuacaoControllerObterPorId.queryKey = '/api/v1/tipos-pontuacao/{id}'

export const queryTiposPontuacaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-pontuacao/{chave}', pathParams)
export const useQueryTiposPontuacaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-pontuacao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposPontuacaoControllerObterPorChave.queryKey = '/api/v1/tipos-pontuacao/{chave}'

export const mutationTiposProdutoControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-produto', {}, {}, options)
export const useMutationTiposProdutoControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-produto', {}, {}, options), config)

export const queryTiposProdutoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-produto', queryParams)
export const useQueryTiposProdutoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposProdutoControllerBuscar.queryKey = '/api/v1/tipos-de-produto'

export const mutationTiposProdutoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-produto/{id}', pathParams, {}, options)
export const useMutationTiposProdutoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-produto/{id}', pathParams, {}, options), config)

export const mutationTiposProdutoControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-produto/{id}', pathParams, {}, options)
export const useMutationTiposProdutoControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-produto/{id}', pathParams, {}, options), config)

export const queryTiposProdutoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{id}', pathParams)
export const useQueryTiposProdutoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposProdutoControllerObterPorId.queryKey = '/api/v1/tipos-de-produto/{id}'

export const queryTiposProdutoControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-produto/buscar-detalhado/{id}', pathParams)
export const useQueryTiposProdutoControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposProdutoControllerObterPorIdDetalhado.queryKey = '/api/v1/tipos-de-produto/buscar-detalhado/{id}'

export const mutationTiposProdutoControllerCadastrarTipoProdutoConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-produto/{id}/contas', pathParams, {}, options)
export const useMutationTiposProdutoControllerCadastrarTipoProdutoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-produto/{id}/contas', pathParams, {}, options), config)

export const queryTiposProdutoControllerListarTipoProdutoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{id}/contas', pathParams, queryParams)
export const useQueryTiposProdutoControllerListarTipoProdutoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposProdutoControllerListarTipoProdutoContas.queryKey = '/api/v1/tipos-de-produto/{id}/contas'

export const queryTiposProdutoControllerObterTipoProdutoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{id}/contas/{contaId}', pathParams)
export const useQueryTiposProdutoControllerObterTipoProdutoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposProdutoControllerObterTipoProdutoContaPorId.queryKey = '/api/v1/tipos-de-produto/{id}/contas/{contaId}'

export const mutationTiposProdutoControllerAtualizarTipoProdutoConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-produto/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposProdutoControllerAtualizarTipoProdutoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-produto/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposProdutoControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposProdutoControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposProdutoControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-produto/{id}/contas-disponiveis'

export const queryTiposProdutoControllerListarProdutoPorTiposProduto = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-produto/{chave}', pathParams, queryParams)
export const useQueryTiposProdutoControllerListarProdutoPorTiposProduto = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-produto/{chave}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposProdutoControllerListarProdutoPorTiposProduto.queryKey = '/api/v1/tipos-de-produto/{chave}'

export const queryTiposQuestionarioControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-questionario', queryParams)
export const useQueryTiposQuestionarioControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-questionario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposQuestionarioControllerListar.queryKey = '/api/v1/tipos-de-questionario'

export const queryTiposQuestionarioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-questionario/{id}', pathParams)
export const useQueryTiposQuestionarioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-questionario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposQuestionarioControllerObterPorId.queryKey = '/api/v1/tipos-de-questionario/{id}'

export const queryTiposQuestionarioControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-questionario/{chave}', pathParams)
export const useQueryTiposQuestionarioControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-questionario/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposQuestionarioControllerObterPorChave.queryKey = '/api/v1/tipos-de-questionario/{chave}'

export const queryTiposRedeSocialControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-rede-social', queryParams)
export const useQueryTiposRedeSocialControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-rede-social', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposRedeSocialControllerListar.queryKey = '/api/v1/tipos-de-rede-social'

export const queryTiposRegimeTributarioControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-regime-tributario', queryParams)
export const useQueryTiposRegimeTributarioControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-regime-tributario', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposRegimeTributarioControllerBuscar.queryKey = '/api/v1/tipos-de-regime-tributario'

export const queryTiposRegimeTributarioControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-regime-tributario/{id}', pathParams)
export const useQueryTiposRegimeTributarioControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-regime-tributario/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposRegimeTributarioControllerObterPorId.queryKey = '/api/v1/tipos-de-regime-tributario/{id}'

export const queryTiposResponsavelControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel', queryParams)
export const useQueryTiposResponsavelControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposResponsavelControllerBuscar.queryKey = '/api/v1/tipos-de-responsavel'

export const mutationTiposResponsavelControllerCadastrarTipoResponsavel = (options) => mutationFn('post', '/api/v1/tipos-de-responsavel', {}, {}, options)
export const useMutationTiposResponsavelControllerCadastrarTipoResponsavel = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-responsavel', {}, {}, options), config)

export const queryTiposResponsavelControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}', pathParams)
export const useQueryTiposResponsavelControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposResponsavelControllerObterPorId.queryKey = '/api/v1/tipos-de-responsavel/{id}'

export const mutationTiposResponsavelControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-responsavel/{id}', pathParams, {}, options)
export const useMutationTiposResponsavelControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-responsavel/{id}', pathParams, {}, options), config)

export const mutationTiposResponsavelControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-responsavel/{id}', pathParams, {}, options)
export const useMutationTiposResponsavelControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-responsavel/{id}', pathParams, {}, options), config)

export const mutationTiposResponsavelControllerCadastrarTipoResponsavelTipoTag = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel', pathParams, {}, options)
export const useMutationTiposResponsavelControllerCadastrarTipoResponsavelTipoTag = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel', pathParams, {}, options), config)

export const queryTiposResponsavelControllerListarTiposTag = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel', pathParams, queryParams)
export const useQueryTiposResponsavelControllerListarTiposTag = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposResponsavelControllerListarTiposTag.queryKey = '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel'

export const mutationTiposResponsavelControllerDeletarTipoTagTag = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel/{tipoTagTipoResponsavelChave}', pathParams, {}, options)
export const useMutationTiposResponsavelControllerDeletarTipoTagTag = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-responsavel/{id}/tipos-tag-tipo-reponsavel/{tipoTagTipoResponsavelChave}', pathParams, {}, options), config)

export const mutationTiposResponsavelControllerCadastrarTipoResponsavelConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-responsavel/{id}/contas', pathParams, {}, options)
export const useMutationTiposResponsavelControllerCadastrarTipoResponsavelConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-responsavel/{id}/contas', pathParams, {}, options), config)

export const queryTiposResponsavelControllerListarTipoResponsavelContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}/contas', pathParams, queryParams)
export const useQueryTiposResponsavelControllerListarTipoResponsavelContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposResponsavelControllerListarTipoResponsavelContas.queryKey = '/api/v1/tipos-de-responsavel/{id}/contas'

export const queryTiposResponsavelControllerObterTipoResponsavelContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}/contas/{contaId}', pathParams)
export const useQueryTiposResponsavelControllerObterTipoResponsavelContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposResponsavelControllerObterTipoResponsavelContaPorId.queryKey = '/api/v1/tipos-de-responsavel/{id}/contas/{contaId}'

export const mutationTiposResponsavelControllerAtualizarTipoResponsavelConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-responsavel/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposResponsavelControllerAtualizarTipoResponsavelConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-responsavel/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposResponsavelControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-responsavel/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposResponsavelControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-responsavel/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposResponsavelControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-responsavel/{id}/contas-disponiveis'

export const queryTiposSalaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-sala', queryParams)
export const useQueryTiposSalaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposSalaControllerBuscar.queryKey = '/api/v1/tipos-de-sala'

export const mutationTiposSalaControllerCadastrar = (options) => mutationFn('post', '/api/v1/tipos-de-sala', {}, {}, options)
export const useMutationTiposSalaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-sala', {}, {}, options), config)

export const queryTiposSalaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-sala/{id}', pathParams)
export const useQueryTiposSalaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSalaControllerObterPorId.queryKey = '/api/v1/tipos-de-sala/{id}'

export const mutationTiposSalaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-sala/{id}', pathParams, {}, options)
export const useMutationTiposSalaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-sala/{id}', pathParams, {}, options), config)

export const mutationTiposSalaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/tipos-de-sala/{id}', pathParams, {}, options)
export const useMutationTiposSalaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tipos-de-sala/{id}', pathParams, {}, options), config)

export const mutationTiposSalaControllerCadastrarTipoSalaConta = (pathParams, options) => mutationFn('post', '/api/v1/tipos-de-sala/{id}/contas', pathParams, {}, options)
export const useMutationTiposSalaControllerCadastrarTipoSalaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tipos-de-sala/{id}/contas', pathParams, {}, options), config)

export const queryTiposSalaControllerListarTipoSalaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-sala/{id}/contas', pathParams, queryParams)
export const useQueryTiposSalaControllerListarTipoSalaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSalaControllerListarTipoSalaContas.queryKey = '/api/v1/tipos-de-sala/{id}/contas'

export const queryTiposSalaControllerObterTipoSalaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-sala/{id}/contas/{contaId}', pathParams)
export const useQueryTiposSalaControllerObterTipoSalaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTiposSalaControllerObterTipoSalaContaPorId.queryKey = '/api/v1/tipos-de-sala/{id}/contas/{contaId}'

export const mutationTiposSalaControllerAtualizarTipoSalaConta = (pathParams, options) => mutationFn('put', '/api/v1/tipos-de-sala/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTiposSalaControllerAtualizarTipoSalaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tipos-de-sala/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTiposSalaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-sala/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTiposSalaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sala/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSalaControllerListarContasDisponiveis.queryKey = '/api/v1/tipos-de-sala/{id}/contas-disponiveis'

export const queryTiposSanguineosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-sanguineos', queryParams)
export const useQueryTiposSanguineosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-sanguineos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposSanguineosControllerBuscar.queryKey = '/api/v1/tipos-sanguineos'

export const queryTiposSanguineosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-sanguineos/{id}', pathParams)
export const useQueryTiposSanguineosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-sanguineos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSanguineosControllerObterPorId.queryKey = '/api/v1/tipos-sanguineos/{id}'

export const queryTiposSanguineosControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-sanguineos/{chave}', pathParams)
export const useQueryTiposSanguineosControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-sanguineos/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposSanguineosControllerObterPorChave.queryKey = '/api/v1/tipos-sanguineos/{chave}'

export const queryTiposSexoControllerObterListaDeTipoSexo = (options) => queryFn(options)('/api/v1/tipos-de-sexo')
export const useQueryTiposSexoControllerObterListaDeTipoSexo = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sexo'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposSexoControllerObterListaDeTipoSexo.queryKey = '/api/v1/tipos-de-sexo'

export const queryTiposSexoControllerObterTipoSexoPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-sexo/{id}', pathParams)
export const useQueryTiposSexoControllerObterTipoSexoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-sexo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposSexoControllerObterTipoSexoPorId.queryKey = '/api/v1/tipos-de-sexo/{id}'

export const queryTiposStatusChequeControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-cheque', queryParams)
export const useQueryTiposStatusChequeControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-cheque', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposStatusChequeControllerListar.queryKey = '/api/v1/tipos-de-status-de-cheque'

export const queryTiposStatusChequeControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-cheque/{id}', pathParams)
export const useQueryTiposStatusChequeControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-cheque/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposStatusChequeControllerObterPorId.queryKey = '/api/v1/tipos-de-status-de-cheque/{id}'

export const queryTiposStatusChequeControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-cheque/{chave}', pathParams)
export const useQueryTiposStatusChequeControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-cheque/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposStatusChequeControllerObterPorChave.queryKey = '/api/v1/tipos-de-status-de-cheque/{chave}'

export const queryTiposStatusTituloControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-titulo', queryParams)
export const useQueryTiposStatusTituloControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-titulo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposStatusTituloControllerListar.queryKey = '/api/v1/tipos-de-status-de-titulo'

export const queryTiposStatusTituloControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-titulo/{id}', pathParams)
export const useQueryTiposStatusTituloControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-titulo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposStatusTituloControllerObterPorId.queryKey = '/api/v1/tipos-de-status-de-titulo/{id}'

export const queryTiposStatusTituloControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-status-de-titulo/{chave}', pathParams)
export const useQueryTiposStatusTituloControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-status-de-titulo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposStatusTituloControllerObterPorChave.queryKey = '/api/v1/tipos-de-status-de-titulo/{chave}'

export const queryTiposTagControllerBuscarTagsPorTipoTagId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-tag/{id}/tags', pathParams, queryParams)
export const useQueryTiposTagControllerBuscarTagsPorTipoTagId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTagControllerBuscarTagsPorTipoTagId.queryKey = '/api/v1/tipos-de-tag/{id}/tags'

export const queryTiposTagControllerBuscarTagsPorTipoTagChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-tag/{chave}/tags', pathParams, queryParams)
export const useQueryTiposTagControllerBuscarTagsPorTipoTagChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag/{chave}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTagControllerBuscarTagsPorTipoTagChave.queryKey = '/api/v1/tipos-de-tag/{chave}/tags'

export const queryTiposTagControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-tag', queryParams)
export const useQueryTiposTagControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTagControllerBuscar.queryKey = '/api/v1/tipos-de-tag'

export const queryTiposTagControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tag/{id}', pathParams)
export const useQueryTiposTagControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTagControllerObterPorId.queryKey = '/api/v1/tipos-de-tag/{id}'

export const queryTiposTagControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tag/{chave}', pathParams)
export const useQueryTiposTagControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTagControllerObterPorChave.queryKey = '/api/v1/tipos-de-tag/{chave}'

export const queryTiposTagsTipoResponsavelControllerObterListadeTipoTagTipoResponsavel = (options) => queryFn(options)('/api/v1/tipos-de-tag-tipo-responsavel')
export const useQueryTiposTagsTipoResponsavelControllerObterListadeTipoTagTipoResponsavel = (config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag-tipo-responsavel'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTagsTipoResponsavelControllerObterListadeTipoTagTipoResponsavel.queryKey = '/api/v1/tipos-de-tag-tipo-responsavel'

export const queryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tag-tipo-responsavel/{id}', pathParams)
export const useQueryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag-tipo-responsavel/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorId.queryKey = '/api/v1/tipos-de-tag-tipo-responsavel/{id}'

export const queryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tag-tipo-responsavel/{chave}', pathParams)
export const useQueryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag-tipo-responsavel/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTagsTipoResponsavelControllerObterTipoTagTipoResponsavelPorChave.queryKey = '/api/v1/tipos-de-tag-tipo-responsavel/{chave}'

export const queryTiposTagsTipoResponsavelControllerObterTipoResponsavelTipoTagTipoResponsavelPorChave = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tipos-de-tag-tipo-responsavel/{chave}/tipos-responsaveis', pathParams, queryParams)
export const useQueryTiposTagsTipoResponsavelControllerObterTipoResponsavelTipoTagTipoResponsavelPorChave = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tag-tipo-responsavel/{chave}/tipos-responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTagsTipoResponsavelControllerObterTipoResponsavelTipoTagTipoResponsavelPorChave.queryKey = '/api/v1/tipos-de-tag-tipo-responsavel/{chave}/tipos-responsaveis'

export const queryTiposTarefaControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-tarefa', queryParams)
export const useQueryTiposTarefaControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tarefa', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTarefaControllerListar.queryKey = '/api/v1/tipos-de-tarefa'

export const queryTiposTarefaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tarefa/{id}', pathParams)
export const useQueryTiposTarefaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tarefa/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTarefaControllerObterPorId.queryKey = '/api/v1/tipos-de-tarefa/{id}'

export const queryTiposTarefaControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tarefa/{chave}', pathParams)
export const useQueryTiposTarefaControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tarefa/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTarefaControllerObterPorChave.queryKey = '/api/v1/tipos-de-tarefa/{chave}'

export const queryTiposTelefoneControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-telefone', queryParams)
export const useQueryTiposTelefoneControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-telefone', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTelefoneControllerListar.queryKey = '/api/v1/tipos-de-telefone'

export const queryTiposTempoPrevistoRetornoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-tempo-previsto-de-retorno', queryParams)
export const useQueryTiposTempoPrevistoRetornoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tempo-previsto-de-retorno', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTempoPrevistoRetornoControllerListar.queryKey = '/api/v1/tipos-de-tempo-previsto-de-retorno'

export const queryTiposTempoPrevistoRetornoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tempo-previsto-de-retorno/{id}', pathParams)
export const useQueryTiposTempoPrevistoRetornoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tempo-previsto-de-retorno/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTempoPrevistoRetornoControllerObterPorId.queryKey = '/api/v1/tipos-de-tempo-previsto-de-retorno/{id}'

export const queryTiposTempoPrevistoRetornoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-tempo-previsto-de-retorno/{chave}', pathParams)
export const useQueryTiposTempoPrevistoRetornoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-tempo-previsto-de-retorno/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTempoPrevistoRetornoControllerObterPorChave.queryKey = '/api/v1/tipos-de-tempo-previsto-de-retorno/{chave}'

export const queryTiposTituloControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-titulo', queryParams)
export const useQueryTiposTituloControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-titulo', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTituloControllerListar.queryKey = '/api/v1/tipos-de-titulo'

export const queryTiposTituloControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-titulo/{id}', pathParams)
export const useQueryTiposTituloControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-titulo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposTituloControllerObterPorId.queryKey = '/api/v1/tipos-de-titulo/{id}'

export const queryTiposTituloControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-titulo/{chave}', pathParams)
export const useQueryTiposTituloControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-titulo/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposTituloControllerObterPorChave.queryKey = '/api/v1/tipos-de-titulo/{chave}'

export const queryTiposTributacaoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-tributacao', queryParams)
export const useQueryTiposTributacaoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-tributacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposTributacaoControllerListar.queryKey = '/api/v1/tipos-tributacao'

export const queryTiposValorCompensadoVencimentoControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tipos-valor-compensado-vencimento', queryParams)
export const useQueryTiposValorCompensadoVencimentoControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-valor-compensado-vencimento', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposValorCompensadoVencimentoControllerListar.queryKey = '/api/v1/tipos-valor-compensado-vencimento'

export const queryTiposVencimentoComissaoControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/tipos-de-vencimento-de-comissao', queryParams)
export const useQueryTiposVencimentoComissaoControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-vencimento-de-comissao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTiposVencimentoComissaoControllerBuscar.queryKey = '/api/v1/tipos-de-vencimento-de-comissao'

export const queryTiposVencimentoComissaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-vencimento-de-comissao/{id}', pathParams)
export const useQueryTiposVencimentoComissaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-vencimento-de-comissao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTiposVencimentoComissaoControllerObterPorId.queryKey = '/api/v1/tipos-de-vencimento-de-comissao/{id}'

export const queryTiposVencimentoComissaoControllerObterPorChave = (pathParams, options) => queryFn(options)('/api/v1/tipos-de-vencimento-de-comissao/{chave}', pathParams)
export const useQueryTiposVencimentoComissaoControllerObterPorChave = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tipos-de-vencimento-de-comissao/{chave}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.chave, ...config }
})
useQueryTiposVencimentoComissaoControllerObterPorChave.queryKey = '/api/v1/tipos-de-vencimento-de-comissao/{chave}'

export const mutationTitulosControllerCadastrar = (options) => mutationFn('post', '/api/v1/titulos', {}, {}, options)
export const useMutationTitulosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos', {}, {}, options), config)

export const queryTitulosControllerListar = (queryParams, options) => queryFn(options)('/api/v1/titulos', queryParams)
export const useQueryTitulosControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTitulosControllerListar.queryKey = '/api/v1/titulos'

export const queryTitulosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}', pathParams)
export const useQueryTitulosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterPorId.queryKey = '/api/v1/titulos/{id}'

export const mutationTitulosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/titulos/{id}', pathParams, {}, options)
export const useMutationTitulosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/titulos/{id}', pathParams, {}, options), config)

export const mutationTitulosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/titulos/{id}', pathParams, {}, options)
export const useMutationTitulosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/{id}', pathParams, {}, options), config)

export const mutationTitulosControllerCadastrarTituloConfiguracaoDesconto = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/desconto-pontualidade', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarTituloConfiguracaoDesconto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/desconto-pontualidade', pathParams, {}, options), config)

export const queryTitulosControllerObterTituloConfiguracaoDescontoPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/desconto-pontualidade/{descontoId}', pathParams)
export const useQueryTitulosControllerObterTituloConfiguracaoDescontoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/desconto-pontualidade/{descontoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.descontoId, ...config }
})
useQueryTitulosControllerObterTituloConfiguracaoDescontoPorId.queryKey = '/api/v1/titulos/{id}/desconto-pontualidade/{descontoId}'

export const queryTitulosControllerListarTituloConfiguracaoDescontos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/descontos-pontualidade', pathParams, queryParams)
export const useQueryTitulosControllerListarTituloConfiguracaoDescontos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/descontos-pontualidade', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarTituloConfiguracaoDescontos.queryKey = '/api/v1/titulos/{id}/descontos-pontualidade'

export const mutationTitulosControllerDeletarTituloConfiguracaoDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/titulos/{id}/descontos-pontualidade/{descontoId}', pathParams, {}, options)
export const useMutationTitulosControllerDeletarTituloConfiguracaoDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/{id}/descontos-pontualidade/{descontoId}', pathParams, {}, options), config)

export const mutationTitulosControllerCadastrarTituloConfiguracaoConvenio = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/convenios', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarTituloConfiguracaoConvenio = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/convenios', pathParams, {}, options), config)

export const queryTitulosControllerListarTituloConfiguracaoConvenios = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/convenios', pathParams, queryParams)
export const useQueryTitulosControllerListarTituloConfiguracaoConvenios = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/convenios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarTituloConfiguracaoConvenios.queryKey = '/api/v1/titulos/{id}/convenios'

export const queryTitulosControllerObterTituloConfiguracaoConvenioPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/convenios/{convenioId}', pathParams)
export const useQueryTitulosControllerObterTituloConfiguracaoConvenioPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/convenios/{convenioId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.convenioId, ...config }
})
useQueryTitulosControllerObterTituloConfiguracaoConvenioPorId.queryKey = '/api/v1/titulos/{id}/convenios/{convenioId}'

export const mutationTitulosControllerDeletarTituloConfiguracaoConvenio = (pathParams, options) => mutationFn('delete', '/api/v1/titulos/{id}/convenios/{convenioId}', pathParams, {}, options)
export const useMutationTitulosControllerDeletarTituloConfiguracaoConvenio = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/{id}/convenios/{convenioId}', pathParams, {}, options), config)

export const mutationTitulosControllerGerarBaixaLote = (options) => mutationFn('post', '/api/v1/titulos/baixa-lote', {}, {}, options)
export const useMutationTitulosControllerGerarBaixaLote = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/baixa-lote', {}, {}, options), config)

export const mutationTitulosControllerEstornar = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/estorno', pathParams, {}, options)
export const useMutationTitulosControllerEstornar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/estorno', pathParams, {}, options), config)

export const mutationTitulosControllerEstornarLote = (options) => mutationFn('post', '/api/v1/titulos/estorno-lote', {}, {}, options)
export const useMutationTitulosControllerEstornarLote = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/estorno-lote', {}, {}, options), config)

export const mutationTitulosControllerCadastrarBaixa = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/baixas', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarBaixa = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/baixas', pathParams, {}, options), config)

export const queryTitulosControllerListarBaixasPorTitulo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/baixas', pathParams, queryParams)
export const useQueryTitulosControllerListarBaixasPorTitulo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/baixas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarBaixasPorTitulo.queryKey = '/api/v1/titulos/{id}/baixas'

export const mutationTitulosControllerCadastrarBaixaEmLista = (options) => mutationFn('post', '/api/v1/titulos/baixas-lista', {}, {}, options)
export const useMutationTitulosControllerCadastrarBaixaEmLista = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/baixas-lista', {}, {}, options), config)

export const queryTitulosControllerListarBaixa = (queryParams, options) => queryFn(options)('/api/v1/titulos/baixas', queryParams)
export const useQueryTitulosControllerListarBaixa = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/baixas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTitulosControllerListarBaixa.queryKey = '/api/v1/titulos/baixas'

export const mutationTitulosControllerCadastrarDesconto = (options) => mutationFn('post', '/api/v1/titulos/baixas/descontos', {}, {}, options)
export const useMutationTitulosControllerCadastrarDesconto = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/baixas/descontos', {}, {}, options), config)

export const queryTitulosControllerListarDescontos = (queryParams, options) => queryFn(options)('/api/v1/titulos/baixas/descontos', queryParams)
export const useQueryTitulosControllerListarDescontos = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/baixas/descontos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTitulosControllerListarDescontos.queryKey = '/api/v1/titulos/baixas/descontos'

export const queryTitulosControllerObterDescontoPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/baixas/descontos/{id}', pathParams)
export const useQueryTitulosControllerObterDescontoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/baixas/descontos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterDescontoPorId.queryKey = '/api/v1/titulos/baixas/descontos/{id}'

export const mutationTitulosControllerDeletarDesconto = (pathParams, options) => mutationFn('delete', '/api/v1/titulos/baixas/descontos/{id}', pathParams, {}, options)
export const useMutationTitulosControllerDeletarDesconto = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/baixas/descontos/{id}', pathParams, {}, options), config)

export const queryTitulosControllerListarDescontosPorBaixaTitulo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/baixas/{id}/descontos', pathParams, queryParams)
export const useQueryTitulosControllerListarDescontosPorBaixaTitulo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/baixas/{id}/descontos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarDescontosPorBaixaTitulo.queryKey = '/api/v1/titulos/baixas/{id}/descontos'

export const queryTitulosControllerObterTituloConfiguracaoBolsaPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/bolsas/{bolsaId}', pathParams)
export const useQueryTitulosControllerObterTituloConfiguracaoBolsaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/bolsas/{bolsaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.bolsaId, ...config }
})
useQueryTitulosControllerObterTituloConfiguracaoBolsaPorId.queryKey = '/api/v1/titulos/{id}/bolsas/{bolsaId}'

export const queryTitulosControllerListarTituloConfiguracaoBolsas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/bolsas', pathParams, queryParams)
export const useQueryTitulosControllerListarTituloConfiguracaoBolsas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/bolsas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarTituloConfiguracaoBolsas.queryKey = '/api/v1/titulos/{id}/bolsas'

export const queryTitulosControllerObterBoletoPorTituloId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/boletos', pathParams)
export const useQueryTitulosControllerObterBoletoPorTituloId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/boletos', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterBoletoPorTituloId.queryKey = '/api/v1/titulos/{id}/boletos'

export const mutationTitulosControllerCadastrarBoleto = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/boletos', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarBoleto = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/boletos', pathParams, {}, options), config)

export const queryTitulosControllerListarTitulosPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/titulos/filtros', queryParams)
export const useQueryTitulosControllerListarTitulosPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTitulosControllerListarTitulosPorFiltros.queryKey = '/api/v1/titulos/filtros'

export const mutationTitulosControllerCadastrarTituloJustificativaMovimento = (pathParams, options) => mutationFn('post', '/api/v1/titulos/{id}/justificativas-movimento', pathParams, {}, options)
export const useMutationTitulosControllerCadastrarTituloJustificativaMovimento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/titulos/{id}/justificativas-movimento', pathParams, {}, options), config)

export const queryTitulosControllerListarTituloJustificativasMovimento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/justificativas-movimento', pathParams, queryParams)
export const useQueryTitulosControllerListarTituloJustificativasMovimento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/justificativas-movimento', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerListarTituloJustificativasMovimento.queryKey = '/api/v1/titulos/{id}/justificativas-movimento'

export const queryTitulosControllerCadastrarTituloJustificativaMovimentoPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/movimentos/{movimentoCaixaId}', pathParams)
export const useQueryTitulosControllerCadastrarTituloJustificativaMovimentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/movimentos/{movimentoCaixaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.movimentoCaixaId, ...config }
})
useQueryTitulosControllerCadastrarTituloJustificativaMovimentoPorId.queryKey = '/api/v1/titulos/{id}/movimentos/{movimentoCaixaId}'

export const queryTitulosControllerObterPorTituloId = (pathParams, options) => queryFn(options)('/api/v1/titulos/{id}/obter-config-link-pagamento', pathParams)
export const useQueryTitulosControllerObterPorTituloId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/obter-config-link-pagamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterPorTituloId.queryKey = '/api/v1/titulos/{id}/obter-config-link-pagamento'

export const mutationTitulosControllerAtualizaAssinaturaPlano = (pathParams, options) => mutationFn('put', '/api/v1/titulos/{id}/atualizar-assinatura', pathParams, {}, options)
export const useMutationTitulosControllerAtualizaAssinaturaPlano = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/titulos/{id}/atualizar-assinatura', pathParams, {}, options), config)

export const mutationTitulosControllerCancelarLote = (options) => mutationFn('post', '/api/v1/titulos/cancelar-lote', {}, {}, options)
export const useMutationTitulosControllerCancelarLote = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/cancelar-lote', {}, {}, options), config)

export const queryTitulosControllerObterParcelasPorTransacaoId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/{id}/parcelas', pathParams, queryParams)
export const useQueryTitulosControllerObterParcelasPorTransacaoId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/{id}/parcelas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterParcelasPorTransacaoId.queryKey = '/api/v1/titulos/{id}/parcelas'

export const queryTitulosControllerObterTituloDescontoPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/titulos/descontos/{id}', pathParams, queryParams)
export const useQueryTitulosControllerObterTituloDescontoPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos/descontos/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosControllerObterTituloDescontoPorId.queryKey = '/api/v1/titulos/descontos/{id}'

export const mutationTitulosControllerDeletarTituloDesconto = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/titulos/descontos/{id}', pathParams, queryParams, options)
export const useMutationTitulosControllerDeletarTituloDesconto = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/titulos/descontos/{id}', pathParams, queryParams, options), config)

export const mutationTitulosControllerCadastrarTituloDesconto = (options) => mutationFn('post', '/api/v1/titulos/descontos', {}, {}, options)
export const useMutationTitulosControllerCadastrarTituloDesconto = (config, options) => useMutation(mutationFn('post', '/api/v1/titulos/descontos', {}, {}, options), config)

export const queryTitulosJustificativaMovimentoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/titulos-justificativas-movimento/{id}', pathParams)
export const useQueryTitulosJustificativaMovimentoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/titulos-justificativas-movimento/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTitulosJustificativaMovimentoControllerObterPorId.queryKey = '/api/v1/titulos-justificativas-movimento/{id}'

export const mutationTitulosJustificativaMovimentoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/titulos-justificativas-movimento/{id}', pathParams, {}, options)
export const useMutationTitulosJustificativaMovimentoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/titulos-justificativas-movimento/{id}', pathParams, {}, options), config)

export const mutationTributacoesControllerCadastrarDadosBasicos = (options) => mutationFn('post', '/api/v1/tributacoes', {}, {}, options)
export const useMutationTributacoesControllerCadastrarDadosBasicos = (config, options) => useMutation(mutationFn('post', '/api/v1/tributacoes', {}, {}, options), config)

export const queryTributacoesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/tributacoes', queryParams)
export const useQueryTributacoesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTributacoesControllerListar.queryKey = '/api/v1/tributacoes'

export const queryTributacoesControllerObterDadosBasicosPorId = (pathParams, options) => queryFn(options)('/api/v1/tributacoes/{id}', pathParams)
export const useQueryTributacoesControllerObterDadosBasicosPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerObterDadosBasicosPorId.queryKey = '/api/v1/tributacoes/{id}'

export const mutationTributacoesControllerAtualizarDadosBasicos = (pathParams, options) => mutationFn('put', '/api/v1/tributacoes/{id}', pathParams, {}, options)
export const useMutationTributacoesControllerAtualizarDadosBasicos = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tributacoes/{id}', pathParams, {}, options), config)

export const mutationTributacoesControllerCadastrarTributacaoCnae = (pathParams, options) => mutationFn('post', '/api/v1/tributacoes/{id}/cnaes', pathParams, {}, options)
export const useMutationTributacoesControllerCadastrarTributacaoCnae = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tributacoes/{id}/cnaes', pathParams, {}, options), config)

export const queryTributacoesControllerListarTributacaoCnaes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/cnaes', pathParams, queryParams)
export const useQueryTributacoesControllerListarTributacaoCnaes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/cnaes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerListarTributacaoCnaes.queryKey = '/api/v1/tributacoes/{id}/cnaes'

export const queryTributacoesControllerObterTributacaoCnaePorId = (pathParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/cnaes/{cnaeId}', pathParams)
export const useQueryTributacoesControllerObterTributacaoCnaePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/cnaes/{cnaeId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.cnaeId, ...config }
})
useQueryTributacoesControllerObterTributacaoCnaePorId.queryKey = '/api/v1/tributacoes/{id}/cnaes/{cnaeId}'

export const mutationTributacoesControllerCadastrarTributacaoCfop = (pathParams, options) => mutationFn('post', '/api/v1/tributacoes/{id}/cfops', pathParams, {}, options)
export const useMutationTributacoesControllerCadastrarTributacaoCfop = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tributacoes/{id}/cfops', pathParams, {}, options), config)

export const queryTributacoesControllerListarTributacaoCfops = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/cfops', pathParams, queryParams)
export const useQueryTributacoesControllerListarTributacaoCfops = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/cfops', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerListarTributacaoCfops.queryKey = '/api/v1/tributacoes/{id}/cfops'

export const queryTributacoesControllerObterTributacaoCfopPorId = (pathParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/cfops/{cfopId}', pathParams)
export const useQueryTributacoesControllerObterTributacaoCfopPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/cfops/{cfopId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.cfopId, ...config }
})
useQueryTributacoesControllerObterTributacaoCfopPorId.queryKey = '/api/v1/tributacoes/{id}/cfops/{cfopId}'

export const mutationTributacoesControllerDeletarTributacaoCfop = (pathParams, options) => mutationFn('delete', '/api/v1/tributacoes/{id}/cfops/{cfopId}', pathParams, {}, options)
export const useMutationTributacoesControllerDeletarTributacaoCfop = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tributacoes/{id}/cfops/{cfopId}', pathParams, {}, options), config)

export const mutationTributacoesControllerCadastrarTributacaoConta = (pathParams, options) => mutationFn('post', '/api/v1/tributacoes/{id}/contas', pathParams, {}, options)
export const useMutationTributacoesControllerCadastrarTributacaoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/tributacoes/{id}/contas', pathParams, {}, options), config)

export const queryTributacoesControllerListarTributacaoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/contas', pathParams, queryParams)
export const useQueryTributacoesControllerListarTributacaoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerListarTributacaoContas.queryKey = '/api/v1/tributacoes/{id}/contas'

export const queryTributacoesControllerObterTributacaoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/contas/{contaId}', pathParams)
export const useQueryTributacoesControllerObterTributacaoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTributacoesControllerObterTributacaoContaPorId.queryKey = '/api/v1/tributacoes/{id}/contas/{contaId}'

export const mutationTributacoesControllerAtualizarTributacaoConta = (pathParams, options) => mutationFn('put', '/api/v1/tributacoes/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTributacoesControllerAtualizarTributacaoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/tributacoes/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTributacoesControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/tributacoes/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTributacoesControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesControllerListarContasDisponiveis.queryKey = '/api/v1/tributacoes/{id}/contas-disponiveis'

export const queryTributacoesCnaeControllerGetById = (pathParams, options) => queryFn(options)('/api/v1/tributacoes-cnae/{id}', pathParams)
export const useQueryTributacoesCnaeControllerGetById = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/tributacoes-cnae/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTributacoesCnaeControllerGetById.queryKey = '/api/v1/tributacoes-cnae/{id}'

export const mutationTributacoesCnaeControllerRemove = (pathParams, options) => mutationFn('delete', '/api/v1/tributacoes-cnae/{id}', pathParams, {}, options)
export const useMutationTributacoesCnaeControllerRemove = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/tributacoes-cnae/{id}', pathParams, {}, options), config)

export const queryTurmaCalendarioFeriadoEventoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-calendario-feriados-e-eventos/{id}', pathParams)
export const useQueryTurmaCalendarioFeriadoEventoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-calendario-feriados-e-eventos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaCalendarioFeriadoEventoControllerObterPorId.queryKey = '/api/v1/turma-calendario-feriados-e-eventos/{id}'

export const mutationTurmaDiarioAulaIntegranteControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/turma-diarios-aula-integrante/{id}/justificativa-faltas-arquivos', pathParams, {}, options)
export const useMutationTurmaDiarioAulaIntegranteControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-diarios-aula-integrante/{id}/justificativa-faltas-arquivos', pathParams, {}, options), config)

export const queryTurmaDiarioAulaIntegranteControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-diarios-aula-integrante/{id}/justificativa-faltas-arquivos', pathParams, queryParams)
export const useQueryTurmaDiarioAulaIntegranteControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-diarios-aula-integrante/{id}/justificativa-faltas-arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaDiarioAulaIntegranteControllerListarArquivos.queryKey = '/api/v1/turma-diarios-aula-integrante/{id}/justificativa-faltas-arquivos'

export const mutationTurmaDiarioAulaIntegranteControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/turma-diarios-aula-integrante/{id}/justificativa-faltas-arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationTurmaDiarioAulaIntegranteControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-diarios-aula-integrante/{id}/justificativa-faltas-arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationTurmaDiariosAulaControllerCadastrarDiarioAulaPlanoAula = (pathParams, options) => mutationFn('post', '/api/v1/turma-diarios-aula/{id}/planos-aula', pathParams, {}, options)
export const useMutationTurmaDiariosAulaControllerCadastrarDiarioAulaPlanoAula = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-diarios-aula/{id}/planos-aula', pathParams, {}, options), config)

export const queryTurmaDiariosAulaControllerListarDiarioAulaPlanoAula = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-diarios-aula/{id}/planos-aula', pathParams, queryParams)
export const useQueryTurmaDiariosAulaControllerListarDiarioAulaPlanoAula = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-diarios-aula/{id}/planos-aula', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaDiariosAulaControllerListarDiarioAulaPlanoAula.queryKey = '/api/v1/turma-diarios-aula/{id}/planos-aula'

export const queryTurmaHorarioQuadrosControllerObterTurmaHorarioQuadroPorId = (pathParams, options) => queryFn(options)('/api/v1/turmas-horario-quadros/{id}', pathParams)
export const useQueryTurmaHorarioQuadrosControllerObterTurmaHorarioQuadroPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-horario-quadros/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaHorarioQuadrosControllerObterTurmaHorarioQuadroPorId.queryKey = '/api/v1/turmas-horario-quadros/{id}'

export const mutationTurmaHorarioQuadrosControllerAtualizarTurmaHorarioQuadro = (pathParams, options) => mutationFn('put', '/api/v1/turmas-horario-quadros/{id}', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerAtualizarTurmaHorarioQuadro = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas-horario-quadros/{id}', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerDeletarTurmaHorarioQuadro = (pathParams, options) => mutationFn('delete', '/api/v1/turmas-horario-quadros/{id}', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerDeletarTurmaHorarioQuadro = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas-horario-quadros/{id}', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerLancarPresencas = (pathParams, options) => mutationFn('post', '/api/v1/turmas-horario-quadros/{id}', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerLancarPresencas = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-horario-quadros/{id}', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerDeletarTurmaHorarioQuadroPorData = (pathParams, options) => mutationFn('patch', '/api/v1/turmas-horario-quadros/{id}/deletar-por-data', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerDeletarTurmaHorarioQuadroPorData = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turmas-horario-quadros/{id}/deletar-por-data', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerDeletarTurmaHorarioQuadrosPosterioresAnoLetivo = (pathParams, options) => mutationFn('delete', '/api/v1/turmas-horario-quadros/{id}/deletar-posteriores', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerDeletarTurmaHorarioQuadrosPosterioresAnoLetivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas-horario-quadros/{id}/deletar-posteriores', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerLancarPresencaGeral = (pathParams, options) => mutationFn('post', '/api/v1/turmas-horario-quadros/{id}/presenca-geral', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerLancarPresencaGeral = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-horario-quadros/{id}/presenca-geral', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerLancarPresencaDiarioInfantil = (pathParams, options) => mutationFn('post', '/api/v1/turmas-horario-quadros/{id}/presenca-diario-infantil', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerLancarPresencaDiarioInfantil = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-horario-quadros/{id}/presenca-diario-infantil', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerLancarPresencaGeralDiarioInfantil = (options) => mutationFn('post', '/api/v1/turmas-horario-quadros/presenca-geral-diario-infantil', {}, {}, options)
export const useMutationTurmaHorarioQuadrosControllerLancarPresencaGeralDiarioInfantil = (config, options) => useMutation(mutationFn('post', '/api/v1/turmas-horario-quadros/presenca-geral-diario-infantil', {}, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerAtualizarModeloDiarioInfatilTurma = (pathParams, options) => mutationFn('put', '/api/v1/turmas-horario-quadros/{id}/diario-infantil-turma', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerAtualizarModeloDiarioInfatilTurma = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas-horario-quadros/{id}/diario-infantil-turma', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerAtualizarAutomatizarFrequencia = (pathParams, options) => mutationFn('patch', '/api/v1/turmas-horario-quadros/{id}/diario-infantil-automatizar-frequencia', pathParams, {}, options)
export const useMutationTurmaHorarioQuadrosControllerAtualizarAutomatizarFrequencia = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turmas-horario-quadros/{id}/diario-infantil-automatizar-frequencia', pathParams, {}, options), config)

export const mutationTurmaHorarioQuadrosControllerExistemAulasParaDias = (options) => mutationFn('post', '/api/v1/turmas-horario-quadros/existem-aulas', {}, {}, options)
export const useMutationTurmaHorarioQuadrosControllerExistemAulasParaDias = (config, options) => useMutation(mutationFn('post', '/api/v1/turmas-horario-quadros/existem-aulas', {}, {}, options), config)

export const mutationTurmaHorariosControllerCadastrarTurmaHorarioQuadro = (pathParams, options) => mutationFn('post', '/api/v1/turma-horarios/{id}/quadros', pathParams, {}, options)
export const useMutationTurmaHorariosControllerCadastrarTurmaHorarioQuadro = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-horarios/{id}/quadros', pathParams, {}, options), config)

export const queryTurmaHorariosControllerListarTurmaHorarioQuadros = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-horarios/{id}/quadros', pathParams, queryParams)
export const useQueryTurmaHorariosControllerListarTurmaHorarioQuadros = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-horarios/{id}/quadros', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaHorariosControllerListarTurmaHorarioQuadros.queryKey = '/api/v1/turma-horarios/{id}/quadros'

export const queryTurmaHorariosControllerListarMapaHorarios = (queryParams, options) => queryFn(options)('/api/v1/turma-horarios/mapa-horarios', queryParams)
export const useQueryTurmaHorariosControllerListarMapaHorarios = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-horarios/mapa-horarios', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmaHorariosControllerListarMapaHorarios.queryKey = '/api/v1/turma-horarios/mapa-horarios'

export const queryTurmaHorariosControllerListarTurmaHorarioComposicoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-horarios/{id}/composicoes', pathParams, queryParams)
export const useQueryTurmaHorariosControllerListarTurmaHorarioComposicoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-horarios/{id}/composicoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaHorariosControllerListarTurmaHorarioComposicoes.queryKey = '/api/v1/turma-horarios/{id}/composicoes'

export const queryTurmaHorariosControllerObterDatasMargensConfiguracao = (pathParams, options) => queryFn(options)('/api/v1/turma-horarios/{id}/margens-data-configuracao', pathParams)
export const useQueryTurmaHorariosControllerObterDatasMargensConfiguracao = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-horarios/{id}/margens-data-configuracao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaHorariosControllerObterDatasMargensConfiguracao.queryKey = '/api/v1/turma-horarios/{id}/margens-data-configuracao'

export const mutationTurmaIntegranteDisciplinaDocumentosControllerAtualizarTurmaIntegranteDisciplinaDocumento = (pathParams, options) => mutationFn('put', '/api/v1/turma-integrante-disciplina-documentos/{id}/turma-integrante/{turmaIntegranteId}', pathParams, {}, options)
export const useMutationTurmaIntegranteDisciplinaDocumentosControllerAtualizarTurmaIntegranteDisciplinaDocumento = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-integrante-disciplina-documentos/{id}/turma-integrante/{turmaIntegranteId}', pathParams, {}, options), config)

export const mutationTurmaIntegranteDisciplinaDocumentosControllerDeletarTurmaIntegranteDisciplinaDocumento = (pathParams, options) => mutationFn('delete', '/api/v1/turma-integrante-disciplina-documentos/{id}', pathParams, {}, options)
export const useMutationTurmaIntegranteDisciplinaDocumentosControllerDeletarTurmaIntegranteDisciplinaDocumento = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-integrante-disciplina-documentos/{id}', pathParams, {}, options), config)

export const queryTurmaIntegranteDisciplinaDocumentosControllerObterTurmaIntegranteDisciplinaDocumentoPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-integrante-disciplina-documentos/{id}', pathParams)
export const useQueryTurmaIntegranteDisciplinaDocumentosControllerObterTurmaIntegranteDisciplinaDocumentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrante-disciplina-documentos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaIntegranteDisciplinaDocumentosControllerObterTurmaIntegranteDisciplinaDocumentoPorId.queryKey = '/api/v1/turma-integrante-disciplina-documentos/{id}'

export const mutationTurmaIntegranteDisciplinaDocumentosControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/turma-integrante-disciplina-documentos/{id}/arquivos', pathParams, {}, options)
export const useMutationTurmaIntegranteDisciplinaDocumentosControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-integrante-disciplina-documentos/{id}/arquivos', pathParams, {}, options), config)

export const queryTurmaIntegranteDisciplinaDocumentosControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-integrante-disciplina-documentos/{id}/arquivos', pathParams, queryParams)
export const useQueryTurmaIntegranteDisciplinaDocumentosControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrante-disciplina-documentos/{id}/arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaIntegranteDisciplinaDocumentosControllerListarArquivos.queryKey = '/api/v1/turma-integrante-disciplina-documentos/{id}/arquivos'

export const queryTurmaIntegranteDisciplinaDocumentosControllerObterTurmaIntegranteDisciplinaDocumentoArquivoPorArquivoId = (pathParams, options) => queryFn(options)('/api/v1/turma-integrante-disciplina-documentos/arquivos/{arquivoId}', pathParams)
export const useQueryTurmaIntegranteDisciplinaDocumentosControllerObterTurmaIntegranteDisciplinaDocumentoArquivoPorArquivoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrante-disciplina-documentos/arquivos/{arquivoId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.arquivoId, ...config }
})
useQueryTurmaIntegranteDisciplinaDocumentosControllerObterTurmaIntegranteDisciplinaDocumentoArquivoPorArquivoId.queryKey = '/api/v1/turma-integrante-disciplina-documentos/arquivos/{arquivoId}'

export const mutationTurmaIntegranteDisciplinaDocumentosControllerDeletarArquivo = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/turma-integrante-disciplina-documentos/{id}/arquivos/{arquivoId}', pathParams, queryParams, options)
export const useMutationTurmaIntegranteDisciplinaDocumentosControllerDeletarArquivo = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-integrante-disciplina-documentos/{id}/arquivos/{arquivoId}', pathParams, queryParams, options), config)

export const queryTurmaIntegranteParecerDescritivoControllerListarPorTurma = (pathParams, options) => queryFn(options)('/api/v1/turma-integrante-parecer-descritivo/turma/{turmaId}', pathParams)
export const useQueryTurmaIntegranteParecerDescritivoControllerListarPorTurma = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrante-parecer-descritivo/turma/{turmaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.turmaId, ...config }
})
useQueryTurmaIntegranteParecerDescritivoControllerListarPorTurma.queryKey = '/api/v1/turma-integrante-parecer-descritivo/turma/{turmaId}'

export const mutationTurmaIntegranteParecerDescritivoControllerAssinarResponsavel = (pathParams, options) => mutationFn('patch', '/api/v1/turma-integrante-parecer-descritivo/{id}/assinar-responsavel', pathParams, {}, options)
export const useMutationTurmaIntegranteParecerDescritivoControllerAssinarResponsavel = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turma-integrante-parecer-descritivo/{id}/assinar-responsavel', pathParams, {}, options), config)

export const mutationTurmaIntegranteParecerDescritivoControllerAprovaCoordenacao = (pathParams, options) => mutationFn('patch', '/api/v1/turma-integrante-parecer-descritivo/{id}/aprova-coordenacao', pathParams, {}, options)
export const useMutationTurmaIntegranteParecerDescritivoControllerAprovaCoordenacao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turma-integrante-parecer-descritivo/{id}/aprova-coordenacao', pathParams, {}, options), config)

export const mutationTurmaIntegranteParecerDescritivoControllerAdicionaObservacao = (pathParams, options) => mutationFn('patch', '/api/v1/turma-integrante-parecer-descritivo/{id}/adiciona-observacao', pathParams, {}, options)
export const useMutationTurmaIntegranteParecerDescritivoControllerAdicionaObservacao = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turma-integrante-parecer-descritivo/{id}/adiciona-observacao', pathParams, {}, options), config)

export const mutationTurmaIntegranteParecerDescritivoControllerImprimirPareceresDescritivos = (pathParams, options) => mutationFn('post', '/api/v1/turma-integrante-parecer-descritivo/turma/{turmaId}/pareceres-descritivos', pathParams, {}, options)
export const useMutationTurmaIntegranteParecerDescritivoControllerImprimirPareceresDescritivos = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-integrante-parecer-descritivo/turma/{turmaId}/pareceres-descritivos', pathParams, {}, options), config)

export const mutationTurmaIntegrantesControllerAtualizarIntegrante = (pathParams, options) => mutationFn('put', '/api/v1/turma-integrantes/{id}', pathParams, {}, options)
export const useMutationTurmaIntegrantesControllerAtualizarIntegrante = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-integrantes/{id}', pathParams, {}, options), config)

export const mutationTurmaIntegrantesControllerDeletarIntegrante = (pathParams, options) => mutationFn('delete', '/api/v1/turma-integrantes/{id}', pathParams, {}, options)
export const useMutationTurmaIntegrantesControllerDeletarIntegrante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-integrantes/{id}', pathParams, {}, options), config)

export const queryTurmaIntegrantesControllerObterIntegrantePorId = (pathParams, options) => queryFn(options)('/api/v1/turma-integrantes/{id}', pathParams)
export const useQueryTurmaIntegrantesControllerObterIntegrantePorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrantes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaIntegrantesControllerObterIntegrantePorId.queryKey = '/api/v1/turma-integrantes/{id}'

export const queryTurmaIntegrantesControllerObterIntegrantePorTurmaIdTurmaIntegranteId = (pathParams, options) => queryFn(options)('/api/v1/turma-integrantes/{turmaId}/integrantes/{turmaIntegranteId}', pathParams)
export const useQueryTurmaIntegrantesControllerObterIntegrantePorTurmaIdTurmaIntegranteId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrantes/{turmaId}/integrantes/{turmaIntegranteId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.turmaId && pathParams.turmaIntegranteId, ...config }
})
useQueryTurmaIntegrantesControllerObterIntegrantePorTurmaIdTurmaIntegranteId.queryKey = '/api/v1/turma-integrantes/{turmaId}/integrantes/{turmaIntegranteId}'

export const queryTurmaIntegrantesControllerListarIntegranteDisciplinasTurma = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-integrantes/{id}/disciplinas', pathParams, queryParams)
export const useQueryTurmaIntegrantesControllerListarIntegranteDisciplinasTurma = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrantes/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaIntegrantesControllerListarIntegranteDisciplinasTurma.queryKey = '/api/v1/turma-integrantes/{id}/disciplinas'

export const mutationTurmaIntegrantesControllerCadastrarTagIntegrante = (pathParams, options) => mutationFn('post', '/api/v1/turma-integrantes/{id}/tags', pathParams, {}, options)
export const useMutationTurmaIntegrantesControllerCadastrarTagIntegrante = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-integrantes/{id}/tags', pathParams, {}, options), config)

export const queryTurmaIntegrantesControllerListarTagsIntegrante = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-integrantes/{id}/tags', pathParams, queryParams)
export const useQueryTurmaIntegrantesControllerListarTagsIntegrante = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrantes/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaIntegrantesControllerListarTagsIntegrante.queryKey = '/api/v1/turma-integrantes/{id}/tags'

export const mutationTurmaIntegrantesControllerDeletarTagIntegrante = (pathParams, options) => mutationFn('delete', '/api/v1/turma-integrantes/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationTurmaIntegrantesControllerDeletarTagIntegrante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-integrantes/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryTurmaIntegrantesControllerListarTagsDisponiveisIntegrante = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-integrantes/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryTurmaIntegrantesControllerListarTagsDisponiveisIntegrante = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrantes/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaIntegrantesControllerListarTagsDisponiveisIntegrante.queryKey = '/api/v1/turma-integrantes/{id}/tags-disponiveis'

export const mutationTurmaIntegrantesControllerMudarIntegranteTurma = (pathParams, options) => mutationFn('post', '/api/v1/turma-integrantes/{id}/mudar-integrante-de-turma', pathParams, {}, options)
export const useMutationTurmaIntegrantesControllerMudarIntegranteTurma = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-integrantes/{id}/mudar-integrante-de-turma', pathParams, {}, options), config)

export const queryTurmaIntegrantesControllerListarTurmaIntegranteDisciplinaDocumentos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-integrantes/{id}/turmas-integrantes-disciplinas-documentos', pathParams, queryParams)
export const useQueryTurmaIntegrantesControllerListarTurmaIntegranteDisciplinaDocumentos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrantes/{id}/turmas-integrantes-disciplinas-documentos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaIntegrantesControllerListarTurmaIntegranteDisciplinaDocumentos.queryKey = '/api/v1/turma-integrantes/{id}/turmas-integrantes-disciplinas-documentos'

export const mutationTurmaIntegrantesControllerCadastrarTurmaIntegranteDisciplinaDocumento = (pathParams, options) => mutationFn('post', '/api/v1/turma-integrantes/{id}/documentos', pathParams, {}, options)
export const useMutationTurmaIntegrantesControllerCadastrarTurmaIntegranteDisciplinaDocumento = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-integrantes/{id}/documentos', pathParams, {}, options), config)

export const queryTurmaIntegrantesControllerObterTurmaIntegrantesPorSituacaoTurma = (queryParams, options) => queryFn(options)('/api/v1/turma-integrantes/integrantes-turma-situacao', queryParams)
export const useQueryTurmaIntegrantesControllerObterTurmaIntegrantesPorSituacaoTurma = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-integrantes/integrantes-turma-situacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmaIntegrantesControllerObterTurmaIntegrantesPorSituacaoTurma.queryKey = '/api/v1/turma-integrantes/integrantes-turma-situacao'

export const mutationTurmaNotasIntegrantesControllerAtualizarJustificativaNotaIntegrante = (pathParams, options) => mutationFn('put', '/api/v1/turma-notas-integrantes/{id}/justificativa', pathParams, {}, options)
export const useMutationTurmaNotasIntegrantesControllerAtualizarJustificativaNotaIntegrante = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-notas-integrantes/{id}/justificativa', pathParams, {}, options), config)

export const queryTurmaNotasIntegrantesControllerListarJustificativasNotaIntegranteHabilidade = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-notas-integrantes/{id}/justificativas-habilidades', pathParams, queryParams)
export const useQueryTurmaNotasIntegrantesControllerListarJustificativasNotaIntegranteHabilidade = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-notas-integrantes/{id}/justificativas-habilidades', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaNotasIntegrantesControllerListarJustificativasNotaIntegranteHabilidade.queryKey = '/api/v1/turma-notas-integrantes/{id}/justificativas-habilidades'

export const mutationTurmaNotasIntegrantesControllerCadastrarArquivo = (pathParams, options) => mutationFn('post', '/api/v1/turma-notas-integrantes/{id}/justificativas-arquivos', pathParams, {}, options)
export const useMutationTurmaNotasIntegrantesControllerCadastrarArquivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-notas-integrantes/{id}/justificativas-arquivos', pathParams, {}, options), config)

export const queryTurmaNotasIntegrantesControllerListarArquivos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-notas-integrantes/{id}/justificativas-arquivos', pathParams, queryParams)
export const useQueryTurmaNotasIntegrantesControllerListarArquivos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-notas-integrantes/{id}/justificativas-arquivos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmaNotasIntegrantesControllerListarArquivos.queryKey = '/api/v1/turma-notas-integrantes/{id}/justificativas-arquivos'

export const mutationTurmaNotasIntegrantesControllerDeletarArquivo = (pathParams, options) => mutationFn('delete', '/api/v1/turma-notas-integrantes/{id}/justificativas-arquivos/{arquivoId}', pathParams, {}, options)
export const useMutationTurmaNotasIntegrantesControllerDeletarArquivo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-notas-integrantes/{id}/justificativas-arquivos/{arquivoId}', pathParams, {}, options), config)

export const mutationTurmaNotasIntegrantesHabilidadesJustificativaControllerAtualizarTurmaNotaIntegranteHabilidadeJustificativa = (pathParams, options) => mutationFn('put', '/api/v1/turma-notas-integrantes-habilidades-justificativa/{id}', pathParams, {}, options)
export const useMutationTurmaNotasIntegrantesHabilidadesJustificativaControllerAtualizarTurmaNotaIntegranteHabilidadeJustificativa = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-notas-integrantes-habilidades-justificativa/{id}', pathParams, {}, options), config)

export const queryTurmasControllerObterDiarioAula = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/diario-aula', pathParams, queryParams)
export const useQueryTurmasControllerObterDiarioAula = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/diario-aula', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterDiarioAula.queryKey = '/api/v1/turmas/{id}/diario-aula'

export const queryTurmasControllerObterDiarioInfantil = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/diario-aula-infantil', pathParams, queryParams)
export const useQueryTurmasControllerObterDiarioInfantil = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/diario-aula-infantil', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterDiarioInfantil.queryKey = '/api/v1/turmas/{id}/diario-aula-infantil'

export const queryTurmasControllerObterDiarioInfantilExisteItemInformado = (pathParams, options) => queryFn(options)('/api/v1/turmas/{id}/diario-aula-infantil/{data}', pathParams)
export const useQueryTurmasControllerObterDiarioInfantilExisteItemInformado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/diario-aula-infantil/{data}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.data, ...config }
})
useQueryTurmasControllerObterDiarioInfantilExisteItemInformado.queryKey = '/api/v1/turmas/{id}/diario-aula-infantil/{data}'

export const queryTurmasControllerObterDiarioInfantilExisteItemInformadoNoAno = (pathParams, options) => queryFn(options)('/api/v1/turmas/{id}/diario-aula-infantil/informado-ano', pathParams)
export const useQueryTurmasControllerObterDiarioInfantilExisteItemInformadoNoAno = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/diario-aula-infantil/informado-ano', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterDiarioInfantilExisteItemInformadoNoAno.queryKey = '/api/v1/turmas/{id}/diario-aula-infantil/informado-ano'

export const queryTurmasControllerObterDiarioAulaIntegrante = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/pessoa/{pessoaId}/diario-aula', pathParams, queryParams)
export const useQueryTurmasControllerObterDiarioAulaIntegrante = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/pessoa/{pessoaId}/diario-aula', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.pessoaId, ...config }
})
useQueryTurmasControllerObterDiarioAulaIntegrante.queryKey = '/api/v1/turmas/{id}/pessoa/{pessoaId}/diario-aula'

export const queryTurmasControllerObterFinanceiro = (pathParams, options) => queryFn(options)('/api/v1/turmas/{id}/planos-financeiro', pathParams)
export const useQueryTurmasControllerObterFinanceiro = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/planos-financeiro', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterFinanceiro.queryKey = '/api/v1/turmas/{id}/planos-financeiro'

export const mutationTurmasControllerCadastrar = (options) => mutationFn('post', '/api/v1/turmas', {}, {}, options)
export const useMutationTurmasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/turmas', {}, {}, options), config)

export const queryTurmasControllerListar = (queryParams, options) => queryFn(options)('/api/v1/turmas', queryParams)
export const useQueryTurmasControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmasControllerListar.queryKey = '/api/v1/turmas'

export const queryTurmasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turmas/{id}', pathParams)
export const useQueryTurmasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterPorId.queryKey = '/api/v1/turmas/{id}'

export const mutationTurmasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/turmas/{id}', pathParams, {}, options)
export const useMutationTurmasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas/{id}', pathParams, {}, options), config)

export const mutationTurmasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/turmas/{id}', pathParams, {}, options)
export const useMutationTurmasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas/{id}', pathParams, {}, options), config)

export const queryTurmasControllerListarTurmasSincronizadasApp = (queryParams, options) => queryFn(options)('/api/v1/turmas/app', queryParams)
export const useQueryTurmasControllerListarTurmasSincronizadasApp = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/app', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmasControllerListarTurmasSincronizadasApp.queryKey = '/api/v1/turmas/app'

export const queryTurmasControllerListarPorFiltros = (queryParams, options) => queryFn(options)('/api/v1/turmas/listar-filtros', queryParams)
export const useQueryTurmasControllerListarPorFiltros = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/listar-filtros', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmasControllerListarPorFiltros.queryKey = '/api/v1/turmas/listar-filtros'

export const mutationTurmasControllerCadastrarEquipePedagogica = (pathParams, options) => mutationFn('post', '/api/v1/turmas/{id}/funcionarios', pathParams, {}, options)
export const useMutationTurmasControllerCadastrarEquipePedagogica = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas/{id}/funcionarios', pathParams, {}, options), config)

export const queryTurmasControllerListarEquipePedagogica = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/funcionarios', pathParams, queryParams)
export const useQueryTurmasControllerListarEquipePedagogica = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/funcionarios', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerListarEquipePedagogica.queryKey = '/api/v1/turmas/{id}/funcionarios'

export const mutationTurmasControllerDeletarFuncionarioFuncao = (pathParams, options) => mutationFn('delete', '/api/v1/turmas/{id}/funcionarios/{funcionarioId}/funcoes/{funcaoId}', pathParams, {}, options)
export const useMutationTurmasControllerDeletarFuncionarioFuncao = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas/{id}/funcionarios/{funcionarioId}/funcoes/{funcaoId}', pathParams, {}, options), config)

export const mutationTurmasControllerCadastrarTagTurma = (pathParams, options) => mutationFn('post', '/api/v1/turmas/{id}/tags', pathParams, {}, options)
export const useMutationTurmasControllerCadastrarTagTurma = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas/{id}/tags', pathParams, {}, options), config)

export const queryTurmasControllerListarTagsTurma = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/tags', pathParams, queryParams)
export const useQueryTurmasControllerListarTagsTurma = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/tags', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerListarTagsTurma.queryKey = '/api/v1/turmas/{id}/tags'

export const mutationTurmasControllerDeletarTagTurma = (pathParams, options) => mutationFn('delete', '/api/v1/turmas/{id}/tags/{tagId}', pathParams, {}, options)
export const useMutationTurmasControllerDeletarTagTurma = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas/{id}/tags/{tagId}', pathParams, {}, options), config)

export const queryTurmasControllerListarTagsDisponiveisTurma = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/tags-disponiveis', pathParams, queryParams)
export const useQueryTurmasControllerListarTagsDisponiveisTurma = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/tags-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerListarTagsDisponiveisTurma.queryKey = '/api/v1/turmas/{id}/tags-disponiveis'

export const queryTurmasControllerObterOcorrencias = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/ocorrencias', pathParams, queryParams)
export const useQueryTurmasControllerObterOcorrencias = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/ocorrencias', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterOcorrencias.queryKey = '/api/v1/turmas/{id}/ocorrencias'

export const queryTurmasControllerListarTurmasPorIntegranteId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/turmas-integrante/{pessoaId}', pathParams, queryParams)
export const useQueryTurmasControllerListarTurmasPorIntegranteId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/turmas-integrante/{pessoaId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.pessoaId, ...config }
})
useQueryTurmasControllerListarTurmasPorIntegranteId.queryKey = '/api/v1/turmas/turmas-integrante/{pessoaId}'

export const queryTurmasControllerListarTurmasPorAnoFaixaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/turmas-ano-faixa/{anoFaixaId}', pathParams, queryParams)
export const useQueryTurmasControllerListarTurmasPorAnoFaixaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/turmas-ano-faixa/{anoFaixaId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.anoFaixaId, ...config }
})
useQueryTurmasControllerListarTurmasPorAnoFaixaId.queryKey = '/api/v1/turmas/turmas-ano-faixa/{anoFaixaId}'

export const queryTurmasControllerListarAnosLetivo = (options) => queryFn(options)('/api/v1/turmas/anos-letivo')
export const useQueryTurmasControllerListarAnosLetivo = (config, options) => useQuery({
  queryKey: ['/api/v1/turmas/anos-letivo'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmasControllerListarAnosLetivo.queryKey = '/api/v1/turmas/anos-letivo'

export const mutationTurmasControllerCadastrarIntegrante = (pathParams, options) => mutationFn('post', '/api/v1/turmas/{id}/integrantes', pathParams, {}, options)
export const useMutationTurmasControllerCadastrarIntegrante = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas/{id}/integrantes', pathParams, {}, options), config)

export const queryTurmasControllerListarIntegrantesTurma = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/integrantes', pathParams, queryParams)
export const useQueryTurmasControllerListarIntegrantesTurma = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/integrantes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerListarIntegrantesTurma.queryKey = '/api/v1/turmas/{id}/integrantes'

export const queryTurmasControllerListarIntegrantesDisciplinaTurma = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/disciplina/{disciplinaId}/integrantes', pathParams, queryParams)
export const useQueryTurmasControllerListarIntegrantesDisciplinaTurma = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/disciplina/{disciplinaId}/integrantes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.disciplinaId, ...config }
})
useQueryTurmasControllerListarIntegrantesDisciplinaTurma.queryKey = '/api/v1/turmas/{id}/disciplina/{disciplinaId}/integrantes'

export const queryTurmasControllerObterResponsaveisTurma = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/responsaveis', pathParams, queryParams)
export const useQueryTurmasControllerObterResponsaveisTurma = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/responsaveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterResponsaveisTurma.queryKey = '/api/v1/turmas/{id}/responsaveis'

export const queryTurmasControllerObterAcessoAppResponsaveisTurma = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/responsaveis/acesso-app', pathParams, queryParams)
export const useQueryTurmasControllerObterAcessoAppResponsaveisTurma = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/responsaveis/acesso-app', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterAcessoAppResponsaveisTurma.queryKey = '/api/v1/turmas/{id}/responsaveis/acesso-app'

export const queryTurmasControllerObterTurmaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/turmas/{id}/contas/{contaId}', pathParams)
export const useQueryTurmasControllerObterTurmaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTurmasControllerObterTurmaContaPorId.queryKey = '/api/v1/turmas/{id}/contas/{contaId}'

export const mutationTurmasControllerAtualizarTurmaConta = (pathParams, options) => mutationFn('put', '/api/v1/turmas/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTurmasControllerAtualizarTurmaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTurmasControllerListarTurmaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/contas', pathParams, queryParams)
export const useQueryTurmasControllerListarTurmaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerListarTurmaContas.queryKey = '/api/v1/turmas/{id}/contas'

export const mutationTurmasControllerCadastrarTurmaGradeCurricular = (pathParams, options) => mutationFn('post', '/api/v1/turmas/{id}/grades-curriculares', pathParams, {}, options)
export const useMutationTurmasControllerCadastrarTurmaGradeCurricular = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas/{id}/grades-curriculares', pathParams, {}, options), config)

export const queryTurmasControllerObterTurmaGradeCurricularPorTurmaId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/grades-curriculares', pathParams, queryParams)
export const useQueryTurmasControllerObterTurmaGradeCurricularPorTurmaId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/grades-curriculares', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterTurmaGradeCurricularPorTurmaId.queryKey = '/api/v1/turmas/{id}/grades-curriculares'

export const mutationTurmasControllerCadastrarTurmaGradeCurricularParecerDescritivo = (pathParams, options) => mutationFn('post', '/api/v1/turmas/{id}/grades-curriculares/{idTurmaGradeCurricular}/parecer-descritivo', pathParams, {}, options)
export const useMutationTurmasControllerCadastrarTurmaGradeCurricularParecerDescritivo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas/{id}/grades-curriculares/{idTurmaGradeCurricular}/parecer-descritivo', pathParams, {}, options), config)

export const queryTurmasControllerObterTurmaGradeCurricularParecerDescritivo = (pathParams, options) => queryFn(options)('/api/v1/turmas/{id}/grades-curriculares/{idTurmaGradeCurricular}/parecer-descritivo', pathParams)
export const useQueryTurmasControllerObterTurmaGradeCurricularParecerDescritivo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/grades-curriculares/{idTurmaGradeCurricular}/parecer-descritivo', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.idTurmaGradeCurricular, ...config }
})
useQueryTurmasControllerObterTurmaGradeCurricularParecerDescritivo.queryKey = '/api/v1/turmas/{id}/grades-curriculares/{idTurmaGradeCurricular}/parecer-descritivo'

export const mutationTurmasControllerAprovarCoordenacaoPareceresPorPeriodoAvaliativo = (pathParams, options) => mutationFn('patch', '/api/v1/turmas/{id}/aprova-coordenacao-pareceres', pathParams, {}, options)
export const useMutationTurmasControllerAprovarCoordenacaoPareceresPorPeriodoAvaliativo = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turmas/{id}/aprova-coordenacao-pareceres', pathParams, {}, options), config)

export const queryTurmasControllerListarDisciplinasTurma = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/disciplinas', pathParams, queryParams)
export const useQueryTurmasControllerListarDisciplinasTurma = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerListarDisciplinasTurma.queryKey = '/api/v1/turmas/{id}/disciplinas'

export const queryTurmasControllerObterDataAulasDisciplina = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/disciplina{disciplinaId}/aulas', pathParams, queryParams)
export const useQueryTurmasControllerObterDataAulasDisciplina = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/disciplina{disciplinaId}/aulas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.disciplinaId, ...config }
})
useQueryTurmasControllerObterDataAulasDisciplina.queryKey = '/api/v1/turmas/{id}/disciplina{disciplinaId}/aulas'

export const queryTurmasControllerObterDiasDisponiveisParaLancamentoAula = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/datas-disponiveis-aula', pathParams, queryParams)
export const useQueryTurmasControllerObterDiasDisponiveisParaLancamentoAula = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/datas-disponiveis-aula', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterDiasDisponiveisParaLancamentoAula.queryKey = '/api/v1/turmas/{id}/datas-disponiveis-aula'

export const mutationTurmasControllerCadastrarNotaPeriodoIntegrante = (pathParams, options) => mutationFn('post', '/api/v1/turmas/{id}/notas', pathParams, {}, options)
export const useMutationTurmasControllerCadastrarNotaPeriodoIntegrante = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas/{id}/notas', pathParams, {}, options), config)

export const mutationTurmasControllerAtualizarNotaPeriodoIntegrante = (pathParams, options) => mutationFn('put', '/api/v1/turmas/{id}/notas/{notaId}', pathParams, {}, options)
export const useMutationTurmasControllerAtualizarNotaPeriodoIntegrante = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas/{id}/notas/{notaId}', pathParams, {}, options), config)

export const mutationTurmasControllerDeletarNotaTurma = (pathParams, options) => mutationFn('delete', '/api/v1/turmas/{id}/notas/{notaId}/turma-nota-integrante', pathParams, {}, options)
export const useMutationTurmasControllerDeletarNotaTurma = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas/{id}/notas/{notaId}/turma-nota-integrante', pathParams, {}, options), config)

export const queryTurmasControllerObterNotasDisciplinas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/disciplina-notas/{disciplinaId}/avaliacao/{avaliacaoId}/periodo-avaliativo/{periodoAvaliativoId}', pathParams, queryParams)
export const useQueryTurmasControllerObterNotasDisciplinas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/disciplina-notas/{disciplinaId}/avaliacao/{avaliacaoId}/periodo-avaliativo/{periodoAvaliativoId}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.disciplinaId && pathParams.avaliacaoId && pathParams.periodoAvaliativoId, ...config }
})
useQueryTurmasControllerObterNotasDisciplinas.queryKey = '/api/v1/turmas/{id}/disciplina-notas/{disciplinaId}/avaliacao/{avaliacaoId}/periodo-avaliativo/{periodoAvaliativoId}'

export const queryTurmasControllerObterAvaliacoesFaltasIntegrante = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/disciplina-notas', pathParams, queryParams)
export const useQueryTurmasControllerObterAvaliacoesFaltasIntegrante = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/disciplina-notas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterAvaliacoesFaltasIntegrante.queryKey = '/api/v1/turmas/{id}/disciplina-notas'

export const queryTurmasControllerObterNotasFinaisFaltasIntegrante = (pathParams, options) => queryFn(options)('/api/v1/turmas/{id}/notas-finais-frequencias/{turmaIntegranteId}', pathParams)
export const useQueryTurmasControllerObterNotasFinaisFaltasIntegrante = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/notas-finais-frequencias/{turmaIntegranteId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.turmaIntegranteId, ...config }
})
useQueryTurmasControllerObterNotasFinaisFaltasIntegrante.queryKey = '/api/v1/turmas/{id}/notas-finais-frequencias/{turmaIntegranteId}'

export const queryTurmasControllerObterBoletimIntegrante = (pathParams, options) => queryFn(options)('/api/v1/turmas/{id}/turma-integrante/{turmaIntegranteId}/boletim', pathParams)
export const useQueryTurmasControllerObterBoletimIntegrante = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/turma-integrante/{turmaIntegranteId}/boletim', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.turmaIntegranteId, ...config }
})
useQueryTurmasControllerObterBoletimIntegrante.queryKey = '/api/v1/turmas/{id}/turma-integrante/{turmaIntegranteId}/boletim'

export const mutationTurmasControllerAtualizarBoletimIntegrante = (pathParams, options) => mutationFn('put', '/api/v1/turmas/boletim/{turmaIntegranteId}', pathParams, {}, options)
export const useMutationTurmasControllerAtualizarBoletimIntegrante = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas/boletim/{turmaIntegranteId}', pathParams, {}, options), config)

export const queryTurmasControllerObterSituacaoFinalIntegrantes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/situacao-final-integrantes', pathParams, queryParams)
export const useQueryTurmasControllerObterSituacaoFinalIntegrantes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/situacao-final-integrantes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterSituacaoFinalIntegrantes.queryKey = '/api/v1/turmas/{id}/situacao-final-integrantes'

export const mutationTurmasControllerAtualizarSituacaoFinalIntegrante = (pathParams, options) => mutationFn('put', '/api/v1/turmas/atualiza-situacao-final-integrante/{turmaIntegranteId}', pathParams, {}, options)
export const useMutationTurmasControllerAtualizarSituacaoFinalIntegrante = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas/atualiza-situacao-final-integrante/{turmaIntegranteId}', pathParams, {}, options), config)

export const mutationTurmasControllerCadastrarConfiguracaoAula = (pathParams, options) => mutationFn('post', '/api/v1/turmas/{turmaId}/configuracao-de-aula', pathParams, {}, options)
export const useMutationTurmasControllerCadastrarConfiguracaoAula = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas/{turmaId}/configuracao-de-aula', pathParams, {}, options), config)

export const mutationTurmasControllerDeletarConfiguracaoAula = (pathParams, queryParams, options) => mutationFn('delete', '/api/v1/turmas/{id}/configuracao-de-aula/{horariosFuncionamentoEducacaoId}', pathParams, queryParams, options)
export const useMutationTurmasControllerDeletarConfiguracaoAula = (pathParams, queryParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas/{id}/configuracao-de-aula/{horariosFuncionamentoEducacaoId}', pathParams, queryParams, options), config)

export const queryTurmasControllerObterListaHorariosFuncionamento = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/configuracao-de-aula', pathParams, queryParams)
export const useQueryTurmasControllerObterListaHorariosFuncionamento = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/configuracao-de-aula', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterListaHorariosFuncionamento.queryKey = '/api/v1/turmas/{id}/configuracao-de-aula'

export const queryTurmasControllerObterPeriodosAvaliativos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas/{id}/periodos-avaliativos', pathParams, queryParams)
export const useQueryTurmasControllerObterPeriodosAvaliativos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas/{id}/periodos-avaliativos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasControllerObterPeriodosAvaliativos.queryKey = '/api/v1/turmas/{id}/periodos-avaliativos'

export const mutationTurmasDiarioAulaPlanosAulaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/turma-diarios-aula-planos-aula/{id}', pathParams, {}, options)
export const useMutationTurmasDiarioAulaPlanosAulaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-diarios-aula-planos-aula/{id}', pathParams, {}, options), config)

export const mutationTurmasEquipePedagogicaControllerCadastrarDisciplina = (pathParams, options) => mutationFn('post', '/api/v1/turmas-equipe-pedagogica/{id}/disciplinas', pathParams, {}, options)
export const useMutationTurmasEquipePedagogicaControllerCadastrarDisciplina = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-equipe-pedagogica/{id}/disciplinas', pathParams, {}, options), config)

export const queryTurmasEquipePedagogicaControllerObterListaDisciplinas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-equipe-pedagogica/{id}/disciplinas', pathParams, queryParams)
export const useQueryTurmasEquipePedagogicaControllerObterListaDisciplinas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-equipe-pedagogica/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasEquipePedagogicaControllerObterListaDisciplinas.queryKey = '/api/v1/turmas-equipe-pedagogica/{id}/disciplinas'

export const queryTurmasEquipePedagogicaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turmas-equipe-pedagogica/{id}', pathParams)
export const useQueryTurmasEquipePedagogicaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-equipe-pedagogica/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasEquipePedagogicaControllerObterPorId.queryKey = '/api/v1/turmas-equipe-pedagogica/{id}'

export const mutationTurmasEquipePedagogicaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/turmas-equipe-pedagogica/{id}', pathParams, {}, options)
export const useMutationTurmasEquipePedagogicaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas-equipe-pedagogica/{id}', pathParams, {}, options), config)

export const mutationTurmasEquipePedagogicaDisciplinaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/turmas-equipe-pedagogica-disciplina/{id}', pathParams, {}, options)
export const useMutationTurmasEquipePedagogicaDisciplinaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas-equipe-pedagogica-disciplina/{id}', pathParams, {}, options), config)

export const mutationTurmasGradeCurricularControllerAtualizarTurmaGradeCurricular = (pathParams, options) => mutationFn('put', '/api/v1/turmas-grades-curriculares/{id}', pathParams, {}, options)
export const useMutationTurmasGradeCurricularControllerAtualizarTurmaGradeCurricular = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas-grades-curriculares/{id}', pathParams, {}, options), config)

export const queryTurmasGradeCurricularControllerObterTurmaGradeCurricular = (pathParams, options) => queryFn(options)('/api/v1/turmas-grades-curriculares/{id}', pathParams)
export const useQueryTurmasGradeCurricularControllerObterTurmaGradeCurricular = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grades-curriculares/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradeCurricularControllerObterTurmaGradeCurricular.queryKey = '/api/v1/turmas-grades-curriculares/{id}'

export const mutationTurmasGradeCurricularControllerCadastrarTurmaGradeCurriclarDisciplinaExtra = (pathParams, options) => mutationFn('post', '/api/v1/turmas-grades-curriculares/{id}/disciplinas-extra', pathParams, {}, options)
export const useMutationTurmasGradeCurricularControllerCadastrarTurmaGradeCurriclarDisciplinaExtra = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-grades-curriculares/{id}/disciplinas-extra', pathParams, {}, options), config)

export const queryTurmasGradeCurricularControllerListarTurmaGradeCurricularDisciplinasExtra = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grades-curriculares/{id}/disciplinas-extra', pathParams, queryParams)
export const useQueryTurmasGradeCurricularControllerListarTurmaGradeCurricularDisciplinasExtra = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grades-curriculares/{id}/disciplinas-extra', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradeCurricularControllerListarTurmaGradeCurricularDisciplinasExtra.queryKey = '/api/v1/turmas-grades-curriculares/{id}/disciplinas-extra'

export const queryTurmasGradeCurricularControllerListarTurmaGradeCurricularDisciplinas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grades-curriculares/{id}/disciplinas', pathParams, queryParams)
export const useQueryTurmasGradeCurricularControllerListarTurmaGradeCurricularDisciplinas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grades-curriculares/{id}/disciplinas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradeCurricularControllerListarTurmaGradeCurricularDisciplinas.queryKey = '/api/v1/turmas-grades-curriculares/{id}/disciplinas'

export const queryTurmasGradesCurricularesDisciplinasControllerObterTurmaGradeCurricularDisciplina = (pathParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerObterTurmaGradeCurricularDisciplina = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerObterTurmaGradeCurricularDisciplina.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}'

export const mutationTurmasGradesCurricularesDisciplinasControllerDeletarTurmaGradeCurriclarDisciplina = (pathParams, options) => mutationFn('delete', '/api/v1/turmas-grade-curricular-disciplinas/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerDeletarTurmaGradeCurriclarDisciplina = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turmas-grade-curricular-disciplinas/{id}', pathParams, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasControllerAtualizarTurmaGradeCurriclarDisciplinaExtra = (pathParams, options) => mutationFn('put', '/api/v1/turmas-grade-curricular-disciplinas/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerAtualizarTurmaGradeCurriclarDisciplinaExtra = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turmas-grade-curricular-disciplinas/{id}', pathParams, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasControllerCadastrarAtividadePratica = (pathParams, options) => mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/atividades-praticas', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerCadastrarAtividadePratica = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/atividades-praticas', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasControllerBuscarAtividadesPratica = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/atividades-praticas', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerBuscarAtividadesPratica = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/atividades-praticas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerBuscarAtividadesPratica.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/atividades-praticas'

export const mutationTurmasGradesCurricularesDisciplinasControllerCadastrarBibliografiaBasica = (pathParams, options) => mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-basicas', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerCadastrarBibliografiaBasica = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-basicas', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasControllerListarBibliografiaBasica = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-basicas', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerListarBibliografiaBasica = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-basicas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerListarBibliografiaBasica.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-basicas'

export const mutationTurmasGradesCurricularesDisciplinasControllerCadastrarBibliografiaComplementar = (pathParams, options) => mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-complementares', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerCadastrarBibliografiaComplementar = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-complementares', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasControllerListarBibliografiaComplementar = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-complementares', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerListarBibliografiaComplementar = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-complementares', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerListarBibliografiaComplementar.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/bibliografias-complementares'

export const mutationTurmasGradesCurricularesDisciplinasControllerCadastrarCriteriosAvaliacao = (pathParams, options) => mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/criterios-avaliacao', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerCadastrarCriteriosAvaliacao = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/criterios-avaliacao', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasControllerListarCriteriosAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/criterios-avaliacao', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerListarCriteriosAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/criterios-avaliacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerListarCriteriosAvaliacao.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/criterios-avaliacao'

export const mutationTurmasGradesCurricularesDisciplinasControllerCadastrarPlanosAula = (pathParams, options) => mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/planos-de-aula', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerCadastrarPlanosAula = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/planos-de-aula', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasControllerListarPlanosAula = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/planos-de-aula', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerListarPlanosAula = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/planos-de-aula', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerListarPlanosAula.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/planos-de-aula'

export const queryTurmasGradesCurricularesDisciplinasControllerObterPlanoEnsinoPorGradeCurricularDisciplinaId = (pathParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/planos-ensino', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerObterPlanoEnsinoPorGradeCurricularDisciplinaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/planos-ensino', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerObterPlanoEnsinoPorGradeCurricularDisciplinaId.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/planos-ensino'

export const mutationTurmasGradesCurricularesDisciplinasControllerCadastrarRecursoDidatico = (pathParams, options) => mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/recursos-didaticos', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerCadastrarRecursoDidatico = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/recursos-didaticos', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasControllerListarRecursoDidatico = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/recursos-didaticos', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerListarRecursoDidatico = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/recursos-didaticos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerListarRecursoDidatico.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/recursos-didaticos'

export const mutationTurmasGradesCurricularesDisciplinasControllerCadastrarTecnicaEnsino = (pathParams, options) => mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/tecnicas-ensino', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasControllerCadastrarTecnicaEnsino = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turmas-grade-curricular-disciplinas/{id}/tecnicas-ensino', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasControllerListarTecnicaEnsino = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/tecnicas-ensino', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerListarTecnicaEnsino = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/tecnicas-ensino', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerListarTecnicaEnsino.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/tecnicas-ensino'

export const queryTurmasGradesCurricularesDisciplinasControllerObterNumeroAulaPlanoAulaTurmaGradeCurricular = (pathParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/numero-aula', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerObterNumeroAulaPlanoAulaTurmaGradeCurricular = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/numero-aula', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerObterNumeroAulaPlanoAulaTurmaGradeCurricular.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/numero-aula'

export const queryTurmasGradesCurricularesDisciplinasControllerListarModelosAvaliacao = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/modelos-avaliacao', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerListarModelosAvaliacao = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/modelos-avaliacao', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerListarModelosAvaliacao.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/modelos-avaliacao'

export const queryTurmasGradesCurricularesDisciplinasControllerObterIntegrantesEnsaladosDisciplina = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/integrantes-ensalados-disciplina', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerObterIntegrantesEnsaladosDisciplina = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/integrantes-ensalados-disciplina', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerObterIntegrantesEnsaladosDisciplina.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/integrantes-ensalados-disciplina'

export const queryTurmasGradesCurricularesDisciplinasControllerObterPeriodosAvaliativos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerObterPeriodosAvaliativos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerObterPeriodosAvaliativos.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos'

export const queryTurmasGradesCurricularesDisciplinasControllerListarAvaliacoes = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos/{tipoPeriodoNotaId}/avaliacoes', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerListarAvaliacoes = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos/{tipoPeriodoNotaId}/avaliacoes', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tipoPeriodoNotaId, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerListarAvaliacoes.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos/{tipoPeriodoNotaId}/avaliacoes'

export const queryTurmasGradesCurricularesDisciplinasControllerObterNotasPeriodoAvaliativo = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos/{tipoPeriodoNotaId}/notas', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerObterNotasPeriodoAvaliativo = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos/{tipoPeriodoNotaId}/notas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.tipoPeriodoNotaId, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerObterNotasPeriodoAvaliativo.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/periodos-avaliativos/{tipoPeriodoNotaId}/notas'

export const queryTurmasGradesCurricularesDisciplinasControllerObterNotasFinaisFrequenciaDisciplina = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turmas-grade-curricular-disciplinas/{id}/notas-finais-frequencia', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasControllerObterNotasFinaisFrequenciaDisciplina = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turmas-grade-curricular-disciplinas/{id}/notas-finais-frequencia', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasControllerObterNotasFinaisFrequenciaDisciplina.queryKey = '/api/v1/turmas-grade-curricular-disciplinas/{id}/notas-finais-frequencia'

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPorId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}'

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarAssinante = (pathParams, options) => mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarAssinante = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPeriodos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/periodos', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPeriodos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/periodos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterPeriodos.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/periodos'

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterFormulas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/formulas', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterFormulas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/formulas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerObterFormulas.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/formulas'

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarTurmaGradeCurricularDisciplinaModeloAvaliacaoPeriodoGruposPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-grupos', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarTurmaGradeCurricularDisciplinaModeloAvaliacaoPeriodoGruposPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-grupos', pathParams, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarTurmaGradeCurricularDisciplinaModeloAvaliacaoPeriodoAvaliacoesPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-avaliacoes', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarTurmaGradeCurricularDisciplinaModeloAvaliacaoPeriodoAvaliacoesPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-avaliacoes', pathParams, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarTurmaGradeCurricularDisciplinaModeloAvaliacaoPeriodoConceitosPorPeriodos = (pathParams, options) => mutationFn('patch', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-conceitos', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoControllerDeletarTurmaGradeCurricularDisciplinaModeloAvaliacaoPeriodoConceitosPorPeriodos = (pathParams, config, options) => useMutation(mutationFn('patch', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao/{id}/deletar-conceitos', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterPorId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}'

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterGrupos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/grupos', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterGrupos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/grupos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterGrupos.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/grupos'

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterConceitos = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/conceitos', pathParams, queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterConceitos = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/conceitos', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoControllerObterConceitos.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo/{id}/conceitos'

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerCadastrarModeloAvaliacaoPeriodoConceito = (options) => mutationFn('post', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito', {}, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerCadastrarModeloAvaliacaoPeriodoConceito = (config, options) => useMutation(mutationFn('post', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito', {}, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerAtualizarModeloAvaliacaoPeriodoConceito = (pathParams, options) => mutationFn('put', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerAtualizarModeloAvaliacaoPeriodoConceito = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/{id}', pathParams, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerDeletarModeloAvaliacaoPeriodoConceito = (pathParams, options) => mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerDeletarModeloAvaliacaoPeriodoConceito = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/{id}', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/conceitos-identicos-por-periodos', queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/conceitos-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoConceitoControllerObterConceitosIdenticosPorPeriodo.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-conceito/conceitos-identicos-por-periodos'

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerCadastrarModeloAvaliacaoPeriodoDetalhe = (options) => mutationFn('post', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe', {}, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerCadastrarModeloAvaliacaoPeriodoDetalhe = (config, options) => useMutation(mutationFn('post', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe', {}, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterPorId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/{id}'

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos', queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoDetalhesControllerObterDetalhesIdenticosPorPeriodo.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-detalhe/detalhes-identicos-por-periodos'

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerCadastrarModeloAvaliacaoPeriodoFormula = (options) => mutationFn('post', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula', {}, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerCadastrarModeloAvaliacaoPeriodoFormula = (config, options) => useMutation(mutationFn('post', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula', {}, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerDeletarModeloAvaliacaoPeriodoFormula = (pathParams, options) => mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerDeletarModeloAvaliacaoPeriodoFormula = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoFormulasControllerObterPorId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-formula/{id}'

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerCadastrarModeloAvaliacaoPeriodoGrupo = (options) => mutationFn('post', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo', {}, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerCadastrarModeloAvaliacaoPeriodoGrupo = (config, options) => useMutation(mutationFn('post', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo', {}, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerAtualizarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('put', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerAtualizarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/{id}', pathParams, {}, options), config)

export const mutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, options) => mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/{id}', pathParams, {}, options)
export const useMutationTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerDeletarModeloAvaliacaoPeriodoGrupo = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/{id}', pathParams, {}, options), config)

export const queryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo = (queryParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/grupos-identicos-por-periodos', queryParams)
export const useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/grupos-identicos-por-periodos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmasGradesCurricularesDisciplinasModelosAvaliacaoPeriodoGrupoControllerObterGruposIdenticosPorPeriodo.queryKey = '/api/v1/turma-grade-curricular-disciplinas-modelos-avaliacao-periodo-grupo/grupos-identicos-por-periodos'

export const queryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterLista = (queryParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao', queryParams)
export const useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterLista = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterLista.queryKey = '/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao'

export const queryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterPorId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}'

export const queryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmaGradeCurricularDisciplinaSistemaAvaliacaoArredondamentoPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/arredondamento', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmaGradeCurricularDisciplinaSistemaAvaliacaoArredondamentoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/arredondamento', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmaGradeCurricularDisciplinaSistemaAvaliacaoArredondamentoPorId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/arredondamento'

export const queryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmGradeCurricularDisciplinaSistemaAvaliacaoRecuperacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/recuperacao', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmGradeCurricularDisciplinaSistemaAvaliacaoRecuperacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/recuperacao', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmGradeCurricularDisciplinaSistemaAvaliacaoRecuperacaoPorId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/recuperacao'

export const queryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmaGradeCurricularDisciplinaSistemaAvaliacaoExameFinalPorId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/exame-final', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmaGradeCurricularDisciplinaSistemaAvaliacaoExameFinalPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/exame-final', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterTurmaGradeCurricularDisciplinaSistemaAvaliacaoExameFinalPorId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/exame-final'

export const queryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterConfiguracaoNotaParcialPorTurmaGradeCurricularDisciplinaSistemaAvaliacaoId = (pathParams, options) => queryFn(options)('/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/nota-parcial', pathParams)
export const useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterConfiguracaoNotaParcialPorTurmaGradeCurricularDisciplinaSistemaAvaliacaoId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/nota-parcial', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasGradesCurricularesDisciplinassSistemaAvaliacaoControllerObterConfiguracaoNotaParcialPorTurmaGradeCurricularDisciplinaSistemaAvaliacaoId.queryKey = '/api/v1/turma-grade-curricular-disciplinas-sistema-avaliacao/{id}/nota-parcial'

export const queryTurmasHorarioComposicaoControllerObterRecursoDidatico = (pathParams, options) => queryFn(options)('/api/v1/turma-horarios-Composicoes/{id}', pathParams)
export const useQueryTurmasHorarioComposicaoControllerObterRecursoDidatico = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-horarios-Composicoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasHorarioComposicaoControllerObterRecursoDidatico.queryKey = '/api/v1/turma-horarios-Composicoes/{id}'

export const mutationTurmasHorarioComposicaoControllerAtualizarProfessorAula = (pathParams, options) => mutationFn('put', '/api/v1/turma-horarios-Composicoes/{id}', pathParams, {}, options)
export const useMutationTurmasHorarioComposicaoControllerAtualizarProfessorAula = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turma-horarios-Composicoes/{id}', pathParams, {}, options), config)

export const mutationTurmasHorarioComposicaoControllerCadastrarConteudo = (pathParams, options) => mutationFn('post', '/api/v1/turma-horarios-Composicoes/{id}/conteudo', pathParams, {}, options)
export const useMutationTurmasHorarioComposicaoControllerCadastrarConteudo = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turma-horarios-Composicoes/{id}/conteudo', pathParams, {}, options), config)

export const queryTurmasHorarioComposicaoControllerObterConteudo = (pathParams, options) => queryFn(options)('/api/v1/turma-horarios-Composicoes/{id}/conteudo', pathParams)
export const useQueryTurmasHorarioComposicaoControllerObterConteudo = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turma-horarios-Composicoes/{id}/conteudo', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurmasHorarioComposicaoControllerObterConteudo.queryKey = '/api/v1/turma-horarios-Composicoes/{id}/conteudo'

export const queryTurnosControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/turnos', queryParams)
export const useQueryTurnosControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turnos', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryTurnosControllerBuscar.queryKey = '/api/v1/turnos'

export const mutationTurnosControllerCadastrar = (options) => mutationFn('post', '/api/v1/turnos', {}, {}, options)
export const useMutationTurnosControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/turnos', {}, {}, options), config)

export const queryTurnosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/turnos/{id}', pathParams)
export const useQueryTurnosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turnos/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurnosControllerObterPorId.queryKey = '/api/v1/turnos/{id}'

export const mutationTurnosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/turnos/{id}', pathParams, {}, options)
export const useMutationTurnosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turnos/{id}', pathParams, {}, options), config)

export const mutationTurnosControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/turnos/{id}', pathParams, {}, options)
export const useMutationTurnosControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/turnos/{id}', pathParams, {}, options), config)

export const mutationTurnosControllerCadastrarTurnoConta = (pathParams, options) => mutationFn('post', '/api/v1/turnos/{id}/contas', pathParams, {}, options)
export const useMutationTurnosControllerCadastrarTurnoConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/turnos/{id}/contas', pathParams, {}, options), config)

export const queryTurnosControllerListarTurnoContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turnos/{id}/contas', pathParams, queryParams)
export const useQueryTurnosControllerListarTurnoContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turnos/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurnosControllerListarTurnoContas.queryKey = '/api/v1/turnos/{id}/contas'

export const queryTurnosControllerObterTurnoContaPorId = (pathParams, options) => queryFn(options)('/api/v1/turnos/{id}/contas/{contaId}', pathParams)
export const useQueryTurnosControllerObterTurnoContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turnos/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryTurnosControllerObterTurnoContaPorId.queryKey = '/api/v1/turnos/{id}/contas/{contaId}'

export const mutationTurnosControllerAtualizarTurnoConta = (pathParams, options) => mutationFn('put', '/api/v1/turnos/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationTurnosControllerAtualizarTurnoConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/turnos/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryTurnosControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/turnos/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryTurnosControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/turnos/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryTurnosControllerListarContasDisponiveis.queryKey = '/api/v1/turnos/{id}/contas-disponiveis'

export const mutationUnidadesMedidaControllerCadastrar = (options) => mutationFn('post', '/api/v1/unidades-de-medida', {}, {}, options)
export const useMutationUnidadesMedidaControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/unidades-de-medida', {}, {}, options), config)

export const queryUnidadesMedidaControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/unidades-de-medida', queryParams)
export const useQueryUnidadesMedidaControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUnidadesMedidaControllerBuscar.queryKey = '/api/v1/unidades-de-medida'

export const mutationUnidadesMedidaControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/unidades-de-medida/{id}', pathParams, {}, options)
export const useMutationUnidadesMedidaControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/unidades-de-medida/{id}', pathParams, {}, options), config)

export const mutationUnidadesMedidaControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/unidades-de-medida/{id}', pathParams, {}, options)
export const useMutationUnidadesMedidaControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/unidades-de-medida/{id}', pathParams, {}, options), config)

export const queryUnidadesMedidaControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/unidades-de-medida/{id}', pathParams)
export const useQueryUnidadesMedidaControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesMedidaControllerObterPorId.queryKey = '/api/v1/unidades-de-medida/{id}'

export const queryUnidadesMedidaControllerObterPorIdDetalhado = (pathParams, options) => queryFn(options)('/api/v1/unidades-de-medida/buscar-detalhado/{id}', pathParams)
export const useQueryUnidadesMedidaControllerObterPorIdDetalhado = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/buscar-detalhado/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesMedidaControllerObterPorIdDetalhado.queryKey = '/api/v1/unidades-de-medida/buscar-detalhado/{id}'

export const mutationUnidadesMedidaControllerCadastrarUnidadeMedidaConta = (pathParams, options) => mutationFn('post', '/api/v1/unidades-de-medida/{id}/contas', pathParams, {}, options)
export const useMutationUnidadesMedidaControllerCadastrarUnidadeMedidaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/unidades-de-medida/{id}/contas', pathParams, {}, options), config)

export const queryUnidadesMedidaControllerListarUnidadeMedidaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/unidades-de-medida/{id}/contas', pathParams, queryParams)
export const useQueryUnidadesMedidaControllerListarUnidadeMedidaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesMedidaControllerListarUnidadeMedidaContas.queryKey = '/api/v1/unidades-de-medida/{id}/contas'

export const queryUnidadesMedidaControllerObterUnidadeMedidaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/unidades-de-medida/{id}/contas/{contaId}', pathParams)
export const useQueryUnidadesMedidaControllerObterUnidadeMedidaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryUnidadesMedidaControllerObterUnidadeMedidaContaPorId.queryKey = '/api/v1/unidades-de-medida/{id}/contas/{contaId}'

export const mutationUnidadesMedidaControllerAtualizarUnidadeMedidaConta = (pathParams, options) => mutationFn('put', '/api/v1/unidades-de-medida/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUnidadesMedidaControllerAtualizarUnidadeMedidaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/unidades-de-medida/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryUnidadesMedidaControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/unidades-de-medida/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryUnidadesMedidaControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-de-medida/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesMedidaControllerListarContasDisponiveis.queryKey = '/api/v1/unidades-de-medida/{id}/contas-disponiveis'

export const queryUnidadesTematicasControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/unidades-tematicas', queryParams)
export const useQueryUnidadesTematicasControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-tematicas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUnidadesTematicasControllerBuscar.queryKey = '/api/v1/unidades-tematicas'

export const mutationUnidadesTematicasControllerCadastrar = (options) => mutationFn('post', '/api/v1/unidades-tematicas', {}, {}, options)
export const useMutationUnidadesTematicasControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/unidades-tematicas', {}, {}, options), config)

export const queryUnidadesTematicasControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/unidades-tematicas/{id}', pathParams)
export const useQueryUnidadesTematicasControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-tematicas/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesTematicasControllerObterPorId.queryKey = '/api/v1/unidades-tematicas/{id}'

export const mutationUnidadesTematicasControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/unidades-tematicas/{id}', pathParams, {}, options)
export const useMutationUnidadesTematicasControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/unidades-tematicas/{id}', pathParams, {}, options), config)

export const mutationUnidadesTematicasControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/unidades-tematicas/{id}', pathParams, {}, options)
export const useMutationUnidadesTematicasControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/unidades-tematicas/{id}', pathParams, {}, options), config)

export const mutationUnidadesTematicasControllerCadastrarUnidadeTematicaConta = (pathParams, options) => mutationFn('post', '/api/v1/unidades-tematicas/{id}/contas', pathParams, {}, options)
export const useMutationUnidadesTematicasControllerCadastrarUnidadeTematicaConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/unidades-tematicas/{id}/contas', pathParams, {}, options), config)

export const queryUnidadesTematicasControllerListarUnidadeTematicaContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/unidades-tematicas/{id}/contas', pathParams, queryParams)
export const useQueryUnidadesTematicasControllerListarUnidadeTematicaContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-tematicas/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesTematicasControllerListarUnidadeTematicaContas.queryKey = '/api/v1/unidades-tematicas/{id}/contas'

export const queryUnidadesTematicasControllerObterUnidadeTematicaContaPorId = (pathParams, options) => queryFn(options)('/api/v1/unidades-tematicas/{id}/contas/{contaId}', pathParams)
export const useQueryUnidadesTematicasControllerObterUnidadeTematicaContaPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-tematicas/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryUnidadesTematicasControllerObterUnidadeTematicaContaPorId.queryKey = '/api/v1/unidades-tematicas/{id}/contas/{contaId}'

export const mutationUnidadesTematicasControllerAtualizarUnidadeTematicaConta = (pathParams, options) => mutationFn('put', '/api/v1/unidades-tematicas/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUnidadesTematicasControllerAtualizarUnidadeTematicaConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/unidades-tematicas/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryUnidadesTematicasControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/unidades-tematicas/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryUnidadesTematicasControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-tematicas/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesTematicasControllerListarContasDisponiveis.queryKey = '/api/v1/unidades-tematicas/{id}/contas-disponiveis'

export const mutationUnidadesTematicasControllerCadastrarUnidadeTematicaHabilidadeEnsinoFundamental = (pathParams, options) => mutationFn('post', '/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental', pathParams, {}, options)
export const useMutationUnidadesTematicasControllerCadastrarUnidadeTematicaHabilidadeEnsinoFundamental = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental', pathParams, {}, options), config)

export const queryUnidadesTematicasControllerListarUnidadeTematicaHabilidadesEnsinoFundamental = (pathParams, queryParams, options) => queryFn(options)('/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental', pathParams, queryParams)
export const useQueryUnidadesTematicasControllerListarUnidadeTematicaHabilidadesEnsinoFundamental = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUnidadesTematicasControllerListarUnidadeTematicaHabilidadesEnsinoFundamental.queryKey = '/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental'

export const queryUnidadesTematicasControllerObterUnidadeTematicaHabilidadeEnsinoFundamentalPorId = (pathParams, options) => queryFn(options)('/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams)
export const useQueryUnidadesTematicasControllerObterUnidadeTematicaHabilidadeEnsinoFundamentalPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.habilidadeEnsinoFundamentalId, ...config }
})
useQueryUnidadesTematicasControllerObterUnidadeTematicaHabilidadeEnsinoFundamentalPorId.queryKey = '/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}'

export const mutationUnidadesTematicasControllerDeletarUnidadeTematicaHabilidadeEnsinoFundamental = (pathParams, options) => mutationFn('delete', '/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams, {}, options)
export const useMutationUnidadesTematicasControllerDeletarUnidadeTematicaHabilidadeEnsinoFundamental = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/unidades-tematicas/{id}/habilidadesEnsinoFundamental/{habilidadeEnsinoFundamentalId}', pathParams, {}, options), config)

export const mutationUsuariosControllerLogar = (options) => mutationFn('post', '/api/v1/usuarios/login', {}, {}, options)
export const useMutationUsuariosControllerLogar = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/login', {}, {}, options), config)

export const queryUsuariosControllerEu = (options) => queryFn(options)('/api/v1/usuarios/eu')
export const useQueryUsuariosControllerEu = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerEu.queryKey = '/api/v1/usuarios/eu'

export const queryUsuariosControllerMinhasPermissoes = (options) => queryFn(options)('/api/v1/usuarios/eu/permissoes')
export const useQueryUsuariosControllerMinhasPermissoes = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/permissoes'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerMinhasPermissoes.queryKey = '/api/v1/usuarios/eu/permissoes'

export const queryUsuariosControllerObterContasUsuarioLogado = (queryParams, options) => queryFn(options)('/api/v1/usuarios/eu/contas', queryParams)
export const useQueryUsuariosControllerObterContasUsuarioLogado = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/contas', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterContasUsuarioLogado.queryKey = '/api/v1/usuarios/eu/contas'

export const queryUsuariosControllerObterMinhasNotificacoes = (queryParams, options) => queryFn(options)('/api/v1/usuarios/eu/notificacoes', queryParams)
export const useQueryUsuariosControllerObterMinhasNotificacoes = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/notificacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterMinhasNotificacoes.queryKey = '/api/v1/usuarios/eu/notificacoes'

export const mutationUsuariosControllerAtualizarStatusNotificacoes = (options) => mutationFn('put', '/api/v1/usuarios/eu/notificacoes', {}, {}, options)
export const useMutationUsuariosControllerAtualizarStatusNotificacoes = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/notificacoes', {}, {}, options), config)

export const mutationUsuariosControllerRemoverVisualizacaoMinhasNotificacoes = (options) => mutationFn('patch', '/api/v1/usuarios/eu/notificacoes/remover-visualizacao', {}, {}, options)
export const useMutationUsuariosControllerRemoverVisualizacaoMinhasNotificacoes = (config, options) => useMutation(mutationFn('patch', '/api/v1/usuarios/eu/notificacoes/remover-visualizacao', {}, {}, options), config)

export const queryUsuariosControllerObterNotificacoesChannel = (queryParams, options) => queryFn(options)('/api/v1/usuarios/eu/channel/notificacoes', queryParams)
export const useQueryUsuariosControllerObterNotificacoesChannel = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/channel/notificacoes', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterNotificacoesChannel.queryKey = '/api/v1/usuarios/eu/channel/notificacoes'

export const mutationUsuariosControllerDeletarNotificacoesChannel = (options) => mutationFn('delete', '/api/v1/usuarios/eu/channel/notificacoes', {}, {}, options)
export const useMutationUsuariosControllerDeletarNotificacoesChannel = (config, options) => useMutation(mutationFn('delete', '/api/v1/usuarios/eu/channel/notificacoes', {}, {}, options), config)

export const queryUsuariosControllerObterNotificacaoPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/eu/notificacoes/{id}', pathParams)
export const useQueryUsuariosControllerObterNotificacaoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/notificacoes/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterNotificacaoPorId.queryKey = '/api/v1/usuarios/eu/notificacoes/{id}'

export const mutationUsuariosControllerAtualizarNotificacaoUsuarioRecebido = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/recebido', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarNotificacaoUsuarioRecebido = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/recebido', pathParams, {}, options), config)

export const mutationUsuariosControllerAtualizarNotificacaoUsuarioLido = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/lido', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarNotificacaoUsuarioLido = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/lido', pathParams, {}, options), config)

export const mutationUsuariosControllerAtualizarNotificacaoUsuarioClicado = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/clicado', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarNotificacaoUsuarioClicado = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/notificacoes/{id}/clicado', pathParams, {}, options), config)

export const queryUsuariosControllerObterCaixaAtual = (options) => queryFn(options)('/api/v1/usuarios/eu/caixa-atual')
export const useQueryUsuariosControllerObterCaixaAtual = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/caixa-atual'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterCaixaAtual.queryKey = '/api/v1/usuarios/eu/caixa-atual'

export const mutationUsuariosControllerTrocarConta = (options) => mutationFn('put', '/api/v1/usuarios/eu/trocar-conta', {}, {}, options)
export const useMutationUsuariosControllerTrocarConta = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/trocar-conta', {}, {}, options), config)

export const queryUsuariosControllerObterTermoPendente = (options) => queryFn(options)('/api/v1/usuarios/eu/termos/pendente')
export const useQueryUsuariosControllerObterTermoPendente = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/termos/pendente'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterTermoPendente.queryKey = '/api/v1/usuarios/eu/termos/pendente'

export const queryUsuariosControllerObterTermoAceitoAtivoUsuarioLogado = (options) => queryFn(options)('/api/v1/usuarios/eu/termos/ativo')
export const useQueryUsuariosControllerObterTermoAceitoAtivoUsuarioLogado = (config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/termos/ativo'].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryUsuariosControllerObterTermoAceitoAtivoUsuarioLogado.queryKey = '/api/v1/usuarios/eu/termos/ativo'

export const mutationUsuariosControllerAceitarTermo = (options) => mutationFn('post', '/api/v1/usuarios/eu/termos/aceite', {}, {}, options)
export const useMutationUsuariosControllerAceitarTermo = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/eu/termos/aceite', {}, {}, options), config)

export const queryUsuariosControllerObterTermoPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/eu/termos/{termoAceiteLgpdId}', pathParams)
export const useQueryUsuariosControllerObterTermoPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/eu/termos/{termoAceiteLgpdId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.termoAceiteLgpdId, ...config }
})
useQueryUsuariosControllerObterTermoPorId.queryKey = '/api/v1/usuarios/eu/termos/{termoAceiteLgpdId}'

export const mutationUsuariosControllerRevogarTermo = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/eu/termos/revogacao/{id}', pathParams, {}, options)
export const useMutationUsuariosControllerRevogarTermo = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/eu/termos/revogacao/{id}', pathParams, {}, options), config)

export const queryUsuariosControllerObterTermosAceitosPorUsuarioId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/termos/{id}', pathParams, queryParams)
export const useQueryUsuariosControllerObterTermosAceitosPorUsuarioId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/termos/{id}', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterTermosAceitosPorUsuarioId.queryKey = '/api/v1/usuarios/termos/{id}'

export const queryUsuariosControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}', pathParams)
export const useQueryUsuariosControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterPorId.queryKey = '/api/v1/usuarios/{id}'

export const mutationUsuariosControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}', pathParams, {}, options), config)

export const mutationUsuariosControllerAtualizarContaAtual = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}/eu-conta-atual', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarContaAtual = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}/eu-conta-atual', pathParams, {}, options), config)

export const mutationUsuariosControllerEnviarRecuperacaoSenha = (options) => mutationFn('post', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options)
export const useMutationUsuariosControllerEnviarRecuperacaoSenha = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options), config)

export const mutationUsuariosControllerVerificarCodigoRecuperacaoSenha = (options) => mutationFn('put', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options)
export const useMutationUsuariosControllerVerificarCodigoRecuperacaoSenha = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options), config)

export const mutationUsuariosControllerConfirmarRecuperacaoSenha = (options) => mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options)
export const useMutationUsuariosControllerConfirmarRecuperacaoSenha = (config, options) => useMutation(mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao', {}, {}, options), config)

export const mutationUsuariosControllerConfirmarRecuperacaoSenhaPortal = (options) => mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerConfirmarRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('patch', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const mutationUsuariosControllerVerificarCodigoRecuperacaoSenhaPortal = (options) => mutationFn('put', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerVerificarCodigoRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const mutationUsuariosControllerEnviarRecuperacaoSenhaPortal = (options) => mutationFn('post', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options)
export const useMutationUsuariosControllerEnviarRecuperacaoSenhaPortal = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/senhas/recuperacao-portal', {}, {}, options), config)

export const mutationUsuariosControllerEnviarCriacaoSenhaPortal = (options) => mutationFn('post', '/api/v1/usuarios/senhas/criacao-portal', {}, {}, options)
export const useMutationUsuariosControllerEnviarCriacaoSenhaPortal = (config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/senhas/criacao-portal', {}, {}, options), config)

export const queryUsuariosControllerObterContasUsuario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas', pathParams, queryParams)
export const useQueryUsuariosControllerObterContasUsuario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterContasUsuario.queryKey = '/api/v1/usuarios/{id}/contas'

export const mutationUsuariosControllerCadastrarUsuarioConta = (pathParams, options) => mutationFn('post', '/api/v1/usuarios/{id}/contas', pathParams, {}, options)
export const useMutationUsuariosControllerCadastrarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/usuarios/{id}/contas', pathParams, {}, options), config)

export const queryUsuariosControllerObterContasDisponiveisUsuario = (pathParams, queryParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryUsuariosControllerObterContasDisponiveisUsuario = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryUsuariosControllerObterContasDisponiveisUsuario.queryKey = '/api/v1/usuarios/{id}/contas-disponiveis'

export const queryUsuariosControllerObterUsuarioContaPorUsuarioId = (pathParams, options) => queryFn(options)('/api/v1/usuarios/{id}/contas/{contaId}', pathParams)
export const useQueryUsuariosControllerObterUsuarioContaPorUsuarioId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/usuarios/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryUsuariosControllerObterUsuarioContaPorUsuarioId.queryKey = '/api/v1/usuarios/{id}/contas/{contaId}'

export const mutationUsuariosControllerAtualizarUsuarioConta = (pathParams, options) => mutationFn('put', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsuariosControllerAtualizarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const mutationUsuariosControllerDeletarUsuarioConta = (pathParams, options) => mutationFn('delete', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationUsuariosControllerDeletarUsuarioConta = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/usuarios/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryWhastappTemplatesControllerListar = (queryParams, options) => queryFn(options)('/api/v1/whatsapp-templates', queryParams)
export const useQueryWhastappTemplatesControllerListar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/whatsapp-templates', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryWhastappTemplatesControllerListar.queryKey = '/api/v1/whatsapp-templates'

export const mutationWhastappTemplatesControllerCadastrar = (options) => mutationFn('post', '/api/v1/whatsapp-templates', {}, {}, options)
export const useMutationWhastappTemplatesControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/whatsapp-templates', {}, {}, options), config)

export const mutationWhastappTemplatesControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/whatsapp-templates/{id}', pathParams, {}, options)
export const useMutationWhastappTemplatesControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/whatsapp-templates/{id}', pathParams, {}, options), config)

export const mutationWhastappTemplatesControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/whatsapp-templates/{id}', pathParams, {}, options)
export const useMutationWhastappTemplatesControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/whatsapp-templates/{id}', pathParams, {}, options), config)

export const queryWhastappTemplatesControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/whatsapp-templates/{id}', pathParams)
export const useQueryWhastappTemplatesControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/whatsapp-templates/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWhastappTemplatesControllerObterPorId.queryKey = '/api/v1/whatsapp-templates/{id}'

export const queryWorkFlowsControllerBuscar = (queryParams, options) => queryFn(options)('/api/v1/work-flows', queryParams)
export const useQueryWorkFlowsControllerBuscar = (queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows', queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: true, ...config }
})
useQueryWorkFlowsControllerBuscar.queryKey = '/api/v1/work-flows'

export const mutationWorkFlowsControllerCadastrar = (options) => mutationFn('post', '/api/v1/work-flows', {}, {}, options)
export const useMutationWorkFlowsControllerCadastrar = (config, options) => useMutation(mutationFn('post', '/api/v1/work-flows', {}, {}, options), config)

export const queryWorkFlowsControllerObterCrudPorId = (pathParams, options) => queryFn(options)('/api/v1/work-flows/{id}/crud', pathParams)
export const useQueryWorkFlowsControllerObterCrudPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/crud', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerObterCrudPorId.queryKey = '/api/v1/work-flows/{id}/crud'

export const queryWorkFlowsControllerObterPorId = (pathParams, options) => queryFn(options)('/api/v1/work-flows/{id}', pathParams)
export const useQueryWorkFlowsControllerObterPorId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerObterPorId.queryKey = '/api/v1/work-flows/{id}'

export const mutationWorkFlowsControllerAtualizar = (pathParams, options) => mutationFn('put', '/api/v1/work-flows/{id}', pathParams, {}, options)
export const useMutationWorkFlowsControllerAtualizar = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/work-flows/{id}', pathParams, {}, options), config)

export const mutationWorkFlowsControllerDeletar = (pathParams, options) => mutationFn('delete', '/api/v1/work-flows/{id}', pathParams, {}, options)
export const useMutationWorkFlowsControllerDeletar = (pathParams, config, options) => useMutation(mutationFn('delete', '/api/v1/work-flows/{id}', pathParams, {}, options), config)

export const queryWorkFlowsControllerListarFiltrosPorId = (pathParams, queryParams, options) => queryFn(options)('/api/v1/work-flows/{id}/filtros', pathParams, queryParams)
export const useQueryWorkFlowsControllerListarFiltrosPorId = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/filtros', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerListarFiltrosPorId.queryKey = '/api/v1/work-flows/{id}/filtros'

export const mutationWorkFlowsControllerCadastrarWorkFlowConta = (pathParams, options) => mutationFn('post', '/api/v1/work-flows/{id}/contas', pathParams, {}, options)
export const useMutationWorkFlowsControllerCadastrarWorkFlowConta = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/work-flows/{id}/contas', pathParams, {}, options), config)

export const queryWorkFlowsControllerListarWorkFlowContas = (pathParams, queryParams, options) => queryFn(options)('/api/v1/work-flows/{id}/contas', pathParams, queryParams)
export const useQueryWorkFlowsControllerListarWorkFlowContas = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/contas', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerListarWorkFlowContas.queryKey = '/api/v1/work-flows/{id}/contas'

export const queryWorkFlowsControllerObterWorkFlowContaPorWorkFlowIdContaId = (pathParams, options) => queryFn(options)('/api/v1/work-flows/{id}/contas/{contaId}', pathParams)
export const useQueryWorkFlowsControllerObterWorkFlowContaPorWorkFlowIdContaId = (pathParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/contas/{contaId}', pathParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id && pathParams.contaId, ...config }
})
useQueryWorkFlowsControllerObterWorkFlowContaPorWorkFlowIdContaId.queryKey = '/api/v1/work-flows/{id}/contas/{contaId}'

export const mutationWorkFlowsControllerAtualizarWorkFlowConta = (pathParams, options) => mutationFn('put', '/api/v1/work-flows/{id}/contas/{contaId}', pathParams, {}, options)
export const useMutationWorkFlowsControllerAtualizarWorkFlowConta = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/work-flows/{id}/contas/{contaId}', pathParams, {}, options), config)

export const queryWorkFlowsControllerListarContasDisponiveis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/work-flows/{id}/contas-disponiveis', pathParams, queryParams)
export const useQueryWorkFlowsControllerListarContasDisponiveis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/contas-disponiveis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerListarContasDisponiveis.queryKey = '/api/v1/work-flows/{id}/contas-disponiveis'

export const queryWorkFlowsControllerBuscarFasesFunis = (pathParams, queryParams, options) => queryFn(options)('/api/v1/work-flows/{id}/fases-funis', pathParams, queryParams)
export const useQueryWorkFlowsControllerBuscarFasesFunis = (pathParams = null, queryParams = null, config, options) => useQuery({
  queryKey: ['/api/v1/work-flows/{id}/fases-funis', pathParams, queryParams].filter(item => item),
  queryFn: queryFn(options),
  config : { enabled: pathParams && pathParams.id, ...config }
})
useQueryWorkFlowsControllerBuscarFasesFunis.queryKey = '/api/v1/work-flows/{id}/fases-funis'

export const mutationWorkFlowsControllerCadastrarFaseFunil = (pathParams, options) => mutationFn('post', '/api/v1/work-flows/{id}/fases-funis', pathParams, {}, options)
export const useMutationWorkFlowsControllerCadastrarFaseFunil = (pathParams, config, options) => useMutation(mutationFn('post', '/api/v1/work-flows/{id}/fases-funis', pathParams, {}, options), config)

export const mutationWorkFlowsControllerAtualizarWorkFlowFaseFunilOrdem = (pathParams, options) => mutationFn('put', '/api/v1/work-flows/{id}/fases-funis-ordens', pathParams, {}, options)
export const useMutationWorkFlowsControllerAtualizarWorkFlowFaseFunilOrdem = (pathParams, config, options) => useMutation(mutationFn('put', '/api/v1/work-flows/{id}/fases-funis-ordens', pathParams, {}, options), config)

